/* eslint-disable max-len */
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Dimension: number;
  HexColor: string;
  JSON: unknown;
  Quality: number;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abExperiment) */
export type AbExperiment = Entry & _Node & {
  __typename?: 'AbExperiment';
  _id: Scalars['ID'];
  abVariantsCollection?: Maybe<AbExperimentAbVariantsCollection>;
  analyticsId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<AbExperimentLinkingCollections>;
  reference?: Maybe<AbExperimentReference>;
  seed?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abExperiment) */
export type AbExperimentAbVariantsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AbExperimentAbVariantsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AbVariantFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abExperiment) */
export type AbExperimentAnalyticsIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abExperiment) */
export type AbExperimentContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abExperiment) */
export type AbExperimentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abExperiment) */
export type AbExperimentReferenceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abExperiment) */
export type AbExperimentSeedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AbExperimentAbVariantsCollection = {
  __typename?: 'AbExperimentAbVariantsCollection';
  items: Array<Maybe<AbVariant>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AbExperimentAbVariantsCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

export type AbExperimentCollection = {
  __typename?: 'AbExperimentCollection';
  items: Array<Maybe<AbExperiment>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AbExperimentFilter = {
  AND?: InputMaybe<Array<InputMaybe<AbExperimentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AbExperimentFilter>>>;
  abVariants?: InputMaybe<CfAbVariantNestedFilter>;
  abVariantsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  reference_exists?: InputMaybe<Scalars['Boolean']>;
  seed?: InputMaybe<Scalars['String']>;
  seed_contains?: InputMaybe<Scalars['String']>;
  seed_exists?: InputMaybe<Scalars['Boolean']>;
  seed_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seed_not?: InputMaybe<Scalars['String']>;
  seed_not_contains?: InputMaybe<Scalars['String']>;
  seed_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type AbExperimentLinkingCollections = {
  __typename?: 'AbExperimentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  experimentArmCollection?: Maybe<ExperimentArmCollection>;
};


export type AbExperimentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AbExperimentLinkingCollectionsExperimentArmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AbExperimentLinkingCollectionsExperimentArmCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AbExperimentLinkingCollectionsExperimentArmCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum AbExperimentOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type AbExperimentReference = Banner | Block | BlockHero | Content | Page;

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abVariant) */
export type AbVariant = Entry & _Node & {
  __typename?: 'AbVariant';
  _id: Scalars['ID'];
  analyticsId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<AbVariantLinkingCollections>;
  replacement?: Maybe<AbVariantReplacement>;
  sys: Sys;
  trafficEndRange?: Maybe<Scalars['Int']>;
  trafficStartRange?: Maybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abVariant) */
export type AbVariantAnalyticsIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abVariant) */
export type AbVariantContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abVariant) */
export type AbVariantLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abVariant) */
export type AbVariantReplacementArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abVariant) */
export type AbVariantTrafficEndRangeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/abVariant) */
export type AbVariantTrafficStartRangeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AbVariantCollection = {
  __typename?: 'AbVariantCollection';
  items: Array<Maybe<AbVariant>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AbVariantFilter = {
  AND?: InputMaybe<Array<InputMaybe<AbVariantFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AbVariantFilter>>>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  replacement_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  trafficEndRange?: InputMaybe<Scalars['Int']>;
  trafficEndRange_exists?: InputMaybe<Scalars['Boolean']>;
  trafficEndRange_gt?: InputMaybe<Scalars['Int']>;
  trafficEndRange_gte?: InputMaybe<Scalars['Int']>;
  trafficEndRange_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficEndRange_lt?: InputMaybe<Scalars['Int']>;
  trafficEndRange_lte?: InputMaybe<Scalars['Int']>;
  trafficEndRange_not?: InputMaybe<Scalars['Int']>;
  trafficEndRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficStartRange?: InputMaybe<Scalars['Int']>;
  trafficStartRange_exists?: InputMaybe<Scalars['Boolean']>;
  trafficStartRange_gt?: InputMaybe<Scalars['Int']>;
  trafficStartRange_gte?: InputMaybe<Scalars['Int']>;
  trafficStartRange_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficStartRange_lt?: InputMaybe<Scalars['Int']>;
  trafficStartRange_lte?: InputMaybe<Scalars['Int']>;
  trafficStartRange_not?: InputMaybe<Scalars['Int']>;
  trafficStartRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AbVariantLinkingCollections = {
  __typename?: 'AbVariantLinkingCollections';
  abExperimentCollection?: Maybe<AbExperimentCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type AbVariantLinkingCollectionsAbExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AbVariantLinkingCollectionsAbExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AbVariantLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AbVariantLinkingCollectionsAbExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum AbVariantOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

export type AbVariantReplacement = Banner | Block | BlockHero | Content | Page;

/** This model represents the accordion [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordion) */
export type Accordion = Entry & _Node & {
  __typename?: 'Accordion';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  itemsCollection?: Maybe<AccordionItemsCollection>;
  linkedFrom?: Maybe<AccordionLinkingCollections>;
  multipleOpen?: Maybe<Scalars['Boolean']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** This model represents the accordion [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordion) */
export type AccordionContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** This model represents the accordion [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordion) */
export type AccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccordionItemFilter>;
};


/** This model represents the accordion [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordion) */
export type AccordionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** This model represents the accordion [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordion) */
export type AccordionMultipleOpenArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** This model represents the accordion [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordion) */
export type AccordionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AccordionCollection = {
  __typename?: 'AccordionCollection';
  items: Array<Maybe<Accordion>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AccordionFilter = {
  AND?: InputMaybe<Array<InputMaybe<AccordionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AccordionFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  items?: InputMaybe<CfAccordionItemNestedFilter>;
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  multipleOpen?: InputMaybe<Scalars['Boolean']>;
  multipleOpen_exists?: InputMaybe<Scalars['Boolean']>;
  multipleOpen_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordionItem) */
export type AccordionItem = Entry & _Node & {
  __typename?: 'AccordionItem';
  _id: Scalars['ID'];
  body?: Maybe<AccordionItemBody>;
  callsToActionCollection?: Maybe<AccordionItemCallsToActionCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<AccordionItemLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordionItem) */
export type AccordionItemBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordionItem) */
export type AccordionItemCallsToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionItemCallsToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CallToActionFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordionItem) */
export type AccordionItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordionItem) */
export type AccordionItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/accordionItem) */
export type AccordionItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AccordionItemBody = {
  __typename?: 'AccordionItemBody';
  json: Scalars['JSON'];
  links?: AccordionItemBodyLinks;
};

export type AccordionItemBodyAssets = {
  __typename?: 'AccordionItemBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AccordionItemBodyEntries = {
  __typename?: 'AccordionItemBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AccordionItemBodyLinks = {
  __typename?: 'AccordionItemBodyLinks';
  assets: AccordionItemBodyAssets;
  entries: AccordionItemBodyEntries;
  resources: AccordionItemBodyResources;
};

export type AccordionItemBodyResources = {
  __typename?: 'AccordionItemBodyResources';
  block: Array<AccordionItemBodyResourcesBlock>;
  hyperlink: Array<AccordionItemBodyResourcesHyperlink>;
  inline: Array<AccordionItemBodyResourcesInline>;
};

export type AccordionItemBodyResourcesBlock = ResourceLink & {
  __typename?: 'AccordionItemBodyResourcesBlock';
  sys: ResourceSys;
};

export type AccordionItemBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'AccordionItemBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type AccordionItemBodyResourcesInline = ResourceLink & {
  __typename?: 'AccordionItemBodyResourcesInline';
  sys: ResourceSys;
};

export type AccordionItemCallsToActionCollection = {
  __typename?: 'AccordionItemCallsToActionCollection';
  items: Array<Maybe<CallToAction>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AccordionItemCallsToActionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SetTypeAsc = 'setType_ASC',
  SetTypeDesc = 'setType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type AccordionItemCollection = {
  __typename?: 'AccordionItemCollection';
  items: Array<Maybe<AccordionItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AccordionItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<AccordionItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AccordionItemFilter>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  callsToAction?: InputMaybe<CfCallToActionNestedFilter>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AccordionItemLinkingCollections = {
  __typename?: 'AccordionItemLinkingCollections';
  accordionCollection?: Maybe<AccordionCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type AccordionItemLinkingCollectionsAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionItemLinkingCollectionsAccordionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AccordionItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AccordionItemLinkingCollectionsAccordionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MultipleOpenAsc = 'multipleOpen_ASC',
  MultipleOpenDesc = 'multipleOpen_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum AccordionItemOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type AccordionItemsCollection = {
  __typename?: 'AccordionItemsCollection';
  items: Array<Maybe<AccordionItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AccordionItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type AccordionLinkingCollections = {
  __typename?: 'AccordionLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type AccordionLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AccordionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AccordionLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum AccordionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MultipleOpenAsc = 'multipleOpen_ASC',
  MultipleOpenDesc = 'multipleOpen_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Create an Analytics entry to track event on Google Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/analytics) */
export type Analytics = Entry & _Node & {
  __typename?: 'Analytics';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<AnalyticsLinkingCollections>;
  sys: Sys;
};


/** Create an Analytics entry to track event on Google Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/analytics) */
export type AnalyticsContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Create an Analytics entry to track event on Google Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/analytics) */
export type AnalyticsLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Create an Analytics entry to track event on Google Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/analytics) */
export type AnalyticsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnalyticsCollection = {
  __typename?: 'AnalyticsCollection';
  items: Array<Maybe<Analytics>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnalyticsFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnalyticsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AnalyticsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type AnalyticsLinkingCollections = {
  __typename?: 'AnalyticsLinkingCollections';
  callToActionCollection?: Maybe<CallToActionCollection>;
  chartToggleItemCollection?: Maybe<ChartToggleItemCollection>;
  entryCollection?: Maybe<EntryCollection>;
  featuredHeaderMenuItemCollection?: Maybe<FeaturedHeaderMenuItemCollection>;
  footerCookiesSettingsLinkCollection?: Maybe<FooterCookiesSettingsLinkCollection>;
  footerGroupCollection?: Maybe<FooterGroupCollection>;
  footerItemCollection?: Maybe<FooterItemCollection>;
  formCollection?: Maybe<FormCollection>;
  headerMenuItemCollection?: Maybe<HeaderMenuItemCollection>;
  headerMenuItemGroupCollection?: Maybe<HeaderMenuItemGroupCollection>;
  mosaicTileCollection?: Maybe<MosaicTileCollection>;
  navigatorCollection?: Maybe<NavigatorCollection>;
  navigatorItemCollection?: Maybe<NavigatorItemCollection>;
  tabItemCollection?: Maybe<TabItemCollection>;
  tileCollection?: Maybe<TileCollection>;
};


export type AnalyticsLinkingCollectionsCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsCallToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsChartToggleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsChartToggleItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsFeaturedHeaderMenuItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsFeaturedHeaderMenuItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsFooterCookiesSettingsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsFooterCookiesSettingsLinkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsFooterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsFooterGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsFooterItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsFooterItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsFormCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsHeaderMenuItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsHeaderMenuItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsHeaderMenuItemGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsHeaderMenuItemGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsMosaicTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsMosaicTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsNavigatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsNavigatorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsNavigatorItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsNavigatorItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsTabItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsTabItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsLinkingCollectionsTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsLinkingCollectionsTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AnalyticsLinkingCollectionsCallToActionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SetTypeAsc = 'setType_ASC',
  SetTypeDesc = 'setType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum AnalyticsLinkingCollectionsChartToggleItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum AnalyticsLinkingCollectionsFeaturedHeaderMenuItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CtaLabelAsc = 'ctaLabel_ASC',
  CtaLabelDesc = 'ctaLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum AnalyticsLinkingCollectionsFooterCookiesSettingsLinkCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum AnalyticsLinkingCollectionsFooterGroupCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  GroupKeyAsc = 'groupKey_ASC',
  GroupKeyDesc = 'groupKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum AnalyticsLinkingCollectionsFooterItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum AnalyticsLinkingCollectionsFormCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EndpointAsc = 'endpoint_ASC',
  EndpointDesc = 'endpoint_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PersistFieldEditsAsc = 'persistFieldEdits_ASC',
  PersistFieldEditsDesc = 'persistFieldEdits_DESC',
  PrepopulatePerQueryParamsAsc = 'prepopulatePerQueryParams_ASC',
  PrepopulatePerQueryParamsDesc = 'prepopulatePerQueryParams_DESC',
  RedirectTimeoutAsc = 'redirectTimeout_ASC',
  RedirectTimeoutDesc = 'redirectTimeout_DESC',
  RedirectUrlAsc = 'redirectUrl_ASC',
  RedirectUrlDesc = 'redirectUrl_DESC',
  ResponseTypeAsc = 'responseType_ASC',
  ResponseTypeDesc = 'responseType_DESC',
  SubmitSuccessTextAsc = 'submitSuccessText_ASC',
  SubmitSuccessTextDesc = 'submitSuccessText_DESC',
  SubmitTextAsc = 'submitText_ASC',
  SubmitTextDesc = 'submitText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum AnalyticsLinkingCollectionsHeaderMenuItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum AnalyticsLinkingCollectionsHeaderMenuItemGroupCollectionOrder {
  Column1LabelAsc = 'column1Label_ASC',
  Column1LabelDesc = 'column1Label_DESC',
  Column2LabelAsc = 'column2Label_ASC',
  Column2LabelDesc = 'column2Label_DESC',
  Column3LabelAsc = 'column3Label_ASC',
  Column3LabelDesc = 'column3Label_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum AnalyticsLinkingCollectionsMosaicTileCollectionOrder {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  HighlightAsc = 'highlight_ASC',
  HighlightDesc = 'highlight_DESC',
  MobileColumnsAsc = 'mobileColumns_ASC',
  MobileColumnsDesc = 'mobileColumns_DESC',
  MobileRowsAsc = 'mobileRows_ASC',
  MobileRowsDesc = 'mobileRows_DESC',
  RowsAsc = 'rows_ASC',
  RowsDesc = 'rows_DESC',
  ShowOverlayAsc = 'showOverlay_ASC',
  ShowOverlayDesc = 'showOverlay_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum AnalyticsLinkingCollectionsNavigatorCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum AnalyticsLinkingCollectionsNavigatorItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum AnalyticsLinkingCollectionsTabItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum AnalyticsLinkingCollectionsTileCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  ImageFitAsc = 'imageFit_ASC',
  ImageFitDesc = 'imageFit_DESC',
  ImageSizeAsc = 'imageSize_ASC',
  ImageSizeDesc = 'imageSize_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum AnalyticsOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Accordion that auto cycles through content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordion) */
export type AnimatedAccordion = Entry & _Node & {
  __typename?: 'AnimatedAccordion';
  _id: Scalars['ID'];
  accordionContentsCollection?: Maybe<AnimatedAccordionAccordionContentsCollection>;
  autoPlaySpeed?: Maybe<Scalars['Int']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<AnimatedAccordionLinkingCollections>;
  mediaDirection?: Maybe<Scalars['String']>;
  sys: Sys;
  textAlignmentMobile?: Maybe<Scalars['String']>;
};


/** Accordion that auto cycles through content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordion) */
export type AnimatedAccordionAccordionContentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnimatedAccordionAccordionContentsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnimatedAccordionContentFilter>;
};


/** Accordion that auto cycles through content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordion) */
export type AnimatedAccordionAutoPlaySpeedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Accordion that auto cycles through content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordion) */
export type AnimatedAccordionContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Accordion that auto cycles through content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordion) */
export type AnimatedAccordionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Accordion that auto cycles through content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordion) */
export type AnimatedAccordionMediaDirectionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Accordion that auto cycles through content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordion) */
export type AnimatedAccordionTextAlignmentMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AnimatedAccordionAccordionContentsCollection = {
  __typename?: 'AnimatedAccordionAccordionContentsCollection';
  items: Array<Maybe<AnimatedAccordionContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AnimatedAccordionAccordionContentsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type AnimatedAccordionCollection = {
  __typename?: 'AnimatedAccordionCollection';
  items: Array<Maybe<AnimatedAccordion>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordionContent) */
export type AnimatedAccordionContent = Entry & _Node & {
  __typename?: 'AnimatedAccordionContent';
  _id: Scalars['ID'];
  body?: Maybe<AnimatedAccordionContentBody>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<AnimatedAccordionContentLinkingCollections>;
  media?: Maybe<Asset>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordionContent) */
export type AnimatedAccordionContentBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordionContent) */
export type AnimatedAccordionContentContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordionContent) */
export type AnimatedAccordionContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordionContent) */
export type AnimatedAccordionContentMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animatedAccordionContent) */
export type AnimatedAccordionContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AnimatedAccordionContentBody = {
  __typename?: 'AnimatedAccordionContentBody';
  json: Scalars['JSON'];
  links?: AnimatedAccordionContentBodyLinks;
};

export type AnimatedAccordionContentBodyAssets = {
  __typename?: 'AnimatedAccordionContentBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AnimatedAccordionContentBodyEntries = {
  __typename?: 'AnimatedAccordionContentBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AnimatedAccordionContentBodyLinks = {
  __typename?: 'AnimatedAccordionContentBodyLinks';
  assets: AnimatedAccordionContentBodyAssets;
  entries: AnimatedAccordionContentBodyEntries;
  resources: AnimatedAccordionContentBodyResources;
};

export type AnimatedAccordionContentBodyResources = {
  __typename?: 'AnimatedAccordionContentBodyResources';
  block: Array<AnimatedAccordionContentBodyResourcesBlock>;
  hyperlink: Array<AnimatedAccordionContentBodyResourcesHyperlink>;
  inline: Array<AnimatedAccordionContentBodyResourcesInline>;
};

export type AnimatedAccordionContentBodyResourcesBlock = ResourceLink & {
  __typename?: 'AnimatedAccordionContentBodyResourcesBlock';
  sys: ResourceSys;
};

export type AnimatedAccordionContentBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'AnimatedAccordionContentBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type AnimatedAccordionContentBodyResourcesInline = ResourceLink & {
  __typename?: 'AnimatedAccordionContentBodyResourcesInline';
  sys: ResourceSys;
};

export type AnimatedAccordionContentCollection = {
  __typename?: 'AnimatedAccordionContentCollection';
  items: Array<Maybe<AnimatedAccordionContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnimatedAccordionContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnimatedAccordionContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AnimatedAccordionContentFilter>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnimatedAccordionContentLinkingCollections = {
  __typename?: 'AnimatedAccordionContentLinkingCollections';
  animatedAccordionCollection?: Maybe<AnimatedAccordionCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type AnimatedAccordionContentLinkingCollectionsAnimatedAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnimatedAccordionContentLinkingCollectionsAnimatedAccordionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnimatedAccordionContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AnimatedAccordionContentLinkingCollectionsAnimatedAccordionCollectionOrder {
  AutoPlaySpeedAsc = 'autoPlaySpeed_ASC',
  AutoPlaySpeedDesc = 'autoPlaySpeed_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignmentMobileAsc = 'textAlignmentMobile_ASC',
  TextAlignmentMobileDesc = 'textAlignmentMobile_DESC'
}

export enum AnimatedAccordionContentOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type AnimatedAccordionFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnimatedAccordionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AnimatedAccordionFilter>>>;
  accordionContents?: InputMaybe<CfAnimatedAccordionContentNestedFilter>;
  accordionContentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  autoPlaySpeed?: InputMaybe<Scalars['Int']>;
  autoPlaySpeed_exists?: InputMaybe<Scalars['Boolean']>;
  autoPlaySpeed_gt?: InputMaybe<Scalars['Int']>;
  autoPlaySpeed_gte?: InputMaybe<Scalars['Int']>;
  autoPlaySpeed_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  autoPlaySpeed_lt?: InputMaybe<Scalars['Int']>;
  autoPlaySpeed_lte?: InputMaybe<Scalars['Int']>;
  autoPlaySpeed_not?: InputMaybe<Scalars['Int']>;
  autoPlaySpeed_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mediaDirection?: InputMaybe<Scalars['String']>;
  mediaDirection_contains?: InputMaybe<Scalars['String']>;
  mediaDirection_exists?: InputMaybe<Scalars['Boolean']>;
  mediaDirection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaDirection_not?: InputMaybe<Scalars['String']>;
  mediaDirection_not_contains?: InputMaybe<Scalars['String']>;
  mediaDirection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  textAlignmentMobile?: InputMaybe<Scalars['String']>;
  textAlignmentMobile_contains?: InputMaybe<Scalars['String']>;
  textAlignmentMobile_exists?: InputMaybe<Scalars['Boolean']>;
  textAlignmentMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlignmentMobile_not?: InputMaybe<Scalars['String']>;
  textAlignmentMobile_not_contains?: InputMaybe<Scalars['String']>;
  textAlignmentMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnimatedAccordionLinkingCollections = {
  __typename?: 'AnimatedAccordionLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type AnimatedAccordionLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnimatedAccordionLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnimatedAccordionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AnimatedAccordionLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum AnimatedAccordionOrder {
  AutoPlaySpeedAsc = 'autoPlaySpeed_ASC',
  AutoPlaySpeedDesc = 'autoPlaySpeed_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignmentMobileAsc = 'textAlignmentMobile_ASC',
  TextAlignmentMobileDesc = 'textAlignmentMobile_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframes = Entry & _Node & {
  __typename?: 'AnimationKeyframes';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  delay?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  iterationCount?: Maybe<Scalars['String']>;
  keyframes?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<AnimationKeyframesLinkingCollections>;
  sys: Sys;
  timingFunction?: Maybe<Scalars['String']>;
  transformOrigin?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesDelayArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesDirectionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesDurationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesIterationCountArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesKeyframesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesTimingFunctionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/animationKeyframes) */
export type AnimationKeyframesTransformOriginArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AnimationKeyframesCollection = {
  __typename?: 'AnimationKeyframesCollection';
  items: Array<Maybe<AnimationKeyframes>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnimationKeyframesFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnimationKeyframesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AnimationKeyframesFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  delay?: InputMaybe<Scalars['String']>;
  delay_contains?: InputMaybe<Scalars['String']>;
  delay_exists?: InputMaybe<Scalars['Boolean']>;
  delay_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  delay_not?: InputMaybe<Scalars['String']>;
  delay_not_contains?: InputMaybe<Scalars['String']>;
  delay_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  direction?: InputMaybe<Scalars['String']>;
  direction_contains?: InputMaybe<Scalars['String']>;
  direction_exists?: InputMaybe<Scalars['Boolean']>;
  direction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  direction_not?: InputMaybe<Scalars['String']>;
  direction_not_contains?: InputMaybe<Scalars['String']>;
  direction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  duration?: InputMaybe<Scalars['String']>;
  duration_contains?: InputMaybe<Scalars['String']>;
  duration_exists?: InputMaybe<Scalars['Boolean']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  duration_not?: InputMaybe<Scalars['String']>;
  duration_not_contains?: InputMaybe<Scalars['String']>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  iterationCount?: InputMaybe<Scalars['String']>;
  iterationCount_contains?: InputMaybe<Scalars['String']>;
  iterationCount_exists?: InputMaybe<Scalars['Boolean']>;
  iterationCount_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  iterationCount_not?: InputMaybe<Scalars['String']>;
  iterationCount_not_contains?: InputMaybe<Scalars['String']>;
  iterationCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keyframes?: InputMaybe<Scalars['String']>;
  keyframes_contains?: InputMaybe<Scalars['String']>;
  keyframes_exists?: InputMaybe<Scalars['Boolean']>;
  keyframes_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keyframes_not?: InputMaybe<Scalars['String']>;
  keyframes_not_contains?: InputMaybe<Scalars['String']>;
  keyframes_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  timingFunction?: InputMaybe<Scalars['String']>;
  timingFunction_contains?: InputMaybe<Scalars['String']>;
  timingFunction_exists?: InputMaybe<Scalars['Boolean']>;
  timingFunction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  timingFunction_not?: InputMaybe<Scalars['String']>;
  timingFunction_not_contains?: InputMaybe<Scalars['String']>;
  timingFunction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transformOrigin?: InputMaybe<Scalars['String']>;
  transformOrigin_contains?: InputMaybe<Scalars['String']>;
  transformOrigin_exists?: InputMaybe<Scalars['Boolean']>;
  transformOrigin_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transformOrigin_not?: InputMaybe<Scalars['String']>;
  transformOrigin_not_contains?: InputMaybe<Scalars['String']>;
  transformOrigin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnimationKeyframesLinkingCollections = {
  __typename?: 'AnimationKeyframesLinkingCollections';
  contentAnimationCollection?: Maybe<ContentAnimationCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type AnimationKeyframesLinkingCollectionsContentAnimationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnimationKeyframesLinkingCollectionsContentAnimationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnimationKeyframesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AnimationKeyframesLinkingCollectionsContentAnimationCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TargetAsc = 'target_ASC',
  TargetDesc = 'target_DESC'
}

export enum AnimationKeyframesOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DelayAsc = 'delay_ASC',
  DelayDesc = 'delay_DESC',
  DirectionAsc = 'direction_ASC',
  DirectionDesc = 'direction_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  IterationCountAsc = 'iterationCount_ASC',
  IterationCountDesc = 'iterationCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimingFunctionAsc = 'timingFunction_ASC',
  TimingFunctionDesc = 'timingFunction_DESC',
  TransformOriginAsc = 'transformOrigin_ASC',
  TransformOriginDesc = 'transformOrigin_DESC'
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  contentType?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
  transform?: InputMaybe<ImageTransformOptions>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']>;
  contentType_contains?: InputMaybe<Scalars['String']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentType_not?: InputMaybe<Scalars['String']>;
  contentType_not_contains?: InputMaybe<Scalars['String']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName?: InputMaybe<Scalars['String']>;
  fileName_contains?: InputMaybe<Scalars['String']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName_not?: InputMaybe<Scalars['String']>;
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size?: InputMaybe<Scalars['Int']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_gt?: InputMaybe<Scalars['Int']>;
  size_gte?: InputMaybe<Scalars['Int']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt?: InputMaybe<Scalars['Int']>;
  size_lte?: InputMaybe<Scalars['Int']>;
  size_not?: InputMaybe<Scalars['Int']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  animatedAccordionContentCollection?: Maybe<AnimatedAccordionContentCollection>;
  blogSchemaCollection?: Maybe<BlogSchemaCollection>;
  buttonCollection?: Maybe<ButtonCollection>;
  downloadableAssetCollection?: Maybe<DownloadableAssetCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faviconCollection?: Maybe<FaviconCollection>;
  iconCollection?: Maybe<IconCollection>;
  imageButtonCollection?: Maybe<ImageButtonCollection>;
  imageCollection?: Maybe<ImageCollection>;
  metasCollection?: Maybe<MetasCollection>;
  mosaicTileCollection?: Maybe<MosaicTileCollection>;
  organizationSchemaCollection?: Maybe<OrganizationSchemaCollection>;
  pageAssetCollection?: Maybe<PageAssetCollection>;
  scrollAnimatedVideoCollection?: Maybe<ScrollAnimatedVideoCollection>;
  templateCollection?: Maybe<TemplateCollection>;
  videoCollection?: Maybe<VideoCollection>;
};


export type AssetLinkingCollectionsAnimatedAccordionContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsBlogSchemaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsDownloadableAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsFaviconCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsIconCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsImageButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsMetasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsMosaicTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsOrganizationSchemaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsPageAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsScrollAnimatedVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

/** Name and title of author of a blog post. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/author) */
export type Author = Entry & _Node & {
  __typename?: 'Author';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<AuthorLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** Name and title of author of a blog post. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/author) */
export type AuthorContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Name and title of author of a blog post. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/author) */
export type AuthorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Name and title of author of a blog post. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/author) */
export type AuthorNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Name and title of author of a blog post. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/author) */
export type AuthorPositionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Name and title of author of a blog post. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/author) */
export type AuthorSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AuthorCollection = {
  __typename?: 'AuthorCollection';
  items: Array<Maybe<Author>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AuthorFilter = {
  AND?: InputMaybe<Array<InputMaybe<AuthorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AuthorFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position?: InputMaybe<Scalars['String']>;
  position_contains?: InputMaybe<Scalars['String']>;
  position_exists?: InputMaybe<Scalars['Boolean']>;
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position_not?: InputMaybe<Scalars['String']>;
  position_not_contains?: InputMaybe<Scalars['String']>;
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type AuthorLinkingCollections = {
  __typename?: 'AuthorLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  slugCollection?: Maybe<SlugCollection>;
};


export type AuthorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AuthorLinkingCollectionsSlugCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AuthorLinkingCollectionsSlugCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AuthorLinkingCollectionsSlugCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  PostedDateAsc = 'postedDate_ASC',
  PostedDateDesc = 'postedDate_DESC',
  ShareableAsc = 'shareable_ASC',
  ShareableDesc = 'shareable_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SlugTypeAsc = 'slugType_ASC',
  SlugTypeDesc = 'slugType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnlistedAsc = 'unlisted_ASC',
  UnlistedDesc = 'unlisted_DESC'
}

export enum AuthorOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Banner that goes at the top of the screen. Child of Navigation Bar V2. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/banner) */
export type Banner = Entry & _Node & {
  __typename?: 'Banner';
  _id: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  content?: Maybe<BannerContent>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<BannerLinkingCollections>;
  sys: Sys;
};


/** Banner that goes at the top of the screen. Child of Navigation Bar V2. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/banner) */
export type BannerBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Banner that goes at the top of the screen. Child of Navigation Bar V2. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/banner) */
export type BannerContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Banner that goes at the top of the screen. Child of Navigation Bar V2. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/banner) */
export type BannerContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Banner that goes at the top of the screen. Child of Navigation Bar V2. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/banner) */
export type BannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BannerCollection = {
  __typename?: 'BannerCollection';
  items: Array<Maybe<Banner>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BannerContent = {
  __typename?: 'BannerContent';
  json: Scalars['JSON'];
  links?: BannerContentLinks;
};

export type BannerContentAssets = {
  __typename?: 'BannerContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BannerContentEntries = {
  __typename?: 'BannerContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BannerContentLinks = {
  __typename?: 'BannerContentLinks';
  assets: BannerContentAssets;
  entries: BannerContentEntries;
  resources: BannerContentResources;
};

export type BannerContentResources = {
  __typename?: 'BannerContentResources';
  block: Array<BannerContentResourcesBlock>;
  hyperlink: Array<BannerContentResourcesHyperlink>;
  inline: Array<BannerContentResourcesInline>;
};

export type BannerContentResourcesBlock = ResourceLink & {
  __typename?: 'BannerContentResourcesBlock';
  sys: ResourceSys;
};

export type BannerContentResourcesHyperlink = ResourceLink & {
  __typename?: 'BannerContentResourcesHyperlink';
  sys: ResourceSys;
};

export type BannerContentResourcesInline = ResourceLink & {
  __typename?: 'BannerContentResourcesInline';
  sys: ResourceSys;
};

export type BannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<BannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BannerFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type BannerLinkingCollections = {
  __typename?: 'BannerLinkingCollections';
  abExperimentCollection?: Maybe<AbExperimentCollection>;
  abVariantCollection?: Maybe<AbVariantCollection>;
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type BannerLinkingCollectionsAbExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BannerLinkingCollectionsAbExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BannerLinkingCollectionsAbVariantCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BannerLinkingCollectionsAbVariantCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BannerLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BannerLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BannerLinkingCollectionsAbExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BannerLinkingCollectionsAbVariantCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

export enum BannerLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum BannerOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChart = Entry & _Node & {
  __typename?: 'BarChart';
  _id: Scalars['ID'];
  chartData?: Maybe<ChartData>;
  chartTitle?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  dataColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Scalars['Float']>;
  hideLegend?: Maybe<Scalars['Boolean']>;
  hideXAxisLabels?: Maybe<Scalars['Boolean']>;
  hideXPercentLabels?: Maybe<Scalars['Boolean']>;
  hideYAxisLabels?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<BarChartLinkingCollections>;
  numberOfDecimalPlaces?: Maybe<Scalars['Float']>;
  showAsPercent?: Maybe<Scalars['Boolean']>;
  stackKey?: Maybe<Scalars['String']>;
  sys: Sys;
  width?: Maybe<Scalars['Float']>;
  xAxes?: Maybe<Array<Maybe<Scalars['String']>>>;
  yAxis?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartChartDataArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ChartDataFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartChartTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartDataColorsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartFiltersArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartHideLegendArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartHideXAxisLabelsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartHideXPercentLabelsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartHideYAxisLabelsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartNumberOfDecimalPlacesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartShowAsPercentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartStackKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartXAxesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChart) */
export type BarChartYAxisArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type BarChartCollection = {
  __typename?: 'BarChartCollection';
  items: Array<Maybe<BarChart>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BarChartFilter = {
  AND?: InputMaybe<Array<InputMaybe<BarChartFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BarChartFilter>>>;
  chartData?: InputMaybe<CfChartDataNestedFilter>;
  chartData_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dataColors_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dataColors_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dataColors_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dataColors_exists?: InputMaybe<Scalars['Boolean']>;
  filters_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters_exists?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Float']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Float']>;
  height_gte?: InputMaybe<Scalars['Float']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  height_lt?: InputMaybe<Scalars['Float']>;
  height_lte?: InputMaybe<Scalars['Float']>;
  height_not?: InputMaybe<Scalars['Float']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  hideLegend?: InputMaybe<Scalars['Boolean']>;
  hideLegend_exists?: InputMaybe<Scalars['Boolean']>;
  hideLegend_not?: InputMaybe<Scalars['Boolean']>;
  hideXAxisLabels?: InputMaybe<Scalars['Boolean']>;
  hideXAxisLabels_exists?: InputMaybe<Scalars['Boolean']>;
  hideXAxisLabels_not?: InputMaybe<Scalars['Boolean']>;
  hideXPercentLabels?: InputMaybe<Scalars['Boolean']>;
  hideXPercentLabels_exists?: InputMaybe<Scalars['Boolean']>;
  hideXPercentLabels_not?: InputMaybe<Scalars['Boolean']>;
  hideYAxisLabels?: InputMaybe<Scalars['Boolean']>;
  hideYAxisLabels_exists?: InputMaybe<Scalars['Boolean']>;
  hideYAxisLabels_not?: InputMaybe<Scalars['Boolean']>;
  numberOfDecimalPlaces?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_exists?: InputMaybe<Scalars['Boolean']>;
  numberOfDecimalPlaces_gt?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_gte?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  numberOfDecimalPlaces_lt?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_lte?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_not?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  showAsPercent?: InputMaybe<Scalars['Boolean']>;
  showAsPercent_exists?: InputMaybe<Scalars['Boolean']>;
  showAsPercent_not?: InputMaybe<Scalars['Boolean']>;
  stackKey?: InputMaybe<Scalars['String']>;
  stackKey_contains?: InputMaybe<Scalars['String']>;
  stackKey_exists?: InputMaybe<Scalars['Boolean']>;
  stackKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stackKey_not?: InputMaybe<Scalars['String']>;
  stackKey_not_contains?: InputMaybe<Scalars['String']>;
  stackKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  width?: InputMaybe<Scalars['Float']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Float']>;
  width_gte?: InputMaybe<Scalars['Float']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  width_lt?: InputMaybe<Scalars['Float']>;
  width_lte?: InputMaybe<Scalars['Float']>;
  width_not?: InputMaybe<Scalars['Float']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  xAxes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xAxes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xAxes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xAxes_exists?: InputMaybe<Scalars['Boolean']>;
  yAxis?: InputMaybe<Scalars['String']>;
  yAxis_contains?: InputMaybe<Scalars['String']>;
  yAxis_exists?: InputMaybe<Scalars['Boolean']>;
  yAxis_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yAxis_not?: InputMaybe<Scalars['String']>;
  yAxis_not_contains?: InputMaybe<Scalars['String']>;
  yAxis_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Multiple series of bar charts grouped together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartGroup) */
export type BarChartGroup = Entry & _Node & {
  __typename?: 'BarChartGroup';
  _id: Scalars['ID'];
  barChartsCollection?: Maybe<BarChartGroupBarChartsCollection>;
  chartTitle?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<BarChartGroupLinkingCollections>;
  sys: Sys;
};


/** Multiple series of bar charts grouped together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartGroup) */
export type BarChartGroupBarChartsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartGroupBarChartsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarChartFilter>;
};


/** Multiple series of bar charts grouped together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartGroup) */
export type BarChartGroupChartTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Multiple series of bar charts grouped together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartGroup) */
export type BarChartGroupContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Multiple series of bar charts grouped together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartGroup) */
export type BarChartGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BarChartGroupBarChartsCollection = {
  __typename?: 'BarChartGroupBarChartsCollection';
  items: Array<Maybe<BarChart>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BarChartGroupBarChartsCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  HideLegendAsc = 'hideLegend_ASC',
  HideLegendDesc = 'hideLegend_DESC',
  HideXAxisLabelsAsc = 'hideXAxisLabels_ASC',
  HideXAxisLabelsDesc = 'hideXAxisLabels_DESC',
  HideXPercentLabelsAsc = 'hideXPercentLabels_ASC',
  HideXPercentLabelsDesc = 'hideXPercentLabels_DESC',
  HideYAxisLabelsAsc = 'hideYAxisLabels_ASC',
  HideYAxisLabelsDesc = 'hideYAxisLabels_DESC',
  NumberOfDecimalPlacesAsc = 'numberOfDecimalPlaces_ASC',
  NumberOfDecimalPlacesDesc = 'numberOfDecimalPlaces_DESC',
  ShowAsPercentAsc = 'showAsPercent_ASC',
  ShowAsPercentDesc = 'showAsPercent_DESC',
  StackKeyAsc = 'stackKey_ASC',
  StackKeyDesc = 'stackKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  YAxisAsc = 'yAxis_ASC',
  YAxisDesc = 'yAxis_DESC'
}

export type BarChartGroupCollection = {
  __typename?: 'BarChartGroupCollection';
  items: Array<Maybe<BarChartGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BarChartGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<BarChartGroupFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BarChartGroupFilter>>>;
  barCharts?: InputMaybe<CfBarChartNestedFilter>;
  barChartsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type BarChartGroupLinkingCollections = {
  __typename?: 'BarChartGroupLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type BarChartGroupLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartGroupLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartGroupLinkingCollectionsVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartGroupLinkingCollectionsVisualizationSelectorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BarChartGroupLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum BarChartGroupLinkingCollectionsVisualizationSelectorCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BarChartGroupOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type BarChartLinkingCollections = {
  __typename?: 'BarChartLinkingCollections';
  barChartGroupCollection?: Maybe<BarChartGroupCollection>;
  barChartStackCollection?: Maybe<BarChartStackCollection>;
  blockCollection?: Maybe<BlockCollection>;
  chartToggleItemCollection?: Maybe<ChartToggleItemCollection>;
  entryCollection?: Maybe<EntryCollection>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type BarChartLinkingCollectionsBarChartGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartLinkingCollectionsBarChartGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartLinkingCollectionsBarChartStackCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartLinkingCollectionsBarChartStackCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartLinkingCollectionsChartToggleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartLinkingCollectionsChartToggleItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartLinkingCollectionsVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartLinkingCollectionsVisualizationSelectorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BarChartLinkingCollectionsBarChartGroupCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BarChartLinkingCollectionsBarChartStackCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BarChartLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum BarChartLinkingCollectionsChartToggleItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum BarChartLinkingCollectionsVisualizationSelectorCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BarChartOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  HideLegendAsc = 'hideLegend_ASC',
  HideLegendDesc = 'hideLegend_DESC',
  HideXAxisLabelsAsc = 'hideXAxisLabels_ASC',
  HideXAxisLabelsDesc = 'hideXAxisLabels_DESC',
  HideXPercentLabelsAsc = 'hideXPercentLabels_ASC',
  HideXPercentLabelsDesc = 'hideXPercentLabels_DESC',
  HideYAxisLabelsAsc = 'hideYAxisLabels_ASC',
  HideYAxisLabelsDesc = 'hideYAxisLabels_DESC',
  NumberOfDecimalPlacesAsc = 'numberOfDecimalPlaces_ASC',
  NumberOfDecimalPlacesDesc = 'numberOfDecimalPlaces_DESC',
  ShowAsPercentAsc = 'showAsPercent_ASC',
  ShowAsPercentDesc = 'showAsPercent_DESC',
  StackKeyAsc = 'stackKey_ASC',
  StackKeyDesc = 'stackKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  YAxisAsc = 'yAxis_ASC',
  YAxisDesc = 'yAxis_DESC'
}

/** Multiple series of bar charts stacked together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartStack) */
export type BarChartStack = Entry & _Node & {
  __typename?: 'BarChartStack';
  _id: Scalars['ID'];
  barChartsCollection?: Maybe<BarChartStackBarChartsCollection>;
  chartTitle?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<BarChartStackLinkingCollections>;
  sys: Sys;
};


/** Multiple series of bar charts stacked together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartStack) */
export type BarChartStackBarChartsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartStackBarChartsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarChartFilter>;
};


/** Multiple series of bar charts stacked together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartStack) */
export type BarChartStackChartTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Multiple series of bar charts stacked together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartStack) */
export type BarChartStackContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Multiple series of bar charts stacked together into one chart. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/barChartStack) */
export type BarChartStackLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BarChartStackBarChartsCollection = {
  __typename?: 'BarChartStackBarChartsCollection';
  items: Array<Maybe<BarChart>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BarChartStackBarChartsCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  HideLegendAsc = 'hideLegend_ASC',
  HideLegendDesc = 'hideLegend_DESC',
  HideXAxisLabelsAsc = 'hideXAxisLabels_ASC',
  HideXAxisLabelsDesc = 'hideXAxisLabels_DESC',
  HideXPercentLabelsAsc = 'hideXPercentLabels_ASC',
  HideXPercentLabelsDesc = 'hideXPercentLabels_DESC',
  HideYAxisLabelsAsc = 'hideYAxisLabels_ASC',
  HideYAxisLabelsDesc = 'hideYAxisLabels_DESC',
  NumberOfDecimalPlacesAsc = 'numberOfDecimalPlaces_ASC',
  NumberOfDecimalPlacesDesc = 'numberOfDecimalPlaces_DESC',
  ShowAsPercentAsc = 'showAsPercent_ASC',
  ShowAsPercentDesc = 'showAsPercent_DESC',
  StackKeyAsc = 'stackKey_ASC',
  StackKeyDesc = 'stackKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  YAxisAsc = 'yAxis_ASC',
  YAxisDesc = 'yAxis_DESC'
}

export type BarChartStackCollection = {
  __typename?: 'BarChartStackCollection';
  items: Array<Maybe<BarChartStack>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BarChartStackFilter = {
  AND?: InputMaybe<Array<InputMaybe<BarChartStackFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BarChartStackFilter>>>;
  barCharts?: InputMaybe<CfBarChartNestedFilter>;
  barChartsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type BarChartStackLinkingCollections = {
  __typename?: 'BarChartStackLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type BarChartStackLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartStackLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartStackLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BarChartStackLinkingCollectionsVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartStackLinkingCollectionsVisualizationSelectorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BarChartStackLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum BarChartStackLinkingCollectionsVisualizationSelectorCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BarChartStackOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type Block = Entry & _Node & {
  __typename?: 'Block';
  _id: Scalars['ID'];
  anchorId?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundMediaV2?: Maybe<BlockBackgroundMediaV2>;
  callsToActionCollection?: Maybe<BlockCallsToActionCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  contentsCollection?: Maybe<BlockContentsCollection>;
  eyebrow?: Maybe<Scalars['String']>;
  fullHeight?: Maybe<Scalars['Boolean']>;
  isNarrow?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<BlockLinkingCollections>;
  maxColumns?: Maybe<Scalars['Int']>;
  showCurtain?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<BlockSubtitle>;
  sys: Sys;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<BlockTitle>;
  titleAlignment?: Maybe<Scalars['String']>;
  titleAlignmentMobile?: Maybe<Scalars['String']>;
  widthStyle?: Maybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockAnchorIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockBackgroundMediaV2Args = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockCallsToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockCallsToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CallToActionFilter>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockContentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlockContentsFilter>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockEyebrowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockFullHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockIsNarrowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockMaxColumnsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockShowCurtainArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockThemeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockTitleAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockTitleAlignmentMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use Blocks as building components of a site. Blocks contain Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/block) */
export type BlockWidthStyleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type BlockBackgroundMediaV2 = Image | Video;

export type BlockCallsToActionCollection = {
  __typename?: 'BlockCallsToActionCollection';
  items: Array<Maybe<CallToAction>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BlockCallsToActionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SetTypeAsc = 'setType_ASC',
  SetTypeDesc = 'setType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type BlockCollection = {
  __typename?: 'BlockCollection';
  items: Array<Maybe<Block>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BlockContentsCollection = {
  __typename?: 'BlockContentsCollection';
  items: Array<Maybe<BlockContentsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BlockContentsFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlockContentsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlockContentsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type BlockContentsItem = Accordion | AnimatedAccordion | BarChart | BarChartGroup | BarChartStack | CarouselV3 | ChartToggle | Content | Form | Gallery | GeoVisualization | LineChart | Mosaic | TableVisualization | Tabs | VisualizationSelector;

export type BlockFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlockFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlockFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaV2_exists?: InputMaybe<Scalars['Boolean']>;
  callsToAction?: InputMaybe<CfCallToActionNestedFilter>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
  contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  eyebrow?: InputMaybe<Scalars['String']>;
  eyebrow_contains?: InputMaybe<Scalars['String']>;
  eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
  eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eyebrow_not?: InputMaybe<Scalars['String']>;
  eyebrow_not_contains?: InputMaybe<Scalars['String']>;
  eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullHeight?: InputMaybe<Scalars['Boolean']>;
  fullHeight_exists?: InputMaybe<Scalars['Boolean']>;
  fullHeight_not?: InputMaybe<Scalars['Boolean']>;
  isNarrow?: InputMaybe<Scalars['Boolean']>;
  isNarrow_exists?: InputMaybe<Scalars['Boolean']>;
  isNarrow_not?: InputMaybe<Scalars['Boolean']>;
  maxColumns?: InputMaybe<Scalars['Int']>;
  maxColumns_exists?: InputMaybe<Scalars['Boolean']>;
  maxColumns_gt?: InputMaybe<Scalars['Int']>;
  maxColumns_gte?: InputMaybe<Scalars['Int']>;
  maxColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxColumns_lt?: InputMaybe<Scalars['Int']>;
  maxColumns_lte?: InputMaybe<Scalars['Int']>;
  maxColumns_not?: InputMaybe<Scalars['Int']>;
  maxColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  showCurtain?: InputMaybe<Scalars['Boolean']>;
  showCurtain_exists?: InputMaybe<Scalars['Boolean']>;
  showCurtain_not?: InputMaybe<Scalars['Boolean']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
  theme?: InputMaybe<Scalars['String']>;
  theme_contains?: InputMaybe<Scalars['String']>;
  theme_exists?: InputMaybe<Scalars['Boolean']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme_not?: InputMaybe<Scalars['String']>;
  theme_not_contains?: InputMaybe<Scalars['String']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_contains?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_exists?: InputMaybe<Scalars['Boolean']>;
  titleAlignmentMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignmentMobile_not?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_not_contains?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment_contains?: InputMaybe<Scalars['String']>;
  titleAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  titleAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment_not?: InputMaybe<Scalars['String']>;
  titleAlignment_not_contains?: InputMaybe<Scalars['String']>;
  titleAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  widthStyle?: InputMaybe<Scalars['String']>;
  widthStyle_contains?: InputMaybe<Scalars['String']>;
  widthStyle_exists?: InputMaybe<Scalars['Boolean']>;
  widthStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  widthStyle_not?: InputMaybe<Scalars['String']>;
  widthStyle_not_contains?: InputMaybe<Scalars['String']>;
  widthStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHero = Entry & _Node & {
  __typename?: 'BlockHero';
  _id: Scalars['ID'];
  anchorId?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundMediaLayout?: Maybe<Scalars['String']>;
  backgroundMediaV2?: Maybe<BlockHeroBackgroundMediaV2>;
  body?: Maybe<BlockHeroBody>;
  callsToActionCollection?: Maybe<BlockHeroCallsToActionCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  curtainOpacityPercentage?: Maybe<Scalars['Int']>;
  eyebrow?: Maybe<Scalars['String']>;
  fitWindow?: Maybe<Scalars['Boolean']>;
  foreground?: Maybe<BlockHeroForeground>;
  header?: Maybe<BlockHeroHeader>;
  headerMediaV2?: Maybe<Image>;
  isHeaderDate?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<BlockHeroLinkingCollections>;
  mediaWrap?: Maybe<Scalars['String']>;
  showCurtain?: Maybe<Scalars['Boolean']>;
  showMediaMobile?: Maybe<Scalars['Boolean']>;
  showScrollButton?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['String']>;
  subTitle?: Maybe<BlockHeroSubTitle>;
  sys: Sys;
  textAlign?: Maybe<Scalars['String']>;
  textAlignMobile?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<BlockHeroTitle>;
  useLatestPost?: Maybe<Scalars['Boolean']>;
  verticalTextAlign?: Maybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroAnchorIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroBackgroundMediaLayoutArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroBackgroundMediaV2Args = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroCallsToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockHeroCallsToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CallToActionFilter>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroCurtainOpacityPercentageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroEyebrowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroFitWindowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroForegroundArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroHeaderMediaV2Args = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ImageFilter>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroIsHeaderDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroMediaWrapArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroShowCurtainArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroShowMediaMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroShowScrollButtonArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroTextAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroTextAlignMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroThemeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroUseLatestPostArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a Block Hero for a full screen size intro block to a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blockHero) */
export type BlockHeroVerticalTextAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type BlockHeroBackgroundMediaV2 = Image | Video;

export type BlockHeroBody = {
  __typename?: 'BlockHeroBody';
  json: Scalars['JSON'];
  links?: BlockHeroBodyLinks;
};

export type BlockHeroBodyAssets = {
  __typename?: 'BlockHeroBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BlockHeroBodyEntries = {
  __typename?: 'BlockHeroBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BlockHeroBodyLinks = {
  __typename?: 'BlockHeroBodyLinks';
  assets: BlockHeroBodyAssets;
  entries: BlockHeroBodyEntries;
  resources: BlockHeroBodyResources;
};

export type BlockHeroBodyResources = {
  __typename?: 'BlockHeroBodyResources';
  block: Array<BlockHeroBodyResourcesBlock>;
  hyperlink: Array<BlockHeroBodyResourcesHyperlink>;
  inline: Array<BlockHeroBodyResourcesInline>;
};

export type BlockHeroBodyResourcesBlock = ResourceLink & {
  __typename?: 'BlockHeroBodyResourcesBlock';
  sys: ResourceSys;
};

export type BlockHeroBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'BlockHeroBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type BlockHeroBodyResourcesInline = ResourceLink & {
  __typename?: 'BlockHeroBodyResourcesInline';
  sys: ResourceSys;
};

export type BlockHeroCallsToActionCollection = {
  __typename?: 'BlockHeroCallsToActionCollection';
  items: Array<Maybe<CallToAction>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BlockHeroCallsToActionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SetTypeAsc = 'setType_ASC',
  SetTypeDesc = 'setType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type BlockHeroCollection = {
  __typename?: 'BlockHeroCollection';
  items: Array<Maybe<BlockHero>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BlockHeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlockHeroFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlockHeroFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaLayout?: InputMaybe<Scalars['String']>;
  backgroundMediaLayout_contains?: InputMaybe<Scalars['String']>;
  backgroundMediaLayout_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundMediaLayout_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaLayout_not?: InputMaybe<Scalars['String']>;
  backgroundMediaLayout_not_contains?: InputMaybe<Scalars['String']>;
  backgroundMediaLayout_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaV2_exists?: InputMaybe<Scalars['Boolean']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  callsToAction?: InputMaybe<CfCallToActionNestedFilter>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  curtainOpacityPercentage?: InputMaybe<Scalars['Int']>;
  curtainOpacityPercentage_exists?: InputMaybe<Scalars['Boolean']>;
  curtainOpacityPercentage_gt?: InputMaybe<Scalars['Int']>;
  curtainOpacityPercentage_gte?: InputMaybe<Scalars['Int']>;
  curtainOpacityPercentage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  curtainOpacityPercentage_lt?: InputMaybe<Scalars['Int']>;
  curtainOpacityPercentage_lte?: InputMaybe<Scalars['Int']>;
  curtainOpacityPercentage_not?: InputMaybe<Scalars['Int']>;
  curtainOpacityPercentage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eyebrow?: InputMaybe<Scalars['String']>;
  eyebrow_contains?: InputMaybe<Scalars['String']>;
  eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
  eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eyebrow_not?: InputMaybe<Scalars['String']>;
  eyebrow_not_contains?: InputMaybe<Scalars['String']>;
  eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fitWindow?: InputMaybe<Scalars['Boolean']>;
  fitWindow_exists?: InputMaybe<Scalars['Boolean']>;
  fitWindow_not?: InputMaybe<Scalars['Boolean']>;
  foreground_exists?: InputMaybe<Scalars['Boolean']>;
  headerMediaV2?: InputMaybe<CfImageNestedFilter>;
  headerMediaV2_exists?: InputMaybe<Scalars['Boolean']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  isHeaderDate?: InputMaybe<Scalars['Boolean']>;
  isHeaderDate_exists?: InputMaybe<Scalars['Boolean']>;
  isHeaderDate_not?: InputMaybe<Scalars['Boolean']>;
  mediaWrap?: InputMaybe<Scalars['String']>;
  mediaWrap_contains?: InputMaybe<Scalars['String']>;
  mediaWrap_exists?: InputMaybe<Scalars['Boolean']>;
  mediaWrap_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaWrap_not?: InputMaybe<Scalars['String']>;
  mediaWrap_not_contains?: InputMaybe<Scalars['String']>;
  mediaWrap_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showCurtain?: InputMaybe<Scalars['Boolean']>;
  showCurtain_exists?: InputMaybe<Scalars['Boolean']>;
  showCurtain_not?: InputMaybe<Scalars['Boolean']>;
  showMediaMobile?: InputMaybe<Scalars['Boolean']>;
  showMediaMobile_exists?: InputMaybe<Scalars['Boolean']>;
  showMediaMobile_not?: InputMaybe<Scalars['Boolean']>;
  showScrollButton?: InputMaybe<Scalars['Boolean']>;
  showScrollButton_exists?: InputMaybe<Scalars['Boolean']>;
  showScrollButton_not?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['String']>;
  size_contains?: InputMaybe<Scalars['String']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  size_not?: InputMaybe<Scalars['String']>;
  size_not_contains?: InputMaybe<Scalars['String']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subTitle_contains?: InputMaybe<Scalars['String']>;
  subTitle_exists?: InputMaybe<Scalars['Boolean']>;
  subTitle_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
  textAlign?: InputMaybe<Scalars['String']>;
  textAlignMobile?: InputMaybe<Scalars['String']>;
  textAlignMobile_contains?: InputMaybe<Scalars['String']>;
  textAlignMobile_exists?: InputMaybe<Scalars['Boolean']>;
  textAlignMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlignMobile_not?: InputMaybe<Scalars['String']>;
  textAlignMobile_not_contains?: InputMaybe<Scalars['String']>;
  textAlignMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlign_contains?: InputMaybe<Scalars['String']>;
  textAlign_exists?: InputMaybe<Scalars['Boolean']>;
  textAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlign_not?: InputMaybe<Scalars['String']>;
  textAlign_not_contains?: InputMaybe<Scalars['String']>;
  textAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme?: InputMaybe<Scalars['String']>;
  theme_contains?: InputMaybe<Scalars['String']>;
  theme_exists?: InputMaybe<Scalars['Boolean']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme_not?: InputMaybe<Scalars['String']>;
  theme_not_contains?: InputMaybe<Scalars['String']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  useLatestPost?: InputMaybe<Scalars['Boolean']>;
  useLatestPost_exists?: InputMaybe<Scalars['Boolean']>;
  useLatestPost_not?: InputMaybe<Scalars['Boolean']>;
  verticalTextAlign?: InputMaybe<Scalars['String']>;
  verticalTextAlign_contains?: InputMaybe<Scalars['String']>;
  verticalTextAlign_exists?: InputMaybe<Scalars['Boolean']>;
  verticalTextAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  verticalTextAlign_not?: InputMaybe<Scalars['String']>;
  verticalTextAlign_not_contains?: InputMaybe<Scalars['String']>;
  verticalTextAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BlockHeroForeground = CarouselV3 | Image | SnapchatEmbed | Video;

export type BlockHeroHeader = {
  __typename?: 'BlockHeroHeader';
  json: Scalars['JSON'];
  links?: BlockHeroHeaderLinks;
};

export type BlockHeroHeaderAssets = {
  __typename?: 'BlockHeroHeaderAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BlockHeroHeaderEntries = {
  __typename?: 'BlockHeroHeaderEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BlockHeroHeaderLinks = {
  __typename?: 'BlockHeroHeaderLinks';
  assets: BlockHeroHeaderAssets;
  entries: BlockHeroHeaderEntries;
  resources: BlockHeroHeaderResources;
};

export type BlockHeroHeaderResources = {
  __typename?: 'BlockHeroHeaderResources';
  block: Array<BlockHeroHeaderResourcesBlock>;
  hyperlink: Array<BlockHeroHeaderResourcesHyperlink>;
  inline: Array<BlockHeroHeaderResourcesInline>;
};

export type BlockHeroHeaderResourcesBlock = ResourceLink & {
  __typename?: 'BlockHeroHeaderResourcesBlock';
  sys: ResourceSys;
};

export type BlockHeroHeaderResourcesHyperlink = ResourceLink & {
  __typename?: 'BlockHeroHeaderResourcesHyperlink';
  sys: ResourceSys;
};

export type BlockHeroHeaderResourcesInline = ResourceLink & {
  __typename?: 'BlockHeroHeaderResourcesInline';
  sys: ResourceSys;
};

export type BlockHeroLinkingCollections = {
  __typename?: 'BlockHeroLinkingCollections';
  abExperimentCollection?: Maybe<AbExperimentCollection>;
  abVariantCollection?: Maybe<AbVariantCollection>;
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type BlockHeroLinkingCollectionsAbExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockHeroLinkingCollectionsAbExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BlockHeroLinkingCollectionsAbVariantCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockHeroLinkingCollectionsAbVariantCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BlockHeroLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BlockHeroLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockHeroLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BlockHeroLinkingCollectionsAbExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BlockHeroLinkingCollectionsAbVariantCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

export enum BlockHeroLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum BlockHeroOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaLayoutAsc = 'backgroundMediaLayout_ASC',
  BackgroundMediaLayoutDesc = 'backgroundMediaLayout_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CurtainOpacityPercentageAsc = 'curtainOpacityPercentage_ASC',
  CurtainOpacityPercentageDesc = 'curtainOpacityPercentage_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  IsHeaderDateAsc = 'isHeaderDate_ASC',
  IsHeaderDateDesc = 'isHeaderDate_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  ShowMediaMobileAsc = 'showMediaMobile_ASC',
  ShowMediaMobileDesc = 'showMediaMobile_DESC',
  ShowScrollButtonAsc = 'showScrollButton_ASC',
  ShowScrollButtonDesc = 'showScrollButton_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextAlignMobileAsc = 'textAlignMobile_ASC',
  TextAlignMobileDesc = 'textAlignMobile_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UseLatestPostAsc = 'useLatestPost_ASC',
  UseLatestPostDesc = 'useLatestPost_DESC',
  VerticalTextAlignAsc = 'verticalTextAlign_ASC',
  VerticalTextAlignDesc = 'verticalTextAlign_DESC'
}

export type BlockHeroSubTitle = {
  __typename?: 'BlockHeroSubTitle';
  json: Scalars['JSON'];
  links?: BlockHeroSubTitleLinks;
};

export type BlockHeroSubTitleAssets = {
  __typename?: 'BlockHeroSubTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BlockHeroSubTitleEntries = {
  __typename?: 'BlockHeroSubTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BlockHeroSubTitleLinks = {
  __typename?: 'BlockHeroSubTitleLinks';
  assets: BlockHeroSubTitleAssets;
  entries: BlockHeroSubTitleEntries;
  resources: BlockHeroSubTitleResources;
};

export type BlockHeroSubTitleResources = {
  __typename?: 'BlockHeroSubTitleResources';
  block: Array<BlockHeroSubTitleResourcesBlock>;
  hyperlink: Array<BlockHeroSubTitleResourcesHyperlink>;
  inline: Array<BlockHeroSubTitleResourcesInline>;
};

export type BlockHeroSubTitleResourcesBlock = ResourceLink & {
  __typename?: 'BlockHeroSubTitleResourcesBlock';
  sys: ResourceSys;
};

export type BlockHeroSubTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'BlockHeroSubTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type BlockHeroSubTitleResourcesInline = ResourceLink & {
  __typename?: 'BlockHeroSubTitleResourcesInline';
  sys: ResourceSys;
};

export type BlockHeroTitle = {
  __typename?: 'BlockHeroTitle';
  json: Scalars['JSON'];
  links?: BlockHeroTitleLinks;
};

export type BlockHeroTitleAssets = {
  __typename?: 'BlockHeroTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BlockHeroTitleEntries = {
  __typename?: 'BlockHeroTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BlockHeroTitleLinks = {
  __typename?: 'BlockHeroTitleLinks';
  assets: BlockHeroTitleAssets;
  entries: BlockHeroTitleEntries;
  resources: BlockHeroTitleResources;
};

export type BlockHeroTitleResources = {
  __typename?: 'BlockHeroTitleResources';
  block: Array<BlockHeroTitleResourcesBlock>;
  hyperlink: Array<BlockHeroTitleResourcesHyperlink>;
  inline: Array<BlockHeroTitleResourcesInline>;
};

export type BlockHeroTitleResourcesBlock = ResourceLink & {
  __typename?: 'BlockHeroTitleResourcesBlock';
  sys: ResourceSys;
};

export type BlockHeroTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'BlockHeroTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type BlockHeroTitleResourcesInline = ResourceLink & {
  __typename?: 'BlockHeroTitleResourcesInline';
  sys: ResourceSys;
};

export type BlockLinkingCollections = {
  __typename?: 'BlockLinkingCollections';
  abExperimentCollection?: Maybe<AbExperimentCollection>;
  abVariantCollection?: Maybe<AbVariantCollection>;
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
  scrollAnimatedSectionCollection?: Maybe<ScrollAnimatedSectionCollection>;
};


export type BlockLinkingCollectionsAbExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockLinkingCollectionsAbExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BlockLinkingCollectionsAbVariantCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockLinkingCollectionsAbVariantCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BlockLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BlockLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BlockLinkingCollectionsScrollAnimatedSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockLinkingCollectionsScrollAnimatedSectionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BlockLinkingCollectionsAbExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BlockLinkingCollectionsAbVariantCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

export enum BlockLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum BlockLinkingCollectionsScrollAnimatedSectionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ScrollHeightAsc = 'scrollHeight_ASC',
  ScrollHeightDesc = 'scrollHeight_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BlockOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export type BlockSubtitle = {
  __typename?: 'BlockSubtitle';
  json: Scalars['JSON'];
  links?: BlockSubtitleLinks;
};

export type BlockSubtitleAssets = {
  __typename?: 'BlockSubtitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BlockSubtitleEntries = {
  __typename?: 'BlockSubtitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BlockSubtitleLinks = {
  __typename?: 'BlockSubtitleLinks';
  assets: BlockSubtitleAssets;
  entries: BlockSubtitleEntries;
  resources: BlockSubtitleResources;
};

export type BlockSubtitleResources = {
  __typename?: 'BlockSubtitleResources';
  block: Array<BlockSubtitleResourcesBlock>;
  hyperlink: Array<BlockSubtitleResourcesHyperlink>;
  inline: Array<BlockSubtitleResourcesInline>;
};

export type BlockSubtitleResourcesBlock = ResourceLink & {
  __typename?: 'BlockSubtitleResourcesBlock';
  sys: ResourceSys;
};

export type BlockSubtitleResourcesHyperlink = ResourceLink & {
  __typename?: 'BlockSubtitleResourcesHyperlink';
  sys: ResourceSys;
};

export type BlockSubtitleResourcesInline = ResourceLink & {
  __typename?: 'BlockSubtitleResourcesInline';
  sys: ResourceSys;
};

export type BlockTitle = {
  __typename?: 'BlockTitle';
  json: Scalars['JSON'];
  links?: BlockTitleLinks;
};

export type BlockTitleAssets = {
  __typename?: 'BlockTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BlockTitleEntries = {
  __typename?: 'BlockTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BlockTitleLinks = {
  __typename?: 'BlockTitleLinks';
  assets: BlockTitleAssets;
  entries: BlockTitleEntries;
  resources: BlockTitleResources;
};

export type BlockTitleResources = {
  __typename?: 'BlockTitleResources';
  block: Array<BlockTitleResourcesBlock>;
  hyperlink: Array<BlockTitleResourcesHyperlink>;
  inline: Array<BlockTitleResourcesInline>;
};

export type BlockTitleResourcesBlock = ResourceLink & {
  __typename?: 'BlockTitleResourcesBlock';
  sys: ResourceSys;
};

export type BlockTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'BlockTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type BlockTitleResourcesInline = ResourceLink & {
  __typename?: 'BlockTitleResourcesInline';
  sys: ResourceSys;
};

/** Model for generating a Blog schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blogSchema) */
export type BlogSchema = Entry & _Node & {
  __typename?: 'BlogSchema';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  imagesCollection?: Maybe<AssetCollection>;
  linkedFrom?: Maybe<BlogSchemaLinkingCollections>;
  sys: Sys;
};


/** Model for generating a Blog schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blogSchema) */
export type BlogSchemaContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a Blog schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blogSchema) */
export type BlogSchemaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a Blog schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blogSchema) */
export type BlogSchemaHeadlineArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a Blog schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blogSchema) */
export type BlogSchemaImagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Model for generating a Blog schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/blogSchema) */
export type BlogSchemaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BlogSchemaCollection = {
  __typename?: 'BlogSchemaCollection';
  items: Array<Maybe<BlogSchema>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BlogSchemaFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogSchemaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlogSchemaFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headline?: InputMaybe<Scalars['String']>;
  headline_contains?: InputMaybe<Scalars['String']>;
  headline_exists?: InputMaybe<Scalars['Boolean']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headline_not?: InputMaybe<Scalars['String']>;
  headline_not_contains?: InputMaybe<Scalars['String']>;
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imagesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type BlogSchemaLinkingCollections = {
  __typename?: 'BlogSchemaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  metasCollection?: Maybe<MetasCollection>;
};


export type BlogSchemaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BlogSchemaLinkingCollectionsMetasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlogSchemaLinkingCollectionsMetasCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BlogSchemaLinkingCollectionsMetasCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BlogSchemaOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Breaks are placed between blocks on pages. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/break) */
export type Break = Entry & _Node & {
  __typename?: 'Break';
  _id: Scalars['ID'];
  bottomColor?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  isOverlaid?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<BreakLinkingCollections>;
  sys: Sys;
  topColor?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


/** Breaks are placed between blocks on pages. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/break) */
export type BreakBottomColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Breaks are placed between blocks on pages. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/break) */
export type BreakContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Breaks are placed between blocks on pages. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/break) */
export type BreakIsOverlaidArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Breaks are placed between blocks on pages. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/break) */
export type BreakLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Breaks are placed between blocks on pages. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/break) */
export type BreakTopColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Breaks are placed between blocks on pages. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/break) */
export type BreakTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type BreakCollection = {
  __typename?: 'BreakCollection';
  items: Array<Maybe<Break>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BreakFilter = {
  AND?: InputMaybe<Array<InputMaybe<BreakFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BreakFilter>>>;
  bottomColor?: InputMaybe<Scalars['String']>;
  bottomColor_contains?: InputMaybe<Scalars['String']>;
  bottomColor_exists?: InputMaybe<Scalars['Boolean']>;
  bottomColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomColor_not?: InputMaybe<Scalars['String']>;
  bottomColor_not_contains?: InputMaybe<Scalars['String']>;
  bottomColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  isOverlaid?: InputMaybe<Scalars['Boolean']>;
  isOverlaid_exists?: InputMaybe<Scalars['Boolean']>;
  isOverlaid_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  topColor?: InputMaybe<Scalars['String']>;
  topColor_contains?: InputMaybe<Scalars['String']>;
  topColor_exists?: InputMaybe<Scalars['Boolean']>;
  topColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topColor_not?: InputMaybe<Scalars['String']>;
  topColor_not_contains?: InputMaybe<Scalars['String']>;
  topColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_exists?: InputMaybe<Scalars['Boolean']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BreakLinkingCollections = {
  __typename?: 'BreakLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type BreakLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BreakLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BreakLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BreakLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum BreakOrder {
  BottomColorAsc = 'bottomColor_ASC',
  BottomColorDesc = 'bottomColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  IsOverlaidAsc = 'isOverlaid_ASC',
  IsOverlaidDesc = 'isOverlaid_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopColorAsc = 'topColor_ASC',
  TopColorDesc = 'topColor_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC'
}

/** Break templates are sectional dividers between Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/breakTemplate) */
export type BreakTemplate = Entry & _Node & {
  __typename?: 'BreakTemplate';
  _id: Scalars['ID'];
  breakTemplatesListCollection?: Maybe<BreakTemplateBreakTemplatesListCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<BreakTemplateLinkingCollections>;
  sys: Sys;
};


/** Break templates are sectional dividers between Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/breakTemplate) */
export type BreakTemplateBreakTemplatesListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BreakTemplateBreakTemplatesListCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BreakTemplateItemFilter>;
};


/** Break templates are sectional dividers between Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/breakTemplate) */
export type BreakTemplateContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Break templates are sectional dividers between Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/breakTemplate) */
export type BreakTemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BreakTemplateBreakTemplatesListCollection = {
  __typename?: 'BreakTemplateBreakTemplatesListCollection';
  items: Array<Maybe<BreakTemplateItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BreakTemplateBreakTemplatesListCollectionOrder {
  BreakNameAsc = 'breakName_ASC',
  BreakNameDesc = 'breakName_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type BreakTemplateCollection = {
  __typename?: 'BreakTemplateCollection';
  items: Array<Maybe<BreakTemplate>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BreakTemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<BreakTemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BreakTemplateFilter>>>;
  breakTemplatesList?: InputMaybe<CfBreakTemplateItemNestedFilter>;
  breakTemplatesListCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/breakTemplateItem) */
export type BreakTemplateItem = Entry & _Node & {
  __typename?: 'BreakTemplateItem';
  _id: Scalars['ID'];
  breakName?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<BreakTemplateItemLinkingCollections>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/breakTemplateItem) */
export type BreakTemplateItemBreakNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/breakTemplateItem) */
export type BreakTemplateItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/breakTemplateItem) */
export type BreakTemplateItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BreakTemplateItemCollection = {
  __typename?: 'BreakTemplateItemCollection';
  items: Array<Maybe<BreakTemplateItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BreakTemplateItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<BreakTemplateItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BreakTemplateItemFilter>>>;
  breakName?: InputMaybe<Scalars['String']>;
  breakName_contains?: InputMaybe<Scalars['String']>;
  breakName_exists?: InputMaybe<Scalars['Boolean']>;
  breakName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  breakName_not?: InputMaybe<Scalars['String']>;
  breakName_not_contains?: InputMaybe<Scalars['String']>;
  breakName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type BreakTemplateItemLinkingCollections = {
  __typename?: 'BreakTemplateItemLinkingCollections';
  breakTemplateCollection?: Maybe<BreakTemplateCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type BreakTemplateItemLinkingCollectionsBreakTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BreakTemplateItemLinkingCollectionsBreakTemplateCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BreakTemplateItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BreakTemplateItemLinkingCollectionsBreakTemplateCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BreakTemplateItemOrder {
  BreakNameAsc = 'breakName_ASC',
  BreakNameDesc = 'breakName_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type BreakTemplateLinkingCollections = {
  __typename?: 'BreakTemplateLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type BreakTemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BreakTemplateOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type Button = Entry & _Node & {
  __typename?: 'Button';
  _id: Scalars['ID'];
  buttonType?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  iconName?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  linkedFrom?: Maybe<ButtonLinkingCollections>;
  size?: Maybe<Scalars['String']>;
  sys: Sys;
  targetAsset?: Maybe<Asset>;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<ButtonTitle>;
  url?: Maybe<Scalars['String']>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonButtonTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonIconNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ImageFilter>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonTargetAssetArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonThemeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Use a button for links or downloads. It should usually be a child of a Call To Action and paired with an Analytics component to keep track of click events. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/button) */
export type ButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ButtonCollection = {
  __typename?: 'ButtonCollection';
  items: Array<Maybe<Button>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ButtonFilter = {
  AND?: InputMaybe<Array<InputMaybe<ButtonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ButtonFilter>>>;
  buttonType?: InputMaybe<Scalars['String']>;
  buttonType_contains?: InputMaybe<Scalars['String']>;
  buttonType_exists?: InputMaybe<Scalars['Boolean']>;
  buttonType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonType_not?: InputMaybe<Scalars['String']>;
  buttonType_not_contains?: InputMaybe<Scalars['String']>;
  buttonType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  iconName?: InputMaybe<Scalars['String']>;
  iconName_contains?: InputMaybe<Scalars['String']>;
  iconName_exists?: InputMaybe<Scalars['Boolean']>;
  iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  iconName_not?: InputMaybe<Scalars['String']>;
  iconName_not_contains?: InputMaybe<Scalars['String']>;
  iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image?: InputMaybe<CfImageNestedFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['String']>;
  size_contains?: InputMaybe<Scalars['String']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  size_not?: InputMaybe<Scalars['String']>;
  size_not_contains?: InputMaybe<Scalars['String']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  targetAsset_exists?: InputMaybe<Scalars['Boolean']>;
  theme?: InputMaybe<Scalars['String']>;
  theme_contains?: InputMaybe<Scalars['String']>;
  theme_exists?: InputMaybe<Scalars['Boolean']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme_not?: InputMaybe<Scalars['String']>;
  theme_not_contains?: InputMaybe<Scalars['String']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ButtonLinkingCollections = {
  __typename?: 'ButtonLinkingCollections';
  callToActionCollection?: Maybe<CallToActionCollection>;
  entryCollection?: Maybe<EntryCollection>;
  tabItemCollection?: Maybe<TabItemCollection>;
};


export type ButtonLinkingCollectionsCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsCallToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ButtonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ButtonLinkingCollectionsTabItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsTabItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ButtonLinkingCollectionsCallToActionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SetTypeAsc = 'setType_ASC',
  SetTypeDesc = 'setType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum ButtonLinkingCollectionsTabItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum ButtonOrder {
  ButtonTypeAsc = 'buttonType_ASC',
  ButtonTypeDesc = 'buttonType_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  IconNameAsc = 'iconName_ASC',
  IconNameDesc = 'iconName_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type ButtonTitle = {
  __typename?: 'ButtonTitle';
  json: Scalars['JSON'];
  links?: ButtonTitleLinks;
};

export type ButtonTitleAssets = {
  __typename?: 'ButtonTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ButtonTitleEntries = {
  __typename?: 'ButtonTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ButtonTitleLinks = {
  __typename?: 'ButtonTitleLinks';
  assets: ButtonTitleAssets;
  entries: ButtonTitleEntries;
  resources: ButtonTitleResources;
};

export type ButtonTitleResources = {
  __typename?: 'ButtonTitleResources';
  block: Array<ButtonTitleResourcesBlock>;
  hyperlink: Array<ButtonTitleResourcesHyperlink>;
  inline: Array<ButtonTitleResourcesInline>;
};

export type ButtonTitleResourcesBlock = ResourceLink & {
  __typename?: 'ButtonTitleResourcesBlock';
  sys: ResourceSys;
};

export type ButtonTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'ButtonTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type ButtonTitleResourcesInline = ResourceLink & {
  __typename?: 'ButtonTitleResourcesInline';
  sys: ResourceSys;
};

/** Create a Call To Action component to create a Button with Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/callToAction) */
export type CallToAction = Entry & _Node & {
  __typename?: 'CallToAction';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<CallToActionLinkingCollections>;
  presentation?: Maybe<Button>;
  setType?: Maybe<Scalars['String']>;
  sys: Sys;
  url?: Maybe<Scalars['String']>;
};


/** Create a Call To Action component to create a Button with Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/callToAction) */
export type CallToActionAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Create a Call To Action component to create a Button with Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/callToAction) */
export type CallToActionContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Create a Call To Action component to create a Button with Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/callToAction) */
export type CallToActionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Create a Call To Action component to create a Button with Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/callToAction) */
export type CallToActionPresentationArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ButtonFilter>;
};


/** Create a Call To Action component to create a Button with Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/callToAction) */
export type CallToActionSetTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Create a Call To Action component to create a Button with Analytics. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/callToAction) */
export type CallToActionUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CallToActionCollection = {
  __typename?: 'CallToActionCollection';
  items: Array<Maybe<CallToAction>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CallToActionFilter = {
  AND?: InputMaybe<Array<InputMaybe<CallToActionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CallToActionFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  presentation?: InputMaybe<CfButtonNestedFilter>;
  presentation_exists?: InputMaybe<Scalars['Boolean']>;
  setType?: InputMaybe<Scalars['String']>;
  setType_contains?: InputMaybe<Scalars['String']>;
  setType_exists?: InputMaybe<Scalars['Boolean']>;
  setType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  setType_not?: InputMaybe<Scalars['String']>;
  setType_not_contains?: InputMaybe<Scalars['String']>;
  setType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CallToActionLinkingCollections = {
  __typename?: 'CallToActionLinkingCollections';
  accordionItemCollection?: Maybe<AccordionItemCollection>;
  blockCollection?: Maybe<BlockCollection>;
  blockHeroCollection?: Maybe<BlockHeroCollection>;
  contentCollection?: Maybe<ContentCollection>;
  entryCollection?: Maybe<EntryCollection>;
  latestPostsCollection?: Maybe<LatestPostsCollection>;
  navigationCallToActionCollection?: Maybe<NavigationCallToActionCollection>;
  navigatorCollection?: Maybe<NavigatorCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
  splitBlockCollection?: Maybe<SplitBlockCollection>;
};


export type CallToActionLinkingCollectionsAccordionItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsAccordionItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsBlockHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsBlockHeroCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsLatestPostsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsLatestPostsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsNavigationCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsNavigationCallToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsNavigatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsNavigatorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CallToActionLinkingCollectionsSplitBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkingCollectionsSplitBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CallToActionLinkingCollectionsAccordionItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum CallToActionLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum CallToActionLinkingCollectionsBlockHeroCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaLayoutAsc = 'backgroundMediaLayout_ASC',
  BackgroundMediaLayoutDesc = 'backgroundMediaLayout_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CurtainOpacityPercentageAsc = 'curtainOpacityPercentage_ASC',
  CurtainOpacityPercentageDesc = 'curtainOpacityPercentage_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  IsHeaderDateAsc = 'isHeaderDate_ASC',
  IsHeaderDateDesc = 'isHeaderDate_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  ShowMediaMobileAsc = 'showMediaMobile_ASC',
  ShowMediaMobileDesc = 'showMediaMobile_DESC',
  ShowScrollButtonAsc = 'showScrollButton_ASC',
  ShowScrollButtonDesc = 'showScrollButton_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextAlignMobileAsc = 'textAlignMobile_ASC',
  TextAlignMobileDesc = 'textAlignMobile_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UseLatestPostAsc = 'useLatestPost_ASC',
  UseLatestPostDesc = 'useLatestPost_DESC',
  VerticalTextAlignAsc = 'verticalTextAlign_ASC',
  VerticalTextAlignDesc = 'verticalTextAlign_DESC'
}

export enum CallToActionLinkingCollectionsContentCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BodyAlignmentAsc = 'bodyAlignment_ASC',
  BodyAlignmentDesc = 'bodyAlignment_DESC',
  BodyAlignmentMobileAsc = 'bodyAlignmentMobile_ASC',
  BodyAlignmentMobileDesc = 'bodyAlignmentMobile_DESC',
  CollapseMobileTableAsc = 'collapseMobileTable_ASC',
  CollapseMobileTableDesc = 'collapseMobileTable_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayOnAsc = 'displayOn_ASC',
  DisplayOnDesc = 'displayOn_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowFullResolutionMediaAsc = 'showFullResolutionMedia_ASC',
  ShowFullResolutionMediaDesc = 'showFullResolutionMedia_DESC',
  ShowVideoControlsAsc = 'showVideoControls_ASC',
  ShowVideoControlsDesc = 'showVideoControls_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  UseHeadingTagsForBodyAsc = 'useHeadingTagsForBody_ASC',
  UseHeadingTagsForBodyDesc = 'useHeadingTagsForBody_DESC'
}

export enum CallToActionLinkingCollectionsLatestPostsCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MaxPostsAsc = 'maxPosts_ASC',
  MaxPostsDesc = 'maxPosts_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum CallToActionLinkingCollectionsNavigationCallToActionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CallToActionLinkingCollectionsNavigatorCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum CallToActionLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CallToActionLinkingCollectionsSplitBlockCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  MediaCaptionAsc = 'mediaCaption_ASC',
  MediaCaptionDesc = 'mediaCaption_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignmentAsc = 'textAlignment_ASC',
  TextAlignmentDesc = 'textAlignment_DESC',
  TextAlignmentMobileAsc = 'textAlignmentMobile_ASC',
  TextAlignmentMobileDesc = 'textAlignmentMobile_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VerticalTextAlignmentAsc = 'verticalTextAlignment_ASC',
  VerticalTextAlignmentDesc = 'verticalTextAlignment_DESC'
}

export enum CallToActionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SetTypeAsc = 'setType_ASC',
  SetTypeDesc = 'setType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCard = Entry & _Node & {
  __typename?: 'CarouselCard';
  _id: Scalars['ID'];
  body?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<CarouselCardLinkingCollections>;
  media?: Maybe<CarouselCardMedia>;
  showShadow?: Maybe<Scalars['Boolean']>;
  slugReference?: Maybe<Slug>;
  subtitle?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardShowShadowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardSlugReferenceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SlugFilter>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel card item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselCard) */
export type CarouselCardUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CarouselCardCollection = {
  __typename?: 'CarouselCardCollection';
  items: Array<Maybe<CarouselCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CarouselCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<CarouselCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CarouselCardFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  showShadow?: InputMaybe<Scalars['Boolean']>;
  showShadow_exists?: InputMaybe<Scalars['Boolean']>;
  showShadow_not?: InputMaybe<Scalars['Boolean']>;
  slugReference?: InputMaybe<CfSlugNestedFilter>;
  slugReference_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarouselCardLinkingCollections = {
  __typename?: 'CarouselCardLinkingCollections';
  carouselV3Collection?: Maybe<CarouselV3Collection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type CarouselCardLinkingCollectionsCarouselV3CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CarouselCardLinkingCollectionsCarouselV3CollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CarouselCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CarouselCardLinkingCollectionsCarouselV3CollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaAspectRatioAsc = 'mediaAspectRatio_ASC',
  MediaAspectRatioDesc = 'mediaAspectRatio_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type CarouselCardMedia = Image | Video;

export enum CarouselCardOrder {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ShowShadowAsc = 'showShadow_ASC',
  ShowShadowDesc = 'showShadow_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Model for generating a carousel text item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselText) */
export type CarouselText = Entry & _Node & {
  __typename?: 'CarouselText';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  description?: Maybe<CarouselTextDescription>;
  linkedFrom?: Maybe<CarouselTextLinkingCollections>;
  slugReference?: Maybe<Slug>;
  subtext?: Maybe<CarouselTextSubtext>;
  sys: Sys;
  url?: Maybe<Scalars['String']>;
};


/** Model for generating a carousel text item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselText) */
export type CarouselTextContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel text item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselText) */
export type CarouselTextDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel text item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselText) */
export type CarouselTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Model for generating a carousel text item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselText) */
export type CarouselTextSlugReferenceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SlugFilter>;
};


/** Model for generating a carousel text item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselText) */
export type CarouselTextSubtextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel text item [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselText) */
export type CarouselTextUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CarouselTextCollection = {
  __typename?: 'CarouselTextCollection';
  items: Array<Maybe<CarouselText>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CarouselTextDescription = {
  __typename?: 'CarouselTextDescription';
  json: Scalars['JSON'];
  links?: CarouselTextDescriptionLinks;
};

export type CarouselTextDescriptionAssets = {
  __typename?: 'CarouselTextDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CarouselTextDescriptionEntries = {
  __typename?: 'CarouselTextDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CarouselTextDescriptionLinks = {
  __typename?: 'CarouselTextDescriptionLinks';
  assets: CarouselTextDescriptionAssets;
  entries: CarouselTextDescriptionEntries;
  resources: CarouselTextDescriptionResources;
};

export type CarouselTextDescriptionResources = {
  __typename?: 'CarouselTextDescriptionResources';
  block: Array<CarouselTextDescriptionResourcesBlock>;
  hyperlink: Array<CarouselTextDescriptionResourcesHyperlink>;
  inline: Array<CarouselTextDescriptionResourcesInline>;
};

export type CarouselTextDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'CarouselTextDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type CarouselTextDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'CarouselTextDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type CarouselTextDescriptionResourcesInline = ResourceLink & {
  __typename?: 'CarouselTextDescriptionResourcesInline';
  sys: ResourceSys;
};

export type CarouselTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<CarouselTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CarouselTextFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  slugReference?: InputMaybe<CfSlugNestedFilter>;
  slugReference_exists?: InputMaybe<Scalars['Boolean']>;
  subtext_contains?: InputMaybe<Scalars['String']>;
  subtext_exists?: InputMaybe<Scalars['Boolean']>;
  subtext_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarouselTextLinkingCollections = {
  __typename?: 'CarouselTextLinkingCollections';
  carouselV3Collection?: Maybe<CarouselV3Collection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type CarouselTextLinkingCollectionsCarouselV3CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CarouselTextLinkingCollectionsCarouselV3CollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CarouselTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CarouselTextLinkingCollectionsCarouselV3CollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaAspectRatioAsc = 'mediaAspectRatio_ASC',
  MediaAspectRatioDesc = 'mediaAspectRatio_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CarouselTextOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type CarouselTextSubtext = {
  __typename?: 'CarouselTextSubtext';
  json: Scalars['JSON'];
  links?: CarouselTextSubtextLinks;
};

export type CarouselTextSubtextAssets = {
  __typename?: 'CarouselTextSubtextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CarouselTextSubtextEntries = {
  __typename?: 'CarouselTextSubtextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CarouselTextSubtextLinks = {
  __typename?: 'CarouselTextSubtextLinks';
  assets: CarouselTextSubtextAssets;
  entries: CarouselTextSubtextEntries;
  resources: CarouselTextSubtextResources;
};

export type CarouselTextSubtextResources = {
  __typename?: 'CarouselTextSubtextResources';
  block: Array<CarouselTextSubtextResourcesBlock>;
  hyperlink: Array<CarouselTextSubtextResourcesHyperlink>;
  inline: Array<CarouselTextSubtextResourcesInline>;
};

export type CarouselTextSubtextResourcesBlock = ResourceLink & {
  __typename?: 'CarouselTextSubtextResourcesBlock';
  sys: ResourceSys;
};

export type CarouselTextSubtextResourcesHyperlink = ResourceLink & {
  __typename?: 'CarouselTextSubtextResourcesHyperlink';
  sys: ResourceSys;
};

export type CarouselTextSubtextResourcesInline = ResourceLink & {
  __typename?: 'CarouselTextSubtextResourcesInline';
  sys: ResourceSys;
};

/** Model for generating a carousel v3 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselV3) */
export type CarouselV3 = Entry & _Node & {
  __typename?: 'CarouselV3';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  contentsCollection?: Maybe<CarouselV3ContentsCollection>;
  layout?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<CarouselV3LinkingCollections>;
  mediaAspectRatio?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** Model for generating a carousel v3 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselV3) */
export type CarouselV3ContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel v3 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselV3) */
export type CarouselV3ContentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarouselV3ContentsFilter>;
};


/** Model for generating a carousel v3 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselV3) */
export type CarouselV3LayoutArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a carousel v3 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselV3) */
export type CarouselV3LinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Model for generating a carousel v3 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/carouselV3) */
export type CarouselV3MediaAspectRatioArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CarouselV3Collection = {
  __typename?: 'CarouselV3Collection';
  items: Array<Maybe<CarouselV3>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CarouselV3ContentsCollection = {
  __typename?: 'CarouselV3ContentsCollection';
  items: Array<Maybe<CarouselV3ContentsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CarouselV3ContentsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CarouselV3ContentsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CarouselV3ContentsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  slugReference_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarouselV3ContentsItem = CarouselCard | CarouselText;

export type CarouselV3Filter = {
  AND?: InputMaybe<Array<InputMaybe<CarouselV3Filter>>>;
  OR?: InputMaybe<Array<InputMaybe<CarouselV3Filter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
  contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  layout?: InputMaybe<Scalars['String']>;
  layout_contains?: InputMaybe<Scalars['String']>;
  layout_exists?: InputMaybe<Scalars['Boolean']>;
  layout_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  layout_not?: InputMaybe<Scalars['String']>;
  layout_not_contains?: InputMaybe<Scalars['String']>;
  layout_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaAspectRatio?: InputMaybe<Scalars['String']>;
  mediaAspectRatio_contains?: InputMaybe<Scalars['String']>;
  mediaAspectRatio_exists?: InputMaybe<Scalars['Boolean']>;
  mediaAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaAspectRatio_not?: InputMaybe<Scalars['String']>;
  mediaAspectRatio_not_contains?: InputMaybe<Scalars['String']>;
  mediaAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CarouselV3LinkingCollections = {
  __typename?: 'CarouselV3LinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  blockHeroCollection?: Maybe<BlockHeroCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type CarouselV3LinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CarouselV3LinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CarouselV3LinkingCollectionsBlockHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CarouselV3LinkingCollectionsBlockHeroCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CarouselV3LinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CarouselV3LinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum CarouselV3LinkingCollectionsBlockHeroCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaLayoutAsc = 'backgroundMediaLayout_ASC',
  BackgroundMediaLayoutDesc = 'backgroundMediaLayout_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CurtainOpacityPercentageAsc = 'curtainOpacityPercentage_ASC',
  CurtainOpacityPercentageDesc = 'curtainOpacityPercentage_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  IsHeaderDateAsc = 'isHeaderDate_ASC',
  IsHeaderDateDesc = 'isHeaderDate_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  ShowMediaMobileAsc = 'showMediaMobile_ASC',
  ShowMediaMobileDesc = 'showMediaMobile_DESC',
  ShowScrollButtonAsc = 'showScrollButton_ASC',
  ShowScrollButtonDesc = 'showScrollButton_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextAlignMobileAsc = 'textAlignMobile_ASC',
  TextAlignMobileDesc = 'textAlignMobile_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UseLatestPostAsc = 'useLatestPost_ASC',
  UseLatestPostDesc = 'useLatestPost_DESC',
  VerticalTextAlignAsc = 'verticalTextAlign_ASC',
  VerticalTextAlignDesc = 'verticalTextAlign_DESC'
}

export enum CarouselV3Order {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaAspectRatioAsc = 'mediaAspectRatio_ASC',
  MediaAspectRatioDesc = 'mediaAspectRatio_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Referenced by a page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/category) */
export type Category = Entry & _Node & {
  __typename?: 'Category';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<CategoryLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Referenced by a page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/category) */
export type CategoryContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Referenced by a page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/category) */
export type CategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Referenced by a page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/category) */
export type CategoryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CategoryCollection = {
  __typename?: 'CategoryCollection';
  items: Array<Maybe<Category>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<CategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CategoryFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CategoryLinkingCollections = {
  __typename?: 'CategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type CategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CategoryLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CategoryLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CategoryLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum CategoryOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Upload data to a series in order to populate charts with it. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartData) */
export type ChartData = Entry & _Node & {
  __typename?: 'ChartData';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  data?: Maybe<Scalars['JSON']>;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ChartDataLinkingCollections>;
  localizedData?: Maybe<Scalars['JSON']>;
  seriesName?: Maybe<Series>;
  sys: Sys;
};


/** Upload data to a series in order to populate charts with it. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartData) */
export type ChartDataContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Upload data to a series in order to populate charts with it. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartData) */
export type ChartDataDataArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Upload data to a series in order to populate charts with it. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartData) */
export type ChartDataLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Upload data to a series in order to populate charts with it. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartData) */
export type ChartDataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Upload data to a series in order to populate charts with it. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartData) */
export type ChartDataLocalizedDataArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Upload data to a series in order to populate charts with it. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartData) */
export type ChartDataSeriesNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SeriesFilter>;
};

export type ChartDataCollection = {
  __typename?: 'ChartDataCollection';
  items: Array<Maybe<ChartData>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ChartDataFilter = {
  AND?: InputMaybe<Array<InputMaybe<ChartDataFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ChartDataFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  data_exists?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedData_exists?: InputMaybe<Scalars['Boolean']>;
  seriesName?: InputMaybe<CfSeriesNestedFilter>;
  seriesName_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type ChartDataLinkingCollections = {
  __typename?: 'ChartDataLinkingCollections';
  barChartCollection?: Maybe<BarChartCollection>;
  entryCollection?: Maybe<EntryCollection>;
  geoVisualizationCollection?: Maybe<GeoVisualizationCollection>;
  lineChartCollection?: Maybe<LineChartCollection>;
  tableVisualizationCollection?: Maybe<TableVisualizationCollection>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type ChartDataLinkingCollectionsBarChartCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartDataLinkingCollectionsBarChartCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ChartDataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ChartDataLinkingCollectionsGeoVisualizationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartDataLinkingCollectionsGeoVisualizationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ChartDataLinkingCollectionsLineChartCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartDataLinkingCollectionsLineChartCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ChartDataLinkingCollectionsTableVisualizationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartDataLinkingCollectionsTableVisualizationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ChartDataLinkingCollectionsVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartDataLinkingCollectionsVisualizationSelectorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ChartDataLinkingCollectionsBarChartCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  HideLegendAsc = 'hideLegend_ASC',
  HideLegendDesc = 'hideLegend_DESC',
  HideXAxisLabelsAsc = 'hideXAxisLabels_ASC',
  HideXAxisLabelsDesc = 'hideXAxisLabels_DESC',
  HideXPercentLabelsAsc = 'hideXPercentLabels_ASC',
  HideXPercentLabelsDesc = 'hideXPercentLabels_DESC',
  HideYAxisLabelsAsc = 'hideYAxisLabels_ASC',
  HideYAxisLabelsDesc = 'hideYAxisLabels_DESC',
  NumberOfDecimalPlacesAsc = 'numberOfDecimalPlaces_ASC',
  NumberOfDecimalPlacesDesc = 'numberOfDecimalPlaces_DESC',
  ShowAsPercentAsc = 'showAsPercent_ASC',
  ShowAsPercentDesc = 'showAsPercent_DESC',
  StackKeyAsc = 'stackKey_ASC',
  StackKeyDesc = 'stackKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  YAxisAsc = 'yAxis_ASC',
  YAxisDesc = 'yAxis_DESC'
}

export enum ChartDataLinkingCollectionsGeoVisualizationCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CountryCodeKeyAsc = 'countryCodeKey_ASC',
  CountryCodeKeyDesc = 'countryCodeKey_DESC',
  DataScalingMethodAsc = 'dataScalingMethod_ASC',
  DataScalingMethodDesc = 'dataScalingMethod_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueKeyAsc = 'valueKey_ASC',
  ValueKeyDesc = 'valueKey_DESC'
}

export enum ChartDataLinkingCollectionsLineChartCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CurveAsc = 'curve_ASC',
  CurveDesc = 'curve_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  XKeyAsc = 'xKey_ASC',
  XKeyDesc = 'xKey_DESC'
}

export enum ChartDataLinkingCollectionsTableVisualizationCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EnableSearchAsc = 'enableSearch_ASC',
  EnableSearchDesc = 'enableSearch_DESC',
  InitialRowCountAsc = 'initialRowCount_ASC',
  InitialRowCountDesc = 'initialRowCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ChartDataLinkingCollectionsVisualizationSelectorCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ChartDataOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggle) */
export type ChartToggle = Entry & _Node & {
  __typename?: 'ChartToggle';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  itemsCollection?: Maybe<ChartToggleItemsCollection>;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ChartToggleLinkingCollections>;
  sys: Sys;
};


/** Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggle) */
export type ChartToggleContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggle) */
export type ChartToggleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartToggleItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChartToggleItemFilter>;
};


/** Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggle) */
export type ChartToggleLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggle) */
export type ChartToggleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ChartToggleCollection = {
  __typename?: 'ChartToggleCollection';
  items: Array<Maybe<ChartToggle>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ChartToggleFilter = {
  AND?: InputMaybe<Array<InputMaybe<ChartToggleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ChartToggleFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  items?: InputMaybe<CfChartToggleItemNestedFilter>;
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

/** an item in the Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggleItem) */
export type ChartToggleItem = Entry & _Node & {
  __typename?: 'ChartToggleItem';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  contentsCollection?: Maybe<ChartToggleItemContentsCollection>;
  linkedFrom?: Maybe<ChartToggleItemLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** an item in the Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggleItem) */
export type ChartToggleItemAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** an item in the Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggleItem) */
export type ChartToggleItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** an item in the Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggleItem) */
export type ChartToggleItemContentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChartToggleItemContentsFilter>;
};


/** an item in the Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggleItem) */
export type ChartToggleItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** an item in the Chart Toggle component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/chartToggleItem) */
export type ChartToggleItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ChartToggleItemCollection = {
  __typename?: 'ChartToggleItemCollection';
  items: Array<Maybe<ChartToggleItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ChartToggleItemContentsCollection = {
  __typename?: 'ChartToggleItemContentsCollection';
  items: Array<Maybe<ChartToggleItemContentsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ChartToggleItemContentsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ChartToggleItemContentsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ChartToggleItemContentsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type ChartToggleItemContentsItem = BarChart | ChartToggle | GeoVisualization | LineChart;

export type ChartToggleItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ChartToggleItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ChartToggleItemFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
  contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ChartToggleItemLinkingCollections = {
  __typename?: 'ChartToggleItemLinkingCollections';
  chartToggleCollection?: Maybe<ChartToggleCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type ChartToggleItemLinkingCollectionsChartToggleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartToggleItemLinkingCollectionsChartToggleCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ChartToggleItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ChartToggleItemLinkingCollectionsChartToggleCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ChartToggleItemOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ChartToggleItemsCollection = {
  __typename?: 'ChartToggleItemsCollection';
  items: Array<Maybe<ChartToggleItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ChartToggleItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ChartToggleLinkingCollections = {
  __typename?: 'ChartToggleLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  chartToggleItemCollection?: Maybe<ChartToggleItemCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type ChartToggleLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartToggleLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ChartToggleLinkingCollectionsChartToggleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartToggleLinkingCollectionsChartToggleItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ChartToggleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ChartToggleLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum ChartToggleLinkingCollectionsChartToggleItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum ChartToggleOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Block of code for Content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/code) */
export type Code = Entry & _Node & {
  __typename?: 'Code';
  _id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  language?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<CodeLinkingCollections>;
  sys: Sys;
};


/** Block of code for Content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/code) */
export type CodeCodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Block of code for Content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/code) */
export type CodeContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Block of code for Content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/code) */
export type CodeLanguageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Block of code for Content [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/code) */
export type CodeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CodeCollection = {
  __typename?: 'CodeCollection';
  items: Array<Maybe<Code>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CodeFilter = {
  AND?: InputMaybe<Array<InputMaybe<CodeFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CodeFilter>>>;
  code?: InputMaybe<Scalars['String']>;
  code_contains?: InputMaybe<Scalars['String']>;
  code_exists?: InputMaybe<Scalars['Boolean']>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  code_not?: InputMaybe<Scalars['String']>;
  code_not_contains?: InputMaybe<Scalars['String']>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  language?: InputMaybe<Scalars['String']>;
  language_contains?: InputMaybe<Scalars['String']>;
  language_exists?: InputMaybe<Scalars['Boolean']>;
  language_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language_not?: InputMaybe<Scalars['String']>;
  language_not_contains?: InputMaybe<Scalars['String']>;
  language_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CodeLinkingCollections = {
  __typename?: 'CodeLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type CodeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CodeOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type Content = Entry & _Node & {
  __typename?: 'Content';
  _id: Scalars['ID'];
  anchorId?: Maybe<Scalars['String']>;
  animationsCollection?: Maybe<ContentAnimationsCollection>;
  asset?: Maybe<ContentAsset>;
  backgroundColor?: Maybe<Scalars['String']>;
  body?: Maybe<ContentBody>;
  bodyAlignment?: Maybe<Scalars['String']>;
  bodyAlignmentMobile?: Maybe<Scalars['String']>;
  callsToActionCollection?: Maybe<ContentCallsToActionCollection>;
  collapseMobileTable?: Maybe<Scalars['Boolean']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  displayOn?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ContentLinkingCollections>;
  mediaDirection?: Maybe<Scalars['String']>;
  mediaWrap?: Maybe<Scalars['String']>;
  showFullResolutionMedia?: Maybe<Scalars['Boolean']>;
  showVideoControls?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<ContentSubtitle>;
  sys: Sys;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<ContentTitle>;
  titleAlignment?: Maybe<Scalars['String']>;
  titleAlignmentMobile?: Maybe<Scalars['String']>;
  useHeadingTagsForBody?: Maybe<Scalars['Boolean']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentAnchorIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentAnimationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContentAnimationsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentAnimationFilter>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentAssetArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentBodyAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentBodyAlignmentMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentCallsToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentCallsToActionFilter>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentCollapseMobileTableArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentDisplayOnArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentLayoutArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentMediaDirectionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentMediaWrapArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentShowFullResolutionMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentShowVideoControlsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentThemeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentTitleAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentTitleAlignmentMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Content entries to a Block. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/content) */
export type ContentUseHeadingTagsForBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/contentAnimation) */
export type ContentAnimation = Entry & _Node & {
  __typename?: 'ContentAnimation';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  keyframes?: Maybe<AnimationKeyframes>;
  linkedFrom?: Maybe<ContentAnimationLinkingCollections>;
  sys: Sys;
  target?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/contentAnimation) */
export type ContentAnimationContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/contentAnimation) */
export type ContentAnimationKeyframesArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnimationKeyframesFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/contentAnimation) */
export type ContentAnimationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/contentAnimation) */
export type ContentAnimationTargetArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ContentAnimationCollection = {
  __typename?: 'ContentAnimationCollection';
  items: Array<Maybe<ContentAnimation>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ContentAnimationFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentAnimationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentAnimationFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  keyframes?: InputMaybe<CfAnimationKeyframesNestedFilter>;
  keyframes_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  target?: InputMaybe<Scalars['String']>;
  target_contains?: InputMaybe<Scalars['String']>;
  target_exists?: InputMaybe<Scalars['Boolean']>;
  target_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  target_not?: InputMaybe<Scalars['String']>;
  target_not_contains?: InputMaybe<Scalars['String']>;
  target_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentAnimationLinkingCollections = {
  __typename?: 'ContentAnimationLinkingCollections';
  contentCollection?: Maybe<ContentCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type ContentAnimationLinkingCollectionsContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContentAnimationLinkingCollectionsContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ContentAnimationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ContentAnimationLinkingCollectionsContentCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BodyAlignmentAsc = 'bodyAlignment_ASC',
  BodyAlignmentDesc = 'bodyAlignment_DESC',
  BodyAlignmentMobileAsc = 'bodyAlignmentMobile_ASC',
  BodyAlignmentMobileDesc = 'bodyAlignmentMobile_DESC',
  CollapseMobileTableAsc = 'collapseMobileTable_ASC',
  CollapseMobileTableDesc = 'collapseMobileTable_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayOnAsc = 'displayOn_ASC',
  DisplayOnDesc = 'displayOn_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowFullResolutionMediaAsc = 'showFullResolutionMedia_ASC',
  ShowFullResolutionMediaDesc = 'showFullResolutionMedia_DESC',
  ShowVideoControlsAsc = 'showVideoControls_ASC',
  ShowVideoControlsDesc = 'showVideoControls_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  UseHeadingTagsForBodyAsc = 'useHeadingTagsForBody_ASC',
  UseHeadingTagsForBodyDesc = 'useHeadingTagsForBody_DESC'
}

export enum ContentAnimationOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TargetAsc = 'target_ASC',
  TargetDesc = 'target_DESC'
}

export type ContentAnimationsCollection = {
  __typename?: 'ContentAnimationsCollection';
  items: Array<Maybe<ContentAnimation>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ContentAnimationsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TargetAsc = 'target_ASC',
  TargetDesc = 'target_DESC'
}

export type ContentAsset = Image | SnapchatEmbed | Video;

export type ContentBody = {
  __typename?: 'ContentBody';
  json: Scalars['JSON'];
  links?: ContentBodyLinks;
};

export type ContentBodyAssets = {
  __typename?: 'ContentBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContentBodyEntries = {
  __typename?: 'ContentBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContentBodyLinks = {
  __typename?: 'ContentBodyLinks';
  assets: ContentBodyAssets;
  entries: ContentBodyEntries;
  resources: ContentBodyResources;
};

export type ContentBodyResources = {
  __typename?: 'ContentBodyResources';
  block: Array<ContentBodyResourcesBlock>;
  hyperlink: Array<ContentBodyResourcesHyperlink>;
  inline: Array<ContentBodyResourcesInline>;
};

export type ContentBodyResourcesBlock = ResourceLink & {
  __typename?: 'ContentBodyResourcesBlock';
  sys: ResourceSys;
};

export type ContentBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'ContentBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type ContentBodyResourcesInline = ResourceLink & {
  __typename?: 'ContentBodyResourcesInline';
  sys: ResourceSys;
};

export type ContentCallsToActionCollection = {
  __typename?: 'ContentCallsToActionCollection';
  items: Array<Maybe<ContentCallsToActionItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ContentCallsToActionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentCallsToActionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentCallsToActionFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCallsToActionItem = CallToAction | ImageButton;

export type ContentCollection = {
  __typename?: 'ContentCollection';
  items: Array<Maybe<Content>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  animations?: InputMaybe<CfContentAnimationNestedFilter>;
  animationsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  asset_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodyAlignment?: InputMaybe<Scalars['String']>;
  bodyAlignmentMobile?: InputMaybe<Scalars['String']>;
  bodyAlignmentMobile_contains?: InputMaybe<Scalars['String']>;
  bodyAlignmentMobile_exists?: InputMaybe<Scalars['Boolean']>;
  bodyAlignmentMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodyAlignmentMobile_not?: InputMaybe<Scalars['String']>;
  bodyAlignmentMobile_not_contains?: InputMaybe<Scalars['String']>;
  bodyAlignmentMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodyAlignment_contains?: InputMaybe<Scalars['String']>;
  bodyAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  bodyAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodyAlignment_not?: InputMaybe<Scalars['String']>;
  bodyAlignment_not_contains?: InputMaybe<Scalars['String']>;
  bodyAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  callsToAction?: InputMaybe<CfcallsToActionMultiTypeNestedFilter>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  collapseMobileTable?: InputMaybe<Scalars['Boolean']>;
  collapseMobileTable_exists?: InputMaybe<Scalars['Boolean']>;
  collapseMobileTable_not?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  displayOn?: InputMaybe<Scalars['String']>;
  displayOn_contains?: InputMaybe<Scalars['String']>;
  displayOn_exists?: InputMaybe<Scalars['Boolean']>;
  displayOn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayOn_not?: InputMaybe<Scalars['String']>;
  displayOn_not_contains?: InputMaybe<Scalars['String']>;
  displayOn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  layout?: InputMaybe<Scalars['String']>;
  layout_contains?: InputMaybe<Scalars['String']>;
  layout_exists?: InputMaybe<Scalars['Boolean']>;
  layout_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  layout_not?: InputMaybe<Scalars['String']>;
  layout_not_contains?: InputMaybe<Scalars['String']>;
  layout_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaDirection?: InputMaybe<Scalars['String']>;
  mediaDirection_contains?: InputMaybe<Scalars['String']>;
  mediaDirection_exists?: InputMaybe<Scalars['Boolean']>;
  mediaDirection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaDirection_not?: InputMaybe<Scalars['String']>;
  mediaDirection_not_contains?: InputMaybe<Scalars['String']>;
  mediaDirection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaWrap?: InputMaybe<Scalars['String']>;
  mediaWrap_contains?: InputMaybe<Scalars['String']>;
  mediaWrap_exists?: InputMaybe<Scalars['Boolean']>;
  mediaWrap_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaWrap_not?: InputMaybe<Scalars['String']>;
  mediaWrap_not_contains?: InputMaybe<Scalars['String']>;
  mediaWrap_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showFullResolutionMedia?: InputMaybe<Scalars['Boolean']>;
  showFullResolutionMedia_exists?: InputMaybe<Scalars['Boolean']>;
  showFullResolutionMedia_not?: InputMaybe<Scalars['Boolean']>;
  showVideoControls?: InputMaybe<Scalars['Boolean']>;
  showVideoControls_exists?: InputMaybe<Scalars['Boolean']>;
  showVideoControls_not?: InputMaybe<Scalars['Boolean']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
  theme?: InputMaybe<Scalars['String']>;
  theme_contains?: InputMaybe<Scalars['String']>;
  theme_exists?: InputMaybe<Scalars['Boolean']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme_not?: InputMaybe<Scalars['String']>;
  theme_not_contains?: InputMaybe<Scalars['String']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_contains?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_exists?: InputMaybe<Scalars['Boolean']>;
  titleAlignmentMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignmentMobile_not?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_not_contains?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment_contains?: InputMaybe<Scalars['String']>;
  titleAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  titleAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment_not?: InputMaybe<Scalars['String']>;
  titleAlignment_not_contains?: InputMaybe<Scalars['String']>;
  titleAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  useHeadingTagsForBody?: InputMaybe<Scalars['Boolean']>;
  useHeadingTagsForBody_exists?: InputMaybe<Scalars['Boolean']>;
  useHeadingTagsForBody_not?: InputMaybe<Scalars['Boolean']>;
};

export type ContentLinkingCollections = {
  __typename?: 'ContentLinkingCollections';
  abExperimentCollection?: Maybe<AbExperimentCollection>;
  abVariantCollection?: Maybe<AbVariantCollection>;
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
  tabItemCollection?: Maybe<TabItemCollection>;
};


export type ContentLinkingCollectionsAbExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContentLinkingCollectionsAbExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ContentLinkingCollectionsAbVariantCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContentLinkingCollectionsAbVariantCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ContentLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContentLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ContentLinkingCollectionsTabItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContentLinkingCollectionsTabItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ContentLinkingCollectionsAbExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ContentLinkingCollectionsAbVariantCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

export enum ContentLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum ContentLinkingCollectionsTabItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum ContentOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BodyAlignmentAsc = 'bodyAlignment_ASC',
  BodyAlignmentDesc = 'bodyAlignment_DESC',
  BodyAlignmentMobileAsc = 'bodyAlignmentMobile_ASC',
  BodyAlignmentMobileDesc = 'bodyAlignmentMobile_DESC',
  CollapseMobileTableAsc = 'collapseMobileTable_ASC',
  CollapseMobileTableDesc = 'collapseMobileTable_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayOnAsc = 'displayOn_ASC',
  DisplayOnDesc = 'displayOn_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowFullResolutionMediaAsc = 'showFullResolutionMedia_ASC',
  ShowFullResolutionMediaDesc = 'showFullResolutionMedia_DESC',
  ShowVideoControlsAsc = 'showVideoControls_ASC',
  ShowVideoControlsDesc = 'showVideoControls_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  UseHeadingTagsForBodyAsc = 'useHeadingTagsForBody_ASC',
  UseHeadingTagsForBodyDesc = 'useHeadingTagsForBody_DESC'
}

export type ContentSubtitle = {
  __typename?: 'ContentSubtitle';
  json: Scalars['JSON'];
  links?: ContentSubtitleLinks;
};

export type ContentSubtitleAssets = {
  __typename?: 'ContentSubtitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContentSubtitleEntries = {
  __typename?: 'ContentSubtitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContentSubtitleLinks = {
  __typename?: 'ContentSubtitleLinks';
  assets: ContentSubtitleAssets;
  entries: ContentSubtitleEntries;
  resources: ContentSubtitleResources;
};

export type ContentSubtitleResources = {
  __typename?: 'ContentSubtitleResources';
  block: Array<ContentSubtitleResourcesBlock>;
  hyperlink: Array<ContentSubtitleResourcesHyperlink>;
  inline: Array<ContentSubtitleResourcesInline>;
};

export type ContentSubtitleResourcesBlock = ResourceLink & {
  __typename?: 'ContentSubtitleResourcesBlock';
  sys: ResourceSys;
};

export type ContentSubtitleResourcesHyperlink = ResourceLink & {
  __typename?: 'ContentSubtitleResourcesHyperlink';
  sys: ResourceSys;
};

export type ContentSubtitleResourcesInline = ResourceLink & {
  __typename?: 'ContentSubtitleResourcesInline';
  sys: ResourceSys;
};

export type ContentTitle = {
  __typename?: 'ContentTitle';
  json: Scalars['JSON'];
  links?: ContentTitleLinks;
};

export type ContentTitleAssets = {
  __typename?: 'ContentTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContentTitleEntries = {
  __typename?: 'ContentTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContentTitleLinks = {
  __typename?: 'ContentTitleLinks';
  assets: ContentTitleAssets;
  entries: ContentTitleEntries;
  resources: ContentTitleResources;
};

export type ContentTitleResources = {
  __typename?: 'ContentTitleResources';
  block: Array<ContentTitleResourcesBlock>;
  hyperlink: Array<ContentTitleResourcesHyperlink>;
  inline: Array<ContentTitleResourcesInline>;
};

export type ContentTitleResourcesBlock = ResourceLink & {
  __typename?: 'ContentTitleResourcesBlock';
  sys: ResourceSys;
};

export type ContentTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'ContentTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type ContentTitleResourcesInline = ResourceLink & {
  __typename?: 'ContentTitleResourcesInline';
  sys: ResourceSys;
};

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  concepts: Array<Maybe<TaxonomyConcept>>;
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataConceptsDescendantsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentfulMetadataConceptsFilter = {
  descendants?: InputMaybe<ContentfulMetadataConceptsDescendantsFilter>;
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentfulMetadataFilter = {
  concepts?: InputMaybe<ContentfulMetadataConceptsFilter>;
  concepts_exists?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type Criteria = Entry & _Node & {
  __typename?: 'Criteria';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryAction?: Maybe<Scalars['String']>;
  deviceAction?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Maybe<Scalars['String']>>>;
  languageAction?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedFrom?: Maybe<CriteriaLinkingCollections>;
  platformAction?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  sys: Sys;
  trafficRangeEnd?: Maybe<Scalars['Int']>;
  trafficRangeStart?: Maybe<Scalars['Int']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaCountriesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaCountryActionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaDeviceActionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaDevicesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaLanguageActionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaLanguagesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaPlatformActionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaPlatformsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaTrafficRangeEndArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for the experiments. This is Experiment Criteria V2 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/Criteria) */
export type CriteriaTrafficRangeStartArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CriteriaCollection = {
  __typename?: 'CriteriaCollection';
  items: Array<Maybe<Criteria>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CriteriaFilter = {
  AND?: InputMaybe<Array<InputMaybe<CriteriaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CriteriaFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countries_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countries_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countries_exists?: InputMaybe<Scalars['Boolean']>;
  countryAction?: InputMaybe<Scalars['String']>;
  countryAction_contains?: InputMaybe<Scalars['String']>;
  countryAction_exists?: InputMaybe<Scalars['Boolean']>;
  countryAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryAction_not?: InputMaybe<Scalars['String']>;
  countryAction_not_contains?: InputMaybe<Scalars['String']>;
  countryAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deviceAction?: InputMaybe<Scalars['String']>;
  deviceAction_contains?: InputMaybe<Scalars['String']>;
  deviceAction_exists?: InputMaybe<Scalars['Boolean']>;
  deviceAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deviceAction_not?: InputMaybe<Scalars['String']>;
  deviceAction_not_contains?: InputMaybe<Scalars['String']>;
  deviceAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  devices_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  devices_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  devices_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  devices_exists?: InputMaybe<Scalars['Boolean']>;
  languageAction?: InputMaybe<Scalars['String']>;
  languageAction_contains?: InputMaybe<Scalars['String']>;
  languageAction_exists?: InputMaybe<Scalars['Boolean']>;
  languageAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languageAction_not?: InputMaybe<Scalars['String']>;
  languageAction_not_contains?: InputMaybe<Scalars['String']>;
  languageAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languages_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languages_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languages_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languages_exists?: InputMaybe<Scalars['Boolean']>;
  platformAction?: InputMaybe<Scalars['String']>;
  platformAction_contains?: InputMaybe<Scalars['String']>;
  platformAction_exists?: InputMaybe<Scalars['Boolean']>;
  platformAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platformAction_not?: InputMaybe<Scalars['String']>;
  platformAction_not_contains?: InputMaybe<Scalars['String']>;
  platformAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platforms_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platforms_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platforms_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platforms_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  trafficRangeEnd?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_exists?: InputMaybe<Scalars['Boolean']>;
  trafficRangeEnd_gt?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_gte?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficRangeEnd_lt?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_lte?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_not?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficRangeStart?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_exists?: InputMaybe<Scalars['Boolean']>;
  trafficRangeStart_gt?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_gte?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficRangeStart_lt?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_lte?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_not?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type CriteriaLinkingCollections = {
  __typename?: 'CriteriaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  experimentArmCollection?: Maybe<ExperimentArmCollection>;
  featuredHeaderMenuItemCollection?: Maybe<FeaturedHeaderMenuItemCollection>;
  footerCookiesSettingsLinkCollection?: Maybe<FooterCookiesSettingsLinkCollection>;
  footerGroupCollection?: Maybe<FooterGroupCollection>;
  footerItemCollection?: Maybe<FooterItemCollection>;
  footerLocaleDropdownCollection?: Maybe<FooterLocaleDropdownCollection>;
  headerMenuItemCollection?: Maybe<HeaderMenuItemCollection>;
  headerMenuItemGroupCollection?: Maybe<HeaderMenuItemGroupCollection>;
  navigationCallToActionCollection?: Maybe<NavigationCallToActionCollection>;
  navigatorItemCollection?: Maybe<NavigatorItemCollection>;
  slugCollection?: Maybe<SlugCollection>;
};


export type CriteriaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsExperimentArmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsExperimentArmCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsFeaturedHeaderMenuItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsFeaturedHeaderMenuItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsFooterCookiesSettingsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsFooterCookiesSettingsLinkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsFooterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsFooterGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsFooterItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsFooterItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsFooterLocaleDropdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsFooterLocaleDropdownCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsHeaderMenuItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsHeaderMenuItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsHeaderMenuItemGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsHeaderMenuItemGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsNavigationCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsNavigationCallToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsNavigatorItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsNavigatorItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CriteriaLinkingCollectionsSlugCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaLinkingCollectionsSlugCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CriteriaLinkingCollectionsExperimentArmCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CriteriaLinkingCollectionsFeaturedHeaderMenuItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CtaLabelAsc = 'ctaLabel_ASC',
  CtaLabelDesc = 'ctaLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum CriteriaLinkingCollectionsFooterCookiesSettingsLinkCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum CriteriaLinkingCollectionsFooterGroupCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  GroupKeyAsc = 'groupKey_ASC',
  GroupKeyDesc = 'groupKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum CriteriaLinkingCollectionsFooterItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum CriteriaLinkingCollectionsFooterLocaleDropdownCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum CriteriaLinkingCollectionsHeaderMenuItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum CriteriaLinkingCollectionsHeaderMenuItemGroupCollectionOrder {
  Column1LabelAsc = 'column1Label_ASC',
  Column1LabelDesc = 'column1Label_DESC',
  Column2LabelAsc = 'column2Label_ASC',
  Column2LabelDesc = 'column2Label_DESC',
  Column3LabelAsc = 'column3Label_ASC',
  Column3LabelDesc = 'column3Label_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum CriteriaLinkingCollectionsNavigationCallToActionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CriteriaLinkingCollectionsNavigatorItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum CriteriaLinkingCollectionsSlugCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  PostedDateAsc = 'postedDate_ASC',
  PostedDateDesc = 'postedDate_DESC',
  ShareableAsc = 'shareable_ASC',
  ShareableDesc = 'shareable_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SlugTypeAsc = 'slugType_ASC',
  SlugTypeDesc = 'slugType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnlistedAsc = 'unlisted_ASC',
  UnlistedDesc = 'unlisted_DESC'
}

export enum CriteriaOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CountryActionAsc = 'countryAction_ASC',
  CountryActionDesc = 'countryAction_DESC',
  DeviceActionAsc = 'deviceAction_ASC',
  DeviceActionDesc = 'deviceAction_DESC',
  LanguageActionAsc = 'languageAction_ASC',
  LanguageActionDesc = 'languageAction_DESC',
  PlatformActionAsc = 'platformAction_ASC',
  PlatformActionDesc = 'platformAction_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficRangeEndAsc = 'trafficRangeEnd_ASC',
  TrafficRangeEndDesc = 'trafficRangeEnd_DESC',
  TrafficRangeStartAsc = 'trafficRangeStart_ASC',
  TrafficRangeStartDesc = 'trafficRangeStart_DESC'
}

/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooter = Entry & _Node & {
  __typename?: 'CustomFooter';
  _id: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  barCollection?: Maybe<CustomFooterBarCollection>;
  columnsCollection?: Maybe<CustomFooterColumnsCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<CustomFooterLinkingCollections>;
  logo?: Maybe<Image>;
  socialCollection?: Maybe<CustomFooterSocialCollection>;
  socialLabel?: Maybe<Scalars['String']>;
  sys: Sys;
  url?: Maybe<Scalars['String']>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterBarCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomFooterBarCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterGroupFilter>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterColumnsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomFooterColumnsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterGroupFilter>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterLogoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ImageFilter>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterSocialCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomFooterSocialCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageButtonFilter>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterSocialLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type for the new Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customFooter) */
export type CustomFooterUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CustomFooterBarCollection = {
  __typename?: 'CustomFooterBarCollection';
  items: Array<Maybe<FooterGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CustomFooterBarCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  GroupKeyAsc = 'groupKey_ASC',
  GroupKeyDesc = 'groupKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type CustomFooterCollection = {
  __typename?: 'CustomFooterCollection';
  items: Array<Maybe<CustomFooter>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CustomFooterColumnsCollection = {
  __typename?: 'CustomFooterColumnsCollection';
  items: Array<Maybe<FooterGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CustomFooterColumnsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  GroupKeyAsc = 'groupKey_ASC',
  GroupKeyDesc = 'groupKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type CustomFooterFilter = {
  AND?: InputMaybe<Array<InputMaybe<CustomFooterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CustomFooterFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bar?: InputMaybe<CfFooterGroupNestedFilter>;
  barCollection_exists?: InputMaybe<Scalars['Boolean']>;
  columns?: InputMaybe<CfFooterGroupNestedFilter>;
  columnsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  logo?: InputMaybe<CfImageNestedFilter>;
  logo_exists?: InputMaybe<Scalars['Boolean']>;
  social?: InputMaybe<CfImageButtonNestedFilter>;
  socialCollection_exists?: InputMaybe<Scalars['Boolean']>;
  socialLabel?: InputMaybe<Scalars['String']>;
  socialLabel_contains?: InputMaybe<Scalars['String']>;
  socialLabel_exists?: InputMaybe<Scalars['Boolean']>;
  socialLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialLabel_not?: InputMaybe<Scalars['String']>;
  socialLabel_not_contains?: InputMaybe<Scalars['String']>;
  socialLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomFooterLinkingCollections = {
  __typename?: 'CustomFooterLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  sitewideValuesCollection?: Maybe<SitewideValuesCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type CustomFooterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CustomFooterLinkingCollectionsSitewideValuesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomFooterLinkingCollectionsSitewideValuesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CustomFooterLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomFooterLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CustomFooterLinkingCollectionsSitewideValuesCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CustomFooterLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CustomFooterOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SocialLabelAsc = 'socialLabel_ASC',
  SocialLabelDesc = 'socialLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type CustomFooterSocialCollection = {
  __typename?: 'CustomFooterSocialCollection';
  items: Array<Maybe<ImageButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CustomFooterSocialCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

/** Customer Quote Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customerQuote) */
export type CustomerQuote = Entry & _Node & {
  __typename?: 'CustomerQuote';
  _id: Scalars['ID'];
  authorName?: Maybe<Scalars['String']>;
  body?: Maybe<CustomerQuoteBody>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<CustomerQuoteLinkingCollections>;
  sys: Sys;
};


/** Customer Quote Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customerQuote) */
export type CustomerQuoteAuthorNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Customer Quote Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customerQuote) */
export type CustomerQuoteBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Customer Quote Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customerQuote) */
export type CustomerQuoteContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Customer Quote Content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/customerQuote) */
export type CustomerQuoteLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomerQuoteBody = {
  __typename?: 'CustomerQuoteBody';
  json: Scalars['JSON'];
  links?: CustomerQuoteBodyLinks;
};

export type CustomerQuoteBodyAssets = {
  __typename?: 'CustomerQuoteBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CustomerQuoteBodyEntries = {
  __typename?: 'CustomerQuoteBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CustomerQuoteBodyLinks = {
  __typename?: 'CustomerQuoteBodyLinks';
  assets: CustomerQuoteBodyAssets;
  entries: CustomerQuoteBodyEntries;
  resources: CustomerQuoteBodyResources;
};

export type CustomerQuoteBodyResources = {
  __typename?: 'CustomerQuoteBodyResources';
  block: Array<CustomerQuoteBodyResourcesBlock>;
  hyperlink: Array<CustomerQuoteBodyResourcesHyperlink>;
  inline: Array<CustomerQuoteBodyResourcesInline>;
};

export type CustomerQuoteBodyResourcesBlock = ResourceLink & {
  __typename?: 'CustomerQuoteBodyResourcesBlock';
  sys: ResourceSys;
};

export type CustomerQuoteBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'CustomerQuoteBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type CustomerQuoteBodyResourcesInline = ResourceLink & {
  __typename?: 'CustomerQuoteBodyResourcesInline';
  sys: ResourceSys;
};

export type CustomerQuoteCollection = {
  __typename?: 'CustomerQuoteCollection';
  items: Array<Maybe<CustomerQuote>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CustomerQuoteFilter = {
  AND?: InputMaybe<Array<InputMaybe<CustomerQuoteFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CustomerQuoteFilter>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorName_contains?: InputMaybe<Scalars['String']>;
  authorName_exists?: InputMaybe<Scalars['Boolean']>;
  authorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  authorName_not?: InputMaybe<Scalars['String']>;
  authorName_not_contains?: InputMaybe<Scalars['String']>;
  authorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CustomerQuoteLinkingCollections = {
  __typename?: 'CustomerQuoteLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type CustomerQuoteLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CustomerQuoteOrder {
  AuthorNameAsc = 'authorName_ASC',
  AuthorNameDesc = 'authorName_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Specify content that will appear when a user clicks on a specific term or phrase [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/definition) */
export type Definition = Entry & _Node & {
  __typename?: 'Definition';
  _id: Scalars['ID'];
  anchorId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  displayText?: Maybe<Scalars['String']>;
  fullText?: Maybe<DefinitionFullText>;
  linkedFrom?: Maybe<DefinitionLinkingCollections>;
  sys: Sys;
};


/** Specify content that will appear when a user clicks on a specific term or phrase [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/definition) */
export type DefinitionAnchorIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Specify content that will appear when a user clicks on a specific term or phrase [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/definition) */
export type DefinitionContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Specify content that will appear when a user clicks on a specific term or phrase [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/definition) */
export type DefinitionDisplayTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Specify content that will appear when a user clicks on a specific term or phrase [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/definition) */
export type DefinitionFullTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Specify content that will appear when a user clicks on a specific term or phrase [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/definition) */
export type DefinitionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DefinitionCollection = {
  __typename?: 'DefinitionCollection';
  items: Array<Maybe<Definition>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type DefinitionFilter = {
  AND?: InputMaybe<Array<InputMaybe<DefinitionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DefinitionFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  displayText?: InputMaybe<Scalars['String']>;
  displayText_contains?: InputMaybe<Scalars['String']>;
  displayText_exists?: InputMaybe<Scalars['Boolean']>;
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayText_not?: InputMaybe<Scalars['String']>;
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullText_contains?: InputMaybe<Scalars['String']>;
  fullText_exists?: InputMaybe<Scalars['Boolean']>;
  fullText_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
};

export type DefinitionFullText = {
  __typename?: 'DefinitionFullText';
  json: Scalars['JSON'];
  links?: DefinitionFullTextLinks;
};

export type DefinitionFullTextAssets = {
  __typename?: 'DefinitionFullTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DefinitionFullTextEntries = {
  __typename?: 'DefinitionFullTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DefinitionFullTextLinks = {
  __typename?: 'DefinitionFullTextLinks';
  assets: DefinitionFullTextAssets;
  entries: DefinitionFullTextEntries;
  resources: DefinitionFullTextResources;
};

export type DefinitionFullTextResources = {
  __typename?: 'DefinitionFullTextResources';
  block: Array<DefinitionFullTextResourcesBlock>;
  hyperlink: Array<DefinitionFullTextResourcesHyperlink>;
  inline: Array<DefinitionFullTextResourcesInline>;
};

export type DefinitionFullTextResourcesBlock = ResourceLink & {
  __typename?: 'DefinitionFullTextResourcesBlock';
  sys: ResourceSys;
};

export type DefinitionFullTextResourcesHyperlink = ResourceLink & {
  __typename?: 'DefinitionFullTextResourcesHyperlink';
  sys: ResourceSys;
};

export type DefinitionFullTextResourcesInline = ResourceLink & {
  __typename?: 'DefinitionFullTextResourcesInline';
  sys: ResourceSys;
};

export type DefinitionLinkingCollections = {
  __typename?: 'DefinitionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type DefinitionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum DefinitionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayTextAsc = 'displayText_ASC',
  DisplayTextDesc = 'displayText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Specify a downloaded asset that can be used inside of other content types [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/downloadableAsset) */
export type DownloadableAsset = Entry & _Node & {
  __typename?: 'DownloadableAsset';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  file?: Maybe<Asset>;
  linkText?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<DownloadableAssetLinkingCollections>;
  sys: Sys;
};


/** Specify a downloaded asset that can be used inside of other content types [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/downloadableAsset) */
export type DownloadableAssetContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Specify a downloaded asset that can be used inside of other content types [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/downloadableAsset) */
export type DownloadableAssetFileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Specify a downloaded asset that can be used inside of other content types [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/downloadableAsset) */
export type DownloadableAssetLinkTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Specify a downloaded asset that can be used inside of other content types [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/downloadableAsset) */
export type DownloadableAssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DownloadableAssetCollection = {
  __typename?: 'DownloadableAssetCollection';
  items: Array<Maybe<DownloadableAsset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type DownloadableAssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<DownloadableAssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DownloadableAssetFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  file_exists?: InputMaybe<Scalars['Boolean']>;
  linkText?: InputMaybe<Scalars['String']>;
  linkText_contains?: InputMaybe<Scalars['String']>;
  linkText_exists?: InputMaybe<Scalars['Boolean']>;
  linkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkText_not?: InputMaybe<Scalars['String']>;
  linkText_not_contains?: InputMaybe<Scalars['String']>;
  linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type DownloadableAssetLinkingCollections = {
  __typename?: 'DownloadableAssetLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type DownloadableAssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum DownloadableAssetOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LinkTextAsc = 'linkText_ASC',
  LinkTextDesc = 'linkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownField = Entry & _Node & {
  __typename?: 'DropdownField';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  error?: Maybe<DropdownFieldError>;
  helpText?: Maybe<Scalars['String']>;
  initialOption?: Maybe<DropdownFieldOption>;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<DropdownFieldLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  optionsCollection?: Maybe<DropdownFieldOptionsCollection>;
  placeholder?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  shouldResetToInitial?: Maybe<Scalars['Boolean']>;
  sys: Sys;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldErrorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldHelpTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldInitialOptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<DropdownFieldOptionFilter>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DropdownFieldOptionsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DropdownFieldOptionFilter>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldRequiredArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownField) */
export type DropdownFieldShouldResetToInitialArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type DropdownFieldCollection = {
  __typename?: 'DropdownFieldCollection';
  items: Array<Maybe<DropdownField>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type DropdownFieldError = {
  __typename?: 'DropdownFieldError';
  json: Scalars['JSON'];
  links?: DropdownFieldErrorLinks;
};

export type DropdownFieldErrorAssets = {
  __typename?: 'DropdownFieldErrorAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DropdownFieldErrorEntries = {
  __typename?: 'DropdownFieldErrorEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DropdownFieldErrorLinks = {
  __typename?: 'DropdownFieldErrorLinks';
  assets: DropdownFieldErrorAssets;
  entries: DropdownFieldErrorEntries;
  resources: DropdownFieldErrorResources;
};

export type DropdownFieldErrorResources = {
  __typename?: 'DropdownFieldErrorResources';
  block: Array<DropdownFieldErrorResourcesBlock>;
  hyperlink: Array<DropdownFieldErrorResourcesHyperlink>;
  inline: Array<DropdownFieldErrorResourcesInline>;
};

export type DropdownFieldErrorResourcesBlock = ResourceLink & {
  __typename?: 'DropdownFieldErrorResourcesBlock';
  sys: ResourceSys;
};

export type DropdownFieldErrorResourcesHyperlink = ResourceLink & {
  __typename?: 'DropdownFieldErrorResourcesHyperlink';
  sys: ResourceSys;
};

export type DropdownFieldErrorResourcesInline = ResourceLink & {
  __typename?: 'DropdownFieldErrorResourcesInline';
  sys: ResourceSys;
};

export type DropdownFieldFilter = {
  AND?: InputMaybe<Array<InputMaybe<DropdownFieldFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DropdownFieldFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  error_contains?: InputMaybe<Scalars['String']>;
  error_exists?: InputMaybe<Scalars['Boolean']>;
  error_not_contains?: InputMaybe<Scalars['String']>;
  helpText?: InputMaybe<Scalars['String']>;
  helpText_contains?: InputMaybe<Scalars['String']>;
  helpText_exists?: InputMaybe<Scalars['Boolean']>;
  helpText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  helpText_not?: InputMaybe<Scalars['String']>;
  helpText_not_contains?: InputMaybe<Scalars['String']>;
  helpText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  initialOption?: InputMaybe<CfDropdownFieldOptionNestedFilter>;
  initialOption_exists?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  options?: InputMaybe<CfDropdownFieldOptionNestedFilter>;
  optionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  placeholder?: InputMaybe<Scalars['String']>;
  placeholder_contains?: InputMaybe<Scalars['String']>;
  placeholder_exists?: InputMaybe<Scalars['Boolean']>;
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  placeholder_not?: InputMaybe<Scalars['String']>;
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  required?: InputMaybe<Scalars['Boolean']>;
  required_exists?: InputMaybe<Scalars['Boolean']>;
  required_not?: InputMaybe<Scalars['Boolean']>;
  shouldResetToInitial?: InputMaybe<Scalars['Boolean']>;
  shouldResetToInitial_exists?: InputMaybe<Scalars['Boolean']>;
  shouldResetToInitial_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type DropdownFieldLinkingCollections = {
  __typename?: 'DropdownFieldLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  formRowCollection?: Maybe<FormRowCollection>;
};


export type DropdownFieldLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type DropdownFieldLinkingCollectionsFormRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DropdownFieldLinkingCollectionsFormRowCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum DropdownFieldLinkingCollectionsFormRowCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Add Select Field Option to Dropdown Field or Radio Select Field as options for the user to select. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownFieldOption) */
export type DropdownFieldOption = Entry & _Node & {
  __typename?: 'DropdownFieldOption';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  key?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<DropdownFieldOptionLinkingCollections>;
  sys: Sys;
  value?: Maybe<Scalars['String']>;
};


/** Add Select Field Option to Dropdown Field or Radio Select Field as options for the user to select. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownFieldOption) */
export type DropdownFieldOptionContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Select Field Option to Dropdown Field or Radio Select Field as options for the user to select. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownFieldOption) */
export type DropdownFieldOptionKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Select Field Option to Dropdown Field or Radio Select Field as options for the user to select. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownFieldOption) */
export type DropdownFieldOptionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Add Select Field Option to Dropdown Field or Radio Select Field as options for the user to select. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/dropdownFieldOption) */
export type DropdownFieldOptionValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type DropdownFieldOptionCollection = {
  __typename?: 'DropdownFieldOptionCollection';
  items: Array<Maybe<DropdownFieldOption>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type DropdownFieldOptionFilter = {
  AND?: InputMaybe<Array<InputMaybe<DropdownFieldOptionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DropdownFieldOptionFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_exists?: InputMaybe<Scalars['Boolean']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DropdownFieldOptionLinkingCollections = {
  __typename?: 'DropdownFieldOptionLinkingCollections';
  dropdownFieldCollection?: Maybe<DropdownFieldCollection>;
  entryCollection?: Maybe<EntryCollection>;
  multiselectDropdownFieldCollection?: Maybe<MultiselectDropdownFieldCollection>;
  radioSelectFieldCollection?: Maybe<RadioSelectFieldCollection>;
};


export type DropdownFieldOptionLinkingCollectionsDropdownFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DropdownFieldOptionLinkingCollectionsDropdownFieldCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type DropdownFieldOptionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type DropdownFieldOptionLinkingCollectionsMultiselectDropdownFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DropdownFieldOptionLinkingCollectionsMultiselectDropdownFieldCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type DropdownFieldOptionLinkingCollectionsRadioSelectFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DropdownFieldOptionLinkingCollectionsRadioSelectFieldCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum DropdownFieldOptionLinkingCollectionsDropdownFieldCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HelpTextAsc = 'helpText_ASC',
  HelpTextDesc = 'helpText_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  ShouldResetToInitialAsc = 'shouldResetToInitial_ASC',
  ShouldResetToInitialDesc = 'shouldResetToInitial_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum DropdownFieldOptionLinkingCollectionsMultiselectDropdownFieldCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HelpTextAsc = 'helpText_ASC',
  HelpTextDesc = 'helpText_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum DropdownFieldOptionLinkingCollectionsRadioSelectFieldCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HelpTextAsc = 'helpText_ASC',
  HelpTextDesc = 'helpText_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  ShouldResetToInitialAsc = 'shouldResetToInitial_ASC',
  ShouldResetToInitialDesc = 'shouldResetToInitial_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum DropdownFieldOptionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type DropdownFieldOptionsCollection = {
  __typename?: 'DropdownFieldOptionsCollection';
  items: Array<Maybe<DropdownFieldOption>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum DropdownFieldOptionsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export enum DropdownFieldOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HelpTextAsc = 'helpText_ASC',
  HelpTextDesc = 'helpText_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  ShouldResetToInitialAsc = 'shouldResetToInitial_ASC',
  ShouldResetToInitialDesc = 'shouldResetToInitial_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Entry = {
  contentfulMetadata?: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experiment) */
export type Experiment = Entry & _Node & {
  __typename?: 'Experiment';
  _id: Scalars['ID'];
  analyticsId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  defaultReference?: Maybe<ExperimentDefaultReference>;
  experimentArmsCollection?: Maybe<ExperimentExperimentArmsCollection>;
  linkedFrom?: Maybe<ExperimentLinkingCollections>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experiment) */
export type ExperimentAnalyticsIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experiment) */
export type ExperimentContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experiment) */
export type ExperimentDefaultReferenceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experiment) */
export type ExperimentExperimentArmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExperimentExperimentArmsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExperimentArmFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experiment) */
export type ExperimentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experimentArm) */
export type ExperimentArm = Entry & _Node & {
  __typename?: 'ExperimentArm';
  _id: Scalars['ID'];
  abExperimentsCollection?: Maybe<ExperimentArmAbExperimentsCollection>;
  analyticsId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  criteria?: Maybe<Criteria>;
  linkedFrom?: Maybe<ExperimentArmLinkingCollections>;
  reference?: Maybe<ExperimentArmReference>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experimentArm) */
export type ExperimentArmAbExperimentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExperimentArmAbExperimentsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AbExperimentFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experimentArm) */
export type ExperimentArmAnalyticsIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experimentArm) */
export type ExperimentArmContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experimentArm) */
export type ExperimentArmCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experimentArm) */
export type ExperimentArmLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/experimentArm) */
export type ExperimentArmReferenceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type ExperimentArmAbExperimentsCollection = {
  __typename?: 'ExperimentArmAbExperimentsCollection';
  items: Array<Maybe<AbExperiment>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ExperimentArmAbExperimentsCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ExperimentArmCollection = {
  __typename?: 'ExperimentArmCollection';
  items: Array<Maybe<ExperimentArm>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ExperimentArmFilter = {
  AND?: InputMaybe<Array<InputMaybe<ExperimentArmFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ExperimentArmFilter>>>;
  abExperiments?: InputMaybe<CfAbExperimentNestedFilter>;
  abExperimentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria?: InputMaybe<CfCriteriaNestedFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  reference_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type ExperimentArmLinkingCollections = {
  __typename?: 'ExperimentArmLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  experimentCollection?: Maybe<ExperimentCollection>;
};


export type ExperimentArmLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ExperimentArmLinkingCollectionsExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExperimentArmLinkingCollectionsExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ExperimentArmLinkingCollectionsExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ExperimentArmOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ExperimentArmReference = Page | Redirect;

export type ExperimentCollection = {
  __typename?: 'ExperimentCollection';
  items: Array<Maybe<Experiment>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ExperimentDefaultReference = Page | Redirect;

export type ExperimentExperimentArmsCollection = {
  __typename?: 'ExperimentExperimentArmsCollection';
  items: Array<Maybe<ExperimentArm>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ExperimentExperimentArmsCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ExperimentFilter = {
  AND?: InputMaybe<Array<InputMaybe<ExperimentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ExperimentFilter>>>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  defaultReference_exists?: InputMaybe<Scalars['Boolean']>;
  experimentArms?: InputMaybe<CfExperimentArmNestedFilter>;
  experimentArmsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type ExperimentLinkingCollections = {
  __typename?: 'ExperimentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  slugCollection?: Maybe<SlugCollection>;
};


export type ExperimentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ExperimentLinkingCollectionsSlugCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExperimentLinkingCollectionsSlugCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ExperimentLinkingCollectionsSlugCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  PostedDateAsc = 'postedDate_ASC',
  PostedDateDesc = 'postedDate_DESC',
  ShareableAsc = 'shareable_ASC',
  ShareableDesc = 'shareable_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SlugTypeAsc = 'slugType_ASC',
  SlugTypeDesc = 'slugType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnlistedAsc = 'unlisted_ASC',
  UnlistedDesc = 'unlisted_DESC'
}

export enum ExperimentOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Form feature for appending additional data to form submissions [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/extraParametersFormFeature) */
export type ExtraParametersFormFeature = Entry & _Node & {
  __typename?: 'ExtraParametersFormFeature';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<ExtraParametersFormFeatureLinkingCollections>;
  sys: Sys;
  utmCampaignFieldName?: Maybe<Scalars['String']>;
  utmContentFieldName?: Maybe<Scalars['String']>;
  utmMediumFieldName?: Maybe<Scalars['String']>;
  utmSourceFieldName?: Maybe<Scalars['String']>;
  utmTermFieldName?: Maybe<Scalars['String']>;
};


/** Form feature for appending additional data to form submissions [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/extraParametersFormFeature) */
export type ExtraParametersFormFeatureContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Form feature for appending additional data to form submissions [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/extraParametersFormFeature) */
export type ExtraParametersFormFeatureLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Form feature for appending additional data to form submissions [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/extraParametersFormFeature) */
export type ExtraParametersFormFeatureUtmCampaignFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Form feature for appending additional data to form submissions [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/extraParametersFormFeature) */
export type ExtraParametersFormFeatureUtmContentFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Form feature for appending additional data to form submissions [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/extraParametersFormFeature) */
export type ExtraParametersFormFeatureUtmMediumFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Form feature for appending additional data to form submissions [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/extraParametersFormFeature) */
export type ExtraParametersFormFeatureUtmSourceFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Form feature for appending additional data to form submissions [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/extraParametersFormFeature) */
export type ExtraParametersFormFeatureUtmTermFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ExtraParametersFormFeatureCollection = {
  __typename?: 'ExtraParametersFormFeatureCollection';
  items: Array<Maybe<ExtraParametersFormFeature>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ExtraParametersFormFeatureFilter = {
  AND?: InputMaybe<Array<InputMaybe<ExtraParametersFormFeatureFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ExtraParametersFormFeatureFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  utmCampaignFieldName?: InputMaybe<Scalars['String']>;
  utmCampaignFieldName_contains?: InputMaybe<Scalars['String']>;
  utmCampaignFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmCampaignFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmCampaignFieldName_not?: InputMaybe<Scalars['String']>;
  utmCampaignFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmCampaignFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmContentFieldName?: InputMaybe<Scalars['String']>;
  utmContentFieldName_contains?: InputMaybe<Scalars['String']>;
  utmContentFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmContentFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmContentFieldName_not?: InputMaybe<Scalars['String']>;
  utmContentFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmContentFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmMediumFieldName?: InputMaybe<Scalars['String']>;
  utmMediumFieldName_contains?: InputMaybe<Scalars['String']>;
  utmMediumFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmMediumFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmMediumFieldName_not?: InputMaybe<Scalars['String']>;
  utmMediumFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmMediumFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmSourceFieldName?: InputMaybe<Scalars['String']>;
  utmSourceFieldName_contains?: InputMaybe<Scalars['String']>;
  utmSourceFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmSourceFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmSourceFieldName_not?: InputMaybe<Scalars['String']>;
  utmSourceFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmSourceFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmTermFieldName?: InputMaybe<Scalars['String']>;
  utmTermFieldName_contains?: InputMaybe<Scalars['String']>;
  utmTermFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmTermFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmTermFieldName_not?: InputMaybe<Scalars['String']>;
  utmTermFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmTermFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ExtraParametersFormFeatureLinkingCollections = {
  __typename?: 'ExtraParametersFormFeatureLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  formCollection?: Maybe<FormCollection>;
};


export type ExtraParametersFormFeatureLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ExtraParametersFormFeatureLinkingCollectionsFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExtraParametersFormFeatureLinkingCollectionsFormCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ExtraParametersFormFeatureLinkingCollectionsFormCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EndpointAsc = 'endpoint_ASC',
  EndpointDesc = 'endpoint_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PersistFieldEditsAsc = 'persistFieldEdits_ASC',
  PersistFieldEditsDesc = 'persistFieldEdits_DESC',
  PrepopulatePerQueryParamsAsc = 'prepopulatePerQueryParams_ASC',
  PrepopulatePerQueryParamsDesc = 'prepopulatePerQueryParams_DESC',
  RedirectTimeoutAsc = 'redirectTimeout_ASC',
  RedirectTimeoutDesc = 'redirectTimeout_DESC',
  RedirectUrlAsc = 'redirectUrl_ASC',
  RedirectUrlDesc = 'redirectUrl_DESC',
  ResponseTypeAsc = 'responseType_ASC',
  ResponseTypeDesc = 'responseType_DESC',
  SubmitSuccessTextAsc = 'submitSuccessText_ASC',
  SubmitSuccessTextDesc = 'submitSuccessText_DESC',
  SubmitTextAsc = 'submitText_ASC',
  SubmitTextDesc = 'submitText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ExtraParametersFormFeatureOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UtmCampaignFieldNameAsc = 'utmCampaignFieldName_ASC',
  UtmCampaignFieldNameDesc = 'utmCampaignFieldName_DESC',
  UtmContentFieldNameAsc = 'utmContentFieldName_ASC',
  UtmContentFieldNameDesc = 'utmContentFieldName_DESC',
  UtmMediumFieldNameAsc = 'utmMediumFieldName_ASC',
  UtmMediumFieldNameDesc = 'utmMediumFieldName_DESC',
  UtmSourceFieldNameAsc = 'utmSourceFieldName_ASC',
  UtmSourceFieldNameDesc = 'utmSourceFieldName_DESC',
  UtmTermFieldNameAsc = 'utmTermFieldName_ASC',
  UtmTermFieldNameDesc = 'utmTermFieldName_DESC'
}

/** Question and answer item for FAQ Page Schema. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageItemSchema) */
export type FaqPageItemSchema = Entry & _Node & {
  __typename?: 'FaqPageItemSchema';
  _id: Scalars['ID'];
  answer?: Maybe<FaqPageItemSchemaAnswer>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<FaqPageItemSchemaLinkingCollections>;
  question?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** Question and answer item for FAQ Page Schema. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageItemSchema) */
export type FaqPageItemSchemaAnswerArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Question and answer item for FAQ Page Schema. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageItemSchema) */
export type FaqPageItemSchemaContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Question and answer item for FAQ Page Schema. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageItemSchema) */
export type FaqPageItemSchemaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Question and answer item for FAQ Page Schema. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageItemSchema) */
export type FaqPageItemSchemaQuestionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqPageItemSchemaAnswer = {
  __typename?: 'FaqPageItemSchemaAnswer';
  json: Scalars['JSON'];
  links?: FaqPageItemSchemaAnswerLinks;
};

export type FaqPageItemSchemaAnswerAssets = {
  __typename?: 'FaqPageItemSchemaAnswerAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FaqPageItemSchemaAnswerEntries = {
  __typename?: 'FaqPageItemSchemaAnswerEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FaqPageItemSchemaAnswerLinks = {
  __typename?: 'FaqPageItemSchemaAnswerLinks';
  assets: FaqPageItemSchemaAnswerAssets;
  entries: FaqPageItemSchemaAnswerEntries;
  resources: FaqPageItemSchemaAnswerResources;
};

export type FaqPageItemSchemaAnswerResources = {
  __typename?: 'FaqPageItemSchemaAnswerResources';
  block: Array<FaqPageItemSchemaAnswerResourcesBlock>;
  hyperlink: Array<FaqPageItemSchemaAnswerResourcesHyperlink>;
  inline: Array<FaqPageItemSchemaAnswerResourcesInline>;
};

export type FaqPageItemSchemaAnswerResourcesBlock = ResourceLink & {
  __typename?: 'FaqPageItemSchemaAnswerResourcesBlock';
  sys: ResourceSys;
};

export type FaqPageItemSchemaAnswerResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqPageItemSchemaAnswerResourcesHyperlink';
  sys: ResourceSys;
};

export type FaqPageItemSchemaAnswerResourcesInline = ResourceLink & {
  __typename?: 'FaqPageItemSchemaAnswerResourcesInline';
  sys: ResourceSys;
};

export type FaqPageItemSchemaCollection = {
  __typename?: 'FaqPageItemSchemaCollection';
  items: Array<Maybe<FaqPageItemSchema>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqPageItemSchemaFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqPageItemSchemaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqPageItemSchemaFilter>>>;
  answer_contains?: InputMaybe<Scalars['String']>;
  answer_exists?: InputMaybe<Scalars['Boolean']>;
  answer_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  question?: InputMaybe<Scalars['String']>;
  question_contains?: InputMaybe<Scalars['String']>;
  question_exists?: InputMaybe<Scalars['Boolean']>;
  question_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  question_not?: InputMaybe<Scalars['String']>;
  question_not_contains?: InputMaybe<Scalars['String']>;
  question_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FaqPageItemSchemaLinkingCollections = {
  __typename?: 'FaqPageItemSchemaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqPageSchemaCollection?: Maybe<FaqPageSchemaCollection>;
};


export type FaqPageItemSchemaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FaqPageItemSchemaLinkingCollectionsFaqPageSchemaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqPageItemSchemaLinkingCollectionsFaqPageSchemaCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqPageItemSchemaLinkingCollectionsFaqPageSchemaCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FaqPageItemSchemaOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Model for generating a FAQ Page schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageSchema) */
export type FaqPageSchema = Entry & _Node & {
  __typename?: 'FaqPageSchema';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  itemsCollection?: Maybe<FaqPageSchemaItemsCollection>;
  linkedFrom?: Maybe<FaqPageSchemaLinkingCollections>;
  sys: Sys;
};


/** Model for generating a FAQ Page schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageSchema) */
export type FaqPageSchemaContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating a FAQ Page schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageSchema) */
export type FaqPageSchemaItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqPageSchemaItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqPageItemSchemaFilter>;
};


/** Model for generating a FAQ Page schema according to schema.org. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/faqPageSchema) */
export type FaqPageSchemaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqPageSchemaCollection = {
  __typename?: 'FaqPageSchemaCollection';
  items: Array<Maybe<FaqPageSchema>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqPageSchemaFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqPageSchemaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqPageSchemaFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  items?: InputMaybe<CfFaqPageItemSchemaNestedFilter>;
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type FaqPageSchemaItemsCollection = {
  __typename?: 'FaqPageSchemaItemsCollection';
  items: Array<Maybe<FaqPageItemSchema>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqPageSchemaItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type FaqPageSchemaLinkingCollections = {
  __typename?: 'FaqPageSchemaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  metasCollection?: Maybe<MetasCollection>;
};


export type FaqPageSchemaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FaqPageSchemaLinkingCollectionsMetasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqPageSchemaLinkingCollectionsMetasCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqPageSchemaLinkingCollectionsMetasCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FaqPageSchemaOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Add favicon to site [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/favicon) */
export type Favicon = Entry & _Node & {
  __typename?: 'Favicon';
  _id: Scalars['ID'];
  asset?: Maybe<Asset>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<FaviconLinkingCollections>;
  size?: Maybe<Scalars['Int']>;
  sys: Sys;
};


/** Add favicon to site [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/favicon) */
export type FaviconAssetArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Add favicon to site [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/favicon) */
export type FaviconContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add favicon to site [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/favicon) */
export type FaviconLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Add favicon to site [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/favicon) */
export type FaviconSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaviconCollection = {
  __typename?: 'FaviconCollection';
  items: Array<Maybe<Favicon>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaviconFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaviconFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaviconFilter>>>;
  asset_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  size?: InputMaybe<Scalars['Int']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_gt?: InputMaybe<Scalars['Int']>;
  size_gte?: InputMaybe<Scalars['Int']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt?: InputMaybe<Scalars['Int']>;
  size_lte?: InputMaybe<Scalars['Int']>;
  size_not?: InputMaybe<Scalars['Int']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FaviconLinkingCollections = {
  __typename?: 'FaviconLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type FaviconLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaviconOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Engineering feature flags for rolling out new features. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featureFlags) */
export type FeatureFlags = Entry & _Node & {
  __typename?: 'FeatureFlags';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  featureFlags?: Maybe<Scalars['JSON']>;
  linkedFrom?: Maybe<FeatureFlagsLinkingCollections>;
  sys: Sys;
};


/** Engineering feature flags for rolling out new features. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featureFlags) */
export type FeatureFlagsContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Engineering feature flags for rolling out new features. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featureFlags) */
export type FeatureFlagsFeatureFlagsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Engineering feature flags for rolling out new features. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featureFlags) */
export type FeatureFlagsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FeatureFlagsCollection = {
  __typename?: 'FeatureFlagsCollection';
  items: Array<Maybe<FeatureFlags>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeatureFlagsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeatureFlagsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FeatureFlagsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featureFlags_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type FeatureFlagsLinkingCollections = {
  __typename?: 'FeatureFlagsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  sitewideValuesCollection?: Maybe<SitewideValuesCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type FeatureFlagsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FeatureFlagsLinkingCollectionsSitewideValuesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeatureFlagsLinkingCollectionsSitewideValuesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FeatureFlagsLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeatureFlagsLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FeatureFlagsLinkingCollectionsSitewideValuesCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FeatureFlagsLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FeatureFlagsOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItem = Entry & _Node & {
  __typename?: 'FeaturedHeaderMenuItem';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  ctaLabel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hideCriteria?: Maybe<Criteria>;
  icon?: Maybe<Icon>;
  linkedFrom?: Maybe<FeaturedHeaderMenuItemLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemCtaLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemHideCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<IconFilter>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Featured navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/featuredHeaderMenuItem) */
export type FeaturedHeaderMenuItemUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FeaturedHeaderMenuItemCollection = {
  __typename?: 'FeaturedHeaderMenuItemCollection';
  items: Array<Maybe<FeaturedHeaderMenuItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeaturedHeaderMenuItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedHeaderMenuItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FeaturedHeaderMenuItemFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  ctaLabel?: InputMaybe<Scalars['String']>;
  ctaLabel_contains?: InputMaybe<Scalars['String']>;
  ctaLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ctaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaLabel_not?: InputMaybe<Scalars['String']>;
  ctaLabel_not_contains?: InputMaybe<Scalars['String']>;
  ctaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hideCriteria?: InputMaybe<CfCriteriaNestedFilter>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<CfIconNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FeaturedHeaderMenuItemLinkingCollections = {
  __typename?: 'FeaturedHeaderMenuItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  headerMenuItemGroupCollection?: Maybe<HeaderMenuItemGroupCollection>;
};


export type FeaturedHeaderMenuItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FeaturedHeaderMenuItemLinkingCollectionsHeaderMenuItemGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeaturedHeaderMenuItemLinkingCollectionsHeaderMenuItemGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FeaturedHeaderMenuItemLinkingCollectionsHeaderMenuItemGroupCollectionOrder {
  Column1LabelAsc = 'column1Label_ASC',
  Column1LabelDesc = 'column1Label_DESC',
  Column2LabelAsc = 'column2Label_ASC',
  Column2LabelDesc = 'column2Label_DESC',
  Column3LabelAsc = 'column3Label_ASC',
  Column3LabelDesc = 'column3Label_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum FeaturedHeaderMenuItemOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CtaLabelAsc = 'ctaLabel_ASC',
  CtaLabelDesc = 'ctaLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Add a footnote if additional context is necessary. It will be added to the bottom of the page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footNotes) */
export type FootNotes = Entry & _Node & {
  __typename?: 'FootNotes';
  _id: Scalars['ID'];
  anchorId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  displayText?: Maybe<Scalars['String']>;
  fullText?: Maybe<FootNotesFullText>;
  linkedFrom?: Maybe<FootNotesLinkingCollections>;
  number?: Maybe<Scalars['Int']>;
  sys: Sys;
};


/** Add a footnote if additional context is necessary. It will be added to the bottom of the page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footNotes) */
export type FootNotesAnchorIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a footnote if additional context is necessary. It will be added to the bottom of the page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footNotes) */
export type FootNotesContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a footnote if additional context is necessary. It will be added to the bottom of the page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footNotes) */
export type FootNotesDisplayTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a footnote if additional context is necessary. It will be added to the bottom of the page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footNotes) */
export type FootNotesFullTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a footnote if additional context is necessary. It will be added to the bottom of the page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footNotes) */
export type FootNotesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Add a footnote if additional context is necessary. It will be added to the bottom of the page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footNotes) */
export type FootNotesNumberArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FootNotesCollection = {
  __typename?: 'FootNotesCollection';
  items: Array<Maybe<FootNotes>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FootNotesFilter = {
  AND?: InputMaybe<Array<InputMaybe<FootNotesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FootNotesFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  displayText?: InputMaybe<Scalars['String']>;
  displayText_contains?: InputMaybe<Scalars['String']>;
  displayText_exists?: InputMaybe<Scalars['Boolean']>;
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayText_not?: InputMaybe<Scalars['String']>;
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullText_contains?: InputMaybe<Scalars['String']>;
  fullText_exists?: InputMaybe<Scalars['Boolean']>;
  fullText_not_contains?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  number_exists?: InputMaybe<Scalars['Boolean']>;
  number_gt?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
  number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  number_lt?: InputMaybe<Scalars['Int']>;
  number_lte?: InputMaybe<Scalars['Int']>;
  number_not?: InputMaybe<Scalars['Int']>;
  number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FootNotesFullText = {
  __typename?: 'FootNotesFullText';
  json: Scalars['JSON'];
  links?: FootNotesFullTextLinks;
};

export type FootNotesFullTextAssets = {
  __typename?: 'FootNotesFullTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FootNotesFullTextEntries = {
  __typename?: 'FootNotesFullTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FootNotesFullTextLinks = {
  __typename?: 'FootNotesFullTextLinks';
  assets: FootNotesFullTextAssets;
  entries: FootNotesFullTextEntries;
  resources: FootNotesFullTextResources;
};

export type FootNotesFullTextResources = {
  __typename?: 'FootNotesFullTextResources';
  block: Array<FootNotesFullTextResourcesBlock>;
  hyperlink: Array<FootNotesFullTextResourcesHyperlink>;
  inline: Array<FootNotesFullTextResourcesInline>;
};

export type FootNotesFullTextResourcesBlock = ResourceLink & {
  __typename?: 'FootNotesFullTextResourcesBlock';
  sys: ResourceSys;
};

export type FootNotesFullTextResourcesHyperlink = ResourceLink & {
  __typename?: 'FootNotesFullTextResourcesHyperlink';
  sys: ResourceSys;
};

export type FootNotesFullTextResourcesInline = ResourceLink & {
  __typename?: 'FootNotesFullTextResourcesInline';
  sys: ResourceSys;
};

export type FootNotesLinkingCollections = {
  __typename?: 'FootNotesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type FootNotesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FootNotesLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FootNotesLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FootNotesLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum FootNotesOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayTextAsc = 'displayText_ASC',
  DisplayTextDesc = 'displayText_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Footer Cookie Settings Link model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerCookiesSettingsLink) */
export type FooterCookiesSettingsLink = Entry & _Node & {
  __typename?: 'FooterCookiesSettingsLink';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  criteria?: Maybe<Criteria>;
  linkedFrom?: Maybe<FooterCookiesSettingsLinkLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Footer Cookie Settings Link model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerCookiesSettingsLink) */
export type FooterCookiesSettingsLinkAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Footer Cookie Settings Link model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerCookiesSettingsLink) */
export type FooterCookiesSettingsLinkContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Footer Cookie Settings Link model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerCookiesSettingsLink) */
export type FooterCookiesSettingsLinkCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** Footer Cookie Settings Link model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerCookiesSettingsLink) */
export type FooterCookiesSettingsLinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Footer Cookie Settings Link model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerCookiesSettingsLink) */
export type FooterCookiesSettingsLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FooterCookiesSettingsLinkCollection = {
  __typename?: 'FooterCookiesSettingsLinkCollection';
  items: Array<Maybe<FooterCookiesSettingsLink>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FooterCookiesSettingsLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterCookiesSettingsLinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterCookiesSettingsLinkFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria?: InputMaybe<CfCriteriaNestedFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FooterCookiesSettingsLinkLinkingCollections = {
  __typename?: 'FooterCookiesSettingsLinkLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  footerGroupCollection?: Maybe<FooterGroupCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type FooterCookiesSettingsLinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FooterCookiesSettingsLinkLinkingCollectionsFooterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterCookiesSettingsLinkLinkingCollectionsFooterGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FooterCookiesSettingsLinkLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterCookiesSettingsLinkLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FooterCookiesSettingsLinkLinkingCollectionsFooterGroupCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  GroupKeyAsc = 'groupKey_ASC',
  GroupKeyDesc = 'groupKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum FooterCookiesSettingsLinkLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FooterCookiesSettingsLinkOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** This model will hold and group footer items [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerGroup) */
export type FooterGroup = Entry & _Node & {
  __typename?: 'FooterGroup';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  criteria?: Maybe<Criteria>;
  groupKey?: Maybe<Scalars['String']>;
  itemsCollection?: Maybe<FooterGroupItemsCollection>;
  linkedFrom?: Maybe<FooterGroupLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** This model will hold and group footer items [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerGroup) */
export type FooterGroupAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** This model will hold and group footer items [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerGroup) */
export type FooterGroupContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** This model will hold and group footer items [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerGroup) */
export type FooterGroupCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** This model will hold and group footer items [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerGroup) */
export type FooterGroupGroupKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** This model will hold and group footer items [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerGroup) */
export type FooterGroupItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterGroupItemsFilter>;
};


/** This model will hold and group footer items [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerGroup) */
export type FooterGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** This model will hold and group footer items [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerGroup) */
export type FooterGroupTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FooterGroupCollection = {
  __typename?: 'FooterGroupCollection';
  items: Array<Maybe<FooterGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FooterGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterGroupFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterGroupFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria?: InputMaybe<CfCriteriaNestedFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  groupKey?: InputMaybe<Scalars['String']>;
  groupKey_contains?: InputMaybe<Scalars['String']>;
  groupKey_exists?: InputMaybe<Scalars['Boolean']>;
  groupKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupKey_not?: InputMaybe<Scalars['String']>;
  groupKey_not_contains?: InputMaybe<Scalars['String']>;
  groupKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  items?: InputMaybe<CfitemsMultiTypeNestedFilter>;
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FooterGroupItemsCollection = {
  __typename?: 'FooterGroupItemsCollection';
  items: Array<Maybe<FooterGroupItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FooterGroupItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterGroupItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterGroupItemsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FooterGroupItemsItem = FooterCookiesSettingsLink | FooterItem | FooterLocaleDropdown;

export type FooterGroupLinkingCollections = {
  __typename?: 'FooterGroupLinkingCollections';
  customFooterCollection?: Maybe<CustomFooterCollection>;
  entryCollection?: Maybe<EntryCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type FooterGroupLinkingCollectionsCustomFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterGroupLinkingCollectionsCustomFooterCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FooterGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FooterGroupLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterGroupLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FooterGroupLinkingCollectionsCustomFooterCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SocialLabelAsc = 'socialLabel_ASC',
  SocialLabelDesc = 'socialLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum FooterGroupLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FooterGroupOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  GroupKeyAsc = 'groupKey_ASC',
  GroupKeyDesc = 'groupKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Footer item for the Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerItem) */
export type FooterItem = Entry & _Node & {
  __typename?: 'FooterItem';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  criteria?: Maybe<Criteria>;
  linkedFrom?: Maybe<FooterItemLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Footer item for the Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerItem) */
export type FooterItemAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Footer item for the Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerItem) */
export type FooterItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Footer item for the Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerItem) */
export type FooterItemCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** Footer item for the Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerItem) */
export type FooterItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Footer item for the Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerItem) */
export type FooterItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Footer item for the Custom Footer [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerItem) */
export type FooterItemUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FooterItemCollection = {
  __typename?: 'FooterItemCollection';
  items: Array<Maybe<FooterItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FooterItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterItemFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria?: InputMaybe<CfCriteriaNestedFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FooterItemLinkingCollections = {
  __typename?: 'FooterItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  footerGroupCollection?: Maybe<FooterGroupCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type FooterItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FooterItemLinkingCollectionsFooterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterItemLinkingCollectionsFooterGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FooterItemLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterItemLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FooterItemLinkingCollectionsFooterGroupCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  GroupKeyAsc = 'groupKey_ASC',
  GroupKeyDesc = 'groupKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum FooterItemLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FooterItemOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Footer Locale Dropdown model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerLocaleDropdown) */
export type FooterLocaleDropdown = Entry & _Node & {
  __typename?: 'FooterLocaleDropdown';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  criteria?: Maybe<Criteria>;
  linkedFrom?: Maybe<FooterLocaleDropdownLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Footer Locale Dropdown model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerLocaleDropdown) */
export type FooterLocaleDropdownContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Footer Locale Dropdown model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerLocaleDropdown) */
export type FooterLocaleDropdownCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** Footer Locale Dropdown model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerLocaleDropdown) */
export type FooterLocaleDropdownLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Footer Locale Dropdown model [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/footerLocaleDropdown) */
export type FooterLocaleDropdownTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FooterLocaleDropdownCollection = {
  __typename?: 'FooterLocaleDropdownCollection';
  items: Array<Maybe<FooterLocaleDropdown>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FooterLocaleDropdownFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterLocaleDropdownFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterLocaleDropdownFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria?: InputMaybe<CfCriteriaNestedFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FooterLocaleDropdownLinkingCollections = {
  __typename?: 'FooterLocaleDropdownLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  footerGroupCollection?: Maybe<FooterGroupCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type FooterLocaleDropdownLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FooterLocaleDropdownLinkingCollectionsFooterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterLocaleDropdownLinkingCollectionsFooterGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FooterLocaleDropdownLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterLocaleDropdownLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FooterLocaleDropdownLinkingCollectionsFooterGroupCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  GroupKeyAsc = 'groupKey_ASC',
  GroupKeyDesc = 'groupKey_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum FooterLocaleDropdownLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FooterLocaleDropdownOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type Form = Entry & _Node & {
  __typename?: 'Form';
  _id: Scalars['ID'];
  additionalFeaturesCollection?: Maybe<FormAdditionalFeaturesCollection>;
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  endpoint?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<FormLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  persistFieldEdits?: Maybe<Scalars['Boolean']>;
  prepopulatePerQueryParams?: Maybe<Scalars['Boolean']>;
  redirectTimeout?: Maybe<Scalars['Int']>;
  redirectUrl?: Maybe<Scalars['String']>;
  responseType?: Maybe<Scalars['String']>;
  rowsCollection?: Maybe<FormRowsCollection>;
  submitSuccessText?: Maybe<Scalars['String']>;
  submitText?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormAdditionalFeaturesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FormAdditionalFeaturesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtraParametersFormFeatureFilter>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormEndpointArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormPersistFieldEditsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormPrepopulatePerQueryParamsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormRedirectTimeoutArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormRedirectUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormResponseTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormRowsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FormRowsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FormRowFilter>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormSubmitSuccessTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a form to collect information from a user. A new form will require a security review. https://wiki.sc-corp.net/pages/viewpage.action?pageId=157456843 [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/form) */
export type FormSubmitTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FormAdditionalFeaturesCollection = {
  __typename?: 'FormAdditionalFeaturesCollection';
  items: Array<Maybe<ExtraParametersFormFeature>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FormAdditionalFeaturesCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UtmCampaignFieldNameAsc = 'utmCampaignFieldName_ASC',
  UtmCampaignFieldNameDesc = 'utmCampaignFieldName_DESC',
  UtmContentFieldNameAsc = 'utmContentFieldName_ASC',
  UtmContentFieldNameDesc = 'utmContentFieldName_DESC',
  UtmMediumFieldNameAsc = 'utmMediumFieldName_ASC',
  UtmMediumFieldNameDesc = 'utmMediumFieldName_DESC',
  UtmSourceFieldNameAsc = 'utmSourceFieldName_ASC',
  UtmSourceFieldNameDesc = 'utmSourceFieldName_DESC',
  UtmTermFieldNameAsc = 'utmTermFieldName_ASC',
  UtmTermFieldNameDesc = 'utmTermFieldName_DESC'
}

export type FormCollection = {
  __typename?: 'FormCollection';
  items: Array<Maybe<Form>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FormFilter = {
  AND?: InputMaybe<Array<InputMaybe<FormFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FormFilter>>>;
  additionalFeatures?: InputMaybe<CfExtraParametersFormFeatureNestedFilter>;
  additionalFeaturesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  endpoint?: InputMaybe<Scalars['String']>;
  endpoint_contains?: InputMaybe<Scalars['String']>;
  endpoint_exists?: InputMaybe<Scalars['Boolean']>;
  endpoint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endpoint_not?: InputMaybe<Scalars['String']>;
  endpoint_not_contains?: InputMaybe<Scalars['String']>;
  endpoint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  persistFieldEdits?: InputMaybe<Scalars['Boolean']>;
  persistFieldEdits_exists?: InputMaybe<Scalars['Boolean']>;
  persistFieldEdits_not?: InputMaybe<Scalars['Boolean']>;
  prepopulatePerQueryParams?: InputMaybe<Scalars['Boolean']>;
  prepopulatePerQueryParams_exists?: InputMaybe<Scalars['Boolean']>;
  prepopulatePerQueryParams_not?: InputMaybe<Scalars['Boolean']>;
  redirectTimeout?: InputMaybe<Scalars['Int']>;
  redirectTimeout_exists?: InputMaybe<Scalars['Boolean']>;
  redirectTimeout_gt?: InputMaybe<Scalars['Int']>;
  redirectTimeout_gte?: InputMaybe<Scalars['Int']>;
  redirectTimeout_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  redirectTimeout_lt?: InputMaybe<Scalars['Int']>;
  redirectTimeout_lte?: InputMaybe<Scalars['Int']>;
  redirectTimeout_not?: InputMaybe<Scalars['Int']>;
  redirectTimeout_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  redirectUrl_contains?: InputMaybe<Scalars['String']>;
  redirectUrl_exists?: InputMaybe<Scalars['Boolean']>;
  redirectUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirectUrl_not?: InputMaybe<Scalars['String']>;
  redirectUrl_not_contains?: InputMaybe<Scalars['String']>;
  redirectUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  responseType?: InputMaybe<Scalars['String']>;
  responseType_contains?: InputMaybe<Scalars['String']>;
  responseType_exists?: InputMaybe<Scalars['Boolean']>;
  responseType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  responseType_not?: InputMaybe<Scalars['String']>;
  responseType_not_contains?: InputMaybe<Scalars['String']>;
  responseType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rows?: InputMaybe<CfFormRowNestedFilter>;
  rowsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  submitSuccessText?: InputMaybe<Scalars['String']>;
  submitSuccessText_contains?: InputMaybe<Scalars['String']>;
  submitSuccessText_exists?: InputMaybe<Scalars['Boolean']>;
  submitSuccessText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  submitSuccessText_not?: InputMaybe<Scalars['String']>;
  submitSuccessText_not_contains?: InputMaybe<Scalars['String']>;
  submitSuccessText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  submitText?: InputMaybe<Scalars['String']>;
  submitText_contains?: InputMaybe<Scalars['String']>;
  submitText_exists?: InputMaybe<Scalars['Boolean']>;
  submitText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  submitText_not?: InputMaybe<Scalars['String']>;
  submitText_not_contains?: InputMaybe<Scalars['String']>;
  submitText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FormLinkingCollections = {
  __typename?: 'FormLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
  tabItemCollection?: Maybe<TabItemCollection>;
};


export type FormLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FormLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FormLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FormLinkingCollectionsTabItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FormLinkingCollectionsTabItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FormLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum FormLinkingCollectionsTabItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum FormOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EndpointAsc = 'endpoint_ASC',
  EndpointDesc = 'endpoint_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PersistFieldEditsAsc = 'persistFieldEdits_ASC',
  PersistFieldEditsDesc = 'persistFieldEdits_DESC',
  PrepopulatePerQueryParamsAsc = 'prepopulatePerQueryParams_ASC',
  PrepopulatePerQueryParamsDesc = 'prepopulatePerQueryParams_DESC',
  RedirectTimeoutAsc = 'redirectTimeout_ASC',
  RedirectTimeoutDesc = 'redirectTimeout_DESC',
  RedirectUrlAsc = 'redirectUrl_ASC',
  RedirectUrlDesc = 'redirectUrl_DESC',
  ResponseTypeAsc = 'responseType_ASC',
  ResponseTypeDesc = 'responseType_DESC',
  SubmitSuccessTextAsc = 'submitSuccessText_ASC',
  SubmitSuccessTextDesc = 'submitSuccessText_DESC',
  SubmitTextAsc = 'submitText_ASC',
  SubmitTextDesc = 'submitText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Add Rows as building blocks to a Form. Consists of Dropdowns, Inputs, Submit Buttons. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/formRow) */
export type FormRow = Entry & _Node & {
  __typename?: 'FormRow';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  fieldsCollection?: Maybe<FormRowFieldsCollection>;
  linkedFrom?: Maybe<FormRowLinkingCollections>;
  rowAsText?: Maybe<FormRowRowAsText>;
  sys: Sys;
};


/** Add Rows as building blocks to a Form. Consists of Dropdowns, Inputs, Submit Buttons. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/formRow) */
export type FormRowContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add Rows as building blocks to a Form. Consists of Dropdowns, Inputs, Submit Buttons. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/formRow) */
export type FormRowFieldsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FormRowFieldsFilter>;
};


/** Add Rows as building blocks to a Form. Consists of Dropdowns, Inputs, Submit Buttons. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/formRow) */
export type FormRowLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Add Rows as building blocks to a Form. Consists of Dropdowns, Inputs, Submit Buttons. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/formRow) */
export type FormRowRowAsTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FormRowCollection = {
  __typename?: 'FormRowCollection';
  items: Array<Maybe<FormRow>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FormRowFieldsCollection = {
  __typename?: 'FormRowFieldsCollection';
  items: Array<Maybe<FormRowFieldsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FormRowFieldsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FormRowFieldsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FormRowFieldsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FormRowFieldsItem = DropdownField | InputField | MultiselectDropdownField | PresetDropdownField | RadioSelectField | SubmitButton;

export type FormRowFilter = {
  AND?: InputMaybe<Array<InputMaybe<FormRowFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FormRowFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fields?: InputMaybe<CffieldsMultiTypeNestedFilter>;
  fieldsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  rowAsText_contains?: InputMaybe<Scalars['String']>;
  rowAsText_exists?: InputMaybe<Scalars['Boolean']>;
  rowAsText_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
};

export type FormRowLinkingCollections = {
  __typename?: 'FormRowLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  formCollection?: Maybe<FormCollection>;
};


export type FormRowLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FormRowLinkingCollectionsFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FormRowLinkingCollectionsFormCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FormRowLinkingCollectionsFormCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EndpointAsc = 'endpoint_ASC',
  EndpointDesc = 'endpoint_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PersistFieldEditsAsc = 'persistFieldEdits_ASC',
  PersistFieldEditsDesc = 'persistFieldEdits_DESC',
  PrepopulatePerQueryParamsAsc = 'prepopulatePerQueryParams_ASC',
  PrepopulatePerQueryParamsDesc = 'prepopulatePerQueryParams_DESC',
  RedirectTimeoutAsc = 'redirectTimeout_ASC',
  RedirectTimeoutDesc = 'redirectTimeout_DESC',
  RedirectUrlAsc = 'redirectUrl_ASC',
  RedirectUrlDesc = 'redirectUrl_DESC',
  ResponseTypeAsc = 'responseType_ASC',
  ResponseTypeDesc = 'responseType_DESC',
  SubmitSuccessTextAsc = 'submitSuccessText_ASC',
  SubmitSuccessTextDesc = 'submitSuccessText_DESC',
  SubmitTextAsc = 'submitText_ASC',
  SubmitTextDesc = 'submitText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FormRowOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type FormRowRowAsText = {
  __typename?: 'FormRowRowAsText';
  json: Scalars['JSON'];
  links?: FormRowRowAsTextLinks;
};

export type FormRowRowAsTextAssets = {
  __typename?: 'FormRowRowAsTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FormRowRowAsTextEntries = {
  __typename?: 'FormRowRowAsTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FormRowRowAsTextLinks = {
  __typename?: 'FormRowRowAsTextLinks';
  assets: FormRowRowAsTextAssets;
  entries: FormRowRowAsTextEntries;
  resources: FormRowRowAsTextResources;
};

export type FormRowRowAsTextResources = {
  __typename?: 'FormRowRowAsTextResources';
  block: Array<FormRowRowAsTextResourcesBlock>;
  hyperlink: Array<FormRowRowAsTextResourcesHyperlink>;
  inline: Array<FormRowRowAsTextResourcesInline>;
};

export type FormRowRowAsTextResourcesBlock = ResourceLink & {
  __typename?: 'FormRowRowAsTextResourcesBlock';
  sys: ResourceSys;
};

export type FormRowRowAsTextResourcesHyperlink = ResourceLink & {
  __typename?: 'FormRowRowAsTextResourcesHyperlink';
  sys: ResourceSys;
};

export type FormRowRowAsTextResourcesInline = ResourceLink & {
  __typename?: 'FormRowRowAsTextResourcesInline';
  sys: ResourceSys;
};

export type FormRowsCollection = {
  __typename?: 'FormRowsCollection';
  items: Array<Maybe<FormRow>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FormRowsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type Gallery = Entry & _Node & {
  __typename?: 'Gallery';
  _id: Scalars['ID'];
  clearFiltersCtaCopy?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  defaultImage?: Maybe<Image>;
  dropdownsCollection?: Maybe<GalleryDropdownsCollection>;
  emptyPlaceholder?: Maybe<Scalars['String']>;
  hideFilters?: Maybe<Scalars['Boolean']>;
  hidePagination?: Maybe<Scalars['Boolean']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<GalleryLinkingCollections>;
  loadMoreCtaCopy?: Maybe<Scalars['String']>;
  mobileFiltersWrapperText?: Maybe<Scalars['String']>;
  showDates?: Maybe<Scalars['Boolean']>;
  showDescriptions?: Maybe<Scalars['Boolean']>;
  showMedia?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<Scalars['String']>;
  sys: Sys;
  tilesCollection?: Maybe<GalleryTilesCollection>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryClearFiltersCtaCopyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryDefaultImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ImageFilter>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryDropdownsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryDropdownsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GalleryDropdownFilter>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryEmptyPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryHideFiltersArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryHidePaginationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryItemsPerPageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryLoadMoreCtaCopyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryMobileFiltersWrapperTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryShowDatesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryShowDescriptionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryShowMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GallerySortByArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Gallery of posts with dropdowns to filter the posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/gallery) */
export type GalleryTilesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryTilesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TileFilter>;
};

export type GalleryCollection = {
  __typename?: 'GalleryCollection';
  items: Array<Maybe<Gallery>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdown) */
export type GalleryDropdown = Entry & _Node & {
  __typename?: 'GalleryDropdown';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  dropdownItemsCollection?: Maybe<GalleryDropdownDropdownItemsCollection>;
  linkedFrom?: Maybe<GalleryDropdownLinkingCollections>;
  matchAllOptionsTitle?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdown) */
export type GalleryDropdownContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdown) */
export type GalleryDropdownDropdownItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryDropdownDropdownItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GalleryDropdownItemFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdown) */
export type GalleryDropdownLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdown) */
export type GalleryDropdownMatchAllOptionsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdown) */
export type GalleryDropdownTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type GalleryDropdownCollection = {
  __typename?: 'GalleryDropdownCollection';
  items: Array<Maybe<GalleryDropdown>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GalleryDropdownDropdownItemsCollection = {
  __typename?: 'GalleryDropdownDropdownItemsCollection';
  items: Array<Maybe<GalleryDropdownItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum GalleryDropdownDropdownItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type GalleryDropdownFilter = {
  AND?: InputMaybe<Array<InputMaybe<GalleryDropdownFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GalleryDropdownFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dropdownItems?: InputMaybe<CfGalleryDropdownItemNestedFilter>;
  dropdownItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  matchAllOptionsTitle?: InputMaybe<Scalars['String']>;
  matchAllOptionsTitle_contains?: InputMaybe<Scalars['String']>;
  matchAllOptionsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  matchAllOptionsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  matchAllOptionsTitle_not?: InputMaybe<Scalars['String']>;
  matchAllOptionsTitle_not_contains?: InputMaybe<Scalars['String']>;
  matchAllOptionsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdownItem) */
export type GalleryDropdownItem = Entry & _Node & {
  __typename?: 'GalleryDropdownItem';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<GalleryDropdownItemLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdownItem) */
export type GalleryDropdownItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdownItem) */
export type GalleryDropdownItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/galleryDropdownItem) */
export type GalleryDropdownItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type GalleryDropdownItemCollection = {
  __typename?: 'GalleryDropdownItemCollection';
  items: Array<Maybe<GalleryDropdownItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GalleryDropdownItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<GalleryDropdownItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GalleryDropdownItemFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GalleryDropdownItemLinkingCollections = {
  __typename?: 'GalleryDropdownItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  galleryDropdownCollection?: Maybe<GalleryDropdownCollection>;
};


export type GalleryDropdownItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type GalleryDropdownItemLinkingCollectionsGalleryDropdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryDropdownItemLinkingCollectionsGalleryDropdownCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GalleryDropdownItemLinkingCollectionsGalleryDropdownCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MatchAllOptionsTitleAsc = 'matchAllOptionsTitle_ASC',
  MatchAllOptionsTitleDesc = 'matchAllOptionsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum GalleryDropdownItemOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type GalleryDropdownLinkingCollections = {
  __typename?: 'GalleryDropdownLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  galleryCollection?: Maybe<GalleryCollection>;
};


export type GalleryDropdownLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type GalleryDropdownLinkingCollectionsGalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryDropdownLinkingCollectionsGalleryCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GalleryDropdownLinkingCollectionsGalleryCollectionOrder {
  ClearFiltersCtaCopyAsc = 'clearFiltersCtaCopy_ASC',
  ClearFiltersCtaCopyDesc = 'clearFiltersCtaCopy_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EmptyPlaceholderAsc = 'emptyPlaceholder_ASC',
  EmptyPlaceholderDesc = 'emptyPlaceholder_DESC',
  HideFiltersAsc = 'hideFilters_ASC',
  HideFiltersDesc = 'hideFilters_DESC',
  HidePaginationAsc = 'hidePagination_ASC',
  HidePaginationDesc = 'hidePagination_DESC',
  ItemsPerPageAsc = 'itemsPerPage_ASC',
  ItemsPerPageDesc = 'itemsPerPage_DESC',
  LoadMoreCtaCopyAsc = 'loadMoreCtaCopy_ASC',
  LoadMoreCtaCopyDesc = 'loadMoreCtaCopy_DESC',
  MobileFiltersWrapperTextAsc = 'mobileFiltersWrapperText_ASC',
  MobileFiltersWrapperTextDesc = 'mobileFiltersWrapperText_DESC',
  ShowDatesAsc = 'showDates_ASC',
  ShowDatesDesc = 'showDates_DESC',
  ShowDescriptionsAsc = 'showDescriptions_ASC',
  ShowDescriptionsDesc = 'showDescriptions_DESC',
  ShowMediaAsc = 'showMedia_ASC',
  ShowMediaDesc = 'showMedia_DESC',
  SortByAsc = 'sortBy_ASC',
  SortByDesc = 'sortBy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum GalleryDropdownOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MatchAllOptionsTitleAsc = 'matchAllOptionsTitle_ASC',
  MatchAllOptionsTitleDesc = 'matchAllOptionsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type GalleryDropdownsCollection = {
  __typename?: 'GalleryDropdownsCollection';
  items: Array<Maybe<GalleryDropdown>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum GalleryDropdownsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MatchAllOptionsTitleAsc = 'matchAllOptionsTitle_ASC',
  MatchAllOptionsTitleDesc = 'matchAllOptionsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type GalleryFilter = {
  AND?: InputMaybe<Array<InputMaybe<GalleryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GalleryFilter>>>;
  clearFiltersCtaCopy?: InputMaybe<Scalars['String']>;
  clearFiltersCtaCopy_contains?: InputMaybe<Scalars['String']>;
  clearFiltersCtaCopy_exists?: InputMaybe<Scalars['Boolean']>;
  clearFiltersCtaCopy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clearFiltersCtaCopy_not?: InputMaybe<Scalars['String']>;
  clearFiltersCtaCopy_not_contains?: InputMaybe<Scalars['String']>;
  clearFiltersCtaCopy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  defaultImage?: InputMaybe<CfImageNestedFilter>;
  defaultImage_exists?: InputMaybe<Scalars['Boolean']>;
  dropdowns?: InputMaybe<CfGalleryDropdownNestedFilter>;
  dropdownsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  emptyPlaceholder?: InputMaybe<Scalars['String']>;
  emptyPlaceholder_contains?: InputMaybe<Scalars['String']>;
  emptyPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  emptyPlaceholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  emptyPlaceholder_not?: InputMaybe<Scalars['String']>;
  emptyPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  emptyPlaceholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hideFilters?: InputMaybe<Scalars['Boolean']>;
  hideFilters_exists?: InputMaybe<Scalars['Boolean']>;
  hideFilters_not?: InputMaybe<Scalars['Boolean']>;
  hidePagination?: InputMaybe<Scalars['Boolean']>;
  hidePagination_exists?: InputMaybe<Scalars['Boolean']>;
  hidePagination_not?: InputMaybe<Scalars['Boolean']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  itemsPerPage_exists?: InputMaybe<Scalars['Boolean']>;
  itemsPerPage_gt?: InputMaybe<Scalars['Int']>;
  itemsPerPage_gte?: InputMaybe<Scalars['Int']>;
  itemsPerPage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  itemsPerPage_lt?: InputMaybe<Scalars['Int']>;
  itemsPerPage_lte?: InputMaybe<Scalars['Int']>;
  itemsPerPage_not?: InputMaybe<Scalars['Int']>;
  itemsPerPage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  loadMoreCtaCopy?: InputMaybe<Scalars['String']>;
  loadMoreCtaCopy_contains?: InputMaybe<Scalars['String']>;
  loadMoreCtaCopy_exists?: InputMaybe<Scalars['Boolean']>;
  loadMoreCtaCopy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loadMoreCtaCopy_not?: InputMaybe<Scalars['String']>;
  loadMoreCtaCopy_not_contains?: InputMaybe<Scalars['String']>;
  loadMoreCtaCopy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileFiltersWrapperText?: InputMaybe<Scalars['String']>;
  mobileFiltersWrapperText_contains?: InputMaybe<Scalars['String']>;
  mobileFiltersWrapperText_exists?: InputMaybe<Scalars['Boolean']>;
  mobileFiltersWrapperText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileFiltersWrapperText_not?: InputMaybe<Scalars['String']>;
  mobileFiltersWrapperText_not_contains?: InputMaybe<Scalars['String']>;
  mobileFiltersWrapperText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showDates?: InputMaybe<Scalars['Boolean']>;
  showDates_exists?: InputMaybe<Scalars['Boolean']>;
  showDates_not?: InputMaybe<Scalars['Boolean']>;
  showDescriptions?: InputMaybe<Scalars['Boolean']>;
  showDescriptions_exists?: InputMaybe<Scalars['Boolean']>;
  showDescriptions_not?: InputMaybe<Scalars['Boolean']>;
  showMedia?: InputMaybe<Scalars['Boolean']>;
  showMedia_exists?: InputMaybe<Scalars['Boolean']>;
  showMedia_not?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortBy_contains?: InputMaybe<Scalars['String']>;
  sortBy_exists?: InputMaybe<Scalars['Boolean']>;
  sortBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortBy_not?: InputMaybe<Scalars['String']>;
  sortBy_not_contains?: InputMaybe<Scalars['String']>;
  sortBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  tiles?: InputMaybe<CfTileNestedFilter>;
  tilesCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type GalleryLinkingCollections = {
  __typename?: 'GalleryLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type GalleryLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type GalleryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GalleryLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum GalleryOrder {
  ClearFiltersCtaCopyAsc = 'clearFiltersCtaCopy_ASC',
  ClearFiltersCtaCopyDesc = 'clearFiltersCtaCopy_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EmptyPlaceholderAsc = 'emptyPlaceholder_ASC',
  EmptyPlaceholderDesc = 'emptyPlaceholder_DESC',
  HideFiltersAsc = 'hideFilters_ASC',
  HideFiltersDesc = 'hideFilters_DESC',
  HidePaginationAsc = 'hidePagination_ASC',
  HidePaginationDesc = 'hidePagination_DESC',
  ItemsPerPageAsc = 'itemsPerPage_ASC',
  ItemsPerPageDesc = 'itemsPerPage_DESC',
  LoadMoreCtaCopyAsc = 'loadMoreCtaCopy_ASC',
  LoadMoreCtaCopyDesc = 'loadMoreCtaCopy_DESC',
  MobileFiltersWrapperTextAsc = 'mobileFiltersWrapperText_ASC',
  MobileFiltersWrapperTextDesc = 'mobileFiltersWrapperText_DESC',
  ShowDatesAsc = 'showDates_ASC',
  ShowDatesDesc = 'showDates_DESC',
  ShowDescriptionsAsc = 'showDescriptions_ASC',
  ShowDescriptionsDesc = 'showDescriptions_DESC',
  ShowMediaAsc = 'showMedia_ASC',
  ShowMediaDesc = 'showMedia_DESC',
  SortByAsc = 'sortBy_ASC',
  SortByDesc = 'sortBy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type GalleryTilesCollection = {
  __typename?: 'GalleryTilesCollection';
  items: Array<Maybe<Tile>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum GalleryTilesCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  ImageFitAsc = 'imageFit_ASC',
  ImageFitDesc = 'imageFit_DESC',
  ImageSizeAsc = 'imageSize_ASC',
  ImageSizeDesc = 'imageSize_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Filter for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilter) */
export type GeoDataFilter = Entry & _Node & {
  __typename?: 'GeoDataFilter';
  _id: Scalars['ID'];
  breakpoint?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<GeoDataFilterLinkingCollections>;
  sys: Sys;
};


/** Filter for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilter) */
export type GeoDataFilterBreakpointArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilter) */
export type GeoDataFilterContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilter) */
export type GeoDataFilterLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filter for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilter) */
export type GeoDataFilterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeoDataFilterCollection = {
  __typename?: 'GeoDataFilterCollection';
  items: Array<Maybe<GeoDataFilter>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GeoDataFilterFilter = {
  AND?: InputMaybe<Array<InputMaybe<GeoDataFilterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GeoDataFilterFilter>>>;
  breakpoint?: InputMaybe<Scalars['String']>;
  breakpoint_contains?: InputMaybe<Scalars['String']>;
  breakpoint_exists?: InputMaybe<Scalars['Boolean']>;
  breakpoint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  breakpoint_not?: InputMaybe<Scalars['String']>;
  breakpoint_not_contains?: InputMaybe<Scalars['String']>;
  breakpoint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type GeoDataFilterLinkingCollections = {
  __typename?: 'GeoDataFilterLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  geoDataFiltersCollection?: Maybe<GeoDataFiltersCollection>;
};


export type GeoDataFilterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type GeoDataFilterLinkingCollectionsGeoDataFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GeoDataFilterLinkingCollectionsGeoDataFiltersCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GeoDataFilterLinkingCollectionsGeoDataFiltersCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum GeoDataFilterOrder {
  BreakpointAsc = 'breakpoint_ASC',
  BreakpointDesc = 'breakpoint_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Filters for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilters) */
export type GeoDataFilters = Entry & _Node & {
  __typename?: 'GeoDataFilters';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  filtersCollection?: Maybe<GeoDataFiltersFiltersCollection>;
  linkedFrom?: Maybe<GeoDataFiltersLinkingCollections>;
  sys: Sys;
};


/** Filters for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilters) */
export type GeoDataFiltersContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Filters for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilters) */
export type GeoDataFiltersFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GeoDataFiltersFiltersCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GeoDataFilterFilter>;
};


/** Filters for data in the map [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoDataFilters) */
export type GeoDataFiltersLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeoDataFiltersCollection = {
  __typename?: 'GeoDataFiltersCollection';
  items: Array<Maybe<GeoDataFilters>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GeoDataFiltersFilter = {
  AND?: InputMaybe<Array<InputMaybe<GeoDataFiltersFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GeoDataFiltersFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  filters?: InputMaybe<CfGeoDataFilterNestedFilter>;
  filtersCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type GeoDataFiltersFiltersCollection = {
  __typename?: 'GeoDataFiltersFiltersCollection';
  items: Array<Maybe<GeoDataFilter>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum GeoDataFiltersFiltersCollectionOrder {
  BreakpointAsc = 'breakpoint_ASC',
  BreakpointDesc = 'breakpoint_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type GeoDataFiltersLinkingCollections = {
  __typename?: 'GeoDataFiltersLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type GeoDataFiltersLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GeoDataFiltersOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Map of the world [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoVisualization) */
export type GeoVisualization = Entry & _Node & {
  __typename?: 'GeoVisualization';
  _id: Scalars['ID'];
  chartData?: Maybe<ChartData>;
  chartTitle?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  countryCodeKey?: Maybe<Scalars['String']>;
  dataScalingMethod?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<GeoVisualizationLinkingCollections>;
  sys: Sys;
  valueKey?: Maybe<Scalars['String']>;
};


/** Map of the world [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoVisualization) */
export type GeoVisualizationChartDataArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ChartDataFilter>;
};


/** Map of the world [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoVisualization) */
export type GeoVisualizationChartTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Map of the world [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoVisualization) */
export type GeoVisualizationContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Map of the world [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoVisualization) */
export type GeoVisualizationCountryCodeKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Map of the world [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoVisualization) */
export type GeoVisualizationDataScalingMethodArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Map of the world [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoVisualization) */
export type GeoVisualizationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Map of the world [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/geoVisualization) */
export type GeoVisualizationValueKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type GeoVisualizationCollection = {
  __typename?: 'GeoVisualizationCollection';
  items: Array<Maybe<GeoVisualization>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GeoVisualizationFilter = {
  AND?: InputMaybe<Array<InputMaybe<GeoVisualizationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GeoVisualizationFilter>>>;
  chartData?: InputMaybe<CfChartDataNestedFilter>;
  chartData_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countryCodeKey?: InputMaybe<Scalars['String']>;
  countryCodeKey_contains?: InputMaybe<Scalars['String']>;
  countryCodeKey_exists?: InputMaybe<Scalars['Boolean']>;
  countryCodeKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryCodeKey_not?: InputMaybe<Scalars['String']>;
  countryCodeKey_not_contains?: InputMaybe<Scalars['String']>;
  countryCodeKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dataScalingMethod?: InputMaybe<Scalars['String']>;
  dataScalingMethod_contains?: InputMaybe<Scalars['String']>;
  dataScalingMethod_exists?: InputMaybe<Scalars['Boolean']>;
  dataScalingMethod_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dataScalingMethod_not?: InputMaybe<Scalars['String']>;
  dataScalingMethod_not_contains?: InputMaybe<Scalars['String']>;
  dataScalingMethod_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  valueKey?: InputMaybe<Scalars['String']>;
  valueKey_contains?: InputMaybe<Scalars['String']>;
  valueKey_exists?: InputMaybe<Scalars['Boolean']>;
  valueKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  valueKey_not?: InputMaybe<Scalars['String']>;
  valueKey_not_contains?: InputMaybe<Scalars['String']>;
  valueKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeoVisualizationLinkingCollections = {
  __typename?: 'GeoVisualizationLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  chartToggleItemCollection?: Maybe<ChartToggleItemCollection>;
  entryCollection?: Maybe<EntryCollection>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type GeoVisualizationLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GeoVisualizationLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type GeoVisualizationLinkingCollectionsChartToggleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GeoVisualizationLinkingCollectionsChartToggleItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type GeoVisualizationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type GeoVisualizationLinkingCollectionsVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GeoVisualizationLinkingCollectionsVisualizationSelectorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GeoVisualizationLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum GeoVisualizationLinkingCollectionsChartToggleItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum GeoVisualizationLinkingCollectionsVisualizationSelectorCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum GeoVisualizationOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CountryCodeKeyAsc = 'countryCodeKey_ASC',
  CountryCodeKeyDesc = 'countryCodeKey_DESC',
  DataScalingMethodAsc = 'dataScalingMethod_ASC',
  DataScalingMethodDesc = 'dataScalingMethod_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueKeyAsc = 'valueKey_ASC',
  ValueKeyDesc = 'valueKey_DESC'
}

/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItem = Entry & _Node & {
  __typename?: 'HeaderMenuItem';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  hideCriteria?: Maybe<Criteria>;
  icon?: Maybe<Icon>;
  linkedFrom?: Maybe<HeaderMenuItemLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItemAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItemDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItemHideCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItemIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<IconFilter>;
};


/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Navigation item for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItem) */
export type HeaderMenuItemUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type HeaderMenuItemCollection = {
  __typename?: 'HeaderMenuItemCollection';
  items: Array<Maybe<HeaderMenuItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HeaderMenuItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<HeaderMenuItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HeaderMenuItemFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hideCriteria?: InputMaybe<CfCriteriaNestedFilter>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<CfIconNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroup = Entry & _Node & {
  __typename?: 'HeaderMenuItemGroup';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  column1Label?: Maybe<Scalars['String']>;
  column1MenuItemsCollection?: Maybe<HeaderMenuItemGroupColumn1MenuItemsCollection>;
  column2Label?: Maybe<Scalars['String']>;
  column2MenuItemsCollection?: Maybe<HeaderMenuItemGroupColumn2MenuItemsCollection>;
  column3Label?: Maybe<Scalars['String']>;
  column3MenuItemsCollection?: Maybe<HeaderMenuItemGroupColumn3MenuItemsCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  featuredHeaderMenuItem?: Maybe<FeaturedHeaderMenuItem>;
  hideCriteria?: Maybe<Criteria>;
  linkedFrom?: Maybe<HeaderMenuItemGroupLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupColumn1LabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupColumn1MenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeaderMenuItemGroupColumn1MenuItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeaderMenuItemFilter>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupColumn2LabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupColumn2MenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeaderMenuItemGroupColumn2MenuItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeaderMenuItemFilter>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupColumn3LabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupColumn3MenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeaderMenuItemGroupColumn3MenuItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeaderMenuItemFilter>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupFeaturedHeaderMenuItemArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<FeaturedHeaderMenuItemFilter>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupHideCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Group of navigation items for use in the header menu [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/headerMenuItemGroup) */
export type HeaderMenuItemGroupUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type HeaderMenuItemGroupCollection = {
  __typename?: 'HeaderMenuItemGroupCollection';
  items: Array<Maybe<HeaderMenuItemGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HeaderMenuItemGroupColumn1MenuItemsCollection = {
  __typename?: 'HeaderMenuItemGroupColumn1MenuItemsCollection';
  items: Array<Maybe<HeaderMenuItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum HeaderMenuItemGroupColumn1MenuItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type HeaderMenuItemGroupColumn2MenuItemsCollection = {
  __typename?: 'HeaderMenuItemGroupColumn2MenuItemsCollection';
  items: Array<Maybe<HeaderMenuItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum HeaderMenuItemGroupColumn2MenuItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type HeaderMenuItemGroupColumn3MenuItemsCollection = {
  __typename?: 'HeaderMenuItemGroupColumn3MenuItemsCollection';
  items: Array<Maybe<HeaderMenuItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum HeaderMenuItemGroupColumn3MenuItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type HeaderMenuItemGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<HeaderMenuItemGroupFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HeaderMenuItemGroupFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  column1Label?: InputMaybe<Scalars['String']>;
  column1Label_contains?: InputMaybe<Scalars['String']>;
  column1Label_exists?: InputMaybe<Scalars['Boolean']>;
  column1Label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column1Label_not?: InputMaybe<Scalars['String']>;
  column1Label_not_contains?: InputMaybe<Scalars['String']>;
  column1Label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column1MenuItems?: InputMaybe<CfHeaderMenuItemNestedFilter>;
  column1MenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  column2Label?: InputMaybe<Scalars['String']>;
  column2Label_contains?: InputMaybe<Scalars['String']>;
  column2Label_exists?: InputMaybe<Scalars['Boolean']>;
  column2Label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column2Label_not?: InputMaybe<Scalars['String']>;
  column2Label_not_contains?: InputMaybe<Scalars['String']>;
  column2Label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column2MenuItems?: InputMaybe<CfHeaderMenuItemNestedFilter>;
  column2MenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  column3Label?: InputMaybe<Scalars['String']>;
  column3Label_contains?: InputMaybe<Scalars['String']>;
  column3Label_exists?: InputMaybe<Scalars['Boolean']>;
  column3Label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column3Label_not?: InputMaybe<Scalars['String']>;
  column3Label_not_contains?: InputMaybe<Scalars['String']>;
  column3Label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column3MenuItems?: InputMaybe<CfHeaderMenuItemNestedFilter>;
  column3MenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featuredHeaderMenuItem?: InputMaybe<CfFeaturedHeaderMenuItemNestedFilter>;
  featuredHeaderMenuItem_exists?: InputMaybe<Scalars['Boolean']>;
  hideCriteria?: InputMaybe<CfCriteriaNestedFilter>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HeaderMenuItemGroupLinkingCollections = {
  __typename?: 'HeaderMenuItemGroupLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  navigatorCollection?: Maybe<NavigatorCollection>;
};


export type HeaderMenuItemGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type HeaderMenuItemGroupLinkingCollectionsNavigatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeaderMenuItemGroupLinkingCollectionsNavigatorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HeaderMenuItemGroupLinkingCollectionsNavigatorCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum HeaderMenuItemGroupOrder {
  Column1LabelAsc = 'column1Label_ASC',
  Column1LabelDesc = 'column1Label_DESC',
  Column2LabelAsc = 'column2Label_ASC',
  Column2LabelDesc = 'column2Label_DESC',
  Column3LabelAsc = 'column3Label_ASC',
  Column3LabelDesc = 'column3Label_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type HeaderMenuItemLinkingCollections = {
  __typename?: 'HeaderMenuItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  headerMenuItemGroupCollection?: Maybe<HeaderMenuItemGroupCollection>;
};


export type HeaderMenuItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type HeaderMenuItemLinkingCollectionsHeaderMenuItemGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeaderMenuItemLinkingCollectionsHeaderMenuItemGroupCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HeaderMenuItemLinkingCollectionsHeaderMenuItemGroupCollectionOrder {
  Column1LabelAsc = 'column1Label_ASC',
  Column1LabelDesc = 'column1Label_DESC',
  Column2LabelAsc = 'column2Label_ASC',
  Column2LabelDesc = 'column2Label_DESC',
  Column3LabelAsc = 'column3Label_ASC',
  Column3LabelDesc = 'column3Label_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum HeaderMenuItemOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Component wrapper for icons [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/icon) */
export type Icon = Entry & _Node & {
  __typename?: 'Icon';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  icon?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<IconLinkingCollections>;
  media?: Maybe<Asset>;
  sys: Sys;
};


/** Component wrapper for icons [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/icon) */
export type IconContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component wrapper for icons [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/icon) */
export type IconIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component wrapper for icons [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/icon) */
export type IconLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component wrapper for icons [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/icon) */
export type IconMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type IconCollection = {
  __typename?: 'IconCollection';
  items: Array<Maybe<Icon>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type IconFilter = {
  AND?: InputMaybe<Array<InputMaybe<IconFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<IconFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon?: InputMaybe<Scalars['String']>;
  icon_contains?: InputMaybe<Scalars['String']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_not?: InputMaybe<Scalars['String']>;
  icon_not_contains?: InputMaybe<Scalars['String']>;
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type IconLinkingCollections = {
  __typename?: 'IconLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  featuredHeaderMenuItemCollection?: Maybe<FeaturedHeaderMenuItemCollection>;
  headerMenuItemCollection?: Maybe<HeaderMenuItemCollection>;
};


export type IconLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type IconLinkingCollectionsFeaturedHeaderMenuItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<IconLinkingCollectionsFeaturedHeaderMenuItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type IconLinkingCollectionsHeaderMenuItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<IconLinkingCollectionsHeaderMenuItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum IconLinkingCollectionsFeaturedHeaderMenuItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CtaLabelAsc = 'ctaLabel_ASC',
  CtaLabelDesc = 'ctaLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum IconLinkingCollectionsHeaderMenuItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum IconOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Image wrapper for images. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/image) */
export type Image = Entry & _Node & {
  __typename?: 'Image';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<ImageLinkingCollections>;
  media?: Maybe<Asset>;
  mobileMedia?: Maybe<Asset>;
  sys: Sys;
  wrap?: Maybe<Scalars['String']>;
};


/** Image wrapper for images. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/image) */
export type ImageContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Image wrapper for images. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/image) */
export type ImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Image wrapper for images. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/image) */
export type ImageMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Image wrapper for images. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/image) */
export type ImageMobileMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Image wrapper for images. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/image) */
export type ImageWrapArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Clickable Image [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/imageButton) */
export type ImageButton = Entry & _Node & {
  __typename?: 'ImageButton';
  _id: Scalars['ID'];
  altText?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  height?: Maybe<Scalars['Int']>;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<ImageButtonLinkingCollections>;
  sys: Sys;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


/** Clickable Image [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/imageButton) */
export type ImageButtonAltTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Clickable Image [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/imageButton) */
export type ImageButtonContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Clickable Image [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/imageButton) */
export type ImageButtonHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Clickable Image [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/imageButton) */
export type ImageButtonImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Clickable Image [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/imageButton) */
export type ImageButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Clickable Image [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/imageButton) */
export type ImageButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Clickable Image [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/imageButton) */
export type ImageButtonWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ImageButtonCollection = {
  __typename?: 'ImageButtonCollection';
  items: Array<Maybe<ImageButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ImageButtonFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImageButtonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageButtonFilter>>>;
  altText?: InputMaybe<Scalars['String']>;
  altText_contains?: InputMaybe<Scalars['String']>;
  altText_exists?: InputMaybe<Scalars['Boolean']>;
  altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  altText_not?: InputMaybe<Scalars['String']>;
  altText_not_contains?: InputMaybe<Scalars['String']>;
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ImageButtonLinkingCollections = {
  __typename?: 'ImageButtonLinkingCollections';
  contentCollection?: Maybe<ContentCollection>;
  customFooterCollection?: Maybe<CustomFooterCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type ImageButtonLinkingCollectionsContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageButtonLinkingCollectionsContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageButtonLinkingCollectionsCustomFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageButtonLinkingCollectionsCustomFooterCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageButtonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ImageButtonLinkingCollectionsContentCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BodyAlignmentAsc = 'bodyAlignment_ASC',
  BodyAlignmentDesc = 'bodyAlignment_DESC',
  BodyAlignmentMobileAsc = 'bodyAlignmentMobile_ASC',
  BodyAlignmentMobileDesc = 'bodyAlignmentMobile_DESC',
  CollapseMobileTableAsc = 'collapseMobileTable_ASC',
  CollapseMobileTableDesc = 'collapseMobileTable_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayOnAsc = 'displayOn_ASC',
  DisplayOnDesc = 'displayOn_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowFullResolutionMediaAsc = 'showFullResolutionMedia_ASC',
  ShowFullResolutionMediaDesc = 'showFullResolutionMedia_DESC',
  ShowVideoControlsAsc = 'showVideoControls_ASC',
  ShowVideoControlsDesc = 'showVideoControls_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  UseHeadingTagsForBodyAsc = 'useHeadingTagsForBody_ASC',
  UseHeadingTagsForBodyDesc = 'useHeadingTagsForBody_DESC'
}

export enum ImageButtonLinkingCollectionsCustomFooterCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SocialLabelAsc = 'socialLabel_ASC',
  SocialLabelDesc = 'socialLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum ImageButtonOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

export type ImageCollection = {
  __typename?: 'ImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMedia_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  wrap?: InputMaybe<Scalars['String']>;
  wrap_contains?: InputMaybe<Scalars['String']>;
  wrap_exists?: InputMaybe<Scalars['Boolean']>;
  wrap_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wrap_not?: InputMaybe<Scalars['String']>;
  wrap_not_contains?: InputMaybe<Scalars['String']>;
  wrap_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP'
}

export type ImageLinkingCollections = {
  __typename?: 'ImageLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  blockHeroCollection?: Maybe<BlockHeroCollection>;
  buttonCollection?: Maybe<ButtonCollection>;
  carouselCardCollection?: Maybe<CarouselCardCollection>;
  contentCollection?: Maybe<ContentCollection>;
  customFooterCollection?: Maybe<CustomFooterCollection>;
  entryCollection?: Maybe<EntryCollection>;
  galleryCollection?: Maybe<GalleryCollection>;
  navigatorCollection?: Maybe<NavigatorCollection>;
  pageCollection?: Maybe<PageCollection>;
  splitBlockCollection?: Maybe<SplitBlockCollection>;
  tileCollection?: Maybe<TileCollection>;
};


export type ImageLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsBlockHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsBlockHeroCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsButtonCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsCarouselCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsCarouselCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsCustomFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsCustomFooterCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsGalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsGalleryCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsNavigatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsNavigatorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsSplitBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsSplitBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ImageLinkingCollectionsTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ImageLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum ImageLinkingCollectionsBlockHeroCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaLayoutAsc = 'backgroundMediaLayout_ASC',
  BackgroundMediaLayoutDesc = 'backgroundMediaLayout_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CurtainOpacityPercentageAsc = 'curtainOpacityPercentage_ASC',
  CurtainOpacityPercentageDesc = 'curtainOpacityPercentage_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  IsHeaderDateAsc = 'isHeaderDate_ASC',
  IsHeaderDateDesc = 'isHeaderDate_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  ShowMediaMobileAsc = 'showMediaMobile_ASC',
  ShowMediaMobileDesc = 'showMediaMobile_DESC',
  ShowScrollButtonAsc = 'showScrollButton_ASC',
  ShowScrollButtonDesc = 'showScrollButton_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextAlignMobileAsc = 'textAlignMobile_ASC',
  TextAlignMobileDesc = 'textAlignMobile_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UseLatestPostAsc = 'useLatestPost_ASC',
  UseLatestPostDesc = 'useLatestPost_DESC',
  VerticalTextAlignAsc = 'verticalTextAlign_ASC',
  VerticalTextAlignDesc = 'verticalTextAlign_DESC'
}

export enum ImageLinkingCollectionsButtonCollectionOrder {
  ButtonTypeAsc = 'buttonType_ASC',
  ButtonTypeDesc = 'buttonType_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  IconNameAsc = 'iconName_ASC',
  IconNameDesc = 'iconName_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum ImageLinkingCollectionsCarouselCardCollectionOrder {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ShowShadowAsc = 'showShadow_ASC',
  ShowShadowDesc = 'showShadow_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum ImageLinkingCollectionsContentCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BodyAlignmentAsc = 'bodyAlignment_ASC',
  BodyAlignmentDesc = 'bodyAlignment_DESC',
  BodyAlignmentMobileAsc = 'bodyAlignmentMobile_ASC',
  BodyAlignmentMobileDesc = 'bodyAlignmentMobile_DESC',
  CollapseMobileTableAsc = 'collapseMobileTable_ASC',
  CollapseMobileTableDesc = 'collapseMobileTable_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayOnAsc = 'displayOn_ASC',
  DisplayOnDesc = 'displayOn_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowFullResolutionMediaAsc = 'showFullResolutionMedia_ASC',
  ShowFullResolutionMediaDesc = 'showFullResolutionMedia_DESC',
  ShowVideoControlsAsc = 'showVideoControls_ASC',
  ShowVideoControlsDesc = 'showVideoControls_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  UseHeadingTagsForBodyAsc = 'useHeadingTagsForBody_ASC',
  UseHeadingTagsForBodyDesc = 'useHeadingTagsForBody_DESC'
}

export enum ImageLinkingCollectionsCustomFooterCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SocialLabelAsc = 'socialLabel_ASC',
  SocialLabelDesc = 'socialLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum ImageLinkingCollectionsGalleryCollectionOrder {
  ClearFiltersCtaCopyAsc = 'clearFiltersCtaCopy_ASC',
  ClearFiltersCtaCopyDesc = 'clearFiltersCtaCopy_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EmptyPlaceholderAsc = 'emptyPlaceholder_ASC',
  EmptyPlaceholderDesc = 'emptyPlaceholder_DESC',
  HideFiltersAsc = 'hideFilters_ASC',
  HideFiltersDesc = 'hideFilters_DESC',
  HidePaginationAsc = 'hidePagination_ASC',
  HidePaginationDesc = 'hidePagination_DESC',
  ItemsPerPageAsc = 'itemsPerPage_ASC',
  ItemsPerPageDesc = 'itemsPerPage_DESC',
  LoadMoreCtaCopyAsc = 'loadMoreCtaCopy_ASC',
  LoadMoreCtaCopyDesc = 'loadMoreCtaCopy_DESC',
  MobileFiltersWrapperTextAsc = 'mobileFiltersWrapperText_ASC',
  MobileFiltersWrapperTextDesc = 'mobileFiltersWrapperText_DESC',
  ShowDatesAsc = 'showDates_ASC',
  ShowDatesDesc = 'showDates_DESC',
  ShowDescriptionsAsc = 'showDescriptions_ASC',
  ShowDescriptionsDesc = 'showDescriptions_DESC',
  ShowMediaAsc = 'showMedia_ASC',
  ShowMediaDesc = 'showMedia_DESC',
  SortByAsc = 'sortBy_ASC',
  SortByDesc = 'sortBy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsNavigatorCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum ImageLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum ImageLinkingCollectionsSplitBlockCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  MediaCaptionAsc = 'mediaCaption_ASC',
  MediaCaptionDesc = 'mediaCaption_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignmentAsc = 'textAlignment_ASC',
  TextAlignmentDesc = 'textAlignment_DESC',
  TextAlignmentMobileAsc = 'textAlignmentMobile_ASC',
  TextAlignmentMobileDesc = 'textAlignmentMobile_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VerticalTextAlignmentAsc = 'verticalTextAlignment_ASC',
  VerticalTextAlignmentDesc = 'verticalTextAlignment_DESC'
}

export enum ImageLinkingCollectionsTileCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  ImageFitAsc = 'imageFit_ASC',
  ImageFitDesc = 'imageFit_DESC',
  ImageSizeAsc = 'imageSize_ASC',
  ImageSizeDesc = 'imageSize_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum ImageOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WrapAsc = 'wrap_ASC',
  WrapDesc = 'wrap_DESC'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT'
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>;
};

/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputField = Entry & _Node & {
  __typename?: 'InputField';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  error?: Maybe<InputFieldError>;
  helpText?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<InputFieldLinkingCollections>;
  maxLength?: Maybe<Scalars['Int']>;
  maxValue?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  richLabel?: Maybe<InputFieldRichLabel>;
  shouldResetToInitial?: Maybe<Scalars['Boolean']>;
  sys: Sys;
  type?: Maybe<Scalars['String']>;
  validation?: Maybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldErrorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldHelpTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldInitialValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldMaxLengthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldMaxValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldMinValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldRequiredArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldRichLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldShouldResetToInitialArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A component of Form Row, part of a Form. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/inputField) */
export type InputFieldValidationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type InputFieldCollection = {
  __typename?: 'InputFieldCollection';
  items: Array<Maybe<InputField>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type InputFieldError = {
  __typename?: 'InputFieldError';
  json: Scalars['JSON'];
  links?: InputFieldErrorLinks;
};

export type InputFieldErrorAssets = {
  __typename?: 'InputFieldErrorAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type InputFieldErrorEntries = {
  __typename?: 'InputFieldErrorEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type InputFieldErrorLinks = {
  __typename?: 'InputFieldErrorLinks';
  assets: InputFieldErrorAssets;
  entries: InputFieldErrorEntries;
  resources: InputFieldErrorResources;
};

export type InputFieldErrorResources = {
  __typename?: 'InputFieldErrorResources';
  block: Array<InputFieldErrorResourcesBlock>;
  hyperlink: Array<InputFieldErrorResourcesHyperlink>;
  inline: Array<InputFieldErrorResourcesInline>;
};

export type InputFieldErrorResourcesBlock = ResourceLink & {
  __typename?: 'InputFieldErrorResourcesBlock';
  sys: ResourceSys;
};

export type InputFieldErrorResourcesHyperlink = ResourceLink & {
  __typename?: 'InputFieldErrorResourcesHyperlink';
  sys: ResourceSys;
};

export type InputFieldErrorResourcesInline = ResourceLink & {
  __typename?: 'InputFieldErrorResourcesInline';
  sys: ResourceSys;
};

export type InputFieldFilter = {
  AND?: InputMaybe<Array<InputMaybe<InputFieldFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<InputFieldFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  error_contains?: InputMaybe<Scalars['String']>;
  error_exists?: InputMaybe<Scalars['Boolean']>;
  error_not_contains?: InputMaybe<Scalars['String']>;
  helpText?: InputMaybe<Scalars['String']>;
  helpText_contains?: InputMaybe<Scalars['String']>;
  helpText_exists?: InputMaybe<Scalars['Boolean']>;
  helpText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  helpText_not?: InputMaybe<Scalars['String']>;
  helpText_not_contains?: InputMaybe<Scalars['String']>;
  helpText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  initialValue?: InputMaybe<Scalars['String']>;
  initialValue_contains?: InputMaybe<Scalars['String']>;
  initialValue_exists?: InputMaybe<Scalars['Boolean']>;
  initialValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  initialValue_not?: InputMaybe<Scalars['String']>;
  initialValue_not_contains?: InputMaybe<Scalars['String']>;
  initialValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maxLength?: InputMaybe<Scalars['Int']>;
  maxLength_exists?: InputMaybe<Scalars['Boolean']>;
  maxLength_gt?: InputMaybe<Scalars['Int']>;
  maxLength_gte?: InputMaybe<Scalars['Int']>;
  maxLength_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxLength_lt?: InputMaybe<Scalars['Int']>;
  maxLength_lte?: InputMaybe<Scalars['Int']>;
  maxLength_not?: InputMaybe<Scalars['Int']>;
  maxLength_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxValue?: InputMaybe<Scalars['String']>;
  maxValue_contains?: InputMaybe<Scalars['String']>;
  maxValue_exists?: InputMaybe<Scalars['Boolean']>;
  maxValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maxValue_not?: InputMaybe<Scalars['String']>;
  maxValue_not_contains?: InputMaybe<Scalars['String']>;
  maxValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  minValue?: InputMaybe<Scalars['String']>;
  minValue_contains?: InputMaybe<Scalars['String']>;
  minValue_exists?: InputMaybe<Scalars['Boolean']>;
  minValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  minValue_not?: InputMaybe<Scalars['String']>;
  minValue_not_contains?: InputMaybe<Scalars['String']>;
  minValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  placeholder?: InputMaybe<Scalars['String']>;
  placeholder_contains?: InputMaybe<Scalars['String']>;
  placeholder_exists?: InputMaybe<Scalars['Boolean']>;
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  placeholder_not?: InputMaybe<Scalars['String']>;
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  required?: InputMaybe<Scalars['Boolean']>;
  required_exists?: InputMaybe<Scalars['Boolean']>;
  required_not?: InputMaybe<Scalars['Boolean']>;
  richLabel_contains?: InputMaybe<Scalars['String']>;
  richLabel_exists?: InputMaybe<Scalars['Boolean']>;
  richLabel_not_contains?: InputMaybe<Scalars['String']>;
  shouldResetToInitial?: InputMaybe<Scalars['Boolean']>;
  shouldResetToInitial_exists?: InputMaybe<Scalars['Boolean']>;
  shouldResetToInitial_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_exists?: InputMaybe<Scalars['Boolean']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  validation?: InputMaybe<Scalars['String']>;
  validation_contains?: InputMaybe<Scalars['String']>;
  validation_exists?: InputMaybe<Scalars['Boolean']>;
  validation_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  validation_not?: InputMaybe<Scalars['String']>;
  validation_not_contains?: InputMaybe<Scalars['String']>;
  validation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InputFieldLinkingCollections = {
  __typename?: 'InputFieldLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  formRowCollection?: Maybe<FormRowCollection>;
};


export type InputFieldLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type InputFieldLinkingCollectionsFormRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<InputFieldLinkingCollectionsFormRowCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum InputFieldLinkingCollectionsFormRowCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum InputFieldOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HelpTextAsc = 'helpText_ASC',
  HelpTextDesc = 'helpText_DESC',
  InitialValueAsc = 'initialValue_ASC',
  InitialValueDesc = 'initialValue_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MaxLengthAsc = 'maxLength_ASC',
  MaxLengthDesc = 'maxLength_DESC',
  MaxValueAsc = 'maxValue_ASC',
  MaxValueDesc = 'maxValue_DESC',
  MinValueAsc = 'minValue_ASC',
  MinValueDesc = 'minValue_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  ShouldResetToInitialAsc = 'shouldResetToInitial_ASC',
  ShouldResetToInitialDesc = 'shouldResetToInitial_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ValidationAsc = 'validation_ASC',
  ValidationDesc = 'validation_DESC'
}

export type InputFieldRichLabel = {
  __typename?: 'InputFieldRichLabel';
  json: Scalars['JSON'];
  links?: InputFieldRichLabelLinks;
};

export type InputFieldRichLabelAssets = {
  __typename?: 'InputFieldRichLabelAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type InputFieldRichLabelEntries = {
  __typename?: 'InputFieldRichLabelEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type InputFieldRichLabelLinks = {
  __typename?: 'InputFieldRichLabelLinks';
  assets: InputFieldRichLabelAssets;
  entries: InputFieldRichLabelEntries;
  resources: InputFieldRichLabelResources;
};

export type InputFieldRichLabelResources = {
  __typename?: 'InputFieldRichLabelResources';
  block: Array<InputFieldRichLabelResourcesBlock>;
  hyperlink: Array<InputFieldRichLabelResourcesHyperlink>;
  inline: Array<InputFieldRichLabelResourcesInline>;
};

export type InputFieldRichLabelResourcesBlock = ResourceLink & {
  __typename?: 'InputFieldRichLabelResourcesBlock';
  sys: ResourceSys;
};

export type InputFieldRichLabelResourcesHyperlink = ResourceLink & {
  __typename?: 'InputFieldRichLabelResourcesHyperlink';
  sys: ResourceSys;
};

export type InputFieldRichLabelResourcesInline = ResourceLink & {
  __typename?: 'InputFieldRichLabelResourcesInline';
  sys: ResourceSys;
};

/** Block that displays the latest blog posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/latestPosts) */
export type LatestPosts = Entry & _Node & {
  __typename?: 'LatestPosts';
  _id: Scalars['ID'];
  anchorId?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  cta?: Maybe<CallToAction>;
  linkedFrom?: Maybe<LatestPostsLinkingCollections>;
  maxPosts?: Maybe<Scalars['Int']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Block that displays the latest blog posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/latestPosts) */
export type LatestPostsAnchorIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Block that displays the latest blog posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/latestPosts) */
export type LatestPostsBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Block that displays the latest blog posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/latestPosts) */
export type LatestPostsContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Block that displays the latest blog posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/latestPosts) */
export type LatestPostsCtaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CallToActionFilter>;
};


/** Block that displays the latest blog posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/latestPosts) */
export type LatestPostsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Block that displays the latest blog posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/latestPosts) */
export type LatestPostsMaxPostsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Block that displays the latest blog posts. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/latestPosts) */
export type LatestPostsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LatestPostsCollection = {
  __typename?: 'LatestPostsCollection';
  items: Array<Maybe<LatestPosts>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LatestPostsFilter = {
  AND?: InputMaybe<Array<InputMaybe<LatestPostsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LatestPostsFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cta?: InputMaybe<CfCallToActionNestedFilter>;
  cta_exists?: InputMaybe<Scalars['Boolean']>;
  maxPosts?: InputMaybe<Scalars['Int']>;
  maxPosts_exists?: InputMaybe<Scalars['Boolean']>;
  maxPosts_gt?: InputMaybe<Scalars['Int']>;
  maxPosts_gte?: InputMaybe<Scalars['Int']>;
  maxPosts_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxPosts_lt?: InputMaybe<Scalars['Int']>;
  maxPosts_lte?: InputMaybe<Scalars['Int']>;
  maxPosts_not?: InputMaybe<Scalars['Int']>;
  maxPosts_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LatestPostsLinkingCollections = {
  __typename?: 'LatestPostsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type LatestPostsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LatestPostsLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LatestPostsLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LatestPostsLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum LatestPostsOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MaxPostsAsc = 'maxPosts_ASC',
  MaxPostsDesc = 'maxPosts_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/legalMessage) */
export type LegalMessage = Entry & _Node & {
  __typename?: 'LegalMessage';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  key?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<LegalMessageLinkingCollections>;
  sys: Sys;
  value?: Maybe<Scalars['String']>;
};


/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/legalMessage) */
export type LegalMessageContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/legalMessage) */
export type LegalMessageKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/legalMessage) */
export type LegalMessageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/legalMessage) */
export type LegalMessageValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LegalMessageCollection = {
  __typename?: 'LegalMessageCollection';
  items: Array<Maybe<LegalMessage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LegalMessageFilter = {
  AND?: InputMaybe<Array<InputMaybe<LegalMessageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LegalMessageFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_exists?: InputMaybe<Scalars['Boolean']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LegalMessageLinkingCollections = {
  __typename?: 'LegalMessageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type LegalMessageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LegalMessageOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/lineChart) */
export type LineChart = Entry & _Node & {
  __typename?: 'LineChart';
  _id: Scalars['ID'];
  chartData?: Maybe<ChartData>;
  chartTitle?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  curve?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<LineChartLinkingCollections>;
  sys: Sys;
  xKey?: Maybe<Scalars['String']>;
  yKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/lineChart) */
export type LineChartChartDataArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ChartDataFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/lineChart) */
export type LineChartChartTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/lineChart) */
export type LineChartContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/lineChart) */
export type LineChartCurveArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/lineChart) */
export type LineChartLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/lineChart) */
export type LineChartXKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/lineChart) */
export type LineChartYKeysArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LineChartCollection = {
  __typename?: 'LineChartCollection';
  items: Array<Maybe<LineChart>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LineChartFilter = {
  AND?: InputMaybe<Array<InputMaybe<LineChartFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LineChartFilter>>>;
  chartData?: InputMaybe<CfChartDataNestedFilter>;
  chartData_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  curve?: InputMaybe<Scalars['String']>;
  curve_contains?: InputMaybe<Scalars['String']>;
  curve_exists?: InputMaybe<Scalars['Boolean']>;
  curve_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  curve_not?: InputMaybe<Scalars['String']>;
  curve_not_contains?: InputMaybe<Scalars['String']>;
  curve_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  xKey?: InputMaybe<Scalars['String']>;
  xKey_contains?: InputMaybe<Scalars['String']>;
  xKey_exists?: InputMaybe<Scalars['Boolean']>;
  xKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xKey_not?: InputMaybe<Scalars['String']>;
  xKey_not_contains?: InputMaybe<Scalars['String']>;
  xKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yKeys_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yKeys_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yKeys_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yKeys_exists?: InputMaybe<Scalars['Boolean']>;
};

export type LineChartLinkingCollections = {
  __typename?: 'LineChartLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  chartToggleItemCollection?: Maybe<ChartToggleItemCollection>;
  entryCollection?: Maybe<EntryCollection>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type LineChartLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LineChartLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LineChartLinkingCollectionsChartToggleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LineChartLinkingCollectionsChartToggleItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LineChartLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LineChartLinkingCollectionsVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LineChartLinkingCollectionsVisualizationSelectorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LineChartLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum LineChartLinkingCollectionsChartToggleItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum LineChartLinkingCollectionsVisualizationSelectorCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum LineChartOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CurveAsc = 'curve_ASC',
  CurveDesc = 'curve_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  XKeyAsc = 'xKey_ASC',
  XKeyDesc = 'xKey_DESC'
}

/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/message) */
export type Message = Entry & _Node & {
  __typename?: 'Message';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  key?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<MessageLinkingCollections>;
  sys: Sys;
  value?: Maybe<Scalars['String']>;
};


/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/message) */
export type MessageContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/message) */
export type MessageKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/message) */
export type MessageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Common strings used across components with or without dynamic content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/message) */
export type MessageValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MessageCollection = {
  __typename?: 'MessageCollection';
  items: Array<Maybe<Message>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MessageFilter = {
  AND?: InputMaybe<Array<InputMaybe<MessageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MessageFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_exists?: InputMaybe<Scalars['Boolean']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MessageLinkingCollections = {
  __typename?: 'MessageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type MessageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MessageOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type Metas = Entry & _Node & {
  __typename?: 'Metas';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  customMetas?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<MetasLinkingCollections>;
  noFollow?: Maybe<Scalars['Boolean']>;
  noIndex?: Maybe<Scalars['Boolean']>;
  ogImage?: Maybe<Asset>;
  ogVideo?: Maybe<Asset>;
  schemasCollection?: Maybe<MetasSchemasCollection>;
  sys: Sys;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasCustomMetasArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasNoFollowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasOgImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasOgVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Add a Meta Entry for SEO and social media links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/metas) */
export type MetasSchemasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MetasSchemasFilter>;
};

export type MetasCollection = {
  __typename?: 'MetasCollection';
  items: Array<Maybe<Metas>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MetasFilter = {
  AND?: InputMaybe<Array<InputMaybe<MetasFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MetasFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  customMetas_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customMetas_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customMetas_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customMetas_exists?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']>;
  ogImage_exists?: InputMaybe<Scalars['Boolean']>;
  ogVideo_exists?: InputMaybe<Scalars['Boolean']>;
  schemas?: InputMaybe<CfschemasMultiTypeNestedFilter>;
  schemasCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type MetasLinkingCollections = {
  __typename?: 'MetasLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type MetasLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MetasLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MetasLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MetasLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum MetasOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type MetasSchemasCollection = {
  __typename?: 'MetasSchemasCollection';
  items: Array<Maybe<MetasSchemasItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MetasSchemasFilter = {
  AND?: InputMaybe<Array<InputMaybe<MetasSchemasFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MetasSchemasFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type MetasSchemasItem = BlogSchema | FaqPageSchema | OrganizationSchema;

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaic) */
export type Mosaic = Entry & _Node & {
  __typename?: 'Mosaic';
  _id: Scalars['ID'];
  columns?: Maybe<Scalars['Int']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  filtersCollection?: Maybe<MosaicFiltersCollection>;
  linkedFrom?: Maybe<MosaicLinkingCollections>;
  mobileColumns?: Maybe<Scalars['Int']>;
  sys: Sys;
  tilesCollection?: Maybe<MosaicTilesCollection>;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaic) */
export type MosaicColumnsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaic) */
export type MosaicContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaic) */
export type MosaicFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicFiltersCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MosaicFiltersFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaic) */
export type MosaicLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaic) */
export type MosaicMobileColumnsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaic) */
export type MosaicTilesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicTilesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MosaicTileFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaic) */
export type MosaicTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MosaicCollection = {
  __typename?: 'MosaicCollection';
  items: Array<Maybe<Mosaic>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MosaicFilter = {
  AND?: InputMaybe<Array<InputMaybe<MosaicFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MosaicFilter>>>;
  columns?: InputMaybe<Scalars['Int']>;
  columns_exists?: InputMaybe<Scalars['Boolean']>;
  columns_gt?: InputMaybe<Scalars['Int']>;
  columns_gte?: InputMaybe<Scalars['Int']>;
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  columns_lt?: InputMaybe<Scalars['Int']>;
  columns_lte?: InputMaybe<Scalars['Int']>;
  columns_not?: InputMaybe<Scalars['Int']>;
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  filters?: InputMaybe<CfMosaicFiltersNestedFilter>;
  filtersCollection_exists?: InputMaybe<Scalars['Boolean']>;
  mobileColumns?: InputMaybe<Scalars['Int']>;
  mobileColumns_exists?: InputMaybe<Scalars['Boolean']>;
  mobileColumns_gt?: InputMaybe<Scalars['Int']>;
  mobileColumns_gte?: InputMaybe<Scalars['Int']>;
  mobileColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  mobileColumns_lt?: InputMaybe<Scalars['Int']>;
  mobileColumns_lte?: InputMaybe<Scalars['Int']>;
  mobileColumns_not?: InputMaybe<Scalars['Int']>;
  mobileColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  tiles?: InputMaybe<CfMosaicTileNestedFilter>;
  tilesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicFilters) */
export type MosaicFilters = Entry & _Node & {
  __typename?: 'MosaicFilters';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  icon?: Maybe<Scalars['String']>;
  isAll?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<MosaicFiltersLinkingCollections>;
  sys: Sys;
  text?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicFilters) */
export type MosaicFiltersContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicFilters) */
export type MosaicFiltersIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicFilters) */
export type MosaicFiltersIsAllArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicFilters) */
export type MosaicFiltersLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicFilters) */
export type MosaicFiltersTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MosaicFiltersCollection = {
  __typename?: 'MosaicFiltersCollection';
  items: Array<Maybe<MosaicFilters>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MosaicFiltersCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IsAllAsc = 'isAll_ASC',
  IsAllDesc = 'isAll_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC'
}

export type MosaicFiltersFilter = {
  AND?: InputMaybe<Array<InputMaybe<MosaicFiltersFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MosaicFiltersFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon?: InputMaybe<Scalars['String']>;
  icon_contains?: InputMaybe<Scalars['String']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_not?: InputMaybe<Scalars['String']>;
  icon_not_contains?: InputMaybe<Scalars['String']>;
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isAll?: InputMaybe<Scalars['Boolean']>;
  isAll_exists?: InputMaybe<Scalars['Boolean']>;
  isAll_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MosaicFiltersLinkingCollections = {
  __typename?: 'MosaicFiltersLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  mosaicCollection?: Maybe<MosaicCollection>;
  mosaicTileCollection?: Maybe<MosaicTileCollection>;
};


export type MosaicFiltersLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MosaicFiltersLinkingCollectionsMosaicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicFiltersLinkingCollectionsMosaicCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MosaicFiltersLinkingCollectionsMosaicTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicFiltersLinkingCollectionsMosaicTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MosaicFiltersLinkingCollectionsMosaicCollectionOrder {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MobileColumnsAsc = 'mobileColumns_ASC',
  MobileColumnsDesc = 'mobileColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum MosaicFiltersLinkingCollectionsMosaicTileCollectionOrder {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  HighlightAsc = 'highlight_ASC',
  HighlightDesc = 'highlight_DESC',
  MobileColumnsAsc = 'mobileColumns_ASC',
  MobileColumnsDesc = 'mobileColumns_DESC',
  MobileRowsAsc = 'mobileRows_ASC',
  MobileRowsDesc = 'mobileRows_DESC',
  RowsAsc = 'rows_ASC',
  RowsDesc = 'rows_DESC',
  ShowOverlayAsc = 'showOverlay_ASC',
  ShowOverlayDesc = 'showOverlay_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum MosaicFiltersOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IsAllAsc = 'isAll_ASC',
  IsAllDesc = 'isAll_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC'
}

export type MosaicLinkingCollections = {
  __typename?: 'MosaicLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type MosaicLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MosaicLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MosaicLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum MosaicOrder {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MobileColumnsAsc = 'mobileColumns_ASC',
  MobileColumnsDesc = 'mobileColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTile = Entry & _Node & {
  __typename?: 'MosaicTile';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  columns?: Maybe<Scalars['Int']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  duration?: Maybe<Scalars['String']>;
  filtersCollection?: Maybe<MosaicTileFiltersCollection>;
  highlight?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<MosaicTileLinkingCollections>;
  mobileColumns?: Maybe<Scalars['Int']>;
  mobileRows?: Maybe<Scalars['Int']>;
  preview?: Maybe<Video>;
  rows?: Maybe<Scalars['Int']>;
  showOverlay?: Maybe<Scalars['Boolean']>;
  sys: Sys;
  thumbnail?: Maybe<Asset>;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Video>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileColumnsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileDurationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicTileFiltersCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MosaicFiltersFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileHighlightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileMobileColumnsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileMobileRowsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTilePreviewArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<VideoFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileRowsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileShowOverlayArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/mosaicTile) */
export type MosaicTileVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<VideoFilter>;
};

export type MosaicTileCollection = {
  __typename?: 'MosaicTileCollection';
  items: Array<Maybe<MosaicTile>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MosaicTileFilter = {
  AND?: InputMaybe<Array<InputMaybe<MosaicTileFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MosaicTileFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  columns?: InputMaybe<Scalars['Int']>;
  columns_exists?: InputMaybe<Scalars['Boolean']>;
  columns_gt?: InputMaybe<Scalars['Int']>;
  columns_gte?: InputMaybe<Scalars['Int']>;
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  columns_lt?: InputMaybe<Scalars['Int']>;
  columns_lte?: InputMaybe<Scalars['Int']>;
  columns_not?: InputMaybe<Scalars['Int']>;
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  duration?: InputMaybe<Scalars['String']>;
  duration_contains?: InputMaybe<Scalars['String']>;
  duration_exists?: InputMaybe<Scalars['Boolean']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  duration_not?: InputMaybe<Scalars['String']>;
  duration_not_contains?: InputMaybe<Scalars['String']>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters?: InputMaybe<CfMosaicFiltersNestedFilter>;
  filtersCollection_exists?: InputMaybe<Scalars['Boolean']>;
  highlight?: InputMaybe<Scalars['String']>;
  highlight_contains?: InputMaybe<Scalars['String']>;
  highlight_exists?: InputMaybe<Scalars['Boolean']>;
  highlight_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  highlight_not?: InputMaybe<Scalars['String']>;
  highlight_not_contains?: InputMaybe<Scalars['String']>;
  highlight_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileColumns?: InputMaybe<Scalars['Int']>;
  mobileColumns_exists?: InputMaybe<Scalars['Boolean']>;
  mobileColumns_gt?: InputMaybe<Scalars['Int']>;
  mobileColumns_gte?: InputMaybe<Scalars['Int']>;
  mobileColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  mobileColumns_lt?: InputMaybe<Scalars['Int']>;
  mobileColumns_lte?: InputMaybe<Scalars['Int']>;
  mobileColumns_not?: InputMaybe<Scalars['Int']>;
  mobileColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  mobileRows?: InputMaybe<Scalars['Int']>;
  mobileRows_exists?: InputMaybe<Scalars['Boolean']>;
  mobileRows_gt?: InputMaybe<Scalars['Int']>;
  mobileRows_gte?: InputMaybe<Scalars['Int']>;
  mobileRows_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  mobileRows_lt?: InputMaybe<Scalars['Int']>;
  mobileRows_lte?: InputMaybe<Scalars['Int']>;
  mobileRows_not?: InputMaybe<Scalars['Int']>;
  mobileRows_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  preview?: InputMaybe<CfVideoNestedFilter>;
  preview_exists?: InputMaybe<Scalars['Boolean']>;
  rows?: InputMaybe<Scalars['Int']>;
  rows_exists?: InputMaybe<Scalars['Boolean']>;
  rows_gt?: InputMaybe<Scalars['Int']>;
  rows_gte?: InputMaybe<Scalars['Int']>;
  rows_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  rows_lt?: InputMaybe<Scalars['Int']>;
  rows_lte?: InputMaybe<Scalars['Int']>;
  rows_not?: InputMaybe<Scalars['Int']>;
  rows_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  showOverlay?: InputMaybe<Scalars['Boolean']>;
  showOverlay_exists?: InputMaybe<Scalars['Boolean']>;
  showOverlay_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  video?: InputMaybe<CfVideoNestedFilter>;
  video_exists?: InputMaybe<Scalars['Boolean']>;
};

export type MosaicTileFiltersCollection = {
  __typename?: 'MosaicTileFiltersCollection';
  items: Array<Maybe<MosaicFilters>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MosaicTileFiltersCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IsAllAsc = 'isAll_ASC',
  IsAllDesc = 'isAll_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC'
}

export type MosaicTileLinkingCollections = {
  __typename?: 'MosaicTileLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  mosaicCollection?: Maybe<MosaicCollection>;
};


export type MosaicTileLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MosaicTileLinkingCollectionsMosaicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicTileLinkingCollectionsMosaicCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MosaicTileLinkingCollectionsMosaicCollectionOrder {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MobileColumnsAsc = 'mobileColumns_ASC',
  MobileColumnsDesc = 'mobileColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum MosaicTileOrder {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  HighlightAsc = 'highlight_ASC',
  HighlightDesc = 'highlight_DESC',
  MobileColumnsAsc = 'mobileColumns_ASC',
  MobileColumnsDesc = 'mobileColumns_DESC',
  MobileRowsAsc = 'mobileRows_ASC',
  MobileRowsDesc = 'mobileRows_DESC',
  RowsAsc = 'rows_ASC',
  RowsDesc = 'rows_DESC',
  ShowOverlayAsc = 'showOverlay_ASC',
  ShowOverlayDesc = 'showOverlay_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type MosaicTilesCollection = {
  __typename?: 'MosaicTilesCollection';
  items: Array<Maybe<MosaicTile>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MosaicTilesCollectionOrder {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  HighlightAsc = 'highlight_ASC',
  HighlightDesc = 'highlight_DESC',
  MobileColumnsAsc = 'mobileColumns_ASC',
  MobileColumnsDesc = 'mobileColumns_DESC',
  MobileRowsAsc = 'mobileRows_ASC',
  MobileRowsDesc = 'mobileRows_DESC',
  RowsAsc = 'rows_ASC',
  RowsDesc = 'rows_DESC',
  ShowOverlayAsc = 'showOverlay_ASC',
  ShowOverlayDesc = 'showOverlay_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlock = Entry & _Node & {
  __typename?: 'MultiVideoBlock';
  _id: Scalars['ID'];
  anchorId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<MultiVideoBlockLinkingCollections>;
  mobilePrimaryVideosCollection?: Maybe<MultiVideoBlockMobilePrimaryVideosCollection>;
  mobileSecondaryVideosCollection?: Maybe<MultiVideoBlockMobileSecondaryVideosCollection>;
  panelPosition?: Maybe<Scalars['String']>;
  primaryVideosCollection?: Maybe<MultiVideoBlockPrimaryVideosCollection>;
  secondaryVideosCollection?: Maybe<MultiVideoBlockSecondaryVideosCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockAnchorIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockMobilePrimaryVideosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiVideoBlockMobilePrimaryVideosCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockMobileSecondaryVideosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiVideoBlockMobileSecondaryVideosCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockPanelPositionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockPrimaryVideosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiVideoBlockPrimaryVideosCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockSecondaryVideosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiVideoBlockSecondaryVideosCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiVideoBlock) */
export type MultiVideoBlockTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MultiVideoBlockCollection = {
  __typename?: 'MultiVideoBlockCollection';
  items: Array<Maybe<MultiVideoBlock>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MultiVideoBlockFilter = {
  AND?: InputMaybe<Array<InputMaybe<MultiVideoBlockFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MultiVideoBlockFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mobilePrimaryVideos?: InputMaybe<CfVideoNestedFilter>;
  mobilePrimaryVideosCollection_exists?: InputMaybe<Scalars['Boolean']>;
  mobileSecondaryVideos?: InputMaybe<CfVideoNestedFilter>;
  mobileSecondaryVideosCollection_exists?: InputMaybe<Scalars['Boolean']>;
  panelPosition?: InputMaybe<Scalars['String']>;
  panelPosition_contains?: InputMaybe<Scalars['String']>;
  panelPosition_exists?: InputMaybe<Scalars['Boolean']>;
  panelPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  panelPosition_not?: InputMaybe<Scalars['String']>;
  panelPosition_not_contains?: InputMaybe<Scalars['String']>;
  panelPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryVideos?: InputMaybe<CfVideoNestedFilter>;
  primaryVideosCollection_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryVideos?: InputMaybe<CfVideoNestedFilter>;
  secondaryVideosCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MultiVideoBlockLinkingCollections = {
  __typename?: 'MultiVideoBlockLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type MultiVideoBlockLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MultiVideoBlockLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiVideoBlockLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MultiVideoBlockLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type MultiVideoBlockMobilePrimaryVideosCollection = {
  __typename?: 'MultiVideoBlockMobilePrimaryVideosCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MultiVideoBlockMobilePrimaryVideosCollectionOrder {
  AutoPlayAsc = 'autoPlay_ASC',
  AutoPlayDesc = 'autoPlay_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  StreamUrlAsc = 'streamUrl_ASC',
  StreamUrlDesc = 'streamUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VodSyncStatusAsc = 'vodSyncStatus_ASC',
  VodSyncStatusDesc = 'vodSyncStatus_DESC',
  WrapAsc = 'wrap_ASC',
  WrapDesc = 'wrap_DESC'
}

export type MultiVideoBlockMobileSecondaryVideosCollection = {
  __typename?: 'MultiVideoBlockMobileSecondaryVideosCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MultiVideoBlockMobileSecondaryVideosCollectionOrder {
  AutoPlayAsc = 'autoPlay_ASC',
  AutoPlayDesc = 'autoPlay_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  StreamUrlAsc = 'streamUrl_ASC',
  StreamUrlDesc = 'streamUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VodSyncStatusAsc = 'vodSyncStatus_ASC',
  VodSyncStatusDesc = 'vodSyncStatus_DESC',
  WrapAsc = 'wrap_ASC',
  WrapDesc = 'wrap_DESC'
}

export enum MultiVideoBlockOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  PanelPositionAsc = 'panelPosition_ASC',
  PanelPositionDesc = 'panelPosition_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type MultiVideoBlockPrimaryVideosCollection = {
  __typename?: 'MultiVideoBlockPrimaryVideosCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MultiVideoBlockPrimaryVideosCollectionOrder {
  AutoPlayAsc = 'autoPlay_ASC',
  AutoPlayDesc = 'autoPlay_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  StreamUrlAsc = 'streamUrl_ASC',
  StreamUrlDesc = 'streamUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VodSyncStatusAsc = 'vodSyncStatus_ASC',
  VodSyncStatusDesc = 'vodSyncStatus_DESC',
  WrapAsc = 'wrap_ASC',
  WrapDesc = 'wrap_DESC'
}

export type MultiVideoBlockSecondaryVideosCollection = {
  __typename?: 'MultiVideoBlockSecondaryVideosCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MultiVideoBlockSecondaryVideosCollectionOrder {
  AutoPlayAsc = 'autoPlay_ASC',
  AutoPlayDesc = 'autoPlay_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  StreamUrlAsc = 'streamUrl_ASC',
  StreamUrlDesc = 'streamUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VodSyncStatusAsc = 'vodSyncStatus_ASC',
  VodSyncStatusDesc = 'vodSyncStatus_DESC',
  WrapAsc = 'wrap_ASC',
  WrapDesc = 'wrap_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownField = Entry & _Node & {
  __typename?: 'MultiselectDropdownField';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  error?: Maybe<MultiselectDropdownFieldError>;
  helpText?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<MultiselectDropdownFieldLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  optionsCollection?: Maybe<MultiselectDropdownFieldOptionsCollection>;
  placeholder?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldErrorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldHelpTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiselectDropdownFieldOptionsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DropdownFieldOptionFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/multiselectDropdownField) */
export type MultiselectDropdownFieldRequiredArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MultiselectDropdownFieldCollection = {
  __typename?: 'MultiselectDropdownFieldCollection';
  items: Array<Maybe<MultiselectDropdownField>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MultiselectDropdownFieldError = {
  __typename?: 'MultiselectDropdownFieldError';
  json: Scalars['JSON'];
  links?: MultiselectDropdownFieldErrorLinks;
};

export type MultiselectDropdownFieldErrorAssets = {
  __typename?: 'MultiselectDropdownFieldErrorAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type MultiselectDropdownFieldErrorEntries = {
  __typename?: 'MultiselectDropdownFieldErrorEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type MultiselectDropdownFieldErrorLinks = {
  __typename?: 'MultiselectDropdownFieldErrorLinks';
  assets: MultiselectDropdownFieldErrorAssets;
  entries: MultiselectDropdownFieldErrorEntries;
  resources: MultiselectDropdownFieldErrorResources;
};

export type MultiselectDropdownFieldErrorResources = {
  __typename?: 'MultiselectDropdownFieldErrorResources';
  block: Array<MultiselectDropdownFieldErrorResourcesBlock>;
  hyperlink: Array<MultiselectDropdownFieldErrorResourcesHyperlink>;
  inline: Array<MultiselectDropdownFieldErrorResourcesInline>;
};

export type MultiselectDropdownFieldErrorResourcesBlock = ResourceLink & {
  __typename?: 'MultiselectDropdownFieldErrorResourcesBlock';
  sys: ResourceSys;
};

export type MultiselectDropdownFieldErrorResourcesHyperlink = ResourceLink & {
  __typename?: 'MultiselectDropdownFieldErrorResourcesHyperlink';
  sys: ResourceSys;
};

export type MultiselectDropdownFieldErrorResourcesInline = ResourceLink & {
  __typename?: 'MultiselectDropdownFieldErrorResourcesInline';
  sys: ResourceSys;
};

export type MultiselectDropdownFieldFilter = {
  AND?: InputMaybe<Array<InputMaybe<MultiselectDropdownFieldFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MultiselectDropdownFieldFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  error_contains?: InputMaybe<Scalars['String']>;
  error_exists?: InputMaybe<Scalars['Boolean']>;
  error_not_contains?: InputMaybe<Scalars['String']>;
  helpText?: InputMaybe<Scalars['String']>;
  helpText_contains?: InputMaybe<Scalars['String']>;
  helpText_exists?: InputMaybe<Scalars['Boolean']>;
  helpText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  helpText_not?: InputMaybe<Scalars['String']>;
  helpText_not_contains?: InputMaybe<Scalars['String']>;
  helpText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  options?: InputMaybe<CfDropdownFieldOptionNestedFilter>;
  optionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  placeholder?: InputMaybe<Scalars['String']>;
  placeholder_contains?: InputMaybe<Scalars['String']>;
  placeholder_exists?: InputMaybe<Scalars['Boolean']>;
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  placeholder_not?: InputMaybe<Scalars['String']>;
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  required?: InputMaybe<Scalars['Boolean']>;
  required_exists?: InputMaybe<Scalars['Boolean']>;
  required_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type MultiselectDropdownFieldLinkingCollections = {
  __typename?: 'MultiselectDropdownFieldLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  formRowCollection?: Maybe<FormRowCollection>;
};


export type MultiselectDropdownFieldLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MultiselectDropdownFieldLinkingCollectionsFormRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiselectDropdownFieldLinkingCollectionsFormRowCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MultiselectDropdownFieldLinkingCollectionsFormRowCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type MultiselectDropdownFieldOptionsCollection = {
  __typename?: 'MultiselectDropdownFieldOptionsCollection';
  items: Array<Maybe<DropdownFieldOption>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MultiselectDropdownFieldOptionsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export enum MultiselectDropdownFieldOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HelpTextAsc = 'helpText_ASC',
  HelpTextDesc = 'helpText_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Wrapper for CTAs in the NavBar used to control device rendering behaviors [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigationCallToAction) */
export type NavigationCallToAction = Entry & _Node & {
  __typename?: 'NavigationCallToAction';
  _id: Scalars['ID'];
  callToAction?: Maybe<CallToAction>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  displayModes?: Maybe<Array<Maybe<Scalars['String']>>>;
  hideCriteria?: Maybe<Criteria>;
  linkedFrom?: Maybe<NavigationCallToActionLinkingCollections>;
  sys: Sys;
};


/** Wrapper for CTAs in the NavBar used to control device rendering behaviors [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigationCallToAction) */
export type NavigationCallToActionCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CallToActionFilter>;
};


/** Wrapper for CTAs in the NavBar used to control device rendering behaviors [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigationCallToAction) */
export type NavigationCallToActionContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Wrapper for CTAs in the NavBar used to control device rendering behaviors [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigationCallToAction) */
export type NavigationCallToActionDisplayModesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Wrapper for CTAs in the NavBar used to control device rendering behaviors [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigationCallToAction) */
export type NavigationCallToActionHideCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** Wrapper for CTAs in the NavBar used to control device rendering behaviors [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigationCallToAction) */
export type NavigationCallToActionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NavigationCallToActionCollection = {
  __typename?: 'NavigationCallToActionCollection';
  items: Array<Maybe<NavigationCallToAction>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NavigationCallToActionFilter = {
  AND?: InputMaybe<Array<InputMaybe<NavigationCallToActionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NavigationCallToActionFilter>>>;
  callToAction?: InputMaybe<CfCallToActionNestedFilter>;
  callToAction_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  displayModes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayModes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayModes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayModes_exists?: InputMaybe<Scalars['Boolean']>;
  hideCriteria?: InputMaybe<CfCriteriaNestedFilter>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type NavigationCallToActionLinkingCollections = {
  __typename?: 'NavigationCallToActionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  navigatorCollection?: Maybe<NavigatorCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type NavigationCallToActionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NavigationCallToActionLinkingCollectionsNavigatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigationCallToActionLinkingCollectionsNavigatorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NavigationCallToActionLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigationCallToActionLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NavigationCallToActionLinkingCollectionsNavigatorCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum NavigationCallToActionLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum NavigationCallToActionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type Navigator = Entry & _Node & {
  __typename?: 'Navigator';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  backgroundColor?: Maybe<Scalars['String']>;
  callsToActionCollection?: Maybe<NavigatorCallsToActionCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  headerMenuItemGroupsCollection?: Maybe<NavigatorHeaderMenuItemGroupsCollection>;
  linkedFrom?: Maybe<NavigatorLinkingCollections>;
  logoV2?: Maybe<Image>;
  navigatorItemsCollection?: Maybe<NavigatorNavigatorItemsCollection>;
  sys: Sys;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorCallsToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NavigatorCallsToActionFilter>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorHeaderMenuItemGroupsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorHeaderMenuItemGroupsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeaderMenuItemGroupFilter>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorLogoV2Args = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ImageFilter>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorNavigatorItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorNavigatorItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NavigatorItemFilter>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorThemeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Main navigation bar for site. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigator) */
export type NavigatorUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NavigatorCallsToActionCollection = {
  __typename?: 'NavigatorCallsToActionCollection';
  items: Array<Maybe<NavigatorCallsToActionItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NavigatorCallsToActionFilter = {
  AND?: InputMaybe<Array<InputMaybe<NavigatorCallsToActionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NavigatorCallsToActionFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type NavigatorCallsToActionItem = CallToAction | NavigationCallToAction;

export type NavigatorCollection = {
  __typename?: 'NavigatorCollection';
  items: Array<Maybe<Navigator>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NavigatorFilter = {
  AND?: InputMaybe<Array<InputMaybe<NavigatorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NavigatorFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  callsToAction?: InputMaybe<CfcallsToActionMultiTypeNestedFilter>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headerMenuItemGroups?: InputMaybe<CfHeaderMenuItemGroupNestedFilter>;
  headerMenuItemGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  logoV2?: InputMaybe<CfImageNestedFilter>;
  logoV2_exists?: InputMaybe<Scalars['Boolean']>;
  navigatorItems?: InputMaybe<CfNavigatorItemNestedFilter>;
  navigatorItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  theme?: InputMaybe<Scalars['String']>;
  theme_contains?: InputMaybe<Scalars['String']>;
  theme_exists?: InputMaybe<Scalars['Boolean']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme_not?: InputMaybe<Scalars['String']>;
  theme_not_contains?: InputMaybe<Scalars['String']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NavigatorHeaderMenuItemGroupsCollection = {
  __typename?: 'NavigatorHeaderMenuItemGroupsCollection';
  items: Array<Maybe<HeaderMenuItemGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NavigatorHeaderMenuItemGroupsCollectionOrder {
  Column1LabelAsc = 'column1Label_ASC',
  Column1LabelDesc = 'column1Label_DESC',
  Column2LabelAsc = 'column2Label_ASC',
  Column2LabelDesc = 'column2Label_DESC',
  Column3LabelAsc = 'column3Label_ASC',
  Column3LabelDesc = 'column3Label_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Navigator Item to add to Navigation Bar. Usually local links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigatorItem) */
export type NavigatorItem = Entry & _Node & {
  __typename?: 'NavigatorItem';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  hideCriteria?: Maybe<Criteria>;
  linkedFrom?: Maybe<NavigatorItemLinkingCollections>;
  navigatorItemsCollection?: Maybe<NavigatorItemNavigatorItemsCollection>;
  sys: Sys;
  title?: Maybe<NavigatorItemTitle>;
  url?: Maybe<Scalars['String']>;
};


/** Navigator Item to add to Navigation Bar. Usually local links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigatorItem) */
export type NavigatorItemAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** Navigator Item to add to Navigation Bar. Usually local links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigatorItem) */
export type NavigatorItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Navigator Item to add to Navigation Bar. Usually local links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigatorItem) */
export type NavigatorItemHideCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};


/** Navigator Item to add to Navigation Bar. Usually local links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigatorItem) */
export type NavigatorItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Navigator Item to add to Navigation Bar. Usually local links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigatorItem) */
export type NavigatorItemNavigatorItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorItemNavigatorItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NavigatorItemFilter>;
};


/** Navigator Item to add to Navigation Bar. Usually local links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigatorItem) */
export type NavigatorItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Navigator Item to add to Navigation Bar. Usually local links. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/navigatorItem) */
export type NavigatorItemUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NavigatorItemCollection = {
  __typename?: 'NavigatorItemCollection';
  items: Array<Maybe<NavigatorItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NavigatorItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<NavigatorItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NavigatorItemFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  hideCriteria?: InputMaybe<CfCriteriaNestedFilter>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  navigatorItems?: InputMaybe<CfNavigatorItemNestedFilter>;
  navigatorItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NavigatorItemLinkingCollections = {
  __typename?: 'NavigatorItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  navigatorCollection?: Maybe<NavigatorCollection>;
  navigatorItemCollection?: Maybe<NavigatorItemCollection>;
  sideNavigationCollection?: Maybe<SideNavigationCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type NavigatorItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NavigatorItemLinkingCollectionsNavigatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorItemLinkingCollectionsNavigatorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NavigatorItemLinkingCollectionsNavigatorItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorItemLinkingCollectionsNavigatorItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NavigatorItemLinkingCollectionsSideNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorItemLinkingCollectionsSideNavigationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NavigatorItemLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorItemLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NavigatorItemLinkingCollectionsNavigatorCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum NavigatorItemLinkingCollectionsNavigatorItemCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum NavigatorItemLinkingCollectionsSideNavigationCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MobileBackgroundColorAsc = 'mobileBackgroundColor_ASC',
  MobileBackgroundColorDesc = 'mobileBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum NavigatorItemLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type NavigatorItemNavigatorItemsCollection = {
  __typename?: 'NavigatorItemNavigatorItemsCollection';
  items: Array<Maybe<NavigatorItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NavigatorItemNavigatorItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum NavigatorItemOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type NavigatorItemTitle = {
  __typename?: 'NavigatorItemTitle';
  json: Scalars['JSON'];
  links?: NavigatorItemTitleLinks;
};

export type NavigatorItemTitleAssets = {
  __typename?: 'NavigatorItemTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NavigatorItemTitleEntries = {
  __typename?: 'NavigatorItemTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NavigatorItemTitleLinks = {
  __typename?: 'NavigatorItemTitleLinks';
  assets: NavigatorItemTitleAssets;
  entries: NavigatorItemTitleEntries;
  resources: NavigatorItemTitleResources;
};

export type NavigatorItemTitleResources = {
  __typename?: 'NavigatorItemTitleResources';
  block: Array<NavigatorItemTitleResourcesBlock>;
  hyperlink: Array<NavigatorItemTitleResourcesHyperlink>;
  inline: Array<NavigatorItemTitleResourcesInline>;
};

export type NavigatorItemTitleResourcesBlock = ResourceLink & {
  __typename?: 'NavigatorItemTitleResourcesBlock';
  sys: ResourceSys;
};

export type NavigatorItemTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'NavigatorItemTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type NavigatorItemTitleResourcesInline = ResourceLink & {
  __typename?: 'NavigatorItemTitleResourcesInline';
  sys: ResourceSys;
};

export type NavigatorLinkingCollections = {
  __typename?: 'NavigatorLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  sitewideValuesCollection?: Maybe<SitewideValuesCollection>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
};


export type NavigatorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NavigatorLinkingCollectionsSitewideValuesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorLinkingCollectionsSitewideValuesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NavigatorLinkingCollectionsSitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorLinkingCollectionsSitewideVariantReplacementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NavigatorLinkingCollectionsSitewideValuesCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum NavigatorLinkingCollectionsSitewideVariantReplacementCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type NavigatorNavigatorItemsCollection = {
  __typename?: 'NavigatorNavigatorItemsCollection';
  items: Array<Maybe<NavigatorItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NavigatorNavigatorItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum NavigatorOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Model for generating an Organization schema according to schema.org [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/organizationSchema) */
export type OrganizationSchema = Entry & _Node & {
  __typename?: 'OrganizationSchema';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<OrganizationSchemaLinkingCollections>;
  logo?: Maybe<Asset>;
  name?: Maybe<Scalars['String']>;
  sameAs?: Maybe<Array<Maybe<Scalars['String']>>>;
  sys: Sys;
  url?: Maybe<Scalars['String']>;
};


/** Model for generating an Organization schema according to schema.org [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/organizationSchema) */
export type OrganizationSchemaContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating an Organization schema according to schema.org [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/organizationSchema) */
export type OrganizationSchemaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating an Organization schema according to schema.org [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/organizationSchema) */
export type OrganizationSchemaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Model for generating an Organization schema according to schema.org [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/organizationSchema) */
export type OrganizationSchemaLogoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Model for generating an Organization schema according to schema.org [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/organizationSchema) */
export type OrganizationSchemaNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating an Organization schema according to schema.org [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/organizationSchema) */
export type OrganizationSchemaSameAsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model for generating an Organization schema according to schema.org [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/organizationSchema) */
export type OrganizationSchemaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type OrganizationSchemaCollection = {
  __typename?: 'OrganizationSchemaCollection';
  items: Array<Maybe<OrganizationSchema>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type OrganizationSchemaFilter = {
  AND?: InputMaybe<Array<InputMaybe<OrganizationSchemaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<OrganizationSchemaFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  logo_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sameAs_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sameAs_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sameAs_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sameAs_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OrganizationSchemaLinkingCollections = {
  __typename?: 'OrganizationSchemaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  metasCollection?: Maybe<MetasCollection>;
};


export type OrganizationSchemaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type OrganizationSchemaLinkingCollectionsMetasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<OrganizationSchemaLinkingCollectionsMetasCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum OrganizationSchemaLinkingCollectionsMetasCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum OrganizationSchemaOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type Page = Entry & _Node & {
  __typename?: 'Page';
  _id: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundMediaStyle?: Maybe<Scalars['String']>;
  backgroundMediaV2?: Maybe<PageBackgroundMediaV2>;
  banner?: Maybe<Banner>;
  blocksCollection?: Maybe<PageBlocksCollection>;
  category?: Maybe<Category>;
  collapseFootnotes?: Maybe<Scalars['Boolean']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  footerView?: Maybe<Scalars['String']>;
  footnotesCollection?: Maybe<PageFootnotesCollection>;
  headerView?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<PageLinkingCollections>;
  localizedIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  metas?: Maybe<Metas>;
  scrollSnap?: Maybe<Scalars['Boolean']>;
  sideNavigation?: Maybe<SideNavigation>;
  sys: Sys;
  tileImage?: Maybe<Image>;
  title?: Maybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageBackgroundMediaStyleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageBackgroundMediaV2Args = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageBannerArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<BannerFilter>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageBlocksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageBlocksFilter>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CategoryFilter>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageCollapseFootnotesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageFooterViewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageFootnotesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageFootnotesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FootNotesFilter>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageHeaderViewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageLocalizedInArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageMetasArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<MetasFilter>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageScrollSnapArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageSideNavigationArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SideNavigationFilter>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageTileImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ImageFilter>;
};


/** A Slug or Experiment has a Page, which composes of Block Hero and Blocks to make a web page. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/page) */
export type PageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/pageAsset) */
export type PageAsset = Entry & _Node & {
  __typename?: 'PageAsset';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<PageAssetLinkingCollections>;
  media?: Maybe<Asset>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/pageAsset) */
export type PageAssetContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/pageAsset) */
export type PageAssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/pageAsset) */
export type PageAssetMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type PageAssetCollection = {
  __typename?: 'PageAssetCollection';
  items: Array<Maybe<PageAsset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PageAssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageAssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageAssetFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type PageAssetLinkingCollections = {
  __typename?: 'PageAssetLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  slugCollection?: Maybe<SlugCollection>;
};


export type PageAssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PageAssetLinkingCollectionsSlugCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageAssetLinkingCollectionsSlugCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PageAssetLinkingCollectionsSlugCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  PostedDateAsc = 'postedDate_ASC',
  PostedDateDesc = 'postedDate_DESC',
  ShareableAsc = 'shareable_ASC',
  ShareableDesc = 'shareable_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SlugTypeAsc = 'slugType_ASC',
  SlugTypeDesc = 'slugType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnlistedAsc = 'unlisted_ASC',
  UnlistedDesc = 'unlisted_DESC'
}

export enum PageAssetOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PageBackgroundMediaV2 = Image | Video;

export type PageBlocksCollection = {
  __typename?: 'PageBlocksCollection';
  items: Array<Maybe<PageBlocksItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PageBlocksFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageBlocksFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageBlocksFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type PageBlocksItem = Block | BlockHero | Break | LatestPosts | MultiVideoBlock | SandboxCookieTest | ScrollAnimatedSection | SplitBlock | SubNavigation;

export type PageCollection = {
  __typename?: 'PageCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaStyle?: InputMaybe<Scalars['String']>;
  backgroundMediaStyle_contains?: InputMaybe<Scalars['String']>;
  backgroundMediaStyle_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundMediaStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaStyle_not?: InputMaybe<Scalars['String']>;
  backgroundMediaStyle_not_contains?: InputMaybe<Scalars['String']>;
  backgroundMediaStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaV2_exists?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<CfBannerNestedFilter>;
  banner_exists?: InputMaybe<Scalars['Boolean']>;
  blocks?: InputMaybe<CfblocksMultiTypeNestedFilter>;
  blocksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<CfCategoryNestedFilter>;
  category_exists?: InputMaybe<Scalars['Boolean']>;
  collapseFootnotes?: InputMaybe<Scalars['Boolean']>;
  collapseFootnotes_exists?: InputMaybe<Scalars['Boolean']>;
  collapseFootnotes_not?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  footerView?: InputMaybe<Scalars['String']>;
  footerView_contains?: InputMaybe<Scalars['String']>;
  footerView_exists?: InputMaybe<Scalars['Boolean']>;
  footerView_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footerView_not?: InputMaybe<Scalars['String']>;
  footerView_not_contains?: InputMaybe<Scalars['String']>;
  footerView_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footnotes?: InputMaybe<CfFootNotesNestedFilter>;
  footnotesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  headerView?: InputMaybe<Scalars['String']>;
  headerView_contains?: InputMaybe<Scalars['String']>;
  headerView_exists?: InputMaybe<Scalars['Boolean']>;
  headerView_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerView_not?: InputMaybe<Scalars['String']>;
  headerView_not_contains?: InputMaybe<Scalars['String']>;
  headerView_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedIn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedIn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedIn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedIn_exists?: InputMaybe<Scalars['Boolean']>;
  metas?: InputMaybe<CfMetasNestedFilter>;
  metas_exists?: InputMaybe<Scalars['Boolean']>;
  scrollSnap?: InputMaybe<Scalars['Boolean']>;
  scrollSnap_exists?: InputMaybe<Scalars['Boolean']>;
  scrollSnap_not?: InputMaybe<Scalars['Boolean']>;
  sideNavigation?: InputMaybe<CfSideNavigationNestedFilter>;
  sideNavigation_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  tileImage?: InputMaybe<CfImageNestedFilter>;
  tileImage_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PageFootnotesCollection = {
  __typename?: 'PageFootnotesCollection';
  items: Array<Maybe<FootNotes>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PageFootnotesCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayTextAsc = 'displayText_ASC',
  DisplayTextDesc = 'displayText_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PageLinkingCollections = {
  __typename?: 'PageLinkingCollections';
  abExperimentCollection?: Maybe<AbExperimentCollection>;
  abVariantCollection?: Maybe<AbVariantCollection>;
  entryCollection?: Maybe<EntryCollection>;
  experimentArmCollection?: Maybe<ExperimentArmCollection>;
  experimentCollection?: Maybe<ExperimentCollection>;
  seoScanMetadataCollection?: Maybe<SeoScanMetadataCollection>;
  slugCollection?: Maybe<SlugCollection>;
};


export type PageLinkingCollectionsAbExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsAbExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PageLinkingCollectionsAbVariantCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsAbVariantCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PageLinkingCollectionsExperimentArmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsExperimentArmCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PageLinkingCollectionsExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PageLinkingCollectionsSeoScanMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsSeoScanMetadataCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PageLinkingCollectionsSlugCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageLinkingCollectionsSlugCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PageLinkingCollectionsAbExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PageLinkingCollectionsAbVariantCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

export enum PageLinkingCollectionsExperimentArmCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PageLinkingCollectionsExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PageLinkingCollectionsSeoScanMetadataCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ScanDateTimeAsc = 'scanDateTime_ASC',
  ScanDateTimeDesc = 'scanDateTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PageLinkingCollectionsSlugCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  PostedDateAsc = 'postedDate_ASC',
  PostedDateDesc = 'postedDate_DESC',
  ShareableAsc = 'shareable_ASC',
  ShareableDesc = 'shareable_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SlugTypeAsc = 'slugType_ASC',
  SlugTypeDesc = 'slugType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnlistedAsc = 'unlisted_ASC',
  UnlistedDesc = 'unlisted_DESC'
}

export enum PageOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownField = Entry & _Node & {
  __typename?: 'PresetDropdownField';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  error?: Maybe<PresetDropdownFieldError>;
  helpText?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<PresetDropdownFieldLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  preset?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldErrorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldHelpTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldInitialValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldPresetArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/presetDropdownField) */
export type PresetDropdownFieldRequiredArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PresetDropdownFieldCollection = {
  __typename?: 'PresetDropdownFieldCollection';
  items: Array<Maybe<PresetDropdownField>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PresetDropdownFieldError = {
  __typename?: 'PresetDropdownFieldError';
  json: Scalars['JSON'];
  links?: PresetDropdownFieldErrorLinks;
};

export type PresetDropdownFieldErrorAssets = {
  __typename?: 'PresetDropdownFieldErrorAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PresetDropdownFieldErrorEntries = {
  __typename?: 'PresetDropdownFieldErrorEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PresetDropdownFieldErrorLinks = {
  __typename?: 'PresetDropdownFieldErrorLinks';
  assets: PresetDropdownFieldErrorAssets;
  entries: PresetDropdownFieldErrorEntries;
  resources: PresetDropdownFieldErrorResources;
};

export type PresetDropdownFieldErrorResources = {
  __typename?: 'PresetDropdownFieldErrorResources';
  block: Array<PresetDropdownFieldErrorResourcesBlock>;
  hyperlink: Array<PresetDropdownFieldErrorResourcesHyperlink>;
  inline: Array<PresetDropdownFieldErrorResourcesInline>;
};

export type PresetDropdownFieldErrorResourcesBlock = ResourceLink & {
  __typename?: 'PresetDropdownFieldErrorResourcesBlock';
  sys: ResourceSys;
};

export type PresetDropdownFieldErrorResourcesHyperlink = ResourceLink & {
  __typename?: 'PresetDropdownFieldErrorResourcesHyperlink';
  sys: ResourceSys;
};

export type PresetDropdownFieldErrorResourcesInline = ResourceLink & {
  __typename?: 'PresetDropdownFieldErrorResourcesInline';
  sys: ResourceSys;
};

export type PresetDropdownFieldFilter = {
  AND?: InputMaybe<Array<InputMaybe<PresetDropdownFieldFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PresetDropdownFieldFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  error_contains?: InputMaybe<Scalars['String']>;
  error_exists?: InputMaybe<Scalars['Boolean']>;
  error_not_contains?: InputMaybe<Scalars['String']>;
  helpText?: InputMaybe<Scalars['String']>;
  helpText_contains?: InputMaybe<Scalars['String']>;
  helpText_exists?: InputMaybe<Scalars['Boolean']>;
  helpText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  helpText_not?: InputMaybe<Scalars['String']>;
  helpText_not_contains?: InputMaybe<Scalars['String']>;
  helpText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  initialValue?: InputMaybe<Scalars['String']>;
  initialValue_contains?: InputMaybe<Scalars['String']>;
  initialValue_exists?: InputMaybe<Scalars['Boolean']>;
  initialValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  initialValue_not?: InputMaybe<Scalars['String']>;
  initialValue_not_contains?: InputMaybe<Scalars['String']>;
  initialValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  placeholder?: InputMaybe<Scalars['String']>;
  placeholder_contains?: InputMaybe<Scalars['String']>;
  placeholder_exists?: InputMaybe<Scalars['Boolean']>;
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  placeholder_not?: InputMaybe<Scalars['String']>;
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preset?: InputMaybe<Scalars['String']>;
  preset_contains?: InputMaybe<Scalars['String']>;
  preset_exists?: InputMaybe<Scalars['Boolean']>;
  preset_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preset_not?: InputMaybe<Scalars['String']>;
  preset_not_contains?: InputMaybe<Scalars['String']>;
  preset_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  required?: InputMaybe<Scalars['Boolean']>;
  required_exists?: InputMaybe<Scalars['Boolean']>;
  required_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type PresetDropdownFieldLinkingCollections = {
  __typename?: 'PresetDropdownFieldLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  formRowCollection?: Maybe<FormRowCollection>;
};


export type PresetDropdownFieldLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PresetDropdownFieldLinkingCollectionsFormRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PresetDropdownFieldLinkingCollectionsFormRowCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PresetDropdownFieldLinkingCollectionsFormRowCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PresetDropdownFieldOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HelpTextAsc = 'helpText_ASC',
  HelpTextDesc = 'helpText_DESC',
  InitialValueAsc = 'initialValue_ASC',
  InitialValueDesc = 'initialValue_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  PresetAsc = 'preset_ASC',
  PresetDesc = 'preset_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Query = {
  __typename?: 'Query';
  _node?: Maybe<_Node>;
  abExperiment?: Maybe<AbExperiment>;
  abExperimentCollection?: Maybe<AbExperimentCollection>;
  abVariant?: Maybe<AbVariant>;
  abVariantCollection?: Maybe<AbVariantCollection>;
  accordion?: Maybe<Accordion>;
  accordionCollection?: Maybe<AccordionCollection>;
  accordionItem?: Maybe<AccordionItem>;
  accordionItemCollection?: Maybe<AccordionItemCollection>;
  analytics?: Maybe<Analytics>;
  analyticsCollection?: Maybe<AnalyticsCollection>;
  animatedAccordion?: Maybe<AnimatedAccordion>;
  animatedAccordionCollection?: Maybe<AnimatedAccordionCollection>;
  animatedAccordionContent?: Maybe<AnimatedAccordionContent>;
  animatedAccordionContentCollection?: Maybe<AnimatedAccordionContentCollection>;
  animationKeyframes?: Maybe<AnimationKeyframes>;
  animationKeyframesCollection?: Maybe<AnimationKeyframesCollection>;
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  author?: Maybe<Author>;
  authorCollection?: Maybe<AuthorCollection>;
  banner?: Maybe<Banner>;
  bannerCollection?: Maybe<BannerCollection>;
  barChart?: Maybe<BarChart>;
  barChartCollection?: Maybe<BarChartCollection>;
  barChartGroup?: Maybe<BarChartGroup>;
  barChartGroupCollection?: Maybe<BarChartGroupCollection>;
  barChartStack?: Maybe<BarChartStack>;
  barChartStackCollection?: Maybe<BarChartStackCollection>;
  block?: Maybe<Block>;
  blockCollection?: Maybe<BlockCollection>;
  blockHero?: Maybe<BlockHero>;
  blockHeroCollection?: Maybe<BlockHeroCollection>;
  blogSchema?: Maybe<BlogSchema>;
  blogSchemaCollection?: Maybe<BlogSchemaCollection>;
  break?: Maybe<Break>;
  breakCollection?: Maybe<BreakCollection>;
  breakTemplate?: Maybe<BreakTemplate>;
  breakTemplateCollection?: Maybe<BreakTemplateCollection>;
  breakTemplateItem?: Maybe<BreakTemplateItem>;
  breakTemplateItemCollection?: Maybe<BreakTemplateItemCollection>;
  button?: Maybe<Button>;
  buttonCollection?: Maybe<ButtonCollection>;
  callToAction?: Maybe<CallToAction>;
  callToActionCollection?: Maybe<CallToActionCollection>;
  carouselCard?: Maybe<CarouselCard>;
  carouselCardCollection?: Maybe<CarouselCardCollection>;
  carouselText?: Maybe<CarouselText>;
  carouselTextCollection?: Maybe<CarouselTextCollection>;
  carouselV3?: Maybe<CarouselV3>;
  carouselV3Collection?: Maybe<CarouselV3Collection>;
  category?: Maybe<Category>;
  categoryCollection?: Maybe<CategoryCollection>;
  chartData?: Maybe<ChartData>;
  chartDataCollection?: Maybe<ChartDataCollection>;
  chartToggle?: Maybe<ChartToggle>;
  chartToggleCollection?: Maybe<ChartToggleCollection>;
  chartToggleItem?: Maybe<ChartToggleItem>;
  chartToggleItemCollection?: Maybe<ChartToggleItemCollection>;
  code?: Maybe<Code>;
  codeCollection?: Maybe<CodeCollection>;
  content?: Maybe<Content>;
  contentAnimation?: Maybe<ContentAnimation>;
  contentAnimationCollection?: Maybe<ContentAnimationCollection>;
  contentCollection?: Maybe<ContentCollection>;
  criteria?: Maybe<Criteria>;
  criteriaCollection?: Maybe<CriteriaCollection>;
  customFooter?: Maybe<CustomFooter>;
  customFooterCollection?: Maybe<CustomFooterCollection>;
  customerQuote?: Maybe<CustomerQuote>;
  customerQuoteCollection?: Maybe<CustomerQuoteCollection>;
  definition?: Maybe<Definition>;
  definitionCollection?: Maybe<DefinitionCollection>;
  downloadableAsset?: Maybe<DownloadableAsset>;
  downloadableAssetCollection?: Maybe<DownloadableAssetCollection>;
  dropdownField?: Maybe<DropdownField>;
  dropdownFieldCollection?: Maybe<DropdownFieldCollection>;
  dropdownFieldOption?: Maybe<DropdownFieldOption>;
  dropdownFieldOptionCollection?: Maybe<DropdownFieldOptionCollection>;
  entryCollection?: Maybe<EntryCollection>;
  experiment?: Maybe<Experiment>;
  experimentArm?: Maybe<ExperimentArm>;
  experimentArmCollection?: Maybe<ExperimentArmCollection>;
  experimentCollection?: Maybe<ExperimentCollection>;
  extraParametersFormFeature?: Maybe<ExtraParametersFormFeature>;
  extraParametersFormFeatureCollection?: Maybe<ExtraParametersFormFeatureCollection>;
  faqPageItemSchema?: Maybe<FaqPageItemSchema>;
  faqPageItemSchemaCollection?: Maybe<FaqPageItemSchemaCollection>;
  faqPageSchema?: Maybe<FaqPageSchema>;
  faqPageSchemaCollection?: Maybe<FaqPageSchemaCollection>;
  favicon?: Maybe<Favicon>;
  faviconCollection?: Maybe<FaviconCollection>;
  featureFlags?: Maybe<FeatureFlags>;
  featureFlagsCollection?: Maybe<FeatureFlagsCollection>;
  featuredHeaderMenuItem?: Maybe<FeaturedHeaderMenuItem>;
  featuredHeaderMenuItemCollection?: Maybe<FeaturedHeaderMenuItemCollection>;
  footNotes?: Maybe<FootNotes>;
  footNotesCollection?: Maybe<FootNotesCollection>;
  footerCookiesSettingsLink?: Maybe<FooterCookiesSettingsLink>;
  footerCookiesSettingsLinkCollection?: Maybe<FooterCookiesSettingsLinkCollection>;
  footerGroup?: Maybe<FooterGroup>;
  footerGroupCollection?: Maybe<FooterGroupCollection>;
  footerItem?: Maybe<FooterItem>;
  footerItemCollection?: Maybe<FooterItemCollection>;
  footerLocaleDropdown?: Maybe<FooterLocaleDropdown>;
  footerLocaleDropdownCollection?: Maybe<FooterLocaleDropdownCollection>;
  form?: Maybe<Form>;
  formCollection?: Maybe<FormCollection>;
  formRow?: Maybe<FormRow>;
  formRowCollection?: Maybe<FormRowCollection>;
  gallery?: Maybe<Gallery>;
  galleryCollection?: Maybe<GalleryCollection>;
  galleryDropdown?: Maybe<GalleryDropdown>;
  galleryDropdownCollection?: Maybe<GalleryDropdownCollection>;
  galleryDropdownItem?: Maybe<GalleryDropdownItem>;
  galleryDropdownItemCollection?: Maybe<GalleryDropdownItemCollection>;
  geoDataFilter?: Maybe<GeoDataFilter>;
  geoDataFilterCollection?: Maybe<GeoDataFilterCollection>;
  geoDataFilters?: Maybe<GeoDataFilters>;
  geoDataFiltersCollection?: Maybe<GeoDataFiltersCollection>;
  geoVisualization?: Maybe<GeoVisualization>;
  geoVisualizationCollection?: Maybe<GeoVisualizationCollection>;
  headerMenuItem?: Maybe<HeaderMenuItem>;
  headerMenuItemCollection?: Maybe<HeaderMenuItemCollection>;
  headerMenuItemGroup?: Maybe<HeaderMenuItemGroup>;
  headerMenuItemGroupCollection?: Maybe<HeaderMenuItemGroupCollection>;
  icon?: Maybe<Icon>;
  iconCollection?: Maybe<IconCollection>;
  image?: Maybe<Image>;
  imageButton?: Maybe<ImageButton>;
  imageButtonCollection?: Maybe<ImageButtonCollection>;
  imageCollection?: Maybe<ImageCollection>;
  inputField?: Maybe<InputField>;
  inputFieldCollection?: Maybe<InputFieldCollection>;
  latestPosts?: Maybe<LatestPosts>;
  latestPostsCollection?: Maybe<LatestPostsCollection>;
  legalMessage?: Maybe<LegalMessage>;
  legalMessageCollection?: Maybe<LegalMessageCollection>;
  lineChart?: Maybe<LineChart>;
  lineChartCollection?: Maybe<LineChartCollection>;
  message?: Maybe<Message>;
  messageCollection?: Maybe<MessageCollection>;
  metas?: Maybe<Metas>;
  metasCollection?: Maybe<MetasCollection>;
  mosaic?: Maybe<Mosaic>;
  mosaicCollection?: Maybe<MosaicCollection>;
  mosaicFilters?: Maybe<MosaicFilters>;
  mosaicFiltersCollection?: Maybe<MosaicFiltersCollection>;
  mosaicTile?: Maybe<MosaicTile>;
  mosaicTileCollection?: Maybe<MosaicTileCollection>;
  multiVideoBlock?: Maybe<MultiVideoBlock>;
  multiVideoBlockCollection?: Maybe<MultiVideoBlockCollection>;
  multiselectDropdownField?: Maybe<MultiselectDropdownField>;
  multiselectDropdownFieldCollection?: Maybe<MultiselectDropdownFieldCollection>;
  navigationCallToAction?: Maybe<NavigationCallToAction>;
  navigationCallToActionCollection?: Maybe<NavigationCallToActionCollection>;
  navigator?: Maybe<Navigator>;
  navigatorCollection?: Maybe<NavigatorCollection>;
  navigatorItem?: Maybe<NavigatorItem>;
  navigatorItemCollection?: Maybe<NavigatorItemCollection>;
  organizationSchema?: Maybe<OrganizationSchema>;
  organizationSchemaCollection?: Maybe<OrganizationSchemaCollection>;
  page?: Maybe<Page>;
  pageAsset?: Maybe<PageAsset>;
  pageAssetCollection?: Maybe<PageAssetCollection>;
  pageCollection?: Maybe<PageCollection>;
  presetDropdownField?: Maybe<PresetDropdownField>;
  presetDropdownFieldCollection?: Maybe<PresetDropdownFieldCollection>;
  radioSelectField?: Maybe<RadioSelectField>;
  radioSelectFieldCollection?: Maybe<RadioSelectFieldCollection>;
  redirect?: Maybe<Redirect>;
  redirectCollection?: Maybe<RedirectCollection>;
  sandboxCookieTest?: Maybe<SandboxCookieTest>;
  sandboxCookieTestCollection?: Maybe<SandboxCookieTestCollection>;
  scrollAnimatedSection?: Maybe<ScrollAnimatedSection>;
  scrollAnimatedSectionCollection?: Maybe<ScrollAnimatedSectionCollection>;
  scrollAnimatedVideo?: Maybe<ScrollAnimatedVideo>;
  scrollAnimatedVideoCollection?: Maybe<ScrollAnimatedVideoCollection>;
  seoScanMetadata?: Maybe<SeoScanMetadata>;
  seoScanMetadataCollection?: Maybe<SeoScanMetadataCollection>;
  series?: Maybe<Series>;
  seriesCollection?: Maybe<SeriesCollection>;
  sideNavigation?: Maybe<SideNavigation>;
  sideNavigationCollection?: Maybe<SideNavigationCollection>;
  sitewideConfiguration?: Maybe<SitewideConfiguration>;
  sitewideConfigurationCollection?: Maybe<SitewideConfigurationCollection>;
  sitewideExperiment?: Maybe<SitewideExperiment>;
  sitewideExperimentCollection?: Maybe<SitewideExperimentCollection>;
  sitewideValues?: Maybe<SitewideValues>;
  sitewideValuesCollection?: Maybe<SitewideValuesCollection>;
  sitewideVariant?: Maybe<SitewideVariant>;
  sitewideVariantCollection?: Maybe<SitewideVariantCollection>;
  sitewideVariantReplacement?: Maybe<SitewideVariantReplacement>;
  sitewideVariantReplacementCollection?: Maybe<SitewideVariantReplacementCollection>;
  slug?: Maybe<Slug>;
  slugCollection?: Maybe<SlugCollection>;
  snapchatEmbed?: Maybe<SnapchatEmbed>;
  snapchatEmbedCollection?: Maybe<SnapchatEmbedCollection>;
  splitBlock?: Maybe<SplitBlock>;
  splitBlockCollection?: Maybe<SplitBlockCollection>;
  subNavigation?: Maybe<SubNavigation>;
  subNavigationCollection?: Maybe<SubNavigationCollection>;
  subNavigationItem?: Maybe<SubNavigationItem>;
  subNavigationItemCollection?: Maybe<SubNavigationItemCollection>;
  submitButton?: Maybe<SubmitButton>;
  submitButtonCollection?: Maybe<SubmitButtonCollection>;
  tabItem?: Maybe<TabItem>;
  tabItemCollection?: Maybe<TabItemCollection>;
  tableVisualization?: Maybe<TableVisualization>;
  tableVisualizationCollection?: Maybe<TableVisualizationCollection>;
  tabs?: Maybe<Tabs>;
  tabsCollection?: Maybe<TabsCollection>;
  template?: Maybe<Template>;
  templateCollection?: Maybe<TemplateCollection>;
  tile?: Maybe<Tile>;
  tileCollection?: Maybe<TileCollection>;
  version?: Maybe<Version>;
  versionCollection?: Maybe<VersionCollection>;
  video?: Maybe<Video>;
  videoCollection?: Maybe<VideoCollection>;
  visualizationSelector?: Maybe<VisualizationSelector>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type Query_NodeArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAbExperimentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAbExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AbExperimentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AbExperimentFilter>;
};


export type QueryAbVariantArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAbVariantCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AbVariantOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AbVariantFilter>;
};


export type QueryAccordionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccordionFilter>;
};


export type QueryAccordionItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAccordionItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccordionItemFilter>;
};


export type QueryAnalyticsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAnalyticsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnalyticsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnalyticsFilter>;
};


export type QueryAnimatedAccordionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAnimatedAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnimatedAccordionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnimatedAccordionFilter>;
};


export type QueryAnimatedAccordionContentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAnimatedAccordionContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnimatedAccordionContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnimatedAccordionContentFilter>;
};


export type QueryAnimationKeyframesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAnimationKeyframesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnimationKeyframesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnimationKeyframesFilter>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilter>;
};


export type QueryAuthorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AuthorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuthorFilter>;
};


export type QueryBannerArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerFilter>;
};


export type QueryBarChartArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBarChartCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarChartFilter>;
};


export type QueryBarChartGroupArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBarChartGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartGroupOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarChartGroupFilter>;
};


export type QueryBarChartStackArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBarChartStackCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BarChartStackOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarChartStackFilter>;
};


export type QueryBlockArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlockFilter>;
};


export type QueryBlockHeroArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBlockHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlockHeroOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlockHeroFilter>;
};


export type QueryBlogSchemaArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBlogSchemaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BlogSchemaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogSchemaFilter>;
};


export type QueryBreakArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBreakCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BreakOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BreakFilter>;
};


export type QueryBreakTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBreakTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BreakTemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BreakTemplateFilter>;
};


export type QueryBreakTemplateItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBreakTemplateItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BreakTemplateItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BreakTemplateItemFilter>;
};


export type QueryButtonArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ButtonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ButtonFilter>;
};


export type QueryCallToActionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CallToActionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CallToActionFilter>;
};


export type QueryCarouselCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCarouselCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CarouselCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarouselCardFilter>;
};


export type QueryCarouselTextArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCarouselTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CarouselTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarouselTextFilter>;
};


export type QueryCarouselV3Args = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCarouselV3CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CarouselV3Order>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarouselV3Filter>;
};


export type QueryCategoryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CategoryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryFilter>;
};


export type QueryChartDataArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryChartDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartDataOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChartDataFilter>;
};


export type QueryChartToggleArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryChartToggleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartToggleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChartToggleFilter>;
};


export type QueryChartToggleItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryChartToggleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ChartToggleItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChartToggleItemFilter>;
};


export type QueryCodeArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCodeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CodeOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CodeFilter>;
};


export type QueryContentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryContentAnimationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryContentAnimationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContentAnimationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentAnimationFilter>;
};


export type QueryContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentFilter>;
};


export type QueryCriteriaArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCriteriaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CriteriaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CriteriaFilter>;
};


export type QueryCustomFooterArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCustomFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomFooterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomFooterFilter>;
};


export type QueryCustomerQuoteArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCustomerQuoteCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomerQuoteOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerQuoteFilter>;
};


export type QueryDefinitionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDefinitionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DefinitionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DefinitionFilter>;
};


export type QueryDownloadableAssetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDownloadableAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DownloadableAssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DownloadableAssetFilter>;
};


export type QueryDropdownFieldArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDropdownFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DropdownFieldOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DropdownFieldFilter>;
};


export type QueryDropdownFieldOptionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDropdownFieldOptionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DropdownFieldOptionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DropdownFieldOptionFilter>;
};


export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntryFilter>;
};


export type QueryExperimentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryExperimentArmArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryExperimentArmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExperimentArmOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExperimentArmFilter>;
};


export type QueryExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExperimentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExperimentFilter>;
};


export type QueryExtraParametersFormFeatureArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryExtraParametersFormFeatureCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExtraParametersFormFeatureOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtraParametersFormFeatureFilter>;
};


export type QueryFaqPageItemSchemaArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFaqPageItemSchemaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqPageItemSchemaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqPageItemSchemaFilter>;
};


export type QueryFaqPageSchemaArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFaqPageSchemaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqPageSchemaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqPageSchemaFilter>;
};


export type QueryFaviconArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFaviconCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaviconOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaviconFilter>;
};


export type QueryFeatureFlagsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFeatureFlagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeatureFlagsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeatureFlagsFilter>;
};


export type QueryFeaturedHeaderMenuItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFeaturedHeaderMenuItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeaturedHeaderMenuItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeaturedHeaderMenuItemFilter>;
};


export type QueryFootNotesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFootNotesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FootNotesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FootNotesFilter>;
};


export type QueryFooterCookiesSettingsLinkArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFooterCookiesSettingsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterCookiesSettingsLinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterCookiesSettingsLinkFilter>;
};


export type QueryFooterGroupArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFooterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterGroupOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterGroupFilter>;
};


export type QueryFooterItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFooterItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterItemFilter>;
};


export type QueryFooterLocaleDropdownArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFooterLocaleDropdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterLocaleDropdownOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterLocaleDropdownFilter>;
};


export type QueryFormArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FormOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FormFilter>;
};


export type QueryFormRowArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFormRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FormRowOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FormRowFilter>;
};


export type QueryGalleryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GalleryFilter>;
};


export type QueryGalleryDropdownArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGalleryDropdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryDropdownOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GalleryDropdownFilter>;
};


export type QueryGalleryDropdownItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGalleryDropdownItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryDropdownItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GalleryDropdownItemFilter>;
};


export type QueryGeoDataFilterArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGeoDataFilterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GeoDataFilterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GeoDataFilterFilter>;
};


export type QueryGeoDataFiltersArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGeoDataFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GeoDataFiltersOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GeoDataFiltersFilter>;
};


export type QueryGeoVisualizationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGeoVisualizationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GeoVisualizationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GeoVisualizationFilter>;
};


export type QueryHeaderMenuItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHeaderMenuItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeaderMenuItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeaderMenuItemFilter>;
};


export type QueryHeaderMenuItemGroupArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHeaderMenuItemGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeaderMenuItemGroupOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeaderMenuItemGroupFilter>;
};


export type QueryIconArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryIconCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<IconOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IconFilter>;
};


export type QueryImageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryImageButtonArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryImageButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageButtonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageButtonFilter>;
};


export type QueryImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageFilter>;
};


export type QueryInputFieldArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInputFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<InputFieldOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InputFieldFilter>;
};


export type QueryLatestPostsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLatestPostsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LatestPostsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LatestPostsFilter>;
};


export type QueryLegalMessageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLegalMessageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LegalMessageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegalMessageFilter>;
};


export type QueryLineChartArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLineChartCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LineChartOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LineChartFilter>;
};


export type QueryMessageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMessageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MessageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageFilter>;
};


export type QueryMetasArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMetasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MetasOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MetasFilter>;
};


export type QueryMosaicArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMosaicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MosaicFilter>;
};


export type QueryMosaicFiltersArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMosaicFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicFiltersOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MosaicFiltersFilter>;
};


export type QueryMosaicTileArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMosaicTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MosaicTileOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MosaicTileFilter>;
};


export type QueryMultiVideoBlockArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMultiVideoBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiVideoBlockOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MultiVideoBlockFilter>;
};


export type QueryMultiselectDropdownFieldArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMultiselectDropdownFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultiselectDropdownFieldOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MultiselectDropdownFieldFilter>;
};


export type QueryNavigationCallToActionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNavigationCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigationCallToActionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NavigationCallToActionFilter>;
};


export type QueryNavigatorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNavigatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NavigatorFilter>;
};


export type QueryNavigatorItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNavigatorItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NavigatorItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NavigatorItemFilter>;
};


export type QueryOrganizationSchemaArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOrganizationSchemaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<OrganizationSchemaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationSchemaFilter>;
};


export type QueryPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPageAssetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPageAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageAssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageAssetFilter>;
};


export type QueryPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageFilter>;
};


export type QueryPresetDropdownFieldArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPresetDropdownFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PresetDropdownFieldOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PresetDropdownFieldFilter>;
};


export type QueryRadioSelectFieldArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRadioSelectFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RadioSelectFieldOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RadioSelectFieldFilter>;
};


export type QueryRedirectArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRedirectCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RedirectOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RedirectFilter>;
};


export type QuerySandboxCookieTestArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySandboxCookieTestCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SandboxCookieTestOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SandboxCookieTestFilter>;
};


export type QueryScrollAnimatedSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryScrollAnimatedSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ScrollAnimatedSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScrollAnimatedSectionFilter>;
};


export type QueryScrollAnimatedVideoArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryScrollAnimatedVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ScrollAnimatedVideoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScrollAnimatedVideoFilter>;
};


export type QuerySeoScanMetadataArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySeoScanMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SeoScanMetadataOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoScanMetadataFilter>;
};


export type QuerySeriesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySeriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SeriesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeriesFilter>;
};


export type QuerySideNavigationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySideNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SideNavigationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SideNavigationFilter>;
};


export type QuerySitewideConfigurationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySitewideConfigurationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideConfigurationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitewideConfigurationFilter>;
};


export type QuerySitewideExperimentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySitewideExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideExperimentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitewideExperimentFilter>;
};


export type QuerySitewideValuesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySitewideValuesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideValuesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitewideValuesFilter>;
};


export type QuerySitewideVariantArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySitewideVariantCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideVariantOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitewideVariantFilter>;
};


export type QuerySitewideVariantReplacementArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySitewideVariantReplacementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideVariantReplacementOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitewideVariantReplacementFilter>;
};


export type QuerySlugArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySlugCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SlugOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SlugFilter>;
};


export type QuerySnapchatEmbedArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySnapchatEmbedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SnapchatEmbedOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SnapchatEmbedFilter>;
};


export type QuerySplitBlockArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySplitBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SplitBlockOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SplitBlockFilter>;
};


export type QuerySubNavigationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySubNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SubNavigationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubNavigationFilter>;
};


export type QuerySubNavigationItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySubNavigationItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SubNavigationItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubNavigationItemFilter>;
};


export type QuerySubmitButtonArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySubmitButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SubmitButtonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubmitButtonFilter>;
};


export type QueryTabItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTabItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TabItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TabItemFilter>;
};


export type QueryTableVisualizationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTableVisualizationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TableVisualizationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TableVisualizationFilter>;
};


export type QueryTabsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TabsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TabsFilter>;
};


export type QueryTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TemplateFilter>;
};


export type QueryTileArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TileOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TileFilter>;
};


export type QueryVersionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVersionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VersionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VersionFilter>;
};


export type QueryVideoArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoFilter>;
};


export type QueryVisualizationSelectorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VisualizationSelectorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VisualizationSelectorFilter>;
};

/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectField = Entry & _Node & {
  __typename?: 'RadioSelectField';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  error?: Maybe<RadioSelectFieldError>;
  helpText?: Maybe<Scalars['String']>;
  initialOption?: Maybe<DropdownFieldOption>;
  label?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<RadioSelectFieldLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  optionsCollection?: Maybe<RadioSelectFieldOptionsCollection>;
  required?: Maybe<Scalars['Boolean']>;
  shouldResetToInitial?: Maybe<Scalars['Boolean']>;
  sys: Sys;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldErrorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldHelpTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldInitialOptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<DropdownFieldOptionFilter>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RadioSelectFieldOptionsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DropdownFieldOptionFilter>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldRequiredArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Type of input for Form [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/radioSelectField) */
export type RadioSelectFieldShouldResetToInitialArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type RadioSelectFieldCollection = {
  __typename?: 'RadioSelectFieldCollection';
  items: Array<Maybe<RadioSelectField>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RadioSelectFieldError = {
  __typename?: 'RadioSelectFieldError';
  json: Scalars['JSON'];
  links?: RadioSelectFieldErrorLinks;
};

export type RadioSelectFieldErrorAssets = {
  __typename?: 'RadioSelectFieldErrorAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type RadioSelectFieldErrorEntries = {
  __typename?: 'RadioSelectFieldErrorEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type RadioSelectFieldErrorLinks = {
  __typename?: 'RadioSelectFieldErrorLinks';
  assets: RadioSelectFieldErrorAssets;
  entries: RadioSelectFieldErrorEntries;
  resources: RadioSelectFieldErrorResources;
};

export type RadioSelectFieldErrorResources = {
  __typename?: 'RadioSelectFieldErrorResources';
  block: Array<RadioSelectFieldErrorResourcesBlock>;
  hyperlink: Array<RadioSelectFieldErrorResourcesHyperlink>;
  inline: Array<RadioSelectFieldErrorResourcesInline>;
};

export type RadioSelectFieldErrorResourcesBlock = ResourceLink & {
  __typename?: 'RadioSelectFieldErrorResourcesBlock';
  sys: ResourceSys;
};

export type RadioSelectFieldErrorResourcesHyperlink = ResourceLink & {
  __typename?: 'RadioSelectFieldErrorResourcesHyperlink';
  sys: ResourceSys;
};

export type RadioSelectFieldErrorResourcesInline = ResourceLink & {
  __typename?: 'RadioSelectFieldErrorResourcesInline';
  sys: ResourceSys;
};

export type RadioSelectFieldFilter = {
  AND?: InputMaybe<Array<InputMaybe<RadioSelectFieldFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RadioSelectFieldFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  error_contains?: InputMaybe<Scalars['String']>;
  error_exists?: InputMaybe<Scalars['Boolean']>;
  error_not_contains?: InputMaybe<Scalars['String']>;
  helpText?: InputMaybe<Scalars['String']>;
  helpText_contains?: InputMaybe<Scalars['String']>;
  helpText_exists?: InputMaybe<Scalars['Boolean']>;
  helpText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  helpText_not?: InputMaybe<Scalars['String']>;
  helpText_not_contains?: InputMaybe<Scalars['String']>;
  helpText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  initialOption?: InputMaybe<CfDropdownFieldOptionNestedFilter>;
  initialOption_exists?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  options?: InputMaybe<CfDropdownFieldOptionNestedFilter>;
  optionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  required_exists?: InputMaybe<Scalars['Boolean']>;
  required_not?: InputMaybe<Scalars['Boolean']>;
  shouldResetToInitial?: InputMaybe<Scalars['Boolean']>;
  shouldResetToInitial_exists?: InputMaybe<Scalars['Boolean']>;
  shouldResetToInitial_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type RadioSelectFieldLinkingCollections = {
  __typename?: 'RadioSelectFieldLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  formRowCollection?: Maybe<FormRowCollection>;
};


export type RadioSelectFieldLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type RadioSelectFieldLinkingCollectionsFormRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RadioSelectFieldLinkingCollectionsFormRowCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum RadioSelectFieldLinkingCollectionsFormRowCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type RadioSelectFieldOptionsCollection = {
  __typename?: 'RadioSelectFieldOptionsCollection';
  items: Array<Maybe<DropdownFieldOption>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum RadioSelectFieldOptionsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export enum RadioSelectFieldOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  HelpTextAsc = 'helpText_ASC',
  HelpTextDesc = 'helpText_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  ShouldResetToInitialAsc = 'shouldResetToInitial_ASC',
  ShouldResetToInitialDesc = 'shouldResetToInitial_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Model to create a redirect. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/redirect) */
export type Redirect = Entry & _Node & {
  __typename?: 'Redirect';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  externalUrl?: Maybe<Scalars['String']>;
  isPermanent?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<RedirectLinkingCollections>;
  slug?: Maybe<Slug>;
  sys: Sys;
};


/** Model to create a redirect. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/redirect) */
export type RedirectContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model to create a redirect. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/redirect) */
export type RedirectExternalUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model to create a redirect. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/redirect) */
export type RedirectIsPermanentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Model to create a redirect. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/redirect) */
export type RedirectLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Model to create a redirect. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/redirect) */
export type RedirectSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SlugFilter>;
};

export type RedirectCollection = {
  __typename?: 'RedirectCollection';
  items: Array<Maybe<Redirect>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RedirectFilter = {
  AND?: InputMaybe<Array<InputMaybe<RedirectFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RedirectFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  externalUrl?: InputMaybe<Scalars['String']>;
  externalUrl_contains?: InputMaybe<Scalars['String']>;
  externalUrl_exists?: InputMaybe<Scalars['Boolean']>;
  externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  externalUrl_not?: InputMaybe<Scalars['String']>;
  externalUrl_not_contains?: InputMaybe<Scalars['String']>;
  externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isPermanent?: InputMaybe<Scalars['Boolean']>;
  isPermanent_exists?: InputMaybe<Scalars['Boolean']>;
  isPermanent_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<CfSlugNestedFilter>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type RedirectLinkingCollections = {
  __typename?: 'RedirectLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  experimentArmCollection?: Maybe<ExperimentArmCollection>;
  experimentCollection?: Maybe<ExperimentCollection>;
  slugCollection?: Maybe<SlugCollection>;
};


export type RedirectLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type RedirectLinkingCollectionsExperimentArmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RedirectLinkingCollectionsExperimentArmCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type RedirectLinkingCollectionsExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RedirectLinkingCollectionsExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type RedirectLinkingCollectionsSlugCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RedirectLinkingCollectionsSlugCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum RedirectLinkingCollectionsExperimentArmCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum RedirectLinkingCollectionsExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum RedirectLinkingCollectionsSlugCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  PostedDateAsc = 'postedDate_ASC',
  PostedDateDesc = 'postedDate_DESC',
  ShareableAsc = 'shareable_ASC',
  ShareableDesc = 'shareable_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SlugTypeAsc = 'slugType_ASC',
  SlugTypeDesc = 'slugType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnlistedAsc = 'unlisted_ASC',
  UnlistedDesc = 'unlisted_DESC'
}

export enum RedirectOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  IsPermanentAsc = 'isPermanent_ASC',
  IsPermanentDesc = 'isPermanent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ResourceLink = {
  sys: ResourceSys;
};

export type ResourceSys = {
  __typename?: 'ResourceSys';
  linkType: Scalars['String'];
  urn: Scalars['String'];
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/SandboxCookieTest) */
export type SandboxCookieTest = Entry & _Node & {
  __typename?: 'SandboxCookieTest';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SandboxCookieTestLinkingCollections>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/SandboxCookieTest) */
export type SandboxCookieTestContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/SandboxCookieTest) */
export type SandboxCookieTestLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SandboxCookieTestCollection = {
  __typename?: 'SandboxCookieTestCollection';
  items: Array<Maybe<SandboxCookieTest>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SandboxCookieTestFilter = {
  AND?: InputMaybe<Array<InputMaybe<SandboxCookieTestFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SandboxCookieTestFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type SandboxCookieTestLinkingCollections = {
  __typename?: 'SandboxCookieTestLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type SandboxCookieTestLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SandboxCookieTestLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SandboxCookieTestLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SandboxCookieTestLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum SandboxCookieTestOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedSection) */
export type ScrollAnimatedSection = Entry & _Node & {
  __typename?: 'ScrollAnimatedSection';
  _id: Scalars['ID'];
  animatedContentCollection?: Maybe<ScrollAnimatedSectionAnimatedContentCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<ScrollAnimatedSectionLinkingCollections>;
  mobileContentCollection?: Maybe<ScrollAnimatedSectionMobileContentCollection>;
  scrollHeight?: Maybe<Scalars['Float']>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedSection) */
export type ScrollAnimatedSectionAnimatedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ScrollAnimatedSectionAnimatedContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScrollAnimatedVideoFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedSection) */
export type ScrollAnimatedSectionContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedSection) */
export type ScrollAnimatedSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedSection) */
export type ScrollAnimatedSectionMobileContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ScrollAnimatedSectionMobileContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlockFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedSection) */
export type ScrollAnimatedSectionScrollHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ScrollAnimatedSectionAnimatedContentCollection = {
  __typename?: 'ScrollAnimatedSectionAnimatedContentCollection';
  items: Array<Maybe<ScrollAnimatedVideo>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ScrollAnimatedSectionAnimatedContentCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ScrubEndAsc = 'scrubEnd_ASC',
  ScrubEndDesc = 'scrubEnd_DESC',
  ScrubStartAsc = 'scrubStart_ASC',
  ScrubStartDesc = 'scrubStart_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VideoEndAsc = 'videoEnd_ASC',
  VideoEndDesc = 'videoEnd_DESC',
  VideoStartAsc = 'videoStart_ASC',
  VideoStartDesc = 'videoStart_DESC'
}

export type ScrollAnimatedSectionCollection = {
  __typename?: 'ScrollAnimatedSectionCollection';
  items: Array<Maybe<ScrollAnimatedSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ScrollAnimatedSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ScrollAnimatedSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ScrollAnimatedSectionFilter>>>;
  animatedContent?: InputMaybe<CfScrollAnimatedVideoNestedFilter>;
  animatedContentCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mobileContent?: InputMaybe<CfBlockNestedFilter>;
  mobileContentCollection_exists?: InputMaybe<Scalars['Boolean']>;
  scrollHeight?: InputMaybe<Scalars['Float']>;
  scrollHeight_exists?: InputMaybe<Scalars['Boolean']>;
  scrollHeight_gt?: InputMaybe<Scalars['Float']>;
  scrollHeight_gte?: InputMaybe<Scalars['Float']>;
  scrollHeight_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  scrollHeight_lt?: InputMaybe<Scalars['Float']>;
  scrollHeight_lte?: InputMaybe<Scalars['Float']>;
  scrollHeight_not?: InputMaybe<Scalars['Float']>;
  scrollHeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type ScrollAnimatedSectionLinkingCollections = {
  __typename?: 'ScrollAnimatedSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type ScrollAnimatedSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ScrollAnimatedSectionLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ScrollAnimatedSectionLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ScrollAnimatedSectionLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ScrollAnimatedSectionMobileContentCollection = {
  __typename?: 'ScrollAnimatedSectionMobileContentCollection';
  items: Array<Maybe<Block>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ScrollAnimatedSectionMobileContentCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum ScrollAnimatedSectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ScrollHeightAsc = 'scrollHeight_ASC',
  ScrollHeightDesc = 'scrollHeight_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideo = Entry & _Node & {
  __typename?: 'ScrollAnimatedVideo';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<ScrollAnimatedVideoLinkingCollections>;
  mp4Source?: Maybe<Asset>;
  scrubEnd?: Maybe<Scalars['Float']>;
  scrubStart?: Maybe<Scalars['Float']>;
  sys: Sys;
  videoEnd?: Maybe<Scalars['Float']>;
  videoStart?: Maybe<Scalars['Float']>;
  webmSource?: Maybe<Asset>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideoContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideoMp4SourceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideoScrubEndArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideoScrubStartArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideoVideoEndArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideoVideoStartArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/scrollAnimatedVideo) */
export type ScrollAnimatedVideoWebmSourceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type ScrollAnimatedVideoCollection = {
  __typename?: 'ScrollAnimatedVideoCollection';
  items: Array<Maybe<ScrollAnimatedVideo>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ScrollAnimatedVideoFilter = {
  AND?: InputMaybe<Array<InputMaybe<ScrollAnimatedVideoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ScrollAnimatedVideoFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mp4Source_exists?: InputMaybe<Scalars['Boolean']>;
  scrubEnd?: InputMaybe<Scalars['Float']>;
  scrubEnd_exists?: InputMaybe<Scalars['Boolean']>;
  scrubEnd_gt?: InputMaybe<Scalars['Float']>;
  scrubEnd_gte?: InputMaybe<Scalars['Float']>;
  scrubEnd_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  scrubEnd_lt?: InputMaybe<Scalars['Float']>;
  scrubEnd_lte?: InputMaybe<Scalars['Float']>;
  scrubEnd_not?: InputMaybe<Scalars['Float']>;
  scrubEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  scrubStart?: InputMaybe<Scalars['Float']>;
  scrubStart_exists?: InputMaybe<Scalars['Boolean']>;
  scrubStart_gt?: InputMaybe<Scalars['Float']>;
  scrubStart_gte?: InputMaybe<Scalars['Float']>;
  scrubStart_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  scrubStart_lt?: InputMaybe<Scalars['Float']>;
  scrubStart_lte?: InputMaybe<Scalars['Float']>;
  scrubStart_not?: InputMaybe<Scalars['Float']>;
  scrubStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  sys?: InputMaybe<SysFilter>;
  videoEnd?: InputMaybe<Scalars['Float']>;
  videoEnd_exists?: InputMaybe<Scalars['Boolean']>;
  videoEnd_gt?: InputMaybe<Scalars['Float']>;
  videoEnd_gte?: InputMaybe<Scalars['Float']>;
  videoEnd_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  videoEnd_lt?: InputMaybe<Scalars['Float']>;
  videoEnd_lte?: InputMaybe<Scalars['Float']>;
  videoEnd_not?: InputMaybe<Scalars['Float']>;
  videoEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  videoStart?: InputMaybe<Scalars['Float']>;
  videoStart_exists?: InputMaybe<Scalars['Boolean']>;
  videoStart_gt?: InputMaybe<Scalars['Float']>;
  videoStart_gte?: InputMaybe<Scalars['Float']>;
  videoStart_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  videoStart_lt?: InputMaybe<Scalars['Float']>;
  videoStart_lte?: InputMaybe<Scalars['Float']>;
  videoStart_not?: InputMaybe<Scalars['Float']>;
  videoStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  webmSource_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ScrollAnimatedVideoLinkingCollections = {
  __typename?: 'ScrollAnimatedVideoLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  scrollAnimatedSectionCollection?: Maybe<ScrollAnimatedSectionCollection>;
};


export type ScrollAnimatedVideoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ScrollAnimatedVideoLinkingCollectionsScrollAnimatedSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ScrollAnimatedVideoLinkingCollectionsScrollAnimatedSectionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ScrollAnimatedVideoLinkingCollectionsScrollAnimatedSectionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ScrollHeightAsc = 'scrollHeight_ASC',
  ScrollHeightDesc = 'scrollHeight_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ScrollAnimatedVideoOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ScrubEndAsc = 'scrubEnd_ASC',
  ScrubEndDesc = 'scrubEnd_DESC',
  ScrubStartAsc = 'scrubStart_ASC',
  ScrubStartDesc = 'scrubStart_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VideoEndAsc = 'videoEnd_ASC',
  VideoEndDesc = 'videoEnd_DESC',
  VideoStartAsc = 'videoStart_ASC',
  VideoStartDesc = 'videoStart_DESC'
}

/** Specifies slug/page SEO score information [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/seoScanMetadata) */
export type SeoScanMetadata = Entry & _Node & {
  __typename?: 'SeoScanMetadata';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SeoScanMetadataLinkingCollections>;
  metadata?: Maybe<Scalars['JSON']>;
  page?: Maybe<Page>;
  scanDateTime?: Maybe<Scalars['DateTime']>;
  sys: Sys;
};


/** Specifies slug/page SEO score information [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/seoScanMetadata) */
export type SeoScanMetadataContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Specifies slug/page SEO score information [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/seoScanMetadata) */
export type SeoScanMetadataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Specifies slug/page SEO score information [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/seoScanMetadata) */
export type SeoScanMetadataMetadataArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Specifies slug/page SEO score information [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/seoScanMetadata) */
export type SeoScanMetadataPageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PageFilter>;
};


/** Specifies slug/page SEO score information [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/seoScanMetadata) */
export type SeoScanMetadataScanDateTimeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SeoScanMetadataCollection = {
  __typename?: 'SeoScanMetadataCollection';
  items: Array<Maybe<SeoScanMetadata>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SeoScanMetadataFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoScanMetadataFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoScanMetadataFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  metadata_exists?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<CfPageNestedFilter>;
  page_exists?: InputMaybe<Scalars['Boolean']>;
  scanDateTime?: InputMaybe<Scalars['DateTime']>;
  scanDateTime_exists?: InputMaybe<Scalars['Boolean']>;
  scanDateTime_gt?: InputMaybe<Scalars['DateTime']>;
  scanDateTime_gte?: InputMaybe<Scalars['DateTime']>;
  scanDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  scanDateTime_lt?: InputMaybe<Scalars['DateTime']>;
  scanDateTime_lte?: InputMaybe<Scalars['DateTime']>;
  scanDateTime_not?: InputMaybe<Scalars['DateTime']>;
  scanDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type SeoScanMetadataLinkingCollections = {
  __typename?: 'SeoScanMetadataLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type SeoScanMetadataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SeoScanMetadataOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ScanDateTimeAsc = 'scanDateTime_ASC',
  ScanDateTimeDesc = 'scanDateTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A tag that associates different chart data uploads and contains their schema [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/series) */
export type Series = Entry & _Node & {
  __typename?: 'Series';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  headerNames?: Maybe<Scalars['JSON']>;
  linkedFrom?: Maybe<SeriesLinkingCollections>;
  localizableProperties?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['JSON']>;
  sys: Sys;
};


/** A tag that associates different chart data uploads and contains their schema [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/series) */
export type SeriesContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A tag that associates different chart data uploads and contains their schema [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/series) */
export type SeriesHeaderNamesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A tag that associates different chart data uploads and contains their schema [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/series) */
export type SeriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** A tag that associates different chart data uploads and contains their schema [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/series) */
export type SeriesLocalizablePropertiesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A tag that associates different chart data uploads and contains their schema [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/series) */
export type SeriesNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A tag that associates different chart data uploads and contains their schema [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/series) */
export type SeriesSchemaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SeriesCollection = {
  __typename?: 'SeriesCollection';
  items: Array<Maybe<Series>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SeriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeriesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeriesFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headerNames_exists?: InputMaybe<Scalars['Boolean']>;
  localizableProperties_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizableProperties_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizableProperties_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizableProperties_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  schema_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type SeriesLinkingCollections = {
  __typename?: 'SeriesLinkingCollections';
  chartDataCollection?: Maybe<ChartDataCollection>;
  entryCollection?: Maybe<EntryCollection>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type SeriesLinkingCollectionsChartDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SeriesLinkingCollectionsChartDataCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SeriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SeriesLinkingCollectionsVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SeriesLinkingCollectionsVisualizationSelectorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SeriesLinkingCollectionsChartDataCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SeriesLinkingCollectionsVisualizationSelectorCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SeriesOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sideNavigation) */
export type SideNavigation = Entry & _Node & {
  __typename?: 'SideNavigation';
  _id: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SideNavigationLinkingCollections>;
  mobileBackgroundColor?: Maybe<Scalars['String']>;
  navigatorItemsCollection?: Maybe<SideNavigationNavigatorItemsCollection>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sideNavigation) */
export type SideNavigationBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sideNavigation) */
export type SideNavigationContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sideNavigation) */
export type SideNavigationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sideNavigation) */
export type SideNavigationMobileBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sideNavigation) */
export type SideNavigationNavigatorItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SideNavigationNavigatorItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NavigatorItemFilter>;
};

export type SideNavigationCollection = {
  __typename?: 'SideNavigationCollection';
  items: Array<Maybe<SideNavigation>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SideNavigationFilter = {
  AND?: InputMaybe<Array<InputMaybe<SideNavigationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SideNavigationFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mobileBackgroundColor?: InputMaybe<Scalars['String']>;
  mobileBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  mobileBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  mobileBackgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileBackgroundColor_not?: InputMaybe<Scalars['String']>;
  mobileBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  mobileBackgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  navigatorItems?: InputMaybe<CfNavigatorItemNestedFilter>;
  navigatorItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type SideNavigationLinkingCollections = {
  __typename?: 'SideNavigationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type SideNavigationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SideNavigationLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SideNavigationLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SideNavigationLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type SideNavigationNavigatorItemsCollection = {
  __typename?: 'SideNavigationNavigatorItemsCollection';
  items: Array<Maybe<NavigatorItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SideNavigationNavigatorItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum SideNavigationOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MobileBackgroundColorAsc = 'mobileBackgroundColor_ASC',
  MobileBackgroundColorDesc = 'mobileBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Singleton (only one entry should exist) entry that holds the values for things like Navigation Bar, Footer, and Feature Flags [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideConfiguration) */
export type SitewideConfiguration = Entry & _Node & {
  __typename?: 'SitewideConfiguration';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SitewideConfigurationLinkingCollections>;
  reference?: Maybe<SitewideConfigurationReference>;
  sys: Sys;
};


/** Singleton (only one entry should exist) entry that holds the values for things like Navigation Bar, Footer, and Feature Flags [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideConfiguration) */
export type SitewideConfigurationContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Singleton (only one entry should exist) entry that holds the values for things like Navigation Bar, Footer, and Feature Flags [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideConfiguration) */
export type SitewideConfigurationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Singleton (only one entry should exist) entry that holds the values for things like Navigation Bar, Footer, and Feature Flags [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideConfiguration) */
export type SitewideConfigurationReferenceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type SitewideConfigurationCollection = {
  __typename?: 'SitewideConfigurationCollection';
  items: Array<Maybe<SitewideConfiguration>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SitewideConfigurationFilter = {
  AND?: InputMaybe<Array<InputMaybe<SitewideConfigurationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SitewideConfigurationFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  reference_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type SitewideConfigurationLinkingCollections = {
  __typename?: 'SitewideConfigurationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type SitewideConfigurationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SitewideConfigurationOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type SitewideConfigurationReference = SitewideExperiment | SitewideValues;

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideExperiment) */
export type SitewideExperiment = Entry & _Node & {
  __typename?: 'SitewideExperiment';
  _id: Scalars['ID'];
  analyticsId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  default?: Maybe<SitewideValues>;
  linkedFrom?: Maybe<SitewideExperimentLinkingCollections>;
  seed?: Maybe<Scalars['String']>;
  sys: Sys;
  variantsCollection?: Maybe<SitewideExperimentVariantsCollection>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideExperiment) */
export type SitewideExperimentAnalyticsIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideExperiment) */
export type SitewideExperimentContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideExperiment) */
export type SitewideExperimentDefaultArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SitewideValuesFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideExperiment) */
export type SitewideExperimentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideExperiment) */
export type SitewideExperimentSeedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideExperiment) */
export type SitewideExperimentVariantsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideExperimentVariantsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitewideVariantFilter>;
};

export type SitewideExperimentCollection = {
  __typename?: 'SitewideExperimentCollection';
  items: Array<Maybe<SitewideExperiment>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SitewideExperimentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SitewideExperimentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SitewideExperimentFilter>>>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  default?: InputMaybe<CfSitewideValuesNestedFilter>;
  default_exists?: InputMaybe<Scalars['Boolean']>;
  seed?: InputMaybe<Scalars['String']>;
  seed_contains?: InputMaybe<Scalars['String']>;
  seed_exists?: InputMaybe<Scalars['Boolean']>;
  seed_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seed_not?: InputMaybe<Scalars['String']>;
  seed_not_contains?: InputMaybe<Scalars['String']>;
  seed_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  variants?: InputMaybe<CfSitewideVariantNestedFilter>;
  variantsCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type SitewideExperimentLinkingCollections = {
  __typename?: 'SitewideExperimentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  sitewideConfigurationCollection?: Maybe<SitewideConfigurationCollection>;
};


export type SitewideExperimentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SitewideExperimentLinkingCollectionsSitewideConfigurationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideExperimentLinkingCollectionsSitewideConfigurationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SitewideExperimentLinkingCollectionsSitewideConfigurationCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SitewideExperimentOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type SitewideExperimentVariantsCollection = {
  __typename?: 'SitewideExperimentVariantsCollection';
  items: Array<Maybe<SitewideVariant>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SitewideExperimentVariantsCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideValues) */
export type SitewideValues = Entry & _Node & {
  __typename?: 'SitewideValues';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  customFooter?: Maybe<CustomFooter>;
  featureFlags?: Maybe<FeatureFlags>;
  linkedFrom?: Maybe<SitewideValuesLinkingCollections>;
  navigationBar?: Maybe<Navigator>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideValues) */
export type SitewideValuesContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideValues) */
export type SitewideValuesCustomFooterArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CustomFooterFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideValues) */
export type SitewideValuesFeatureFlagsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<FeatureFlagsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideValues) */
export type SitewideValuesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideValues) */
export type SitewideValuesNavigationBarArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<NavigatorFilter>;
};

export type SitewideValuesCollection = {
  __typename?: 'SitewideValuesCollection';
  items: Array<Maybe<SitewideValues>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SitewideValuesFilter = {
  AND?: InputMaybe<Array<InputMaybe<SitewideValuesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SitewideValuesFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  customFooter?: InputMaybe<CfCustomFooterNestedFilter>;
  customFooter_exists?: InputMaybe<Scalars['Boolean']>;
  featureFlags?: InputMaybe<CfFeatureFlagsNestedFilter>;
  featureFlags_exists?: InputMaybe<Scalars['Boolean']>;
  navigationBar?: InputMaybe<CfNavigatorNestedFilter>;
  navigationBar_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type SitewideValuesLinkingCollections = {
  __typename?: 'SitewideValuesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  sitewideConfigurationCollection?: Maybe<SitewideConfigurationCollection>;
  sitewideExperimentCollection?: Maybe<SitewideExperimentCollection>;
};


export type SitewideValuesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SitewideValuesLinkingCollectionsSitewideConfigurationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideValuesLinkingCollectionsSitewideConfigurationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SitewideValuesLinkingCollectionsSitewideExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideValuesLinkingCollectionsSitewideExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SitewideValuesLinkingCollectionsSitewideConfigurationCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SitewideValuesLinkingCollectionsSitewideExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SitewideValuesOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariant) */
export type SitewideVariant = Entry & _Node & {
  __typename?: 'SitewideVariant';
  _id: Scalars['ID'];
  analyticsId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SitewideVariantLinkingCollections>;
  replacementsCollection?: Maybe<SitewideVariantReplacementsCollection>;
  sys: Sys;
  trafficEndRange?: Maybe<Scalars['Int']>;
  trafficStartRange?: Maybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariant) */
export type SitewideVariantAnalyticsIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariant) */
export type SitewideVariantContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariant) */
export type SitewideVariantLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariant) */
export type SitewideVariantReplacementsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideVariantReplacementsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitewideVariantReplacementFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariant) */
export type SitewideVariantTrafficEndRangeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariant) */
export type SitewideVariantTrafficStartRangeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SitewideVariantCollection = {
  __typename?: 'SitewideVariantCollection';
  items: Array<Maybe<SitewideVariant>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SitewideVariantFilter = {
  AND?: InputMaybe<Array<InputMaybe<SitewideVariantFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SitewideVariantFilter>>>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  replacements?: InputMaybe<CfSitewideVariantReplacementNestedFilter>;
  replacementsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  trafficEndRange?: InputMaybe<Scalars['Int']>;
  trafficEndRange_exists?: InputMaybe<Scalars['Boolean']>;
  trafficEndRange_gt?: InputMaybe<Scalars['Int']>;
  trafficEndRange_gte?: InputMaybe<Scalars['Int']>;
  trafficEndRange_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficEndRange_lt?: InputMaybe<Scalars['Int']>;
  trafficEndRange_lte?: InputMaybe<Scalars['Int']>;
  trafficEndRange_not?: InputMaybe<Scalars['Int']>;
  trafficEndRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficStartRange?: InputMaybe<Scalars['Int']>;
  trafficStartRange_exists?: InputMaybe<Scalars['Boolean']>;
  trafficStartRange_gt?: InputMaybe<Scalars['Int']>;
  trafficStartRange_gte?: InputMaybe<Scalars['Int']>;
  trafficStartRange_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficStartRange_lt?: InputMaybe<Scalars['Int']>;
  trafficStartRange_lte?: InputMaybe<Scalars['Int']>;
  trafficStartRange_not?: InputMaybe<Scalars['Int']>;
  trafficStartRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type SitewideVariantLinkingCollections = {
  __typename?: 'SitewideVariantLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  sitewideExperimentCollection?: Maybe<SitewideExperimentCollection>;
};


export type SitewideVariantLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SitewideVariantLinkingCollectionsSitewideExperimentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideVariantLinkingCollectionsSitewideExperimentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SitewideVariantLinkingCollectionsSitewideExperimentCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SeedAsc = 'seed_ASC',
  SeedDesc = 'seed_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SitewideVariantOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariantReplacement) */
export type SitewideVariantReplacement = Entry & _Node & {
  __typename?: 'SitewideVariantReplacement';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SitewideVariantReplacementLinkingCollections>;
  replacement?: Maybe<SitewideVariantReplacementReplacement>;
  replacementTarget?: Maybe<SitewideVariantReplacementReplacementTarget>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariantReplacement) */
export type SitewideVariantReplacementContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariantReplacement) */
export type SitewideVariantReplacementLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariantReplacement) */
export type SitewideVariantReplacementReplacementArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/sitewideVariantReplacement) */
export type SitewideVariantReplacementReplacementTargetArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type SitewideVariantReplacementCollection = {
  __typename?: 'SitewideVariantReplacementCollection';
  items: Array<Maybe<SitewideVariantReplacement>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SitewideVariantReplacementFilter = {
  AND?: InputMaybe<Array<InputMaybe<SitewideVariantReplacementFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SitewideVariantReplacementFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  replacementTarget_exists?: InputMaybe<Scalars['Boolean']>;
  replacement_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type SitewideVariantReplacementLinkingCollections = {
  __typename?: 'SitewideVariantReplacementLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  sitewideVariantCollection?: Maybe<SitewideVariantCollection>;
};


export type SitewideVariantReplacementLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SitewideVariantReplacementLinkingCollectionsSitewideVariantCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitewideVariantReplacementLinkingCollectionsSitewideVariantCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SitewideVariantReplacementLinkingCollectionsSitewideVariantCollectionOrder {
  AnalyticsIdAsc = 'analyticsId_ASC',
  AnalyticsIdDesc = 'analyticsId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrafficEndRangeAsc = 'trafficEndRange_ASC',
  TrafficEndRangeDesc = 'trafficEndRange_DESC',
  TrafficStartRangeAsc = 'trafficStartRange_ASC',
  TrafficStartRangeDesc = 'trafficStartRange_DESC'
}

export enum SitewideVariantReplacementOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type SitewideVariantReplacementReplacement = CallToAction | CustomFooter | FeatureFlags | FooterCookiesSettingsLink | FooterGroup | FooterItem | FooterLocaleDropdown | NavigationCallToAction | Navigator | NavigatorItem;

export type SitewideVariantReplacementReplacementTarget = CallToAction | CustomFooter | FeatureFlags | FooterCookiesSettingsLink | FooterGroup | FooterItem | FooterLocaleDropdown | NavigationCallToAction | Navigator | NavigatorItem;

export type SitewideVariantReplacementsCollection = {
  __typename?: 'SitewideVariantReplacementsCollection';
  items: Array<Maybe<SitewideVariantReplacement>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SitewideVariantReplacementsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type Slug = Entry & _Node & {
  __typename?: 'Slug';
  _id: Scalars['ID'];
  author?: Maybe<Author>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  displayDate?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<SlugLinkingCollections>;
  page?: Maybe<SlugPage>;
  postedDate?: Maybe<Scalars['DateTime']>;
  shareable?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slugType?: Maybe<Scalars['String']>;
  sys: Sys;
  unlisted?: Maybe<Scalars['Boolean']>;
  unlistedCriteria?: Maybe<Criteria>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AuthorFilter>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugPageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugPostedDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugShareableArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugSlugTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugUnlistedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Slug is the starting point to create a page. Add a Slug to create a Page component with Blocks. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/slug) */
export type SlugUnlistedCriteriaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CriteriaFilter>;
};

export type SlugCollection = {
  __typename?: 'SlugCollection';
  items: Array<Maybe<Slug>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SlugFilter = {
  AND?: InputMaybe<Array<InputMaybe<SlugFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SlugFilter>>>;
  author?: InputMaybe<CfAuthorNestedFilter>;
  author_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  displayDate?: InputMaybe<Scalars['Boolean']>;
  displayDate_exists?: InputMaybe<Scalars['Boolean']>;
  displayDate_not?: InputMaybe<Scalars['Boolean']>;
  page_exists?: InputMaybe<Scalars['Boolean']>;
  postedDate?: InputMaybe<Scalars['DateTime']>;
  postedDate_exists?: InputMaybe<Scalars['Boolean']>;
  postedDate_gt?: InputMaybe<Scalars['DateTime']>;
  postedDate_gte?: InputMaybe<Scalars['DateTime']>;
  postedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  postedDate_lt?: InputMaybe<Scalars['DateTime']>;
  postedDate_lte?: InputMaybe<Scalars['DateTime']>;
  postedDate_not?: InputMaybe<Scalars['DateTime']>;
  postedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  shareable?: InputMaybe<Scalars['Boolean']>;
  shareable_exists?: InputMaybe<Scalars['Boolean']>;
  shareable_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slugType?: InputMaybe<Scalars['String']>;
  slugType_contains?: InputMaybe<Scalars['String']>;
  slugType_exists?: InputMaybe<Scalars['Boolean']>;
  slugType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slugType_not?: InputMaybe<Scalars['String']>;
  slugType_not_contains?: InputMaybe<Scalars['String']>;
  slugType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  unlisted?: InputMaybe<Scalars['Boolean']>;
  unlistedCriteria?: InputMaybe<CfCriteriaNestedFilter>;
  unlistedCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  unlisted_exists?: InputMaybe<Scalars['Boolean']>;
  unlisted_not?: InputMaybe<Scalars['Boolean']>;
};

export type SlugLinkingCollections = {
  __typename?: 'SlugLinkingCollections';
  carouselCardCollection?: Maybe<CarouselCardCollection>;
  carouselTextCollection?: Maybe<CarouselTextCollection>;
  entryCollection?: Maybe<EntryCollection>;
  redirectCollection?: Maybe<RedirectCollection>;
  templateCollection?: Maybe<TemplateCollection>;
  tileCollection?: Maybe<TileCollection>;
};


export type SlugLinkingCollectionsCarouselCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SlugLinkingCollectionsCarouselCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SlugLinkingCollectionsCarouselTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SlugLinkingCollectionsCarouselTextCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SlugLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SlugLinkingCollectionsRedirectCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SlugLinkingCollectionsRedirectCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SlugLinkingCollectionsTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SlugLinkingCollectionsTemplateCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SlugLinkingCollectionsTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SlugLinkingCollectionsTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SlugLinkingCollectionsCarouselCardCollectionOrder {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ShowShadowAsc = 'showShadow_ASC',
  ShowShadowDesc = 'showShadow_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum SlugLinkingCollectionsCarouselTextCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum SlugLinkingCollectionsRedirectCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  IsPermanentAsc = 'isPermanent_ASC',
  IsPermanentDesc = 'isPermanent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SlugLinkingCollectionsTemplateCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SlugLinkingCollectionsTileCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  ImageFitAsc = 'imageFit_ASC',
  ImageFitDesc = 'imageFit_DESC',
  ImageSizeAsc = 'imageSize_ASC',
  ImageSizeDesc = 'imageSize_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum SlugOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  PostedDateAsc = 'postedDate_ASC',
  PostedDateDesc = 'postedDate_DESC',
  ShareableAsc = 'shareable_ASC',
  ShareableDesc = 'shareable_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SlugTypeAsc = 'slugType_ASC',
  SlugTypeDesc = 'slugType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnlistedAsc = 'unlisted_ASC',
  UnlistedDesc = 'unlisted_DESC'
}

export type SlugPage = Experiment | Page | PageAsset | Redirect;

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/snapchatEmbed) */
export type SnapchatEmbed = Entry & _Node & {
  __typename?: 'SnapchatEmbed';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SnapchatEmbedLinkingCollections>;
  sys: Sys;
  url?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/snapchatEmbed) */
export type SnapchatEmbedContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/snapchatEmbed) */
export type SnapchatEmbedLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/snapchatEmbed) */
export type SnapchatEmbedUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SnapchatEmbedCollection = {
  __typename?: 'SnapchatEmbedCollection';
  items: Array<Maybe<SnapchatEmbed>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SnapchatEmbedFilter = {
  AND?: InputMaybe<Array<InputMaybe<SnapchatEmbedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SnapchatEmbedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SnapchatEmbedLinkingCollections = {
  __typename?: 'SnapchatEmbedLinkingCollections';
  blockHeroCollection?: Maybe<BlockHeroCollection>;
  contentCollection?: Maybe<ContentCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type SnapchatEmbedLinkingCollectionsBlockHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SnapchatEmbedLinkingCollectionsBlockHeroCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SnapchatEmbedLinkingCollectionsContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SnapchatEmbedLinkingCollectionsContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SnapchatEmbedLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SnapchatEmbedLinkingCollectionsBlockHeroCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaLayoutAsc = 'backgroundMediaLayout_ASC',
  BackgroundMediaLayoutDesc = 'backgroundMediaLayout_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CurtainOpacityPercentageAsc = 'curtainOpacityPercentage_ASC',
  CurtainOpacityPercentageDesc = 'curtainOpacityPercentage_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  IsHeaderDateAsc = 'isHeaderDate_ASC',
  IsHeaderDateDesc = 'isHeaderDate_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  ShowMediaMobileAsc = 'showMediaMobile_ASC',
  ShowMediaMobileDesc = 'showMediaMobile_DESC',
  ShowScrollButtonAsc = 'showScrollButton_ASC',
  ShowScrollButtonDesc = 'showScrollButton_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextAlignMobileAsc = 'textAlignMobile_ASC',
  TextAlignMobileDesc = 'textAlignMobile_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UseLatestPostAsc = 'useLatestPost_ASC',
  UseLatestPostDesc = 'useLatestPost_DESC',
  VerticalTextAlignAsc = 'verticalTextAlign_ASC',
  VerticalTextAlignDesc = 'verticalTextAlign_DESC'
}

export enum SnapchatEmbedLinkingCollectionsContentCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BodyAlignmentAsc = 'bodyAlignment_ASC',
  BodyAlignmentDesc = 'bodyAlignment_DESC',
  BodyAlignmentMobileAsc = 'bodyAlignmentMobile_ASC',
  BodyAlignmentMobileDesc = 'bodyAlignmentMobile_DESC',
  CollapseMobileTableAsc = 'collapseMobileTable_ASC',
  CollapseMobileTableDesc = 'collapseMobileTable_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayOnAsc = 'displayOn_ASC',
  DisplayOnDesc = 'displayOn_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowFullResolutionMediaAsc = 'showFullResolutionMedia_ASC',
  ShowFullResolutionMediaDesc = 'showFullResolutionMedia_DESC',
  ShowVideoControlsAsc = 'showVideoControls_ASC',
  ShowVideoControlsDesc = 'showVideoControls_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  UseHeadingTagsForBodyAsc = 'useHeadingTagsForBody_ASC',
  UseHeadingTagsForBodyDesc = 'useHeadingTagsForBody_DESC'
}

export enum SnapchatEmbedOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlock = Entry & _Node & {
  __typename?: 'SplitBlock';
  _id: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  body?: Maybe<SplitBlockBody>;
  callsToActionCollection?: Maybe<SplitBlockCallsToActionCollection>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  fitWindow?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<SplitBlockLinkingCollections>;
  media?: Maybe<SplitBlockMedia>;
  mediaCaption?: Maybe<Scalars['String']>;
  mediaDirection?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  sys: Sys;
  textAlignment?: Maybe<Scalars['String']>;
  textAlignmentMobile?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  verticalTextAlignment?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockCallsToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SplitBlockCallsToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CallToActionFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockFitWindowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockMediaCaptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockMediaDirectionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockTextAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockTextAlignmentMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/splitBlock) */
export type SplitBlockVerticalTextAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SplitBlockBody = {
  __typename?: 'SplitBlockBody';
  json: Scalars['JSON'];
  links?: SplitBlockBodyLinks;
};

export type SplitBlockBodyAssets = {
  __typename?: 'SplitBlockBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type SplitBlockBodyEntries = {
  __typename?: 'SplitBlockBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type SplitBlockBodyLinks = {
  __typename?: 'SplitBlockBodyLinks';
  assets: SplitBlockBodyAssets;
  entries: SplitBlockBodyEntries;
  resources: SplitBlockBodyResources;
};

export type SplitBlockBodyResources = {
  __typename?: 'SplitBlockBodyResources';
  block: Array<SplitBlockBodyResourcesBlock>;
  hyperlink: Array<SplitBlockBodyResourcesHyperlink>;
  inline: Array<SplitBlockBodyResourcesInline>;
};

export type SplitBlockBodyResourcesBlock = ResourceLink & {
  __typename?: 'SplitBlockBodyResourcesBlock';
  sys: ResourceSys;
};

export type SplitBlockBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'SplitBlockBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type SplitBlockBodyResourcesInline = ResourceLink & {
  __typename?: 'SplitBlockBodyResourcesInline';
  sys: ResourceSys;
};

export type SplitBlockCallsToActionCollection = {
  __typename?: 'SplitBlockCallsToActionCollection';
  items: Array<Maybe<CallToAction>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SplitBlockCallsToActionCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SetTypeAsc = 'setType_ASC',
  SetTypeDesc = 'setType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type SplitBlockCollection = {
  __typename?: 'SplitBlockCollection';
  items: Array<Maybe<SplitBlock>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SplitBlockFilter = {
  AND?: InputMaybe<Array<InputMaybe<SplitBlockFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SplitBlockFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  callsToAction?: InputMaybe<CfCallToActionNestedFilter>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fitWindow?: InputMaybe<Scalars['Boolean']>;
  fitWindow_exists?: InputMaybe<Scalars['Boolean']>;
  fitWindow_not?: InputMaybe<Scalars['Boolean']>;
  mediaCaption?: InputMaybe<Scalars['String']>;
  mediaCaption_contains?: InputMaybe<Scalars['String']>;
  mediaCaption_exists?: InputMaybe<Scalars['Boolean']>;
  mediaCaption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaCaption_not?: InputMaybe<Scalars['String']>;
  mediaCaption_not_contains?: InputMaybe<Scalars['String']>;
  mediaCaption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaDirection?: InputMaybe<Scalars['String']>;
  mediaDirection_contains?: InputMaybe<Scalars['String']>;
  mediaDirection_exists?: InputMaybe<Scalars['Boolean']>;
  mediaDirection_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaDirection_not?: InputMaybe<Scalars['String']>;
  mediaDirection_not_contains?: InputMaybe<Scalars['String']>;
  mediaDirection_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  textAlignment?: InputMaybe<Scalars['String']>;
  textAlignmentMobile?: InputMaybe<Scalars['String']>;
  textAlignmentMobile_contains?: InputMaybe<Scalars['String']>;
  textAlignmentMobile_exists?: InputMaybe<Scalars['Boolean']>;
  textAlignmentMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlignmentMobile_not?: InputMaybe<Scalars['String']>;
  textAlignmentMobile_not_contains?: InputMaybe<Scalars['String']>;
  textAlignmentMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlignment_contains?: InputMaybe<Scalars['String']>;
  textAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  textAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlignment_not?: InputMaybe<Scalars['String']>;
  textAlignment_not_contains?: InputMaybe<Scalars['String']>;
  textAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  verticalTextAlignment?: InputMaybe<Scalars['String']>;
  verticalTextAlignment_contains?: InputMaybe<Scalars['String']>;
  verticalTextAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  verticalTextAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  verticalTextAlignment_not?: InputMaybe<Scalars['String']>;
  verticalTextAlignment_not_contains?: InputMaybe<Scalars['String']>;
  verticalTextAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SplitBlockLinkingCollections = {
  __typename?: 'SplitBlockLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type SplitBlockLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SplitBlockLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SplitBlockLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SplitBlockLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type SplitBlockMedia = Image | Video;

export enum SplitBlockOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  MediaCaptionAsc = 'mediaCaption_ASC',
  MediaCaptionDesc = 'mediaCaption_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignmentAsc = 'textAlignment_ASC',
  TextAlignmentDesc = 'textAlignment_DESC',
  TextAlignmentMobileAsc = 'textAlignmentMobile_ASC',
  TextAlignmentMobileDesc = 'textAlignmentMobile_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VerticalTextAlignmentAsc = 'verticalTextAlignment_ASC',
  VerticalTextAlignmentDesc = 'verticalTextAlignment_DESC'
}

/** This model hold all details of the Sub Navigation Component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigation) */
export type SubNavigation = Entry & _Node & {
  __typename?: 'SubNavigation';
  _id: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SubNavigationLinkingCollections>;
  subNavigationItemsCollection?: Maybe<SubNavigationSubNavigationItemsCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** This model hold all details of the Sub Navigation Component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigation) */
export type SubNavigationBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** This model hold all details of the Sub Navigation Component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigation) */
export type SubNavigationContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** This model hold all details of the Sub Navigation Component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigation) */
export type SubNavigationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** This model hold all details of the Sub Navigation Component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigation) */
export type SubNavigationSubNavigationItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SubNavigationSubNavigationItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubNavigationItemFilter>;
};


/** This model hold all details of the Sub Navigation Component [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigation) */
export type SubNavigationTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SubNavigationCollection = {
  __typename?: 'SubNavigationCollection';
  items: Array<Maybe<SubNavigation>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SubNavigationFilter = {
  AND?: InputMaybe<Array<InputMaybe<SubNavigationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SubNavigationFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  subNavigationItems?: InputMaybe<CfSubNavigationItemNestedFilter>;
  subNavigationItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigationItem) */
export type SubNavigationItem = Entry & _Node & {
  __typename?: 'SubNavigationItem';
  _id: Scalars['ID'];
  anchorId?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SubNavigationItemLinkingCollections>;
  sys: Sys;
  text?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigationItem) */
export type SubNavigationItemAnchorIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigationItem) */
export type SubNavigationItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigationItem) */
export type SubNavigationItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/subNavigationItem) */
export type SubNavigationItemTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SubNavigationItemCollection = {
  __typename?: 'SubNavigationItemCollection';
  items: Array<Maybe<SubNavigationItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SubNavigationItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<SubNavigationItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SubNavigationItemFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubNavigationItemLinkingCollections = {
  __typename?: 'SubNavigationItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  subNavigationCollection?: Maybe<SubNavigationCollection>;
};


export type SubNavigationItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SubNavigationItemLinkingCollectionsSubNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SubNavigationItemLinkingCollectionsSubNavigationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SubNavigationItemLinkingCollectionsSubNavigationCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum SubNavigationItemOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC'
}

export type SubNavigationLinkingCollections = {
  __typename?: 'SubNavigationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type SubNavigationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SubNavigationLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SubNavigationLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SubNavigationLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum SubNavigationOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type SubNavigationSubNavigationItemsCollection = {
  __typename?: 'SubNavigationSubNavigationItemsCollection';
  items: Array<Maybe<SubNavigationItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SubNavigationSubNavigationItemsCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC'
}

/** By default submit button is positioned below the form. Use this button to place submit button within a form row. Must be a child of Form Row. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/submitButton) */
export type SubmitButton = Entry & _Node & {
  __typename?: 'SubmitButton';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<SubmitButtonLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** By default submit button is positioned below the form. Use this button to place submit button within a form row. Must be a child of Form Row. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/submitButton) */
export type SubmitButtonContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** By default submit button is positioned below the form. Use this button to place submit button within a form row. Must be a child of Form Row. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/submitButton) */
export type SubmitButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** By default submit button is positioned below the form. Use this button to place submit button within a form row. Must be a child of Form Row. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/submitButton) */
export type SubmitButtonNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SubmitButtonCollection = {
  __typename?: 'SubmitButtonCollection';
  items: Array<Maybe<SubmitButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SubmitButtonFilter = {
  AND?: InputMaybe<Array<InputMaybe<SubmitButtonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SubmitButtonFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type SubmitButtonLinkingCollections = {
  __typename?: 'SubmitButtonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  formRowCollection?: Maybe<FormRowCollection>;
};


export type SubmitButtonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SubmitButtonLinkingCollectionsFormRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SubmitButtonLinkingCollectionsFormRowCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SubmitButtonLinkingCollectionsFormRowCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SubmitButtonOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Sys = {
  __typename?: 'Sys';
  environmentId?: Scalars['String'];
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  /** The locale that was requested. */
  locale?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  spaceId?: Scalars['String'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  id?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_exists?: InputMaybe<Scalars['Boolean']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedVersion?: InputMaybe<Scalars['Float']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  publishedVersion_lt?: InputMaybe<Scalars['Float']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabItem) */
export type TabItem = Entry & _Node & {
  __typename?: 'TabItem';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  button?: Maybe<Button>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  contentsCollection?: Maybe<TabItemContentsCollection>;
  linkedFrom?: Maybe<TabItemLinkingCollections>;
  maxColumns?: Maybe<Scalars['Int']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabItem) */
export type TabItemAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabItem) */
export type TabItemButtonArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ButtonFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabItem) */
export type TabItemContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabItem) */
export type TabItemContentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TabItemContentsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabItem) */
export type TabItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabItem) */
export type TabItemMaxColumnsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabItem) */
export type TabItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TabItemCollection = {
  __typename?: 'TabItemCollection';
  items: Array<Maybe<TabItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TabItemContentsCollection = {
  __typename?: 'TabItemContentsCollection';
  items: Array<Maybe<TabItemContentsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TabItemContentsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TabItemContentsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TabItemContentsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type TabItemContentsItem = Content | Form;

export type TabItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<TabItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TabItemFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  button?: InputMaybe<CfButtonNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contents?: InputMaybe<CfcontentsMultiTypeNestedFilter>;
  contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  maxColumns?: InputMaybe<Scalars['Int']>;
  maxColumns_exists?: InputMaybe<Scalars['Boolean']>;
  maxColumns_gt?: InputMaybe<Scalars['Int']>;
  maxColumns_gte?: InputMaybe<Scalars['Int']>;
  maxColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxColumns_lt?: InputMaybe<Scalars['Int']>;
  maxColumns_lte?: InputMaybe<Scalars['Int']>;
  maxColumns_not?: InputMaybe<Scalars['Int']>;
  maxColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TabItemLinkingCollections = {
  __typename?: 'TabItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  tabsCollection?: Maybe<TabsCollection>;
};


export type TabItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabItemLinkingCollectionsTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TabItemLinkingCollectionsTabsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TabItemLinkingCollectionsTabsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum TabItemOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** SDS-M table that is connected to a data series (Chart upload) [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tableVisualization) */
export type TableVisualization = Entry & _Node & {
  __typename?: 'TableVisualization';
  _id: Scalars['ID'];
  chartData?: Maybe<ChartData>;
  chartTitle?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  enableSearch?: Maybe<Scalars['Boolean']>;
  initialRowCount?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<TableVisualizationLinkingCollections>;
  sys: Sys;
  tableColumns?: Maybe<Scalars['JSON']>;
};


/** SDS-M table that is connected to a data series (Chart upload) [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tableVisualization) */
export type TableVisualizationChartDataArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ChartDataFilter>;
};


/** SDS-M table that is connected to a data series (Chart upload) [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tableVisualization) */
export type TableVisualizationChartTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** SDS-M table that is connected to a data series (Chart upload) [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tableVisualization) */
export type TableVisualizationContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** SDS-M table that is connected to a data series (Chart upload) [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tableVisualization) */
export type TableVisualizationEnableSearchArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** SDS-M table that is connected to a data series (Chart upload) [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tableVisualization) */
export type TableVisualizationInitialRowCountArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** SDS-M table that is connected to a data series (Chart upload) [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tableVisualization) */
export type TableVisualizationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** SDS-M table that is connected to a data series (Chart upload) [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tableVisualization) */
export type TableVisualizationTableColumnsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TableVisualizationCollection = {
  __typename?: 'TableVisualizationCollection';
  items: Array<Maybe<TableVisualization>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TableVisualizationFilter = {
  AND?: InputMaybe<Array<InputMaybe<TableVisualizationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TableVisualizationFilter>>>;
  chartData?: InputMaybe<CfChartDataNestedFilter>;
  chartData_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  enableSearch?: InputMaybe<Scalars['Boolean']>;
  enableSearch_exists?: InputMaybe<Scalars['Boolean']>;
  enableSearch_not?: InputMaybe<Scalars['Boolean']>;
  initialRowCount?: InputMaybe<Scalars['Int']>;
  initialRowCount_exists?: InputMaybe<Scalars['Boolean']>;
  initialRowCount_gt?: InputMaybe<Scalars['Int']>;
  initialRowCount_gte?: InputMaybe<Scalars['Int']>;
  initialRowCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  initialRowCount_lt?: InputMaybe<Scalars['Int']>;
  initialRowCount_lte?: InputMaybe<Scalars['Int']>;
  initialRowCount_not?: InputMaybe<Scalars['Int']>;
  initialRowCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  tableColumns_exists?: InputMaybe<Scalars['Boolean']>;
};

export type TableVisualizationLinkingCollections = {
  __typename?: 'TableVisualizationLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
  visualizationSelectorCollection?: Maybe<VisualizationSelectorCollection>;
};


export type TableVisualizationLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TableVisualizationLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TableVisualizationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TableVisualizationLinkingCollectionsVisualizationSelectorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TableVisualizationLinkingCollectionsVisualizationSelectorCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TableVisualizationLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum TableVisualizationLinkingCollectionsVisualizationSelectorCollectionOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum TableVisualizationOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EnableSearchAsc = 'enableSearch_ASC',
  EnableSearchDesc = 'enableSearch_DESC',
  InitialRowCountAsc = 'initialRowCount_ASC',
  InitialRowCountDesc = 'initialRowCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabs) */
export type Tabs = Entry & _Node & {
  __typename?: 'Tabs';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  itemsCollection?: Maybe<TabsItemsCollection>;
  linkedFrom?: Maybe<TabsLinkingCollections>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabs) */
export type TabsContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabs) */
export type TabsItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TabsItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TabItemFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tabs) */
export type TabsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TabsCollection = {
  __typename?: 'TabsCollection';
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TabsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TabsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TabsFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  items?: InputMaybe<CfTabItemNestedFilter>;
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type TabsItemsCollection = {
  __typename?: 'TabsItemsCollection';
  items: Array<Maybe<TabItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TabsItemsCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type TabsLinkingCollections = {
  __typename?: 'TabsLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type TabsLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TabsLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TabsLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum TabsOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * Represents a taxonomy concept entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type TaxonomyConcept = {
  __typename?: 'TaxonomyConcept';
  id?: Maybe<Scalars['String']>;
};

/** A template for page layouts and other re-usable content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/template) */
export type Template = Entry & _Node & {
  __typename?: 'Template';
  _id: Scalars['ID'];
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  duplicationSettings?: Maybe<Scalars['JSON']>;
  linkedFrom?: Maybe<TemplateLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Asset>;
  referenceEntry?: Maybe<Slug>;
  sys: Sys;
};


/** A template for page layouts and other re-usable content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/template) */
export type TemplateContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A template for page layouts and other re-usable content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/template) */
export type TemplateDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A template for page layouts and other re-usable content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/template) */
export type TemplateDuplicationSettingsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A template for page layouts and other re-usable content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/template) */
export type TemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** A template for page layouts and other re-usable content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/template) */
export type TemplateNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** A template for page layouts and other re-usable content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/template) */
export type TemplatePreviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** A template for page layouts and other re-usable content. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/template) */
export type TemplateReferenceEntryArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SlugFilter>;
};

export type TemplateCollection = {
  __typename?: 'TemplateCollection';
  items: Array<Maybe<Template>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TemplateFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  duplicationSettings_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  previewImage_exists?: InputMaybe<Scalars['Boolean']>;
  referenceEntry?: InputMaybe<CfSlugNestedFilter>;
  referenceEntry_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type TemplateLinkingCollections = {
  __typename?: 'TemplateLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type TemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TemplateOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type Tile = Entry & _Node & {
  __typename?: 'Tile';
  _id: Scalars['ID'];
  analytics?: Maybe<Analytics>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  date?: Maybe<Scalars['DateTime']>;
  imageFit?: Maybe<Scalars['String']>;
  imageSize?: Maybe<Scalars['String']>;
  label?: Maybe<TileLabel>;
  linkedFrom?: Maybe<TileLinkingCollections>;
  media?: Maybe<TileMedia>;
  secondaryMedia?: Maybe<Image>;
  slugReference?: Maybe<Slug>;
  sys: Sys;
  title?: Maybe<TileTitle>;
  url?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileAnalyticsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AnalyticsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileImageFitArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileImageSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileSecondaryMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ImageFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileSlugReferenceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SlugFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/tile) */
export type TileUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TileCollection = {
  __typename?: 'TileCollection';
  items: Array<Maybe<Tile>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TileFilter = {
  AND?: InputMaybe<Array<InputMaybe<TileFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TileFilter>>>;
  analytics?: InputMaybe<CfAnalyticsNestedFilter>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  date?: InputMaybe<Scalars['DateTime']>;
  date_exists?: InputMaybe<Scalars['Boolean']>;
  date_gt?: InputMaybe<Scalars['DateTime']>;
  date_gte?: InputMaybe<Scalars['DateTime']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']>;
  date_lte?: InputMaybe<Scalars['DateTime']>;
  date_not?: InputMaybe<Scalars['DateTime']>;
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  imageFit?: InputMaybe<Scalars['String']>;
  imageFit_contains?: InputMaybe<Scalars['String']>;
  imageFit_exists?: InputMaybe<Scalars['Boolean']>;
  imageFit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageFit_not?: InputMaybe<Scalars['String']>;
  imageFit_not_contains?: InputMaybe<Scalars['String']>;
  imageFit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageSize?: InputMaybe<Scalars['String']>;
  imageSize_contains?: InputMaybe<Scalars['String']>;
  imageSize_exists?: InputMaybe<Scalars['Boolean']>;
  imageSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageSize_not?: InputMaybe<Scalars['String']>;
  imageSize_not_contains?: InputMaybe<Scalars['String']>;
  imageSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryMedia?: InputMaybe<CfImageNestedFilter>;
  secondaryMedia_exists?: InputMaybe<Scalars['Boolean']>;
  slugReference?: InputMaybe<CfSlugNestedFilter>;
  slugReference_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TileLabel = {
  __typename?: 'TileLabel';
  json: Scalars['JSON'];
  links?: TileLabelLinks;
};

export type TileLabelAssets = {
  __typename?: 'TileLabelAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TileLabelEntries = {
  __typename?: 'TileLabelEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TileLabelLinks = {
  __typename?: 'TileLabelLinks';
  assets: TileLabelAssets;
  entries: TileLabelEntries;
  resources: TileLabelResources;
};

export type TileLabelResources = {
  __typename?: 'TileLabelResources';
  block: Array<TileLabelResourcesBlock>;
  hyperlink: Array<TileLabelResourcesHyperlink>;
  inline: Array<TileLabelResourcesInline>;
};

export type TileLabelResourcesBlock = ResourceLink & {
  __typename?: 'TileLabelResourcesBlock';
  sys: ResourceSys;
};

export type TileLabelResourcesHyperlink = ResourceLink & {
  __typename?: 'TileLabelResourcesHyperlink';
  sys: ResourceSys;
};

export type TileLabelResourcesInline = ResourceLink & {
  __typename?: 'TileLabelResourcesInline';
  sys: ResourceSys;
};

export type TileLinkingCollections = {
  __typename?: 'TileLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  galleryCollection?: Maybe<GalleryCollection>;
};


export type TileLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TileLinkingCollectionsGalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TileLinkingCollectionsGalleryCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TileLinkingCollectionsGalleryCollectionOrder {
  ClearFiltersCtaCopyAsc = 'clearFiltersCtaCopy_ASC',
  ClearFiltersCtaCopyDesc = 'clearFiltersCtaCopy_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EmptyPlaceholderAsc = 'emptyPlaceholder_ASC',
  EmptyPlaceholderDesc = 'emptyPlaceholder_DESC',
  HideFiltersAsc = 'hideFilters_ASC',
  HideFiltersDesc = 'hideFilters_DESC',
  HidePaginationAsc = 'hidePagination_ASC',
  HidePaginationDesc = 'hidePagination_DESC',
  ItemsPerPageAsc = 'itemsPerPage_ASC',
  ItemsPerPageDesc = 'itemsPerPage_DESC',
  LoadMoreCtaCopyAsc = 'loadMoreCtaCopy_ASC',
  LoadMoreCtaCopyDesc = 'loadMoreCtaCopy_DESC',
  MobileFiltersWrapperTextAsc = 'mobileFiltersWrapperText_ASC',
  MobileFiltersWrapperTextDesc = 'mobileFiltersWrapperText_DESC',
  ShowDatesAsc = 'showDates_ASC',
  ShowDatesDesc = 'showDates_DESC',
  ShowDescriptionsAsc = 'showDescriptions_ASC',
  ShowDescriptionsDesc = 'showDescriptions_DESC',
  ShowMediaAsc = 'showMedia_ASC',
  ShowMediaDesc = 'showMedia_DESC',
  SortByAsc = 'sortBy_ASC',
  SortByDesc = 'sortBy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type TileMedia = Image | Video;

export enum TileOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  ImageFitAsc = 'imageFit_ASC',
  ImageFitDesc = 'imageFit_DESC',
  ImageSizeAsc = 'imageSize_ASC',
  ImageSizeDesc = 'imageSize_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type TileTitle = {
  __typename?: 'TileTitle';
  json: Scalars['JSON'];
  links?: TileTitleLinks;
};

export type TileTitleAssets = {
  __typename?: 'TileTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TileTitleEntries = {
  __typename?: 'TileTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TileTitleLinks = {
  __typename?: 'TileTitleLinks';
  assets: TileTitleAssets;
  entries: TileTitleEntries;
  resources: TileTitleResources;
};

export type TileTitleResources = {
  __typename?: 'TileTitleResources';
  block: Array<TileTitleResourcesBlock>;
  hyperlink: Array<TileTitleResourcesHyperlink>;
  inline: Array<TileTitleResourcesInline>;
};

export type TileTitleResourcesBlock = ResourceLink & {
  __typename?: 'TileTitleResourcesBlock';
  sys: ResourceSys;
};

export type TileTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'TileTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type TileTitleResourcesInline = ResourceLink & {
  __typename?: 'TileTitleResourcesInline';
  sys: ResourceSys;
};

/** Metadata about the model of the content. Current purpose is to link to the migration scripts that have been run on this environment. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/version) */
export type Version = Entry & _Node & {
  __typename?: 'Version';
  _id: Scalars['ID'];
  contentfulMetadata?: ContentfulMetadata;
  lastMigration?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<VersionLinkingCollections>;
  migrations?: Maybe<Array<Maybe<Scalars['String']>>>;
  sys: Sys;
  version?: Maybe<Scalars['String']>;
};


/** Metadata about the model of the content. Current purpose is to link to the migration scripts that have been run on this environment. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/version) */
export type VersionLastMigrationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Metadata about the model of the content. Current purpose is to link to the migration scripts that have been run on this environment. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/version) */
export type VersionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Metadata about the model of the content. Current purpose is to link to the migration scripts that have been run on this environment. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/version) */
export type VersionMigrationsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Metadata about the model of the content. Current purpose is to link to the migration scripts that have been run on this environment. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/version) */
export type VersionVersionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type VersionCollection = {
  __typename?: 'VersionCollection';
  items: Array<Maybe<Version>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VersionFilter = {
  AND?: InputMaybe<Array<InputMaybe<VersionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  lastMigration?: InputMaybe<Scalars['String']>;
  lastMigration_contains?: InputMaybe<Scalars['String']>;
  lastMigration_exists?: InputMaybe<Scalars['Boolean']>;
  lastMigration_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastMigration_not?: InputMaybe<Scalars['String']>;
  lastMigration_not_contains?: InputMaybe<Scalars['String']>;
  lastMigration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  migrations_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  migrations_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  migrations_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  migrations_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  version?: InputMaybe<Scalars['String']>;
  version_contains?: InputMaybe<Scalars['String']>;
  version_exists?: InputMaybe<Scalars['Boolean']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  version_not?: InputMaybe<Scalars['String']>;
  version_not_contains?: InputMaybe<Scalars['String']>;
  version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VersionLinkingCollections = {
  __typename?: 'VersionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type VersionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum VersionOrder {
  LastMigrationAsc = 'lastMigration_ASC',
  LastMigrationDesc = 'lastMigration_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC'
}

/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type Video = Entry & _Node & {
  __typename?: 'Video';
  _id: Scalars['ID'];
  autoPlay?: Maybe<Scalars['Boolean']>;
  captions?: Maybe<Asset>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  externalId?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<VideoLinkingCollections>;
  media?: Maybe<Asset>;
  mobileMedia?: Maybe<Asset>;
  streamUrl?: Maybe<Scalars['String']>;
  sys: Sys;
  thumbnailMedia?: Maybe<Asset>;
  vodSyncStatus?: Maybe<Scalars['String']>;
  wrap?: Maybe<Scalars['String']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoAutoPlayArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoCaptionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoExternalIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoMobileMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoStreamUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoThumbnailMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoVodSyncStatusArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Video component wrapper for a Video asset. Must be under 20mb. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/video) */
export type VideoWrapArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type VideoCollection = {
  __typename?: 'VideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VideoFilter = {
  AND?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  autoPlay?: InputMaybe<Scalars['Boolean']>;
  autoPlay_exists?: InputMaybe<Scalars['Boolean']>;
  autoPlay_not?: InputMaybe<Scalars['Boolean']>;
  captions_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_exists?: InputMaybe<Scalars['Boolean']>;
  externalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMedia_exists?: InputMaybe<Scalars['Boolean']>;
  streamUrl?: InputMaybe<Scalars['String']>;
  streamUrl_contains?: InputMaybe<Scalars['String']>;
  streamUrl_exists?: InputMaybe<Scalars['Boolean']>;
  streamUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  streamUrl_not?: InputMaybe<Scalars['String']>;
  streamUrl_not_contains?: InputMaybe<Scalars['String']>;
  streamUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  thumbnailMedia_exists?: InputMaybe<Scalars['Boolean']>;
  vodSyncStatus?: InputMaybe<Scalars['String']>;
  vodSyncStatus_contains?: InputMaybe<Scalars['String']>;
  vodSyncStatus_exists?: InputMaybe<Scalars['Boolean']>;
  vodSyncStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  vodSyncStatus_not?: InputMaybe<Scalars['String']>;
  vodSyncStatus_not_contains?: InputMaybe<Scalars['String']>;
  vodSyncStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wrap?: InputMaybe<Scalars['String']>;
  wrap_contains?: InputMaybe<Scalars['String']>;
  wrap_exists?: InputMaybe<Scalars['Boolean']>;
  wrap_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wrap_not?: InputMaybe<Scalars['String']>;
  wrap_not_contains?: InputMaybe<Scalars['String']>;
  wrap_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VideoLinkingCollections = {
  __typename?: 'VideoLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  blockHeroCollection?: Maybe<BlockHeroCollection>;
  carouselCardCollection?: Maybe<CarouselCardCollection>;
  contentCollection?: Maybe<ContentCollection>;
  entryCollection?: Maybe<EntryCollection>;
  mosaicTileCollection?: Maybe<MosaicTileCollection>;
  multiVideoBlockCollection?: Maybe<MultiVideoBlockCollection>;
  pageCollection?: Maybe<PageCollection>;
  splitBlockCollection?: Maybe<SplitBlockCollection>;
  tileCollection?: Maybe<TileCollection>;
};


export type VideoLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsBlockHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsBlockHeroCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsCarouselCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsCarouselCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsMosaicTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsMosaicTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsMultiVideoBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsMultiVideoBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsSplitBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsSplitBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VideoLinkingCollectionsTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum VideoLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum VideoLinkingCollectionsBlockHeroCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaLayoutAsc = 'backgroundMediaLayout_ASC',
  BackgroundMediaLayoutDesc = 'backgroundMediaLayout_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  CurtainOpacityPercentageAsc = 'curtainOpacityPercentage_ASC',
  CurtainOpacityPercentageDesc = 'curtainOpacityPercentage_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  IsHeaderDateAsc = 'isHeaderDate_ASC',
  IsHeaderDateDesc = 'isHeaderDate_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  ShowMediaMobileAsc = 'showMediaMobile_ASC',
  ShowMediaMobileDesc = 'showMediaMobile_DESC',
  ShowScrollButtonAsc = 'showScrollButton_ASC',
  ShowScrollButtonDesc = 'showScrollButton_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextAlignMobileAsc = 'textAlignMobile_ASC',
  TextAlignMobileDesc = 'textAlignMobile_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UseLatestPostAsc = 'useLatestPost_ASC',
  UseLatestPostDesc = 'useLatestPost_DESC',
  VerticalTextAlignAsc = 'verticalTextAlign_ASC',
  VerticalTextAlignDesc = 'verticalTextAlign_DESC'
}

export enum VideoLinkingCollectionsCarouselCardCollectionOrder {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ShowShadowAsc = 'showShadow_ASC',
  ShowShadowDesc = 'showShadow_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum VideoLinkingCollectionsContentCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BodyAlignmentAsc = 'bodyAlignment_ASC',
  BodyAlignmentDesc = 'bodyAlignment_DESC',
  BodyAlignmentMobileAsc = 'bodyAlignmentMobile_ASC',
  BodyAlignmentMobileDesc = 'bodyAlignmentMobile_DESC',
  CollapseMobileTableAsc = 'collapseMobileTable_ASC',
  CollapseMobileTableDesc = 'collapseMobileTable_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DisplayOnAsc = 'displayOn_ASC',
  DisplayOnDesc = 'displayOn_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  MediaWrapAsc = 'mediaWrap_ASC',
  MediaWrapDesc = 'mediaWrap_DESC',
  ShowFullResolutionMediaAsc = 'showFullResolutionMedia_ASC',
  ShowFullResolutionMediaDesc = 'showFullResolutionMedia_DESC',
  ShowVideoControlsAsc = 'showVideoControls_ASC',
  ShowVideoControlsDesc = 'showVideoControls_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  UseHeadingTagsForBodyAsc = 'useHeadingTagsForBody_ASC',
  UseHeadingTagsForBodyDesc = 'useHeadingTagsForBody_DESC'
}

export enum VideoLinkingCollectionsMosaicTileCollectionOrder {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  HighlightAsc = 'highlight_ASC',
  HighlightDesc = 'highlight_DESC',
  MobileColumnsAsc = 'mobileColumns_ASC',
  MobileColumnsDesc = 'mobileColumns_DESC',
  MobileRowsAsc = 'mobileRows_ASC',
  MobileRowsDesc = 'mobileRows_DESC',
  RowsAsc = 'rows_ASC',
  RowsDesc = 'rows_DESC',
  ShowOverlayAsc = 'showOverlay_ASC',
  ShowOverlayDesc = 'showOverlay_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum VideoLinkingCollectionsMultiVideoBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  PanelPositionAsc = 'panelPosition_ASC',
  PanelPositionDesc = 'panelPosition_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum VideoLinkingCollectionsPageCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundMediaStyleAsc = 'backgroundMediaStyle_ASC',
  BackgroundMediaStyleDesc = 'backgroundMediaStyle_DESC',
  CollapseFootnotesAsc = 'collapseFootnotes_ASC',
  CollapseFootnotesDesc = 'collapseFootnotes_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FooterViewAsc = 'footerView_ASC',
  FooterViewDesc = 'footerView_DESC',
  HeaderViewAsc = 'headerView_ASC',
  HeaderViewDesc = 'headerView_DESC',
  ScrollSnapAsc = 'scrollSnap_ASC',
  ScrollSnapDesc = 'scrollSnap_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum VideoLinkingCollectionsSplitBlockCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  FitWindowAsc = 'fitWindow_ASC',
  FitWindowDesc = 'fitWindow_DESC',
  MediaCaptionAsc = 'mediaCaption_ASC',
  MediaCaptionDesc = 'mediaCaption_DESC',
  MediaDirectionAsc = 'mediaDirection_ASC',
  MediaDirectionDesc = 'mediaDirection_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignmentAsc = 'textAlignment_ASC',
  TextAlignmentDesc = 'textAlignment_DESC',
  TextAlignmentMobileAsc = 'textAlignmentMobile_ASC',
  TextAlignmentMobileDesc = 'textAlignmentMobile_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VerticalTextAlignmentAsc = 'verticalTextAlignment_ASC',
  VerticalTextAlignmentDesc = 'verticalTextAlignment_DESC'
}

export enum VideoLinkingCollectionsTileCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  ImageFitAsc = 'imageFit_ASC',
  ImageFitDesc = 'imageFit_DESC',
  ImageSizeAsc = 'imageSize_ASC',
  ImageSizeDesc = 'imageSize_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export enum VideoOrder {
  AutoPlayAsc = 'autoPlay_ASC',
  AutoPlayDesc = 'autoPlay_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  StreamUrlAsc = 'streamUrl_ASC',
  StreamUrlDesc = 'streamUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VodSyncStatusAsc = 'vodSyncStatus_ASC',
  VodSyncStatusDesc = 'vodSyncStatus_DESC',
  WrapAsc = 'wrap_ASC',
  WrapDesc = 'wrap_DESC'
}

/** Component used to switch between different visualizations for the same set of data. Includes date filters and highlight cards that will show sums of different data points. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/visualizationSelector) */
export type VisualizationSelector = Entry & _Node & {
  __typename?: 'VisualizationSelector';
  _id: Scalars['ID'];
  chartDataCollection?: Maybe<VisualizationSelectorChartDataCollection>;
  chartTitle?: Maybe<Scalars['String']>;
  contentfulDescription?: Maybe<Scalars['String']>;
  contentfulMetadata?: ContentfulMetadata;
  linkedFrom?: Maybe<VisualizationSelectorLinkingCollections>;
  selectableFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  seriesName?: Maybe<Series>;
  sys: Sys;
  visualizationsCollection?: Maybe<VisualizationSelectorVisualizationsCollection>;
};


/** Component used to switch between different visualizations for the same set of data. Includes date filters and highlight cards that will show sums of different data points. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/visualizationSelector) */
export type VisualizationSelectorChartDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VisualizationSelectorChartDataCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChartDataFilter>;
};


/** Component used to switch between different visualizations for the same set of data. Includes date filters and highlight cards that will show sums of different data points. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/visualizationSelector) */
export type VisualizationSelectorChartTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component used to switch between different visualizations for the same set of data. Includes date filters and highlight cards that will show sums of different data points. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/visualizationSelector) */
export type VisualizationSelectorContentfulDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component used to switch between different visualizations for the same set of data. Includes date filters and highlight cards that will show sums of different data points. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/visualizationSelector) */
export type VisualizationSelectorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component used to switch between different visualizations for the same set of data. Includes date filters and highlight cards that will show sums of different data points. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/visualizationSelector) */
export type VisualizationSelectorSelectableFieldsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component used to switch between different visualizations for the same set of data. Includes date filters and highlight cards that will show sums of different data points. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/visualizationSelector) */
export type VisualizationSelectorSeriesNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SeriesFilter>;
};


/** Component used to switch between different visualizations for the same set of data. Includes date filters and highlight cards that will show sums of different data points. [See type definition](https://app.contentful.com/spaces/akh6d57cjeed/content_types/visualizationSelector) */
export type VisualizationSelectorVisualizationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VisualizationSelectorVisualizationsFilter>;
};

export type VisualizationSelectorChartDataCollection = {
  __typename?: 'VisualizationSelectorChartDataCollection';
  items: Array<Maybe<ChartData>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum VisualizationSelectorChartDataCollectionOrder {
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type VisualizationSelectorCollection = {
  __typename?: 'VisualizationSelectorCollection';
  items: Array<Maybe<VisualizationSelector>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VisualizationSelectorFilter = {
  AND?: InputMaybe<Array<InputMaybe<VisualizationSelectorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VisualizationSelectorFilter>>>;
  chartData?: InputMaybe<CfChartDataNestedFilter>;
  chartDataCollection_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  selectableFields_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectableFields_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectableFields_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectableFields_exists?: InputMaybe<Scalars['Boolean']>;
  seriesName?: InputMaybe<CfSeriesNestedFilter>;
  seriesName_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  visualizations?: InputMaybe<CfvisualizationsMultiTypeNestedFilter>;
  visualizationsCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type VisualizationSelectorLinkingCollections = {
  __typename?: 'VisualizationSelectorLinkingCollections';
  blockCollection?: Maybe<BlockCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type VisualizationSelectorLinkingCollectionsBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VisualizationSelectorLinkingCollectionsBlockCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type VisualizationSelectorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum VisualizationSelectorLinkingCollectionsBlockCollectionOrder {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  FullHeightAsc = 'fullHeight_ASC',
  FullHeightDesc = 'fullHeight_DESC',
  IsNarrowAsc = 'isNarrow_ASC',
  IsNarrowDesc = 'isNarrow_DESC',
  MaxColumnsAsc = 'maxColumns_ASC',
  MaxColumnsDesc = 'maxColumns_DESC',
  ShowCurtainAsc = 'showCurtain_ASC',
  ShowCurtainDesc = 'showCurtain_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAlignmentAsc = 'titleAlignment_ASC',
  TitleAlignmentDesc = 'titleAlignment_DESC',
  TitleAlignmentMobileAsc = 'titleAlignmentMobile_ASC',
  TitleAlignmentMobileDesc = 'titleAlignmentMobile_DESC',
  WidthStyleAsc = 'widthStyle_ASC',
  WidthStyleDesc = 'widthStyle_DESC'
}

export enum VisualizationSelectorOrder {
  ChartTitleAsc = 'chartTitle_ASC',
  ChartTitleDesc = 'chartTitle_DESC',
  ContentfulDescriptionAsc = 'contentfulDescription_ASC',
  ContentfulDescriptionDesc = 'contentfulDescription_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type VisualizationSelectorVisualizationsCollection = {
  __typename?: 'VisualizationSelectorVisualizationsCollection';
  items: Array<Maybe<VisualizationSelectorVisualizationsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VisualizationSelectorVisualizationsFilter = {
  AND?: InputMaybe<Array<InputMaybe<VisualizationSelectorVisualizationsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VisualizationSelectorVisualizationsFilter>>>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type VisualizationSelectorVisualizationsItem = BarChart | BarChartGroup | BarChartStack | GeoVisualization | LineChart | TableVisualization;

export type _Node = {
  _id: Scalars['ID'];
};

export type CfAbExperimentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAbExperimentNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAbExperimentNestedFilter>>>;
  abVariantsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  reference_exists?: InputMaybe<Scalars['Boolean']>;
  seed?: InputMaybe<Scalars['String']>;
  seed_contains?: InputMaybe<Scalars['String']>;
  seed_exists?: InputMaybe<Scalars['Boolean']>;
  seed_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seed_not?: InputMaybe<Scalars['String']>;
  seed_not_contains?: InputMaybe<Scalars['String']>;
  seed_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfAbVariantNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAbVariantNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAbVariantNestedFilter>>>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  replacement_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  trafficEndRange?: InputMaybe<Scalars['Int']>;
  trafficEndRange_exists?: InputMaybe<Scalars['Boolean']>;
  trafficEndRange_gt?: InputMaybe<Scalars['Int']>;
  trafficEndRange_gte?: InputMaybe<Scalars['Int']>;
  trafficEndRange_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficEndRange_lt?: InputMaybe<Scalars['Int']>;
  trafficEndRange_lte?: InputMaybe<Scalars['Int']>;
  trafficEndRange_not?: InputMaybe<Scalars['Int']>;
  trafficEndRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficStartRange?: InputMaybe<Scalars['Int']>;
  trafficStartRange_exists?: InputMaybe<Scalars['Boolean']>;
  trafficStartRange_gt?: InputMaybe<Scalars['Int']>;
  trafficStartRange_gte?: InputMaybe<Scalars['Int']>;
  trafficStartRange_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficStartRange_lt?: InputMaybe<Scalars['Int']>;
  trafficStartRange_lte?: InputMaybe<Scalars['Int']>;
  trafficStartRange_not?: InputMaybe<Scalars['Int']>;
  trafficStartRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type CfAccordionItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAccordionItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAccordionItemNestedFilter>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfAnalyticsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAnalyticsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAnalyticsNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfAnimatedAccordionContentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAnimatedAccordionContentNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAnimatedAccordionContentNestedFilter>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfAnimationKeyframesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAnimationKeyframesNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAnimationKeyframesNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  delay?: InputMaybe<Scalars['String']>;
  delay_contains?: InputMaybe<Scalars['String']>;
  delay_exists?: InputMaybe<Scalars['Boolean']>;
  delay_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  delay_not?: InputMaybe<Scalars['String']>;
  delay_not_contains?: InputMaybe<Scalars['String']>;
  delay_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  direction?: InputMaybe<Scalars['String']>;
  direction_contains?: InputMaybe<Scalars['String']>;
  direction_exists?: InputMaybe<Scalars['Boolean']>;
  direction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  direction_not?: InputMaybe<Scalars['String']>;
  direction_not_contains?: InputMaybe<Scalars['String']>;
  direction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  duration?: InputMaybe<Scalars['String']>;
  duration_contains?: InputMaybe<Scalars['String']>;
  duration_exists?: InputMaybe<Scalars['Boolean']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  duration_not?: InputMaybe<Scalars['String']>;
  duration_not_contains?: InputMaybe<Scalars['String']>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  iterationCount?: InputMaybe<Scalars['String']>;
  iterationCount_contains?: InputMaybe<Scalars['String']>;
  iterationCount_exists?: InputMaybe<Scalars['Boolean']>;
  iterationCount_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  iterationCount_not?: InputMaybe<Scalars['String']>;
  iterationCount_not_contains?: InputMaybe<Scalars['String']>;
  iterationCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keyframes?: InputMaybe<Scalars['String']>;
  keyframes_contains?: InputMaybe<Scalars['String']>;
  keyframes_exists?: InputMaybe<Scalars['Boolean']>;
  keyframes_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keyframes_not?: InputMaybe<Scalars['String']>;
  keyframes_not_contains?: InputMaybe<Scalars['String']>;
  keyframes_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  timingFunction?: InputMaybe<Scalars['String']>;
  timingFunction_contains?: InputMaybe<Scalars['String']>;
  timingFunction_exists?: InputMaybe<Scalars['Boolean']>;
  timingFunction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  timingFunction_not?: InputMaybe<Scalars['String']>;
  timingFunction_not_contains?: InputMaybe<Scalars['String']>;
  timingFunction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transformOrigin?: InputMaybe<Scalars['String']>;
  transformOrigin_contains?: InputMaybe<Scalars['String']>;
  transformOrigin_exists?: InputMaybe<Scalars['Boolean']>;
  transformOrigin_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transformOrigin_not?: InputMaybe<Scalars['String']>;
  transformOrigin_not_contains?: InputMaybe<Scalars['String']>;
  transformOrigin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfAuthorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAuthorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAuthorNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position?: InputMaybe<Scalars['String']>;
  position_contains?: InputMaybe<Scalars['String']>;
  position_exists?: InputMaybe<Scalars['Boolean']>;
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position_not?: InputMaybe<Scalars['String']>;
  position_not_contains?: InputMaybe<Scalars['String']>;
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBannerNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBannerNestedFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CfBarChartNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBarChartNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBarChartNestedFilter>>>;
  chartData_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dataColors_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dataColors_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dataColors_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dataColors_exists?: InputMaybe<Scalars['Boolean']>;
  filters_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters_exists?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Float']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Float']>;
  height_gte?: InputMaybe<Scalars['Float']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  height_lt?: InputMaybe<Scalars['Float']>;
  height_lte?: InputMaybe<Scalars['Float']>;
  height_not?: InputMaybe<Scalars['Float']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  hideLegend?: InputMaybe<Scalars['Boolean']>;
  hideLegend_exists?: InputMaybe<Scalars['Boolean']>;
  hideLegend_not?: InputMaybe<Scalars['Boolean']>;
  hideXAxisLabels?: InputMaybe<Scalars['Boolean']>;
  hideXAxisLabels_exists?: InputMaybe<Scalars['Boolean']>;
  hideXAxisLabels_not?: InputMaybe<Scalars['Boolean']>;
  hideXPercentLabels?: InputMaybe<Scalars['Boolean']>;
  hideXPercentLabels_exists?: InputMaybe<Scalars['Boolean']>;
  hideXPercentLabels_not?: InputMaybe<Scalars['Boolean']>;
  hideYAxisLabels?: InputMaybe<Scalars['Boolean']>;
  hideYAxisLabels_exists?: InputMaybe<Scalars['Boolean']>;
  hideYAxisLabels_not?: InputMaybe<Scalars['Boolean']>;
  numberOfDecimalPlaces?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_exists?: InputMaybe<Scalars['Boolean']>;
  numberOfDecimalPlaces_gt?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_gte?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  numberOfDecimalPlaces_lt?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_lte?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_not?: InputMaybe<Scalars['Float']>;
  numberOfDecimalPlaces_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  showAsPercent?: InputMaybe<Scalars['Boolean']>;
  showAsPercent_exists?: InputMaybe<Scalars['Boolean']>;
  showAsPercent_not?: InputMaybe<Scalars['Boolean']>;
  stackKey?: InputMaybe<Scalars['String']>;
  stackKey_contains?: InputMaybe<Scalars['String']>;
  stackKey_exists?: InputMaybe<Scalars['Boolean']>;
  stackKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stackKey_not?: InputMaybe<Scalars['String']>;
  stackKey_not_contains?: InputMaybe<Scalars['String']>;
  stackKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  width?: InputMaybe<Scalars['Float']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Float']>;
  width_gte?: InputMaybe<Scalars['Float']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  width_lt?: InputMaybe<Scalars['Float']>;
  width_lte?: InputMaybe<Scalars['Float']>;
  width_not?: InputMaybe<Scalars['Float']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  xAxes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xAxes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xAxes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xAxes_exists?: InputMaybe<Scalars['Boolean']>;
  yAxis?: InputMaybe<Scalars['String']>;
  yAxis_contains?: InputMaybe<Scalars['String']>;
  yAxis_exists?: InputMaybe<Scalars['Boolean']>;
  yAxis_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yAxis_not?: InputMaybe<Scalars['String']>;
  yAxis_not_contains?: InputMaybe<Scalars['String']>;
  yAxis_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfBlockNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBlockNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBlockNestedFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaV2_exists?: InputMaybe<Scalars['Boolean']>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  eyebrow?: InputMaybe<Scalars['String']>;
  eyebrow_contains?: InputMaybe<Scalars['String']>;
  eyebrow_exists?: InputMaybe<Scalars['Boolean']>;
  eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eyebrow_not?: InputMaybe<Scalars['String']>;
  eyebrow_not_contains?: InputMaybe<Scalars['String']>;
  eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullHeight?: InputMaybe<Scalars['Boolean']>;
  fullHeight_exists?: InputMaybe<Scalars['Boolean']>;
  fullHeight_not?: InputMaybe<Scalars['Boolean']>;
  isNarrow?: InputMaybe<Scalars['Boolean']>;
  isNarrow_exists?: InputMaybe<Scalars['Boolean']>;
  isNarrow_not?: InputMaybe<Scalars['Boolean']>;
  maxColumns?: InputMaybe<Scalars['Int']>;
  maxColumns_exists?: InputMaybe<Scalars['Boolean']>;
  maxColumns_gt?: InputMaybe<Scalars['Int']>;
  maxColumns_gte?: InputMaybe<Scalars['Int']>;
  maxColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxColumns_lt?: InputMaybe<Scalars['Int']>;
  maxColumns_lte?: InputMaybe<Scalars['Int']>;
  maxColumns_not?: InputMaybe<Scalars['Int']>;
  maxColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  showCurtain?: InputMaybe<Scalars['Boolean']>;
  showCurtain_exists?: InputMaybe<Scalars['Boolean']>;
  showCurtain_not?: InputMaybe<Scalars['Boolean']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
  theme?: InputMaybe<Scalars['String']>;
  theme_contains?: InputMaybe<Scalars['String']>;
  theme_exists?: InputMaybe<Scalars['Boolean']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme_not?: InputMaybe<Scalars['String']>;
  theme_not_contains?: InputMaybe<Scalars['String']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_contains?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_exists?: InputMaybe<Scalars['Boolean']>;
  titleAlignmentMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignmentMobile_not?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_not_contains?: InputMaybe<Scalars['String']>;
  titleAlignmentMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment_contains?: InputMaybe<Scalars['String']>;
  titleAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  titleAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleAlignment_not?: InputMaybe<Scalars['String']>;
  titleAlignment_not_contains?: InputMaybe<Scalars['String']>;
  titleAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  widthStyle?: InputMaybe<Scalars['String']>;
  widthStyle_contains?: InputMaybe<Scalars['String']>;
  widthStyle_exists?: InputMaybe<Scalars['Boolean']>;
  widthStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  widthStyle_not?: InputMaybe<Scalars['String']>;
  widthStyle_not_contains?: InputMaybe<Scalars['String']>;
  widthStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfBreakTemplateItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBreakTemplateItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBreakTemplateItemNestedFilter>>>;
  breakName?: InputMaybe<Scalars['String']>;
  breakName_contains?: InputMaybe<Scalars['String']>;
  breakName_exists?: InputMaybe<Scalars['Boolean']>;
  breakName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  breakName_not?: InputMaybe<Scalars['String']>;
  breakName_not_contains?: InputMaybe<Scalars['String']>;
  breakName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CfButtonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfButtonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfButtonNestedFilter>>>;
  buttonType?: InputMaybe<Scalars['String']>;
  buttonType_contains?: InputMaybe<Scalars['String']>;
  buttonType_exists?: InputMaybe<Scalars['Boolean']>;
  buttonType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonType_not?: InputMaybe<Scalars['String']>;
  buttonType_not_contains?: InputMaybe<Scalars['String']>;
  buttonType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  iconName?: InputMaybe<Scalars['String']>;
  iconName_contains?: InputMaybe<Scalars['String']>;
  iconName_exists?: InputMaybe<Scalars['Boolean']>;
  iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  iconName_not?: InputMaybe<Scalars['String']>;
  iconName_not_contains?: InputMaybe<Scalars['String']>;
  iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['String']>;
  size_contains?: InputMaybe<Scalars['String']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  size_not?: InputMaybe<Scalars['String']>;
  size_not_contains?: InputMaybe<Scalars['String']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  targetAsset_exists?: InputMaybe<Scalars['Boolean']>;
  theme?: InputMaybe<Scalars['String']>;
  theme_contains?: InputMaybe<Scalars['String']>;
  theme_exists?: InputMaybe<Scalars['Boolean']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme_not?: InputMaybe<Scalars['String']>;
  theme_not_contains?: InputMaybe<Scalars['String']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCallToActionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCallToActionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCallToActionNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  presentation_exists?: InputMaybe<Scalars['Boolean']>;
  setType?: InputMaybe<Scalars['String']>;
  setType_contains?: InputMaybe<Scalars['String']>;
  setType_exists?: InputMaybe<Scalars['Boolean']>;
  setType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  setType_not?: InputMaybe<Scalars['String']>;
  setType_not_contains?: InputMaybe<Scalars['String']>;
  setType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCategoryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCategoryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCategoryNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfChartDataNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfChartDataNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfChartDataNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  data_exists?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedData_exists?: InputMaybe<Scalars['Boolean']>;
  seriesName_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfChartToggleItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfChartToggleItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfChartToggleItemNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfContentAnimationNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfContentAnimationNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfContentAnimationNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  keyframes_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  target?: InputMaybe<Scalars['String']>;
  target_contains?: InputMaybe<Scalars['String']>;
  target_exists?: InputMaybe<Scalars['Boolean']>;
  target_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  target_not?: InputMaybe<Scalars['String']>;
  target_not_contains?: InputMaybe<Scalars['String']>;
  target_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCriteriaNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCriteriaNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCriteriaNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countries_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countries_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countries_exists?: InputMaybe<Scalars['Boolean']>;
  countryAction?: InputMaybe<Scalars['String']>;
  countryAction_contains?: InputMaybe<Scalars['String']>;
  countryAction_exists?: InputMaybe<Scalars['Boolean']>;
  countryAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryAction_not?: InputMaybe<Scalars['String']>;
  countryAction_not_contains?: InputMaybe<Scalars['String']>;
  countryAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deviceAction?: InputMaybe<Scalars['String']>;
  deviceAction_contains?: InputMaybe<Scalars['String']>;
  deviceAction_exists?: InputMaybe<Scalars['Boolean']>;
  deviceAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deviceAction_not?: InputMaybe<Scalars['String']>;
  deviceAction_not_contains?: InputMaybe<Scalars['String']>;
  deviceAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  devices_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  devices_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  devices_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  devices_exists?: InputMaybe<Scalars['Boolean']>;
  languageAction?: InputMaybe<Scalars['String']>;
  languageAction_contains?: InputMaybe<Scalars['String']>;
  languageAction_exists?: InputMaybe<Scalars['Boolean']>;
  languageAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languageAction_not?: InputMaybe<Scalars['String']>;
  languageAction_not_contains?: InputMaybe<Scalars['String']>;
  languageAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languages_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languages_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languages_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languages_exists?: InputMaybe<Scalars['Boolean']>;
  platformAction?: InputMaybe<Scalars['String']>;
  platformAction_contains?: InputMaybe<Scalars['String']>;
  platformAction_exists?: InputMaybe<Scalars['Boolean']>;
  platformAction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platformAction_not?: InputMaybe<Scalars['String']>;
  platformAction_not_contains?: InputMaybe<Scalars['String']>;
  platformAction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platforms_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platforms_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platforms_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  platforms_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  trafficRangeEnd?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_exists?: InputMaybe<Scalars['Boolean']>;
  trafficRangeEnd_gt?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_gte?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficRangeEnd_lt?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_lte?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_not?: InputMaybe<Scalars['Int']>;
  trafficRangeEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficRangeStart?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_exists?: InputMaybe<Scalars['Boolean']>;
  trafficRangeStart_gt?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_gte?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficRangeStart_lt?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_lte?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_not?: InputMaybe<Scalars['Int']>;
  trafficRangeStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type CfCustomFooterNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCustomFooterNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCustomFooterNestedFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  barCollection_exists?: InputMaybe<Scalars['Boolean']>;
  columnsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  logo_exists?: InputMaybe<Scalars['Boolean']>;
  socialCollection_exists?: InputMaybe<Scalars['Boolean']>;
  socialLabel?: InputMaybe<Scalars['String']>;
  socialLabel_contains?: InputMaybe<Scalars['String']>;
  socialLabel_exists?: InputMaybe<Scalars['Boolean']>;
  socialLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialLabel_not?: InputMaybe<Scalars['String']>;
  socialLabel_not_contains?: InputMaybe<Scalars['String']>;
  socialLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfDropdownFieldOptionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfDropdownFieldOptionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfDropdownFieldOptionNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_exists?: InputMaybe<Scalars['Boolean']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfExperimentArmNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfExperimentArmNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfExperimentArmNestedFilter>>>;
  abExperimentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  reference_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfExtraParametersFormFeatureNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfExtraParametersFormFeatureNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfExtraParametersFormFeatureNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  utmCampaignFieldName?: InputMaybe<Scalars['String']>;
  utmCampaignFieldName_contains?: InputMaybe<Scalars['String']>;
  utmCampaignFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmCampaignFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmCampaignFieldName_not?: InputMaybe<Scalars['String']>;
  utmCampaignFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmCampaignFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmContentFieldName?: InputMaybe<Scalars['String']>;
  utmContentFieldName_contains?: InputMaybe<Scalars['String']>;
  utmContentFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmContentFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmContentFieldName_not?: InputMaybe<Scalars['String']>;
  utmContentFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmContentFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmMediumFieldName?: InputMaybe<Scalars['String']>;
  utmMediumFieldName_contains?: InputMaybe<Scalars['String']>;
  utmMediumFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmMediumFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmMediumFieldName_not?: InputMaybe<Scalars['String']>;
  utmMediumFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmMediumFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmSourceFieldName?: InputMaybe<Scalars['String']>;
  utmSourceFieldName_contains?: InputMaybe<Scalars['String']>;
  utmSourceFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmSourceFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmSourceFieldName_not?: InputMaybe<Scalars['String']>;
  utmSourceFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmSourceFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmTermFieldName?: InputMaybe<Scalars['String']>;
  utmTermFieldName_contains?: InputMaybe<Scalars['String']>;
  utmTermFieldName_exists?: InputMaybe<Scalars['Boolean']>;
  utmTermFieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  utmTermFieldName_not?: InputMaybe<Scalars['String']>;
  utmTermFieldName_not_contains?: InputMaybe<Scalars['String']>;
  utmTermFieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFaqPageItemSchemaNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqPageItemSchemaNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqPageItemSchemaNestedFilter>>>;
  answer_contains?: InputMaybe<Scalars['String']>;
  answer_exists?: InputMaybe<Scalars['Boolean']>;
  answer_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  question?: InputMaybe<Scalars['String']>;
  question_contains?: InputMaybe<Scalars['String']>;
  question_exists?: InputMaybe<Scalars['Boolean']>;
  question_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  question_not?: InputMaybe<Scalars['String']>;
  question_not_contains?: InputMaybe<Scalars['String']>;
  question_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFeatureFlagsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFeatureFlagsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFeatureFlagsNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featureFlags_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFeaturedHeaderMenuItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFeaturedHeaderMenuItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFeaturedHeaderMenuItemNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  ctaLabel?: InputMaybe<Scalars['String']>;
  ctaLabel_contains?: InputMaybe<Scalars['String']>;
  ctaLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ctaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaLabel_not?: InputMaybe<Scalars['String']>;
  ctaLabel_not_contains?: InputMaybe<Scalars['String']>;
  ctaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFootNotesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFootNotesNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFootNotesNestedFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  displayText?: InputMaybe<Scalars['String']>;
  displayText_contains?: InputMaybe<Scalars['String']>;
  displayText_exists?: InputMaybe<Scalars['Boolean']>;
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayText_not?: InputMaybe<Scalars['String']>;
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullText_contains?: InputMaybe<Scalars['String']>;
  fullText_exists?: InputMaybe<Scalars['Boolean']>;
  fullText_not_contains?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  number_exists?: InputMaybe<Scalars['Boolean']>;
  number_gt?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
  number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  number_lt?: InputMaybe<Scalars['Int']>;
  number_lte?: InputMaybe<Scalars['Int']>;
  number_not?: InputMaybe<Scalars['Int']>;
  number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFooterGroupNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFooterGroupNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFooterGroupNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  groupKey?: InputMaybe<Scalars['String']>;
  groupKey_contains?: InputMaybe<Scalars['String']>;
  groupKey_exists?: InputMaybe<Scalars['Boolean']>;
  groupKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupKey_not?: InputMaybe<Scalars['String']>;
  groupKey_not_contains?: InputMaybe<Scalars['String']>;
  groupKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFormRowNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFormRowNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFormRowNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fieldsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  rowAsText_contains?: InputMaybe<Scalars['String']>;
  rowAsText_exists?: InputMaybe<Scalars['Boolean']>;
  rowAsText_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfGalleryDropdownItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfGalleryDropdownItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfGalleryDropdownItemNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfGalleryDropdownNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfGalleryDropdownNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfGalleryDropdownNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dropdownItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  matchAllOptionsTitle?: InputMaybe<Scalars['String']>;
  matchAllOptionsTitle_contains?: InputMaybe<Scalars['String']>;
  matchAllOptionsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  matchAllOptionsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  matchAllOptionsTitle_not?: InputMaybe<Scalars['String']>;
  matchAllOptionsTitle_not_contains?: InputMaybe<Scalars['String']>;
  matchAllOptionsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfGeoDataFilterNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfGeoDataFilterNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfGeoDataFilterNestedFilter>>>;
  breakpoint?: InputMaybe<Scalars['String']>;
  breakpoint_contains?: InputMaybe<Scalars['String']>;
  breakpoint_exists?: InputMaybe<Scalars['Boolean']>;
  breakpoint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  breakpoint_not?: InputMaybe<Scalars['String']>;
  breakpoint_not_contains?: InputMaybe<Scalars['String']>;
  breakpoint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfHeaderMenuItemGroupNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfHeaderMenuItemGroupNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfHeaderMenuItemGroupNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  column1Label?: InputMaybe<Scalars['String']>;
  column1Label_contains?: InputMaybe<Scalars['String']>;
  column1Label_exists?: InputMaybe<Scalars['Boolean']>;
  column1Label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column1Label_not?: InputMaybe<Scalars['String']>;
  column1Label_not_contains?: InputMaybe<Scalars['String']>;
  column1Label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column1MenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  column2Label?: InputMaybe<Scalars['String']>;
  column2Label_contains?: InputMaybe<Scalars['String']>;
  column2Label_exists?: InputMaybe<Scalars['Boolean']>;
  column2Label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column2Label_not?: InputMaybe<Scalars['String']>;
  column2Label_not_contains?: InputMaybe<Scalars['String']>;
  column2Label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column2MenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  column3Label?: InputMaybe<Scalars['String']>;
  column3Label_contains?: InputMaybe<Scalars['String']>;
  column3Label_exists?: InputMaybe<Scalars['Boolean']>;
  column3Label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column3Label_not?: InputMaybe<Scalars['String']>;
  column3Label_not_contains?: InputMaybe<Scalars['String']>;
  column3Label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  column3MenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featuredHeaderMenuItem_exists?: InputMaybe<Scalars['Boolean']>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfHeaderMenuItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfHeaderMenuItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfHeaderMenuItemNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfIconNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfIconNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfIconNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon?: InputMaybe<Scalars['String']>;
  icon_contains?: InputMaybe<Scalars['String']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_not?: InputMaybe<Scalars['String']>;
  icon_not_contains?: InputMaybe<Scalars['String']>;
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfImageButtonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfImageButtonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfImageButtonNestedFilter>>>;
  altText?: InputMaybe<Scalars['String']>;
  altText_contains?: InputMaybe<Scalars['String']>;
  altText_exists?: InputMaybe<Scalars['Boolean']>;
  altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  altText_not?: InputMaybe<Scalars['String']>;
  altText_not_contains?: InputMaybe<Scalars['String']>;
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type CfImageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfImageNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfImageNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMedia_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  wrap?: InputMaybe<Scalars['String']>;
  wrap_contains?: InputMaybe<Scalars['String']>;
  wrap_exists?: InputMaybe<Scalars['Boolean']>;
  wrap_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wrap_not?: InputMaybe<Scalars['String']>;
  wrap_not_contains?: InputMaybe<Scalars['String']>;
  wrap_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfMetasNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMetasNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMetasNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  customMetas_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customMetas_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customMetas_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customMetas_exists?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']>;
  ogImage_exists?: InputMaybe<Scalars['Boolean']>;
  ogVideo_exists?: InputMaybe<Scalars['Boolean']>;
  schemasCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfMosaicFiltersNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMosaicFiltersNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMosaicFiltersNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon?: InputMaybe<Scalars['String']>;
  icon_contains?: InputMaybe<Scalars['String']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_not?: InputMaybe<Scalars['String']>;
  icon_not_contains?: InputMaybe<Scalars['String']>;
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isAll?: InputMaybe<Scalars['Boolean']>;
  isAll_exists?: InputMaybe<Scalars['Boolean']>;
  isAll_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfMosaicTileNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMosaicTileNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMosaicTileNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  columns?: InputMaybe<Scalars['Int']>;
  columns_exists?: InputMaybe<Scalars['Boolean']>;
  columns_gt?: InputMaybe<Scalars['Int']>;
  columns_gte?: InputMaybe<Scalars['Int']>;
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  columns_lt?: InputMaybe<Scalars['Int']>;
  columns_lte?: InputMaybe<Scalars['Int']>;
  columns_not?: InputMaybe<Scalars['Int']>;
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  duration?: InputMaybe<Scalars['String']>;
  duration_contains?: InputMaybe<Scalars['String']>;
  duration_exists?: InputMaybe<Scalars['Boolean']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  duration_not?: InputMaybe<Scalars['String']>;
  duration_not_contains?: InputMaybe<Scalars['String']>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filtersCollection_exists?: InputMaybe<Scalars['Boolean']>;
  highlight?: InputMaybe<Scalars['String']>;
  highlight_contains?: InputMaybe<Scalars['String']>;
  highlight_exists?: InputMaybe<Scalars['Boolean']>;
  highlight_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  highlight_not?: InputMaybe<Scalars['String']>;
  highlight_not_contains?: InputMaybe<Scalars['String']>;
  highlight_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileColumns?: InputMaybe<Scalars['Int']>;
  mobileColumns_exists?: InputMaybe<Scalars['Boolean']>;
  mobileColumns_gt?: InputMaybe<Scalars['Int']>;
  mobileColumns_gte?: InputMaybe<Scalars['Int']>;
  mobileColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  mobileColumns_lt?: InputMaybe<Scalars['Int']>;
  mobileColumns_lte?: InputMaybe<Scalars['Int']>;
  mobileColumns_not?: InputMaybe<Scalars['Int']>;
  mobileColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  mobileRows?: InputMaybe<Scalars['Int']>;
  mobileRows_exists?: InputMaybe<Scalars['Boolean']>;
  mobileRows_gt?: InputMaybe<Scalars['Int']>;
  mobileRows_gte?: InputMaybe<Scalars['Int']>;
  mobileRows_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  mobileRows_lt?: InputMaybe<Scalars['Int']>;
  mobileRows_lte?: InputMaybe<Scalars['Int']>;
  mobileRows_not?: InputMaybe<Scalars['Int']>;
  mobileRows_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  preview_exists?: InputMaybe<Scalars['Boolean']>;
  rows?: InputMaybe<Scalars['Int']>;
  rows_exists?: InputMaybe<Scalars['Boolean']>;
  rows_gt?: InputMaybe<Scalars['Int']>;
  rows_gte?: InputMaybe<Scalars['Int']>;
  rows_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  rows_lt?: InputMaybe<Scalars['Int']>;
  rows_lte?: InputMaybe<Scalars['Int']>;
  rows_not?: InputMaybe<Scalars['Int']>;
  rows_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  showOverlay?: InputMaybe<Scalars['Boolean']>;
  showOverlay_exists?: InputMaybe<Scalars['Boolean']>;
  showOverlay_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  video_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfNavigatorItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfNavigatorItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfNavigatorItemNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  hideCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  navigatorItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfNavigatorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfNavigatorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfNavigatorNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  callsToActionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headerMenuItemGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  logoV2_exists?: InputMaybe<Scalars['Boolean']>;
  navigatorItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  theme?: InputMaybe<Scalars['String']>;
  theme_contains?: InputMaybe<Scalars['String']>;
  theme_exists?: InputMaybe<Scalars['Boolean']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  theme_not?: InputMaybe<Scalars['String']>;
  theme_not_contains?: InputMaybe<Scalars['String']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaStyle?: InputMaybe<Scalars['String']>;
  backgroundMediaStyle_contains?: InputMaybe<Scalars['String']>;
  backgroundMediaStyle_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundMediaStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaStyle_not?: InputMaybe<Scalars['String']>;
  backgroundMediaStyle_not_contains?: InputMaybe<Scalars['String']>;
  backgroundMediaStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundMediaV2_exists?: InputMaybe<Scalars['Boolean']>;
  banner_exists?: InputMaybe<Scalars['Boolean']>;
  blocksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  category_exists?: InputMaybe<Scalars['Boolean']>;
  collapseFootnotes?: InputMaybe<Scalars['Boolean']>;
  collapseFootnotes_exists?: InputMaybe<Scalars['Boolean']>;
  collapseFootnotes_not?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  footerView?: InputMaybe<Scalars['String']>;
  footerView_contains?: InputMaybe<Scalars['String']>;
  footerView_exists?: InputMaybe<Scalars['Boolean']>;
  footerView_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footerView_not?: InputMaybe<Scalars['String']>;
  footerView_not_contains?: InputMaybe<Scalars['String']>;
  footerView_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footnotesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  headerView?: InputMaybe<Scalars['String']>;
  headerView_contains?: InputMaybe<Scalars['String']>;
  headerView_exists?: InputMaybe<Scalars['Boolean']>;
  headerView_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerView_not?: InputMaybe<Scalars['String']>;
  headerView_not_contains?: InputMaybe<Scalars['String']>;
  headerView_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedIn_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedIn_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedIn_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizedIn_exists?: InputMaybe<Scalars['Boolean']>;
  metas_exists?: InputMaybe<Scalars['Boolean']>;
  scrollSnap?: InputMaybe<Scalars['Boolean']>;
  scrollSnap_exists?: InputMaybe<Scalars['Boolean']>;
  scrollSnap_not?: InputMaybe<Scalars['Boolean']>;
  sideNavigation_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  tileImage_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfScrollAnimatedVideoNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfScrollAnimatedVideoNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfScrollAnimatedVideoNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mp4Source_exists?: InputMaybe<Scalars['Boolean']>;
  scrubEnd?: InputMaybe<Scalars['Float']>;
  scrubEnd_exists?: InputMaybe<Scalars['Boolean']>;
  scrubEnd_gt?: InputMaybe<Scalars['Float']>;
  scrubEnd_gte?: InputMaybe<Scalars['Float']>;
  scrubEnd_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  scrubEnd_lt?: InputMaybe<Scalars['Float']>;
  scrubEnd_lte?: InputMaybe<Scalars['Float']>;
  scrubEnd_not?: InputMaybe<Scalars['Float']>;
  scrubEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  scrubStart?: InputMaybe<Scalars['Float']>;
  scrubStart_exists?: InputMaybe<Scalars['Boolean']>;
  scrubStart_gt?: InputMaybe<Scalars['Float']>;
  scrubStart_gte?: InputMaybe<Scalars['Float']>;
  scrubStart_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  scrubStart_lt?: InputMaybe<Scalars['Float']>;
  scrubStart_lte?: InputMaybe<Scalars['Float']>;
  scrubStart_not?: InputMaybe<Scalars['Float']>;
  scrubStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  sys?: InputMaybe<SysFilter>;
  videoEnd?: InputMaybe<Scalars['Float']>;
  videoEnd_exists?: InputMaybe<Scalars['Boolean']>;
  videoEnd_gt?: InputMaybe<Scalars['Float']>;
  videoEnd_gte?: InputMaybe<Scalars['Float']>;
  videoEnd_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  videoEnd_lt?: InputMaybe<Scalars['Float']>;
  videoEnd_lte?: InputMaybe<Scalars['Float']>;
  videoEnd_not?: InputMaybe<Scalars['Float']>;
  videoEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  videoStart?: InputMaybe<Scalars['Float']>;
  videoStart_exists?: InputMaybe<Scalars['Boolean']>;
  videoStart_gt?: InputMaybe<Scalars['Float']>;
  videoStart_gte?: InputMaybe<Scalars['Float']>;
  videoStart_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  videoStart_lt?: InputMaybe<Scalars['Float']>;
  videoStart_lte?: InputMaybe<Scalars['Float']>;
  videoStart_not?: InputMaybe<Scalars['Float']>;
  videoStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  webmSource_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfSeriesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeriesNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSeriesNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headerNames_exists?: InputMaybe<Scalars['Boolean']>;
  localizableProperties_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizableProperties_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizableProperties_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localizableProperties_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  schema_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfSideNavigationNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSideNavigationNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSideNavigationNestedFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mobileBackgroundColor?: InputMaybe<Scalars['String']>;
  mobileBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  mobileBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  mobileBackgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileBackgroundColor_not?: InputMaybe<Scalars['String']>;
  mobileBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  mobileBackgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  navigatorItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfSitewideValuesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSitewideValuesNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSitewideValuesNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  customFooter_exists?: InputMaybe<Scalars['Boolean']>;
  featureFlags_exists?: InputMaybe<Scalars['Boolean']>;
  navigationBar_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfSitewideVariantNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSitewideVariantNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSitewideVariantNestedFilter>>>;
  analyticsId?: InputMaybe<Scalars['String']>;
  analyticsId_contains?: InputMaybe<Scalars['String']>;
  analyticsId_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsId_not?: InputMaybe<Scalars['String']>;
  analyticsId_not_contains?: InputMaybe<Scalars['String']>;
  analyticsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  replacementsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  trafficEndRange?: InputMaybe<Scalars['Int']>;
  trafficEndRange_exists?: InputMaybe<Scalars['Boolean']>;
  trafficEndRange_gt?: InputMaybe<Scalars['Int']>;
  trafficEndRange_gte?: InputMaybe<Scalars['Int']>;
  trafficEndRange_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficEndRange_lt?: InputMaybe<Scalars['Int']>;
  trafficEndRange_lte?: InputMaybe<Scalars['Int']>;
  trafficEndRange_not?: InputMaybe<Scalars['Int']>;
  trafficEndRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficStartRange?: InputMaybe<Scalars['Int']>;
  trafficStartRange_exists?: InputMaybe<Scalars['Boolean']>;
  trafficStartRange_gt?: InputMaybe<Scalars['Int']>;
  trafficStartRange_gte?: InputMaybe<Scalars['Int']>;
  trafficStartRange_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trafficStartRange_lt?: InputMaybe<Scalars['Int']>;
  trafficStartRange_lte?: InputMaybe<Scalars['Int']>;
  trafficStartRange_not?: InputMaybe<Scalars['Int']>;
  trafficStartRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type CfSitewideVariantReplacementNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSitewideVariantReplacementNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSitewideVariantReplacementNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  replacementTarget_exists?: InputMaybe<Scalars['Boolean']>;
  replacement_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfSlugNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSlugNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSlugNestedFilter>>>;
  author_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  displayDate?: InputMaybe<Scalars['Boolean']>;
  displayDate_exists?: InputMaybe<Scalars['Boolean']>;
  displayDate_not?: InputMaybe<Scalars['Boolean']>;
  page_exists?: InputMaybe<Scalars['Boolean']>;
  postedDate?: InputMaybe<Scalars['DateTime']>;
  postedDate_exists?: InputMaybe<Scalars['Boolean']>;
  postedDate_gt?: InputMaybe<Scalars['DateTime']>;
  postedDate_gte?: InputMaybe<Scalars['DateTime']>;
  postedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  postedDate_lt?: InputMaybe<Scalars['DateTime']>;
  postedDate_lte?: InputMaybe<Scalars['DateTime']>;
  postedDate_not?: InputMaybe<Scalars['DateTime']>;
  postedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  shareable?: InputMaybe<Scalars['Boolean']>;
  shareable_exists?: InputMaybe<Scalars['Boolean']>;
  shareable_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slugType?: InputMaybe<Scalars['String']>;
  slugType_contains?: InputMaybe<Scalars['String']>;
  slugType_exists?: InputMaybe<Scalars['Boolean']>;
  slugType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slugType_not?: InputMaybe<Scalars['String']>;
  slugType_not_contains?: InputMaybe<Scalars['String']>;
  slugType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  unlisted?: InputMaybe<Scalars['Boolean']>;
  unlistedCriteria_exists?: InputMaybe<Scalars['Boolean']>;
  unlisted_exists?: InputMaybe<Scalars['Boolean']>;
  unlisted_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfSubNavigationItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSubNavigationItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSubNavigationItemNestedFilter>>>;
  anchorId?: InputMaybe<Scalars['String']>;
  anchorId_contains?: InputMaybe<Scalars['String']>;
  anchorId_exists?: InputMaybe<Scalars['Boolean']>;
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  anchorId_not?: InputMaybe<Scalars['String']>;
  anchorId_not_contains?: InputMaybe<Scalars['String']>;
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfTabItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTabItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfTabItemNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  button_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  maxColumns?: InputMaybe<Scalars['Int']>;
  maxColumns_exists?: InputMaybe<Scalars['Boolean']>;
  maxColumns_gt?: InputMaybe<Scalars['Int']>;
  maxColumns_gte?: InputMaybe<Scalars['Int']>;
  maxColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxColumns_lt?: InputMaybe<Scalars['Int']>;
  maxColumns_lte?: InputMaybe<Scalars['Int']>;
  maxColumns_not?: InputMaybe<Scalars['Int']>;
  maxColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfTileNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTileNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfTileNestedFilter>>>;
  analytics_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  date?: InputMaybe<Scalars['DateTime']>;
  date_exists?: InputMaybe<Scalars['Boolean']>;
  date_gt?: InputMaybe<Scalars['DateTime']>;
  date_gte?: InputMaybe<Scalars['DateTime']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']>;
  date_lte?: InputMaybe<Scalars['DateTime']>;
  date_not?: InputMaybe<Scalars['DateTime']>;
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  imageFit?: InputMaybe<Scalars['String']>;
  imageFit_contains?: InputMaybe<Scalars['String']>;
  imageFit_exists?: InputMaybe<Scalars['Boolean']>;
  imageFit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageFit_not?: InputMaybe<Scalars['String']>;
  imageFit_not_contains?: InputMaybe<Scalars['String']>;
  imageFit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageSize?: InputMaybe<Scalars['String']>;
  imageSize_contains?: InputMaybe<Scalars['String']>;
  imageSize_exists?: InputMaybe<Scalars['Boolean']>;
  imageSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageSize_not?: InputMaybe<Scalars['String']>;
  imageSize_not_contains?: InputMaybe<Scalars['String']>;
  imageSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryMedia_exists?: InputMaybe<Scalars['Boolean']>;
  slugReference_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfVideoNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfVideoNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfVideoNestedFilter>>>;
  autoPlay?: InputMaybe<Scalars['Boolean']>;
  autoPlay_exists?: InputMaybe<Scalars['Boolean']>;
  autoPlay_not?: InputMaybe<Scalars['Boolean']>;
  captions_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_exists?: InputMaybe<Scalars['Boolean']>;
  externalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  media_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMedia_exists?: InputMaybe<Scalars['Boolean']>;
  streamUrl?: InputMaybe<Scalars['String']>;
  streamUrl_contains?: InputMaybe<Scalars['String']>;
  streamUrl_exists?: InputMaybe<Scalars['Boolean']>;
  streamUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  streamUrl_not?: InputMaybe<Scalars['String']>;
  streamUrl_not_contains?: InputMaybe<Scalars['String']>;
  streamUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  thumbnailMedia_exists?: InputMaybe<Scalars['Boolean']>;
  vodSyncStatus?: InputMaybe<Scalars['String']>;
  vodSyncStatus_contains?: InputMaybe<Scalars['String']>;
  vodSyncStatus_exists?: InputMaybe<Scalars['Boolean']>;
  vodSyncStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  vodSyncStatus_not?: InputMaybe<Scalars['String']>;
  vodSyncStatus_not_contains?: InputMaybe<Scalars['String']>;
  vodSyncStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wrap?: InputMaybe<Scalars['String']>;
  wrap_contains?: InputMaybe<Scalars['String']>;
  wrap_exists?: InputMaybe<Scalars['Boolean']>;
  wrap_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wrap_not?: InputMaybe<Scalars['String']>;
  wrap_not_contains?: InputMaybe<Scalars['String']>;
  wrap_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfblocksMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfblocksMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfblocksMultiTypeNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CfcallsToActionMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfcallsToActionMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfcallsToActionMultiTypeNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CfcontentsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfcontentsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfcontentsMultiTypeNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CffieldsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CffieldsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CffieldsMultiTypeNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfitemsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfitemsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfitemsMultiTypeNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  criteria_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfschemasMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfschemasMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfschemasMultiTypeNestedFilter>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CfvisualizationsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfvisualizationsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfvisualizationsMultiTypeNestedFilter>>>;
  chartTitle?: InputMaybe<Scalars['String']>;
  chartTitle_contains?: InputMaybe<Scalars['String']>;
  chartTitle_exists?: InputMaybe<Scalars['Boolean']>;
  chartTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartTitle_not?: InputMaybe<Scalars['String']>;
  chartTitle_not_contains?: InputMaybe<Scalars['String']>;
  chartTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription?: InputMaybe<Scalars['String']>;
  contentfulDescription_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulDescription_not?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_contains?: InputMaybe<Scalars['String']>;
  contentfulDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};
