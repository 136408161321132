import type { DocumentNode } from 'graphql';

import { queries as accordionQueries } from '../../components/Accordion';
import { query as animatedAccordionQuery } from '../../components/AnimatedAccordion/query';
import { breakQuery } from '../../components/Break';
import { carouselV3Query } from '../../components/CarouselV3';
import { queries as contentQueries } from '../../components/Content';
import { queries as formQueries } from '../../components/Form';
import { lazyGalleryQuery } from '../../components/Gallery';
import { blockHeroQuery } from '../../components/Hero/query';
import { latestPostBlockQuery } from '../../components/LatestPostBlock';
import { query as mosaicQuery } from '../../components/Mosaic';
import { query as mvbQuery } from '../../components/MultiVideoBlock/query';
import { subNavigationQuery } from '../../components/SubNavigation/query';
import { queries as tabQueries } from '../../components/Tabs';
import { queries as barQueries } from '../../components/visualizations/BarChart';
import { chartToggleQuery } from '../../components/visualizations/ChartToggle';
import { queries as geoQueries } from '../../components/visualizations/GeoMap';
import { queries as lineQueries } from '../../components/visualizations/LineChart';
import { queries as vizQueries } from '../../components/visualizations/MultiVisualization';
import { queries as tableQueries } from '../../components/visualizations/Table';
import { query as arDownloadFormBlockQuery } from '../../sites/ar/components/ArDownloadForm/arDownloadFormBlockQuery';
import { avalonFormBlockQuery } from '../../sites/avalon/components/AvalonFormBlock/AvalonFormBlock.query';
import { avalonProductHeroQuery } from '../../sites/avalon/components/AvalonProductHero/AvalonProductHero.query';
import { avalonScrollSectionQuery } from '../../sites/avalon/components/AvalonScrollSection/AvalonScrollSection.query';
import { fixedPositionBlockQuery } from '../../sites/avalon/components/FixedPositionBlock/FixedPositionBlock.query';
import { mosaicGridBlockQuery } from '../../sites/avalon/components/MosaicGridBlock/MosaicGridBlock.query';
import { valuePropsBlockQuery } from '../../sites/avalon/components/ValuePropsBlock/ValuePropsBlock.query';
import { liveEventQuery } from '../../sites/experience/components/LiveEvent/liveEventQuery';
import { latestGalleryCardHeroQuery } from '../../sites/newsroom/components/LatestGalleryCardHero';
import { newsBlockQuery } from '../../sites/snap/components/LatestNewsBlock/LatestNewsBlock.query';
import { snapHomePageQuery } from '../../sites/snap/pages/SnapHomePage.query';

/**
 * Contentful queries that can be selected by the content type (__typename) with a variable "id"
 * being the sys id.
 */
export const contentfulTypeQueries: Record<string, DocumentNode | undefined> = {
  LatestPosts: latestPostBlockQuery,
  MultiVideoBlock: mvbQuery,
  Content: contentQueries.all,
  CarouselV3: carouselV3Query,
  Gallery: lazyGalleryQuery,
  Tabs: tabQueries.all,
  Mosaic: mosaicQuery.all,
  Form: formQueries.all,
  ChartToggle: chartToggleQuery,
  BarChart: barQueries.all,
  LineChart: lineQueries.all,
  GeoVisualization: geoQueries.all,
  VisualizationSelector: vizQueries.all,
  TableVisualization: tableQueries.all,
  Accordion: accordionQueries.all,
  BlockHero: blockHeroQuery,
  Break: breakQuery,
  SubNavigation: subNavigationQuery,
  LatestGalleryCardHero: latestGalleryCardHeroQuery,
  AnimatedAccordion: animatedAccordionQuery,
  AvalonProductHero: avalonProductHeroQuery,
  AvalonFormBlock: avalonFormBlockQuery,
  MosaicGridBlock: mosaicGridBlockQuery,
  AvalonScrollSection: avalonScrollSectionQuery,
  FixedPositionBlock: fixedPositionBlockQuery,
  ValuePropsBlock: valuePropsBlockQuery,
  ArDownloadFormBlock: arDownloadFormBlockQuery,
  LiveEvent: liveEventQuery,
  NewsBlock: newsBlockQuery,
  SnapHomePage: snapHomePageQuery,
};
