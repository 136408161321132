import { getTokensFromAssetUrl } from '@snapchat/mw-common';
import { useContext } from 'react';

import { AppContext } from '../AppContext';
import { Feature, useFeatureFlags } from '../components/FeatureFlags';
import type { ImageDataProps } from '../components/Image';
import type { VideoDataProps } from '../components/Video';
import { Config } from '../config';
import type { MediaSds } from '../types/Media';
import { parseImageMedia, parseVideoMedia } from '../utils/media';

interface MediaContainerProperties {
  media: MediaSds;
  mobileMedia: MediaSds;
}

const parseImage = (image: ImageDataProps): MediaContainerProperties => {
  return {
    media: parseImageMedia(image.media),
    mobileMedia: image.mobileMedia ? parseImageMedia(image.mobileMedia) : {},
  };
};

const parseVideo = (videoContainer: VideoDataProps, allowHls = false): MediaContainerProperties => {
  const videoMedia = videoContainer.media;
  const mobileVideoMedia = videoContainer.mobileMedia;

  const defaultContainerProps = {
    media: parseVideoMedia(videoMedia),
    mobileMedia: mobileVideoMedia ? parseVideoMedia(mobileVideoMedia) : {},
  };

  // If HLS playback is explicitly disabled (i.e. feature flag is off), we fallback to raw asset
  if (!allowHls) {
    return defaultContainerProps;
  }

  // We need to check if streaming is possible for this media. The streamURL is formed in a way that we can use it
  // to determine if the available VOD matches up with the current asset. It includes a concatenation of space, assetId,
  // file name, and file size (we refer to this concatenation as a "file key"). If all these data points match up with the
  // current asset, that means the streamUrl is an accurate and up to date transcoding of the asset. Otherwise we have to
  // fallback to the raw asset.

  const streamUrl = (videoContainer as VideoDataProps).streamUrl;

  // If streamUrl is not present, fallback to raw asset
  if (!streamUrl) {
    return defaultContainerProps;
  }

  const vodAssetHash = streamUrl.replace('https://web-platform.snap.com/vod/', '').split('/')[0];

  const { assetHash: contentfulAssetHash } = getTokensFromAssetUrl(videoMedia.url);

  // if the asset hashes match, we can use the streamUrl
  if (vodAssetHash === contentfulAssetHash) {
    const media = {
      ...parseVideoMedia(videoMedia),
      // Override video source with stream URL
      videoSource: streamUrl,
    };

    return {
      ...defaultContainerProps,
      media,
    };
  }

  // Otherwise, fallback to raw asset
  return defaultContainerProps;
};

/**
 * Takes a VideoDataProps or ImageDataProps and returns an object containing all available metadata
 * needed to render both the primary and mobile version the media. in the most optimal way.
 */
export const useParseMediaContainer = (
  mediaContainer?: VideoDataProps | ImageDataProps
): MediaContainerProperties => {
  const features = useFeatureFlags();
  const appContext = useContext(AppContext);

  const isVideo =
    mediaContainer?.__typename === 'Video' && mediaContainer.media.contentType?.startsWith('video');

  const isImage =
    mediaContainer?.__typename === 'Image' && mediaContainer.media.contentType?.startsWith('image');

  const isHlsVideo = isVideo && mediaContainer?.streamUrl?.endsWith('.m3u8');

  const hlsFeatureEnabled = features[Feature.ENABLE_HLS_PLAYBACK] === 'true';

  // In order to load hls.js as early as possible, in SSR we can set a flag when we detect an HLS video. This flag will
  // used to ensure that an hls.js script is added to the <head /> of the document before the SSR returns (see index.ejs)
  // enabling the client to load hls.js ASAP.
  if (!Config.isClient && isHlsVideo && hlsFeatureEnabled) {
    appContext.onHlsVideo();
  }

  if (!mediaContainer) {
    return {
      media: {},
      mobileMedia: {},
    };
  }

  if (isVideo) {
    const allowHls = hlsFeatureEnabled && isHlsVideo;
    return parseVideo(mediaContainer, allowHls);
  }

  if (isImage) {
    return parseImage(mediaContainer);
  }

  return {
    media: {},
    mobileMedia: {},
  };
};
